import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import FlexContainer from "../../core/containers/flex/FlexContainer";
import Typography from "../../core/typography/Typography";
import Space from "../../core/Space";
import useTheme from "@material-ui/core/styles/useTheme";
import {useBreakpointDown} from "../../../utils/hooks/mediaQuery";


export default function LineGraphDetailed({title, headerRightElement, stats, minWidth}) {

    const style = makeStyles(theme => ({
        root: {
            width: '100%',
            minWidth: minWidth || 300
        },
        lineStat: {
            padding: '0 16px'
        },
        lineBase: {
            height: 15,
            borderRadius: theme.shape.borderRadiusNormal,
            background: theme.palette.grey.shade5
        },
    }))

    const {root, lineStat, lineBase} = style()
    const theme = useTheme()
    const isDownSM = useBreakpointDown('sm')

    // const stats = [
    //     {
    //         label: 'unit',
    //         value: 1,
    //         color: 'warning'
    //     },
    //     {
    //         label: 'unit',
    //         value: 2,
    //         color: 'primary'
    //     },
    // ]

    let totalValue = 0
    stats.forEach((item => totalValue += item.value))

    return (
        <div className={root}>
            <FlexContainer alignItems={'flex-end'} justifyContent={'space-between'}>
                <Typography variant={'h6'} weight={'semibold'}>{title}</Typography>
                {headerRightElement}
            </FlexContainer>
            <Space vertical space={isDownSM ? 1.5 : 2}/>
            <div className={lineStat}>
                <FlexContainer alignItems={'flex-end'} justifyContent={'space-between'}>
                    {stats.map((item, index) => (
                        <div key={index}>
                            <Typography variant={'h5'} weight={'light'}>{item.value}
                                <Typography variant={'caption'} span>&nbsp;&nbsp; {item.label}</Typography>
                            </Typography>
                        </div>
                    ))}
                </FlexContainer>
            </div>
            <Space vertical space={isDownSM ? 0.5 : 1.5}/>
            <div className={lineBase}>
                <FlexContainer>
                    {stats.map((item, index) => (
                        <div key={index} style={{
                            width: `${item.value / totalValue * 100}%`,
                            height: '100%',
                            background: theme.palette[item.color].gradient,
                            borderRadius: item.value / totalValue * 100 === 100 ? '15px' :
                                index === 0  ? '15px 0px 0px 15px' :
                                index === stats.length -1 ? '0px 15px 15px 0px' : '',
                            transition: theme.transitions.main
                        }}> </div>
                    ))}
                </FlexContainer>
            </div>
        </div>
    )
}

import React, { Fragment } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import { isFunction } from "../../../utils/misc";
import Typography from "../typography/Typography";

export default function CheckBoxNative({
  id,
  name,
  checked,
  value,
  disabled,
  onChange,
  label,
  key,
}) {
  const theme = useTheme();
  generateCustomCSS(theme, disabled);
  return (
    <Fragment>
      <label
        className="checkbox-container"
        onClick={!disabled && isFunction(onChange) && onChange}
        key={key}
      >
        <Typography>{label}</Typography>
        <input
          type="checkbox"
          defaultChecked={checked}
          id={id}
          name={name}
          value={value}
        />
        <span className="checkbox-checkmark" />
      </label>
    </Fragment>
  );
}

const generateCustomCSS = (theme, disabled) => {
  let style = document.createElement("style");
  style.innerHTML = `
      /* The checkbox-container */
      .checkbox-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        // height: 0;
        // width: 0;
      }
      
      /* Create a custom checkbox */
      .checkbox-checkmark {
        position: absolute;
        top: -2px;
        left: 0;
        height: 24px;
        width: 24px;
        border-radius: 8px;
        background-color: ${theme.palette.grey.shade5};
      }
      
      /* On mouse-over, add a grey background color */
      .checkbox-container:hover input ~ .checkbox-checkmark {
        background-color: ${
          disabled ? theme.palette.grey.shade5 : theme.palette.grey.shade4
        };
      }
      
      /* When the checkbox is checked, add a blue background */
      .checkbox-container input:checked ~ .checkbox-checkmark {
        background: ${theme.palette.primary.gradient};
      }
      
      /* Create the checkbox-checkmark/indicator (hidden when not checked) */
      .checkbox-checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkbox-checkmark when checked */
      .checkbox-container input:checked ~ .checkbox-checkmark:after {
        display: block;
      }
      
      /* Style the checkbox-checkmark/indicator */
      .checkbox-container .checkbox-checkmark:after {
        left: 8px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
   `;
  document.getElementsByTagName("head")[0].appendChild(style);
};

export const campuses = [
  {
    id: 1,
    label: "Obrero",
    data: {},
  },
  {
    id: 6,
    label: "Mintal",
    data: {},
  },
  {
    id: 7,
    label: "Tagum",
    data: {},
  },
  {
    id: 8,
    label: "Mabini",
    data: {},
  },
];

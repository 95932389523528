import {ACADEMIC_EVALUATION_CLEAR, ACADEMIC_EVALUATION_SET} from "../types";

const init = {
    semesters: [],
}

export default function (state=init, action) {
    switch (action.type) {

        case ACADEMIC_EVALUATION_SET:
            return action.payload

        case ACADEMIC_EVALUATION_CLEAR:
            return init

        default:
            return state
    }
}
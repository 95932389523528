import thumbUpFill from "@iconify/icons-ri/thumb-up-fill";
import thumbDownFill from "@iconify/icons-ri/thumb-down-fill";
import alertFill from '@iconify/icons-ri/alert-fill';
import Typography from "../components/core/typography/Typography";
import React from "react";
import IconMini from "../components/core/icons/IconMini";

export const getGradeColor = grade => {
    return !grade ? null : grade <= 1.75 ? 'warning' : grade <= 3 ? 'success' : grade > 3 ? 'error' : null
}

export const statSerializer = value => {
    return value || '-'
}

export const gradeSerializer = (grade, decimals) => {
    decimals = decimals || 2
    const blacklist = ['CE']
    for (let i of blacklist) {
        if (grade === i) return ''
    }
    if (grade === 0) return '-'
    const parsedGrade = parseFloat(grade).toFixed(decimals || 2)
    return parsedGrade !== 'NaN' ? parsedGrade : grade
}

const remarks = {
    passed: {
        icon: thumbUpFill,
        variant: 'success'
    },
    failed: {
        icon: thumbDownFill,
        variant: 'error'
    },
    incomplete: {
        icon: alertFill,
        variant: 'warning'
    },
    enrolled: {
        // icon: briefcase2Fill,
        variant: 'default'
    },
}

export const getRemarks = remark => {
    remark = remark ? remark.toLowerCase() : ''
    remark = remark === '* enrolled' ? 'enrolled' : remark


    const color = remark in remarks ? remarks[remark].variant : 'default'

    return (
        <Typography color={color} weight={'semibold'}>
            {remark.toUpperCase()}
        </Typography>
    )
}

export const getRemarksIcon = remark => {
    remark = remark.toLowerCase()
    remark = remark === '* enrolled' ? 'enrolled' : remark
    if (!remarks[remark].icon) return null
    return (
        <IconMini icon={remarks[remark].icon} variant={remarks[remark].variant}/>
    )
}
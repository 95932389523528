import {ACCOUNTABILITY_CLEAR, ACCOUNTABILITY_SET} from "../types";

const init = {
    hasAccountability: false,
    outstandingBalance: 0
}

export default function (state = init, action) {
    switch (action.type) {

        case ACCOUNTABILITY_SET:
            return action.payload

        case ACCOUNTABILITY_CLEAR:
            return init

        default:
            return state
    }
}
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "../../components/core/typography/Typography";
import FlexContainer from "../../components/core/containers/flex/FlexContainer";
import Space from "../../components/core/Space";
import { useSelector } from "react-redux";
import Button from "../../components/core/button/Button";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../routes";
import doorLockLine from "@iconify/icons-ri/door-lock-line";
import sunCloudyLine from "@iconify/icons-ri/sun-cloudy-line";
import moonCloudyLine from "@iconify/icons-ri/moon-cloudy-line";
import logoutCircleRLine from "@iconify/icons-ri/logout-circle-r-line";
import arrowLeftLine from "@iconify/icons-ri/arrow-left-line";
import IconMini from "../../components/core/icons/IconMini";
import FlexItem from "../../components/core/containers/flex/FlexItem";
import { isDarkTheme } from "../../utils/appSetting";
import Click from "../../components/core/link/Click";
import { toggleDarkMode } from "../../redux/actions/appSetting";
import MessageDialog from "../../components/core/dialogs/MessageDialog";
import { logout } from "../../redux/actions/auth";
import { toggle } from "../../redux/actions/context";
import { useBreakpointDown } from "../../utils/hooks/mediaQuery";
import Container from "@material-ui/core/Container";
import SimplebarScrollbar from "../../components/core/containers/scrollbars/simplebar/SimplebarScrollbar";
import Dialog from "@material-ui/core/Dialog";
import ChangePassword from "../ChangePassword";
import USePLogo from "../../static/media/img/USePLogo.png";
import Switch from "react-ios-switch";
import useTheme from "@material-ui/core/styles/useTheme";

export default function ProfileDropdown({ open, onClose }) {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const isDark = isDarkTheme();
  const isDownSM = useBreakpointDown("sm");
  const isDownXS = useBreakpointDown("xs");
  const theme = useTheme();
  const styles = style();
  const maxHeight = isDownSM ? "100vh" : "calc(100vh - 70px - 8px)";

  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(
    false
  );

  const {
    studentID,
    name,
    program,
    major,
    profilePicture,
    errorLogout,
  } = useSelector((state) => ({
    studentID: state.AUTH.studentID,
    name: state.AUTH.name,
    program: state.AUTH.program,
    major: state.AUTH.major,
    profilePicture: state.AUTH.profilePicture,
    errorLogout: state.CONTEXT.toggles.errorLogout,
  }));

  const gotoProfile = () => {
    onClose();
    history.push(routes.profile);
  };
  const toggleShowLogoutConfirmation = () => {
    onClose();
    setShowLogoutConfirmation(!showLogoutConfirmation);
  };
  const openChangePassword = () => {
    onClose();
    setShowChangePasswordDialog(true);
  };
  const onCancelChangePassword = () => setShowChangePasswordDialog(false);
  const closeLogoutError = () => toggle("errorLogout", false);
  const onLogout = () =>
    logout(() => {
      history.push("/");
    });

  return (
    <div className={styles.root} style={{ maxHeight: maxHeight }}>
      <SimplebarScrollbar maxHeight={maxHeight} nodisplay={!open}>
        <div className={styles.container}>
          <Container fixed={isDownSM} disableGutters={!isDownSM}>
            {isDownSM && (
              <div className={styles.stickyTop}>
                <div className={styles.backContainer}>
                  <Space vertical space={1} />
                  <FlexContainer justifyContent={"flex-end"}>
                    <Button
                      icon={arrowLeftLine}
                      nolabel
                      onClick={onClose}
                      customStyles={{ minWidth: 0 }}
                    />
                  </FlexContainer>
                </div>
                <Space vertical space={1} />
              </div>
            )}
            <div className={styles.profileContainer}>
              <Space vertical space={4} />
              <FlexContainer direction={"column"} alignItems={"center"}>
                <img
                  src={profilePicture || USePLogo}
                  alt={"Profile"}
                  className={styles.avatar}
                />
                <Space vertical space={3} />
                <Typography
                  color={"primary"}
                  weight={"semibold"}
                  align={"center"}
                >
                  {studentID}
                </Typography>
                <Space vertical space={1} />
                <Typography size={20} weight={"semibold"} align={"center"}>
                  {name?.toUpperCase()}
                </Typography>
                <Space vertical space={1} />
                <Typography align={"center"} weight={"medium"}>
                  {program}
                </Typography>
                {major && (
                  <>
                    <Space vertical space={1} />
                    <Typography align={"center"} weight={"medium"}>
                      major in {major}
                    </Typography>
                  </>
                )}
                {pathname !== routes.updateAccount && (
                  <>
                    <Space vertical space={3} />
                    <Button variant={"primary"} onClick={gotoProfile}>
                      Profile
                    </Button>
                  </>
                )}
              </FlexContainer>
              <Space vertical space={3} />
            </div>

            <div className={styles.menuContainer}>
              <Space vertical space={1} />
              <Click onClick={toggleDarkMode}>
                <div className={styles.menuItem}>
                  <FlexContainer
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <FlexContainer alignItems={"center"}>
                      <FlexItem>
                        <IconMini
                          icon={isDark ? moonCloudyLine : sunCloudyLine}
                        />
                      </FlexItem>
                      <Space space={1.5} />
                      <Typography>Dark Mode</Typography>
                    </FlexContainer>
                    <FlexContainer
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                      classNames={[styles.darkModeSwitch]}
                    >
                      <Switch
                        checked={isDark}
                        handleColor="white"
                        offColor={theme.palette.grey.shade3}
                        onColor={theme.palette.primary.main}
                        onChange={toggleDarkMode}
                      />
                    </FlexContainer>
                  </FlexContainer>
                </div>
              </Click>
              {pathname !== routes.updateAccount && (
                <>
                  <Space vertical space={1} />
                  <Click onClick={openChangePassword}>
                    <div className={styles.menuItem}>
                      <FlexContainer alignItems={"center"}>
                        <FlexItem>
                          <IconMini icon={doorLockLine} />
                        </FlexItem>
                        <Space space={1.5} />
                        <Typography>Change Password</Typography>
                      </FlexContainer>
                    </div>
                  </Click>
                </>
              )}
              <Space vertical space={1} />
              <Click onClick={toggleShowLogoutConfirmation}>
                <div className={styles.menuItem}>
                  <FlexContainer alignItems={"center"}>
                    <FlexItem>
                      <IconMini icon={logoutCircleRLine} />
                    </FlexItem>
                    <Space space={1.5} />
                    <Typography>Logout</Typography>
                  </FlexContainer>
                </div>
              </Click>
              <Space vertical space={2} />
            </div>
          </Container>
        </div>
      </SimplebarScrollbar>
      <MessageDialog
        variant={"warning"}
        open={showLogoutConfirmation}
        icon={logoutCircleRLine}
        title={"Logout Confirmation"}
        onConfirm={onLogout}
        onClose={toggleShowLogoutConfirmation}
        description={
          "All of your session data will " +
          "be cleared upon confirmation. You need to log in again to " +
          "continue using the student portal."
        }
      />
      <MessageDialog
        variant={"error"}
        open={errorLogout}
        icon={logoutCircleRLine}
        title={"Logout Failed"}
        onConfirm={onLogout}
        onClose={closeLogoutError}
        description={`Something's not right. Please try again.`}
        onConfirmLabel={"Logout"}
      />
      <Dialog
        open={showChangePasswordDialog}
        keepMounted
        fullScreen={isDownSM}
        onClose={onCancelChangePassword}
        classes={{
          paper: styles.paper,
        }}
      >
        <div className={styles.changePasswordDialog}>
          <ChangePassword
            fullWidthButton
            showCancel
            onCancel={onCancelChangePassword}
            fullHeight={isDownXS}
          />
        </div>
        <div className={styles.changePasswordFooterBar} />
      </Dialog>
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadiusSmall,
    background: theme.palette.background,
    minHeight: 0,
    width: 348,
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh",
      width: "100%",
    },
  },
  profileContainer: {
    background: theme.palette.background,
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadiusSmall,
  },
  menuContainer: {
    background: theme.palette.background,
    borderRadius: theme.shape.borderRadiusSmall,
  },
  menuItem: {
    background: theme.palette.grey.shade5,
    padding: "8px 16px 8px 13px",
    borderRadius: theme.shape.borderRadiusSmall,
    [theme.breakpoints.down("sm")]: {
      padding: "10px 16px 10px 13px",
    },
  },
  menuItemNoHover: {
    padding: "8px 21px",
  },
  backContainer: {
    background: theme.palette.background,
    padding: "8px 16px",
    borderRadius: theme.shape.borderRadiusSmall,
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 0",
    },
  },
  container: {
    paddingBottom: 8,
    paddingTop: 8,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadiusSmall,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stickyTop: {
    position: "-webkit-sticky",
    width: "100%",
    top: 0,
    background: theme.palette.background,
    zIndex: 2,
    "&": {
      position: "sticky",
    },
  },
  changePasswordDialog: {
    minWidth: 350,
    minHeight: 0,
    padding: theme.spacing(5),
    background: theme.palette.background,
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 48px - 25px)",
      padding: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
  },
  changePasswordFooterBar: {
    background: theme.palette.primary.gradient,
    width: "100%",
    height: 25,
  },
  avatar: {
    height: 90,
    width: 90,
    borderRadius: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 100,
      width: 100,
    },
  },
  darkModeSwitch: {
    transform: "scale(0.75)",
    marginRight: -20,
  },
  paper: {
    boxShadow: isDarkTheme() && `0px 0px 8px ${theme.palette.grey.shade3}`,
  },
}));

import React from "react";
import ConfettiGenerator from "confetti-js";
import makeStyles from "@material-ui/core/styles/makeStyles";

const confettiSettings = (offset, respawn) => {
  return {
    target: "confetti-holder",
    max: "300",
    size: "1",
    animate: true,
    props: ["circle", "square", "triangle", "line"],
    colors: [
      [165, 104, 246],
      [230, 61, 135],
      [0, 199, 228],
      [253, 214, 126],
    ],
    clock: "20",
    rotate: true,
    width: window.innerWidth,
    height: window.innerHeight - offset,
    start_from_edge: true,
    respawn: respawn || true,
  };
};

const generateConfetti = (offset, respawn) => {
  return new ConfettiGenerator(confettiSettings(offset, respawn));
};

export const renderConfetti = (offset, timeout, respawn) => {
  try {
    document.getElementById("confetti-holder").style.transition =
      "opacity 0s ease-in-out";
    document.getElementById("confetti-holder").style.opacity = 1;
    setTimeout(() => {
      document.getElementById("confetti-holder").style.transition =
        "opacity 5s ease-in-out";
      document.getElementById("confetti-holder").style.opacity = 0;
    }, timeout * 1000);
    const confetti = generateConfetti(offset, respawn);
    confetti.render();
  } catch (e) {}
};

export default function Confetti({ show, offset = 0, children }) {
  const { root } = style();
  return (
    <canvas
      id={"confetti-holder"}
      className={root}
      style={{
        marginTop: offset,
      }}
    >
      {children}
    </canvas>
  );
}

const style = makeStyles((theme) => ({
  root: {
    position: "absolute",
    transition: ".5s fadeOut",
  },
}));

import React, { Fragment, useEffect } from "react";
import ReactHelmet from "../../../components/core/Helmet";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import Typography from "../../../components/core/typography/Typography";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import Space from "../../../components/core/Space";
import Button from "../../../components/core/button/Button";
import refreshLine from "@iconify/icons-ri/refresh-line";
import Select from "../../../components/core/input/Select";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { getRemarks, gradeSerializer } from "../../../utils/grades";
import {
  clearAcademicEvaluationData,
  getAcademicEvaluation,
  reEvaluate,
} from "../../../redux/actions/programEvaluation";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../../utils/date";
import { setContext } from "../../../redux/actions/context";
import Table from "../../../components/core/table/Table";
import { useHistory } from "react-router";
import MessageDialog from "../../../components/core/dialogs/MessageDialog";
import { routes } from "../../../routes";
import { getEvaluationStatus } from "../../../redux/actions/universityServicesEvaluation";
import arrowLeftLine from "@iconify/icons-ri/arrow-left-line";

export default function ProgramEvaluationFull() {
  const styles = style();
  const history = useHistory();
  const isDownSM = useBreakpointDown("sm");
  const emptyDataHeight = "calc(100vh - 265px)";
  const maxHeight = "calc(100vh - 180px)";

  const {
    academicEvaluationSemester,
    isGettingAcademicEvaluation,
    evaluationChecked,
    isReEvaluatingAcademicEvaluation,
    hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction,

    // ADDED - Course Evaluation
    hasCompletedCourseEvaluation,

    univServicesEvaluationIsCheckingEvaluationStatus,
    semesters,
    academicEvaluationData,
  } = useSelector((state) => ({
    academicEvaluationSemester: state.CONTEXT.academicEvaluationSemester,
    isGettingAcademicEvaluation:
      state.CONTEXT.toggles.isGettingAcademicEvaluation,
    evaluationChecked: state.CONTEXT.toggles.evaluationChecked,
    isReEvaluatingAcademicEvaluation:
      state.CONTEXT.toggles.isReEvaluatingAcademicEvaluation,
    hasCompletedFacultyEvaluation:
      state.CONTEXT.toggles.hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction:
      state.CONTEXT.toggles.hasCompletedStudentSatisfaction,

      // ADDED - Course Evaluation
      hasCompletedCourseEvaluation :
        state.CONTEXT.toggles.hasCompletedCourseEvaluation ,
  
    univServicesEvaluationIsCheckingEvaluationStatus:
      state.CONTEXT.toggles.univServicesEvaluationIsCheckingEvaluationStatus,
    semesters: state.ACADEMIC_EVALUATION.semesters,
    academicEvaluationData: state.ACADEMIC_EVALUATION,
  }));

  let semesterCourses = [];
  if (academicEvaluationSemester === "all")
    semesters.forEach((item) => {
      semesterCourses = semesterCourses.concat(academicEvaluationData[item.id]);
    });
  else semesterCourses = academicEvaluationData[academicEvaluationSemester];

  useEffect(() => {
    if (!evaluationChecked) getEvaluationStatus();
  }, [evaluationChecked]);

  useEffect(() => {
    if (
      semesters.length < 1 &&
      hasCompletedFacultyEvaluation &&
      hasCompletedStudentSatisfaction
      
      &&
      hasCompletedCourseEvaluation 
    )
      getAcademicEvaluation();
  }, [
    hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction,

    hasCompletedCourseEvaluation, 

    semesters.length,
  ]);

  const exitFullscreen = () => {
    history.goBack();
  };

  const changeSemester = (id) => {
    // if (id === "all") clearAcademicEvaluationData();
    setContext("academicEvaluationSemester", id);
  };
  const goToFacultyEvaluation = () => {
    history.push(routes.universityServicesEvaluationFaculty);
  };
  const goToStudentSatisfaction = () => {
    history.push(routes.universityServicesEvaluationStudentSatisfaction);
  };

  // ADDED - Subject Evaluation
  const goToSubjectEvaluation = () => {
    history.push(routes.universityServicesEvaluationSubject);
  };


  // ADDED - Course Evaluation
  const goToCourseEvaluation = () => {
    history.push(routes.universityServicesEvaluationCourseEvaluation);
  };

  const goBack = () => {
    history.goBack();
  };

  const semestersList = [
    {
      id: "all",
      label: "Show All",
      data: {},
    },
  ].concat(semesters);

  const evaluationColumns = [
    {
      label: "Year / Term",
      align: "left",
      minWidth: 180,
      hidden: academicEvaluationSemester !== "all",
    },
    {
      label: "Code",
      align: "left",
      weight: "semibold",
      noWrap: true,
    },
    {
      label: "Title",
      align: "left",
      minWidth: 250,
    },
    {
      label: "Lec Units",
      align: "center",
    },
    {
      label: "Lab Units",
      align: "center",
    },
    {
      label: "Credit Units",
      align: "center",
    },
    {
      label: "Final",
      align: "center",
      weight: "semibold",
      wrapper: gradeSerializer,
    },
    {
      label: "Re Exam",
      align: "center",
      weight: "semibold",
      wrapper: gradeSerializer,
    },
    {
      label: "Remarks",
      align: "center",
      wrapper: getRemarks,
    },
    {
      label: "Credited Units",
      align: "center",
    },
    {
      label: "Pre Requisites",
      align: "center",
    },
    {
      label: "Pre Requisites Remarks",
      align: "center",
    },
    {
      label: "Equivalent",
      align: "center",
    },
    {
      label: "Year Standing",
      align: "center",
    },
    {
      label: "Academic Year / Term Taken",
      align: "center",
      minWidth: 200,
    },
    {
      label: "Year Level Taken",
      align: "center",
      minWidth: 100,
    },
    {
      label: "Taken from Other School",
      align: "center",
      minWidth: 150,
    },
    {
      label: "Date Entered",
      align: "left",
      minWidth: 200,
      wrapper: formatDateTime,
    },
    {
      label: "Date Posted",
      align: "left",
      minWidth: 200,
      wrapper: formatDateTime,
    },
  ];

  const evaluationData = [];
  if (semesterCourses && semesterCourses.length > 0) {
    for (let i of semesterCourses) {
      evaluationData.push([
        i.semester,
        i.courseCode,
        i.courseTitle,
        i.lecUnits,
        i.labUnits,
        i.creditUnits,
        i.final,
        i.reExam,
        i.remarks,
        i.creditedUnits,
        i.prereq,
        i.prereqPassed,
        i.equivalent,
        i.yearStanding,
        i.AYTermTaken,
        i.yearLevelTaken,
        i.takenFromOtherSchool,
        i.dateEntered,
        i.datePosted,
      ]);
    }
  }

  return (
    <div>
      <ReactHelmet title={"Program Evaluation"} />
      {!isDownSM ? (
        <Fragment>
          <FlexContainer justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant={"h5"} weight={"semibold"}>
              Academic Program Evaluation
            </Typography>
            <FlexItem>
              <FlexContainer alignItems={"center"}>
                <Space space={3} />
                <Select
                  value={academicEvaluationSemester}
                  onSelect={changeSemester}
                  items={semestersList}
                  placeholder={"Year & Term"}
                  width={250}
                />
                <Space space={2} />
                <Button
                  variant={"warning"}
                  icon={refreshLine}
                  isLoading={
                    isGettingAcademicEvaluation ||
                    univServicesEvaluationIsCheckingEvaluationStatus ||
                    isReEvaluatingAcademicEvaluation
                  }
                  onClick={reEvaluate}
                >
                  <Typography color={"white"} nowrap>
                    Re-Evaluate
                  </Typography>
                </Button>
                <Space space={2} />
                <Button icon={arrowLeftLine} onClick={exitFullscreen}>
                  Back
                </Button>
              </FlexContainer>
            </FlexItem>
          </FlexContainer>
        </Fragment>
      ) : (
        <Fragment>
          <Typography variant={"h5"} weight={"semibold"} align={"left"}>
            Academic Program Evaluation
          </Typography>
          <Space vertical space={3} />
          <Select
            value={academicEvaluationSemester}
            onSelect={changeSemester}
            items={semestersList}
            placeholder={"Year & Term"}
          />
          <Space vertical space={2} />
          <FlexContainer direction={"column"} alignItems={"center"}>
            <FlexItem classNames={[styles.headingItem]}>
              <Button
                variant={"warning"}
                icon={refreshLine}
                isLoading={
                  isGettingAcademicEvaluation ||
                  univServicesEvaluationIsCheckingEvaluationStatus ||
                  isReEvaluatingAcademicEvaluation
                }
                onClick={reEvaluate}
                fullwidth
              >
                <Typography color={"white"} nowrap>
                  Re-Evaluate
                </Typography>
              </Button>
            </FlexItem>
            <FlexItem classNames={[styles.headingItem]}>
              <Button icon={arrowLeftLine} onClick={exitFullscreen} fullwidth>
                Back
              </Button>
            </FlexItem>
          </FlexContainer>
        </Fragment>
      )}
      <Space vertical space={4} />
      <Table
        columns={evaluationColumns}
        data={evaluationData}
        initSortIndex={0}
        scrollY={evaluationData.length > 0}
        maxHeight={maxHeight}
        emptyDataHeight={emptyDataHeight}
      />
      <Space vertical space={4} />

      <MessageDialog
        open={
          (!hasCompletedFacultyEvaluation ||
            !hasCompletedStudentSatisfaction
          
            ||
            !hasCompletedCourseEvaluation )
              &&  
          evaluationChecked
        }
        variant={"primary"}
        title={
          !hasCompletedFacultyEvaluation
            ? "Faculty Evaluation"
            : !hasCompletedStudentSatisfaction ? "Student Satisfaction" : "Course Evaluation"
        }
        onCloseLabel={"Back"}
        onClose={goBack}
        description={`
            Please complete the 
            ${
              !hasCompletedFacultyEvaluation
                ? "Faculty Evaluation"
                : !hasCompletedStudentSatisfaction ? "Student Satisfaction" : "Course Evaluation"
            } first to see Academic Program Evaluation.
         `}
        onConfirmLabel={
          !hasCompletedFacultyEvaluation
            ? "Faculty Evaluation"
            : !hasCompletedStudentSatisfaction ? "Student Satisfaction" : "Course Evaluation"
        }
        onConfirm={
          !hasCompletedFacultyEvaluation
            ? goToFacultyEvaluation
            : !hasCompletedStudentSatisfaction ? goToStudentSatisfaction : goToSubjectEvaluation
        }
      />
    </div>
  );
}

const style = makeStyles((theme) => ({
  headingItem: {
    marginBottom: theme.spacing(1.5),
    width: "100%",
  },
}));

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../layout/Header";
import SimplebarScrollbar from "../../components/core/containers/scrollbars/simplebar/SimplebarScrollbar";
import Container from "@material-ui/core/Container";
import Footer from "../../layout/Footer";
import BannerImage from "../../static/media/img/banner.jpg";
import Space from "../../components/core/Space";
import Typography from "../../components/core/typography/Typography";
import ReactHelmet from "../../components/core/Helmet";
import FlexContainer from "../../components/core/containers/flex/FlexContainer";
import USePLogo from "../../static/media/img/USePLogo.png";
import { useSelector } from "react-redux";
import ChangePassword, { passwordMeter } from "../../layout/ChangePassword";
import FlexItem from "../../components/core/containers/flex/FlexItem";
import IconMini from "../../components/core/icons/IconMini";
import Card from "../../components/custom/card/Card";
import Button from "../../components/core/button/Button";
import Input from "../../components/core/input/Input";
import LineBreak from "../../components/core/LineBreak";
import NoticeContainer from "../../components/core/containers/NoticeContainer";
import Select from "../../components/core/input/Select";
import checkLine from "@iconify/icons-ri/check-line";
import { setForm } from "../../redux/actions/context";
import { isEmail, isMobileNumber } from "../../utils/string";
import LoadingComponents from "../../components/core/loader/LoadingComponents";
import ErrorLoad from "../../components/custom/ErrorLoad";
import {
  getSecurityQuestions,
  setAuthData,
  updateAccountDetails,
} from "../../redux/actions/auth";
import MessageDialog from "../../components/core/dialogs/MessageDialog";
import { useBreakpointDown } from "../../utils/hooks/mediaQuery";
import ChangePasswordForm from "./ChangePasswordForm";
import ContactDetailsForm from "./ContactDetailsForm";
import SecurityQuestionForm from "./SecurityQuestionForm";
import Success from "./Success";
import { useForm } from "react-hook-form";
import useTheme from "@material-ui/core/styles/useTheme";
import zxcvbn from "zxcvbn";
import Checkbox from "../../components/core/input/Checkbox";

export default function UpdateAccount() {
  const {
    profilePicture,
    firstName,
    securityQuestions,
    studentID,
    campusID,
    passwordCanBeSubmitted,
    isGettingSecurityQuestions,
    errorToGetSecurityQuestions,
    isUpdatingAccountDetails,
  } = useSelector((state) => ({
    profilePicture: state.AUTH.profilePicture,
    firstName: state.AUTH.firstName,
    securityQuestions: state.AUTH.securityQuestions,
    studentID: state.AUTH.studentID,
    campusID: state.AUTH.campusID,
    passwordCanBeSubmitted: state.CONTEXT.toggles.passwordCanBeSubmitted,
    isGettingSecurityQuestions:
      state.CONTEXT.toggles.isGettingSecurityQuestions,
    errorToGetSecurityQuestions:
      state.CONTEXT.toggles.errorToGetSecurityQuestions,
    isUpdatingAccountDetails: state.CONTEXT.toggles.isUpdatingAccountDetails,
  }));

  const [securityQuestionID, setSecurityQuestionID] = useState(null);
  const [openSubmitSuccess, setOpenSubmitSuccess] = useState(false);
  const [openSubmitError, setOpenSubmitError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const openSuccess = () => setOpenSubmitSuccess(true);
  const openError = () => setOpenSubmitError(true);
  const closeError = () => setOpenSubmitError(false);

  const {
    register,
    watch,
    errors,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm();
  const newPass = watch("newPass", "");

  const theme = useTheme();
  const styles = style();
  const isDownXS = useBreakpointDown("xs");
  const dependency = securityQuestions && securityQuestions.length;
  const passwordMinLength = 8;

  const checkPasswordStrength = (password) => {
    return zxcvbn(password).score + 1;
  };
  const passStrength = checkPasswordStrength(newPass);
  if (newPass && passwordStrength !== passStrength)
    setPasswordStrength(passStrength);

  const submit = (data) => {
    console.log(data);
    closeError();
    const payload = {
      studentID,
      campusID,
      newPass: data.newPass,
      confirmPass: data.confirmPass,
      mobileNo: data.mobile,
      email: data.email,
      securityQID: data.securityQuestionID,
      securityQAns: data.securityQuestionAnswer,
    };
    const onSuccess = () => {
      openSuccess();
    };
    const onFail = (message) => {
      setSubmitError(message);
      openError();
    };
    updateAccountDetails(payload, onSuccess, onFail);
  };
  const validate = () => {
    if (!securityQuestionID)
      setError("securityQuestion", {
        type: "manual",
        message: "Select a security question",
      });
    handleSubmit((data) => submit({ ...data, securityQuestionID }))();
  };
  const onEnter = (event) => {
    if (event.key === "Enter") validate();
  };
  const onStart = () => setAuthData("forceChangePass", false);

  useEffect(() => {
    if (!securityQuestions || securityQuestions.length < 1)
      getSecurityQuestions();
  }, [dependency, securityQuestions]);

  const validationStatus = [
    {
      id: 1,
      desc: `Minimum of 8 characters`,
      satisfied: newPass && newPass.length >= passwordMinLength,
    },
    {
      id: 2,
      desc: "An uppercase character",
      satisfied: newPass && /[A-Z]/.test(newPass),
    },
    {
      id: 3,
      desc: "A lowercase character",
      satisfied: newPass && /[a-z]/.test(newPass),
    },
    {
      id: 4,
      desc: "A number",
      satisfied: newPass && /[0-9]/.test(newPass),
    },
    {
      id: 5,
      desc: "A special character",
      satisfied:
        newPass && /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPass),
    },
  ];

  return (
    <div className={styles.root}>
      <ReactHelmet title={"Welcome"} />
      <Header />
      <div className={styles.body}>
        <div className={styles.bannerImage} />
        <Container fixed>
          <Space vertical space={4} />
          <FlexContainer justifyContent={"center"}>
            <div className={styles.box}>
              <FlexContainer direction={"column"} alignItems={"center"}>
                <img
                  src={profilePicture || USePLogo}
                  alt={"Profile"}
                  className={styles.avatar}
                />
                <Space vertical space={2} />
                <Typography
                  variant={!isDownXS && "h4"}
                  size={isDownXS && 26}
                  weight={isDownXS ? "semibold" : "medium"}
                  align={"center"}
                >
                  Hello, {firstName}!
                </Typography>
                <Space vertical space={0.5} />
                <Typography
                  variant={!isDownXS && "h6"}
                  weight={isDownXS && "semibold"}
                  align={"center"}
                >
                  Welcome to the USeP Student Portal.
                </Typography>
                <Space vertical space={4} />
                {openSubmitSuccess ? (
                  <Success onBegin={onStart} />
                ) : (
                  <>
                    <Typography align={"center"}>
                      To enforce better security on your account, we request you
                      to update your password, contact details, and security
                      question.
                    </Typography>
                    <Space vertical space={6} />
                    <div className={styles.formContainer}>
                      <Typography variant={"h5"} weight={"semibold"}>
                        Password
                      </Typography>
                      <div className={styles.titleLine} />
                      <Space vertical space={4} />
                      <Input
                        inputRef={register({
                          required: "Password required",
                          minLength: {
                            value: passwordMinLength,
                            message: "Minimum of 8 characters",
                          },
                          validate: {
                            lowercase: (value) =>
                              /[a-z]/.test(value) ||
                              "Must contain a lowercase character",
                            uppercase: (value) =>
                              /[A-Z]/.test(value) ||
                              "Must contain an uppercase character",
                            number: (value) =>
                              /[0-9]/.test(value) || "Must contain a number",
                            special: (value) =>
                              /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
                                value
                              ) || "Must contain a special character",
                          },
                        })}
                        name={"newPass"}
                        type={showPassword ? "text" : "password"}
                        placeholder={"New Password"}
                        // textAlign={"center"}
                        // errorAlign={"center"}
                        error={errors.newPass?.message}
                        onKeyDown={onEnter}
                      />
                      <Space vertical space={1.5} />
                      <div className={styles.passwordMeterBarBase}>
                        <div
                          className={styles.passwordMeterBar}
                          style={{
                            width: newPass ? `${passwordStrength * 20}%` : 0,
                            background:
                              passwordStrength &&
                              theme.palette[
                                passwordMeter["score" + passwordStrength].color
                              ].gradient,
                          }}
                        />
                      </div>
                      {newPass && (
                        <>
                          <Space vertical space={0.5} />
                          <Typography variant={"caption"} align={"center"}>
                            Password{" "}
                            {passwordMeter["score" + passwordStrength].message}.
                          </Typography>
                        </>
                      )}

                      <Space vertical space={2} />

                      <div>
                        <Typography>
                          New password <strong>should</strong> contain the
                          following:
                        </Typography>
                        <Space vertical space={1} />
                        {validationStatus.map((item, index) => (
                          <div key={item.id}>
                            {index > 0 && <Space vertical space={0.5} />}
                            <FlexContainer>
                              <FlexItem>
                                {item.satisfied ? (
                                  <IconMini
                                    icon={checkLine}
                                    height={18}
                                    padding={2}
                                    filled
                                    variant={"success"}
                                  />
                                ) : (
                                  <IconMini height={18} padding={2} filled />
                                )}
                              </FlexItem>
                              <Space space={2} />
                              <Typography align={"justify"}>
                                {item.desc}
                              </Typography>
                            </FlexContainer>
                          </div>
                        ))}
                      </div>

                      <Space vertical space={2} />
                      <Input
                        inputRef={register({
                          required: "Confirm your password",
                          validate: {
                            match: (confirmPass) =>
                              confirmPass === newPass ||
                              "Password does not match",
                          },
                        })}
                        name={"confirmPass"}
                        type={showPassword ? "text" : "password"}
                        placeholder={"Confirm Password"}
                        // textAlign={"center"}
                        // errorAlign={"center"}
                        error={errors.confirmPass?.message}
                        onKeyDown={onEnter}
                      />
                      <Space vertical space={2} />
                      <div>
                        <FlexContainer
                          alignItems={"center"}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          <Checkbox checked={showPassword} color={"primary"} />
                          <Space space={2} />
                          <Typography>Show Password</Typography>
                        </FlexContainer>
                      </div>
                      <Space vertical space={6} />
                      <Typography variant={"h5"} weight={"semibold"}>
                        Contact Details
                      </Typography>
                      <div className={styles.titleLine} />
                      <Space vertical space={4} />
                      <div style={{ width: "100%" }}>
                        <NoticeContainer
                          type={"WARNING"}
                          variant={"error"}
                          padding={3}
                          text={
                            "Please make sure to provide active contact details. They will be used for " +
                            "the recovery/security purposes of your account."
                          }
                        />
                        <Space vertical space={4} />
                        <Typography>Email address</Typography>
                        <Space vertical space={1} />
                        <Input
                          inputRef={register({
                            required: "Email Address required",
                            validate: {
                              whitespace: (value) =>
                                value.indexOf(" ") || "Remove whitespace",
                              email: (value) =>
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                                  value
                                ) || "Invalid email address",
                            },
                          })}
                          name={"email"}
                          type={"text"}
                          placeholder={"xxxxxxxxxxxx@xxxxx.xxx"}
                          error={errors.email?.message}
                          onKeyDown={onEnter}
                        />
                        <Space vertical space={2} />
                        <Typography>Mobile number</Typography>
                        <Space vertical space={1} />
                        <Input
                          inputRef={register({
                            required: "Mobile Number required",
                            validate: {
                              mobile: (value) =>
                                /^\d+$/.test(value) || "Invalid mobile number",
                              prefix: (value) =>
                                value.startsWith("09") ||
                                "Invalid mobile number",
                              length: (value) =>
                                value.length === 11 || "Invalid mobile number",
                            },
                          })}
                          name={"mobile"}
                          type={"text"}
                          placeholder={"09XXXXXXXXX"}
                          error={errors.mobile?.message}
                          onKeyDown={onEnter}
                        />
                      </div>

                      <Space vertical space={6} />
                      <Typography variant={"h5"} weight={"semibold"}>
                        Security Question
                      </Typography>
                      <div className={styles.titleLine} />
                      <Space vertical space={4} />
                      <div style={{ width: "100%" }}>
                        <NoticeContainer
                          type={"WARNING"}
                          variant={"error"}
                          padding={3}
                          text={
                            "Please be mindful of the chosen security question and your answer, " +
                            "you may need it in the future to recover your account in case of " +
                            "forgotten credentials."
                          }
                        />
                        <Space vertical space={4} />
                        <Select
                          error={errors.securityQuestion?.message}
                          items={securityQuestions}
                          value={securityQuestionID}
                          onSelect={(id) => {
                            clearErrors("securityQuestion");
                            setSecurityQuestionID(id);
                          }}
                          placeholder={"Select a question"}
                          maxHeight={300}
                        />
                        <Space vertical space={2} />
                        <Input
                          inputRef={register({
                            required: "Answer required",
                          })}
                          name={"securityQuestionAnswer"}
                          type={"text"}
                          placeholder={"Write your answer here"}
                          error={errors.securityQuestionAnswer?.message}
                          onKeyDown={onEnter}
                        />
                      </div>
                      <Space vertical space={8} />
                      <Button
                        variant={"primary"}
                        size={"medium"}
                        isLoading={isUpdatingAccountDetails}
                        disabled={isUpdatingAccountDetails}
                        onClick={validate}
                      >
                        Validate and Submit
                      </Button>
                    </div>
                  </>
                )}
              </FlexContainer>
            </div>
          </FlexContainer>
          <Footer />
        </Container>
      </div>

      <MessageDialog
        open={openSubmitError}
        variant={"error"}
        title={"Failed to Update"}
        onCloseLabel={"Close"}
        onConfirmLabel={"Resubmit"}
        description={submitError}
        onClose={closeError}
        onConfirm={validate}
      />
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  body: {},
  bannerImage: {
    height: 200,
    background: `linear-gradient(0deg, rgba(151, 57, 57, 0.1), rgba(151, 57, 57, 0.1)), url(${BannerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  box: {
    padding: theme.spacing(5),
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: theme.palette.grey.shade4,
    borderRadius: theme.shape.borderRadiusNormal,
    maxWidth: 600,
    marginTop: "-160px",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 0px 16px 0px",
    },
  },
  avatar: {
    height: 125,
    width: 125,
    borderWidth: 10,
    borderStyle: "solid",
    borderColor: theme.palette.background,
    borderRadius: "100%",
  },
  titleLine: {
    width: 48,
    height: 12,
    borderRadius: 100,
    background: theme.palette.primary.gradient,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  passwordMeterBarBase: {
    height: 15,
    width: "100%",
    background: theme.palette.grey.shade5,
    borderRadius: theme.shape.borderRadiusNormal,
  },
  passwordMeterBar: {
    height: 15,
    borderRadius: theme.shape.borderRadiusNormal,
    transition: "width .5s ease-in-out",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 400,
  },
}));

import React, { Fragment, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FlexContainer from "../containers/flex/FlexContainer";
import Typography from "../typography/Typography";
import Space from "../Space";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import useTheme from "@material-ui/core/styles/useTheme";

const LoadingComponents = ({ mini, label }) => {
  const styles = style();
  const theme = useTheme();
  return (
    <div className={styles.root} style={{ height: mini ? 100 : "100vh" }}>
      {mini ? (
        <div>
          <FlexContainer
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Space vertical space={3} />
            <Loader
              type="ThreeDots"
              color={theme.palette.primary.main}
              height={40}
              width={40}
            />
            <Space vertical space={3} />
            {label && <Typography>{label}</Typography>}
          </FlexContainer>
        </div>
      ) : (
        <Fragment>
          <FlexContainer
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"calc(100vh - 80px)"}
          >
            <Space vertical space={3} />
            <Loader
              type="ThreeDots"
              color={theme.palette.primary.main}
              height={40}
              width={40}
            />
            <Space vertical space={3} />
            <Typography variant={"h5"} align={"center"}>
              {label || "Loading"}
            </Typography>
          </FlexContainer>
          <FlexContainer
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            height={24}
          >
            <Typography>Please Wait</Typography>
          </FlexContainer>
        </Fragment>
      )}
    </div>
  );
};

const style = makeStyles((theme) => ({
  root: {
    background: theme.palette.background,
  },
}));

export default memo(LoadingComponents);

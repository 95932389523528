import React from 'react';
import {makeStyles} from "@material-ui/core/styles";


export default function ProgressBar({value, variant, height, minWidth}) {

    const style = makeStyles(theme => ({
        root: {
            height: height || 25,
            borderRadius: theme.shape.borderRadiusNormal,
            background: theme.palette.grey.shade5
        },
        progress: {
            height: height || 25,
            background: theme.palette[variant || 'primary'].gradient,
            width: `${value}%`,
            borderRadius: height || 25,
            transition: 'width 1s ease-in-out'
        },
    }))

    const {root, progress} = style()

    return (
        <div className={root}>
            <div className={progress}> </div>
        </div>
    )
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { isDarkTheme } from "../../utils/appSetting";
import { isFunction } from "../../utils/misc";

export default function Badge({ children, variant, size, fullwidth, onClick }) {
  const isDark = isDarkTheme();

  const style = makeStyles((theme) => ({
    root: {
      // transition: theme.transitions.main,
      background: theme.palette.default.gradient,
      color: isDark ? "#FFFFFF" : "#575757",

      minWidth: fullwidth ? "100%" : 0,
      padding: "6px 18px",
      borderRadius: 100,
      border: "unset",
      fontWeight: 600,
      fontSize: "0.875rem",
      outline: "unset",
      textTransform: "uppercase",

      "&:hover": {
        cursor: onClick ? "pointer" : "unset",
      },

      // no select
      "-webkit-touch-callout": "none" /* iOS Safari */,
      "-webkit-user-select": "none" /* Safari */,
      "-khtml-user-select": "none" /* Konqueror HTML */,
      "-moz-user-select": "none" /* Old versions of Firefox */,
      "-ms-user-select": "none" /* Internet Explorer/Edge */,
      "user-select":
        "none" /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */,
    },
    primary: {
      background: theme.palette.primary.gradient,
      color: "#FFFFFF",
    },
    success: {
      background: theme.palette.success.gradient,
      color: "#FFFFFF",
    },
    warning: {
      background: theme.palette.warning.gradient,
      color: "#FFFFFF",
    },
    error: {
      background: theme.palette.error.gradient,
      color: "#FFFFFF",
    },
    grey1: {
      background: theme.palette.grey.gradient1,
      color: isDark ? "#FFFFFF" : "#575757",
    },
    grey2: {
      background: theme.palette.grey.gradient2,
      color: "#FFFFFF",
    },
    grey3: {
      background: theme.palette.grey.gradient3,
      color: "#FFFFFF",
    },
    grey4: {
      background: theme.palette.grey.gradient4,
      color: isDark ? "#575757" : "#FFFFFF",
    },
    grey5: {
      background: theme.palette.grey.gradient5,
      color: isDark ? "#575757" : "#FFFFFF",
    },
    small: {
      padding: "4px 12px",
      fontSize: ".75rem",
      minWidth: 80,
    },
    medium: {
      padding: "8px 24px",
      fontSize: "1rem",
      minWidth: 120,
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  }));

  const {
    root,
    small,
    medium,
    primary,
    success,
    warning,
    error,
    grey1,
    grey2,
    grey3,
    grey4,
    grey5,
  } = style();

  return (
    <button
      className={clsx(
        root,
        size === "small" ? small : null,
        size === "medium" ? medium : null,
        variant === "primary" ? primary : null,
        variant === "success" ? success : null,
        variant === "warning" ? warning : null,
        variant === "error" ? error : null,
        variant === "grey1" ? grey1 : null,
        variant === "grey2" ? grey2 : null,
        variant === "grey3" ? grey3 : null,
        variant === "grey4" ? grey4 : null,
        variant === "grey5" ? grey5 : null
      )}
      onClick={() => {
        if (isFunction(onClick)) onClick();
      }}
    >
      {" "}
      {children}
    </button>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { isDarkTheme } from "../../../utils/appSetting";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

export default function DialogContainer({
  open,
  variant,
  onClose,
  children,
  disableClose,
  width,
  padding,
}) {
  const theme = useTheme();
  const isDark = isDarkTheme();
  const isDownSM = useBreakpointDown("sm");

  const muiStyle = makeStyles((theme) => ({
    paper: {
      boxShadow: isDark ? `0px 0px 8px ${theme.palette.grey.shade3}` : "",
    },
  }));

  const styles = style();
  const muiStyles = muiStyle();

  return (
    <Dialog
      open={open}
      keepMounted
      fullScreen={isDownSM}
      onClose={onClose}
      disableBackdropClick={disableClose}
      disableEscapeKeyDown={disableClose}
      classes={{
        paper: muiStyles.paper,
      }}
    >
      <div className={styles.root}>
        <div
          className={styles.body}
          style={{
            padding: padding || theme.spacing(5),
            width: isDownSM ? "calc(100vw - 80px)" : width || 360,
            minHeight: isDownSM ? "calc(100vh - 95px)" : 0,
          }}
        >
          {children}
        </div>
        <div
          className={styles.footer}
          style={{
            background: variant
              ? theme.palette[variant].gradient
              : theme.palette.primary.gradient,
          }}
        />
      </div>
    </Dialog>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  body: {
    background: theme.palette.background,
  },
  footer: {
    height: 15,
    width: "100%",
  },
}));

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Space from "../Space";
import Typography from "../typography/Typography";
import FlexContainer from "../containers/flex/FlexContainer";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import globalStyle from "../../../static/styles";

export default function Input({
  id,
  type,
  name,
  value,
  defaultValue,
  placeholder,
  textAlign,
  disabled,
  maxLength,
  minLength,
  error,
  onChange,
  width,
  size,
  rows,
  onKeyDown,
  errorAlign,
  inputRef,
}) {
  const theme = useTheme();
  let padding = size === "medium" ? "12px 20px" : "8px 16px";
  padding =
    type === "textarea"
      ? size === "medium"
        ? "20px 20px"
        : "16px 16px"
      : padding;

  const styles = style();
  const globalStyles = globalStyle();

  return (
    <div
      className={clsx(styles.root, globalStyles.textNoSelect)}
      style={{ width }}
    >
      <div
        className={styles.inputContainer}
        style={{
          padding,
          borderColor: disabled
            ? theme.palette.grey.shade3
            : error
            ? theme.palette.error.main
            : theme.palette.grey.shade2,
        }}
      >
        {type === "textarea" ? (
          <textarea
            id={id}
            ref={inputRef}
            className={clsx(styles.input, styles.scrollbar)}
            name={name}
            value={value}
            placeholder={placeholder}
            rows={rows || 5}
            defaultValue={defaultValue}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            onChange={onChange}
          />
        ) : (
          <input
            id={id}
            ref={inputRef}
            className={styles.input}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            onChange={onChange}
            defaultValue={defaultValue}
            onKeyDown={onKeyDown}
            style={{
              textAlign,
            }}
          />
        )}
      </div>
      {(error || disabled) && (
        <div>
          <Space vertical space={1} />
          <FlexContainer justifyContent={errorAlign}>
            {disabled ? (
              <Typography color={"grey2"}> Disabled </Typography>
            ) : (
              <Typography color={"error"}> {error} </Typography>
            )}
          </FlexContainer>
        </div>
      )}
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  inputContainer: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: theme.shape.borderRadiusSmall,
  },
  input: {
    background: "transparent",
    border: 0,
    padding: 0,
    outline: 0,
    fontSize: theme.typography.body.fontSize,
    color: theme.palette.default.main,
    width: "100%",
    caretColor: theme.palette.default.main,
    "&::placeholder": {
      color: theme.palette.default.main,
    },
    resize: "none",
  },
  scrollbar: {
    paddingRight: 13,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      marginRight: "-8px",
      width: 8,
      height: 8,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      opacity: 1,
      backgroundColor: theme.palette.grey.shade3,
    },
  },
}));

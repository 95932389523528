import React from "react";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Button from "../components/core/button/Button";
import {isFunction} from "../utils/misc";

export const GeneratePDF = ({documentComponent, filename, loadingComponent, button, afterDownloaded}) => (
    <>
        <PDFDownloadLink document={documentComponent} fileName={`${filename || 'file'}.pdf`}>
            {({blob, url, loading, error}) => {
                if (url) {
                    const link = document.createElement("a");
                    link.download = filename;
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    if (isFunction(afterDownloaded)) afterDownloaded()
                }
                return (
                    loading ?
                        loadingComponent || <Button variant={'primary'} isLoading> </Button> :
                        button || <Button variant={'primary'}>Download</Button>
                )
            }}
        </PDFDownloadLink>
    </>
)
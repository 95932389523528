import React from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Space from "../components/space";
import { registerFontLato } from "../registerFonts";
import Footer from "./Footer";
import Header from "./Header";
import Grades from "./Grades";
import { gradeSerializer } from "../../utils/grades";

export default function ReportOfGrades({ data }) {
  registerFontLato();

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 40,
      width: "100%",
    },
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    profileBox: {
      width: "20%",
    },
    infoBox: {
      width: data.profilePicture ? "60%" : "80%",
    },
    qrBox: {
      width: "20%",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    profilePicture: {
      height: 80,
      width: 80,
      borderStyle: "solid",
      borderWidth: 0.5,
    },
    row: {
      flexDirection: "row",
    },
    infoLabel: {
      width: 70,
    },
    infoValue: {
      width: 300,
    },
    gwaBox: {
      height: 80,
      width: 80,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderStyle: "solid",
      borderWidth: 0.5,
    },
  });

  return (
    <Document
      title={"Report of Grades"}
      author={"University of Southeastern Philippines"}
    >
      <Page size={"A4"} wrap style={styles.page}>
        <Header data={data} />
        <Space vertical space={30} />
        <View style={styles.container}>
          {data.profilePicture ? (
            <View style={styles.profileBox}>
              <Image src={data.profilePicture} style={styles.profilePicture} />
            </View>
          ) : null}
          <View style={styles.infoBox}>
            <View style={styles.row}>
              <View style={[styles.infoLabel, { marginTop: 7 }]}>
                <Text style={{ fontSize: 9 }}>Name</Text>
              </View>
              <View style={[styles.infoValue]}>
                <Text style={{ fontSize: 16 }}>{data.name}</Text>
              </View>
            </View>
            <Space vertical space={12} />
            <View style={styles.row}>
              <View style={[styles.infoLabel, { marginTop: 1 }]}>
                <Text style={{ fontSize: 9 }}>ID Number</Text>
              </View>
              <View style={[styles.infoValue]}>
                <Text style={{ fontSize: 10 }}>{data.studentID}</Text>
              </View>
            </View>
            <Space vertical space={4} />
            <View style={styles.row}>
              <View style={[styles.infoLabel, { marginTop: 1 }]}>
                <Text style={{ fontSize: 9 }}>Course</Text>
              </View>
              <View style={[styles.infoValue]}>
                <Text style={{ fontSize: 10 }}>{data.program}</Text>
              </View>
            </View>
            <Space vertical space={4} />
            <View style={styles.row}>
              <View style={[styles.infoLabel, { marginTop: 1 }]}>
                <Text style={{ fontSize: 9 }}>Year Level</Text>
              </View>
              <View style={[styles.infoValue]}>
                <Text style={{ fontSize: 10 }}>{data.yearLevel}</Text>
              </View>
            </View>
          </View>
          <View style={styles.qrBox}>
            {/* <View style={styles.gwaBox}>
              <Text style={{ fontSize: 20 }}>{gradeSerializer(data.gwa)}</Text>
              <Space vertical space={4} />
              <Text style={{ fontSize: 10 }}>GWA</Text>
            </View> */}
          </View>
        </View>
        <Space vertical space={24} />
        <Grades subjects={data.subjects} />
        <Space vertical space={30} />
        <Footer />
      </Page>
    </Document>
  );
}

import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { setForm, toggle } from "../../redux/actions/context";
import print from "../../utils/print";
import { setAuthData, updateProfilePicture } from "../../redux/actions/auth";
import Typography from "../core/typography/Typography";
import Space from "../core/Space";
import FlexContainer from "../core/containers/flex/FlexContainer";
import Button from "../core/button/Button";
import ImageCrop from "../core/image/ImageCrop";
import Dropzone from "../core/input/Dropzone";
import { isDarkTheme } from "../../utils/appSetting";
import Dialog from "@material-ui/core/Dialog";

export default function UpdateProfilePictureMobile() {
  const styles = style();
  const [updateProfPicError, setUpdateProfPicError] = useState(null);
  const {
    profilePicture,
    profileOpenUpdateProfilePic,
    isUpdatingProfilePic,
  } = useSelector((state) => ({
    profilePicture: state.CONTEXT.forms.profilePicture,
    profileOpenUpdateProfilePic:
      state.CONTEXT.toggles.profileOpenUpdateProfilePic,
    isUpdatingProfilePic: state.CONTEXT.toggles.isUpdatingProfilePic,
  }));

  const onDropzoneAdd = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setForm("profilePicture", {
        file,
        base64: reader.result,
      });
    };
    reader.onerror = function (error) {
      print("Error: ", error);
    };
  };
  const onClearPicture = () => setForm("profilePicture", null);
  const onCrop = (blob) =>
    setForm("profilePicture", {
      ...profilePicture,
      cropped: blob,
    });
  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], filename, { type: mime });
  }
  const onUpdateProfilePicture = () => {
    const file = dataURLtoFile(profilePicture.cropped, "profile-picture.jpg");
    const onSuccess = () => {
      toggle("profileOpenUpdateProfilePic", false);
      setForm("profilePicture", null);
      setAuthData("profilePicture", profilePicture.cropped);
      if (updateProfPicError) setUpdateProfPicError(null);
    };
    const onFail = () =>
      setUpdateProfPicError(
        "Failed to update profile picture. Please try again!"
      );
    updateProfilePicture(file, onSuccess, onFail);
  };
  const closeUpdateProfile = () => toggle("profileOpenUpdateProfilePic", false);

  return (
    <>
      <Dialog
        open={profileOpenUpdateProfilePic}
        keepMounted
        fullScreen
        onClose={closeUpdateProfile}
        classes={{
          paper: styles.paper,
        }}
      >
        <div className={styles.root}>
          <div className={styles.body}>
            <FlexContainer justifyContent={"flex-end"} alignItems={"center"}>
              <Button onClick={closeUpdateProfile}>Close</Button>
            </FlexContainer>
            <Space vertical space={3} />
            <Typography variant={"h4"} weight={"medium"}>
              Update Profile Picture
            </Typography>
            <Space vertical space={3} />
            <div className={styles.uploadBox}>
              {profilePicture ? (
                profilePicture.cropped ? (
                  <>
                    <Typography>
                      <Typography color={"warning"} weight={"bold"} span>
                        LAST STEP! &nbsp;
                      </Typography>
                      Review the cropped image. You may change the picture by
                      clicking <strong>Change Picture</strong>. Once final, hit
                      the <strong>SAVE</strong> button to submit.
                    </Typography>
                    <Space vertical space={3} />
                    {/* ERRORS */}
                    {updateProfPicError && (
                      <>
                        <div className={styles.errorBox}>
                          <Typography weight={"semibold"} color={"error"}>
                            {updateProfPicError}
                          </Typography>
                        </div>
                        <Space vertical space={3} />
                      </>
                    )}
                    <img
                      src={profilePicture.cropped}
                      alt={"Profile"}
                      width={"100%"}
                    />
                    <Space vertical space={4} />
                    <FlexContainer
                      justifyContent={
                        isUpdatingProfilePic ? "center" : "space-between"
                      }
                    >
                      {!isUpdatingProfilePic && (
                        <>
                          <Button onClick={onClearPicture}>
                            Change Picture
                          </Button>
                          <Space space={3} />
                        </>
                      )}
                      <Button
                        onClick={onUpdateProfilePicture}
                        variant={"primary"}
                        isLoading={isUpdatingProfilePic}
                      >
                        {updateProfPicError ? "RETRY" : "SAVE"}
                      </Button>
                    </FlexContainer>
                  </>
                ) : (
                  <>
                    <Typography>
                      <Typography color={"warning"} weight={"bold"} span>
                        NEXT STEP! &nbsp;
                      </Typography>
                      Crop the image into square shape. You may change the
                      picture by clicking <strong>Change Picture</strong>.
                    </Typography>
                    <Space vertical space={3} />
                    <ImageCrop
                      image={profilePicture.base64}
                      onChangePicture={onClearPicture}
                      onCrop={onCrop}
                    />
                  </>
                )
              ) : (
                <>
                  <Typography>
                    <Typography color={"warning"} weight={"bold"} span>
                      FIRST STEP! &nbsp;
                    </Typography>
                    Upload your most recent, colored, high-quality digital photo
                    with white background.
                  </Typography>
                  <Space vertical space={3} />
                  <Typography>
                    <Typography color={"error"} weight={"bold"} span>
                      IMPORTANT! &nbsp;
                    </Typography>
                    If you are using your phone's camera, you may need to go to
                    your camera's settings and set the picture quality to
                    'Standard' or 'Low' to fit the file size limit.
                  </Typography>
                  <Space vertical space={3} />
                  <Dropzone onDropzoneAdd={onDropzoneAdd} maxFileSize={2} />
                </>
              )}
            </div>
            <Space vertical space={3} />
          </div>
          <div className={styles.footer} />
        </div>
      </Dialog>
    </>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  body: {
    width: "calc(100vw - 32px)",
    minHeight: "calc(100vh - 47px)",
    padding: 16,
    background: theme.palette.background,
  },
  footer: {
    height: 15,
    width: "100%",
    background: theme.palette.primary.gradient,
  },
  paper: {
    boxShadow: isDarkTheme() ? `0px 0px 8px ${theme.palette.grey.shade3}` : "",
  },
  container: {
    minHeight: "calc(100vh - 95px)",
  },
  profileButtonWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    "& input[type=file]": {
      position: "absolute",
      left: 0,
      top: 0,
      opacity: 0,
    },
  },
  uploadBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  bullet: {
    height: 36,
    width: 36,
    borderRadius: "100%",
    background: theme.palette.primary.gradient,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  errorBox: {
    padding: "12px 16px",
    border: "1px solid " + theme.palette.error.main,
    borderRadius: theme.shape.borderRadius,
  },
}));

import React from 'react';
import './keyframes.scss'

/**
 * @return {string}
 */
export function SlideInForwardCenterClass() {
    return 'slide-in-fwd-center'
}


export default function SlideInForwardCenter({children}) {
    return (
        <div className={'slide-in-fwd-center'}> {children} </div>
    )
}

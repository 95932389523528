import Container from "@material-ui/core/Container";
import LoginHeader from "./LoginHeader";
import clsx from "clsx";

export default function LoginLayout({ classNames, children }) {
  return (
    <>
      <LoginHeader />
      <div className={clsx(...classNames)}>
        <Container fixed>{children}</Container>
      </div>
    </>
  );
}

import React from "react";
import ReactHelmet from "../components/core/Helmet";
import FlexContainer from "../components/core/containers/flex/FlexContainer";
import Typography from "../components/core/typography/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Space from "../components/core/Space";
import Button from "../components/core/button/Button";
import {useHistory} from "react-router";
import Footer from "../layout/Footer";
import FlexItem from "../components/core/containers/flex/FlexItem";
import {routes} from "../routes";

export default function PageNotFound() {

    const style = makeStyles(theme => ({
        root: {
            height: 'calc(100vh - 48px)',
            padding: theme.spacing(3)
        },
        container: {

        }
    }))

    const {root} = style()
    const history = useHistory()

    return (
        <div className={root}>
            <ReactHelmet title={'Page Not Found'}/>
            <FlexContainer direction={'column'} alignItems={'center'} justifyContent={'center'}>
                <Space vertical space={14}/>
                <Typography variant={'h1'} weight={'light'} align={'center'}>404</Typography>
                <Space vertical space={1}/>
                <Typography variant={'h5'} weight={'light'} align={'center'}>PAGE NOT FOUND</Typography>
                <Space vertical space={8}/>
                <Button size={'medium'} variant={'primary'} onClick={() => history.replace(routes.dashboard)}>G0 to
                    Dashboard</Button>
                <Space vertical space={6}/>
                <FlexItem>
                    <Footer/>
                </FlexItem>
            </FlexContainer>
        </div>
    );
}
import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@iconify/react";
import Space from "../Space";
import Typography from "../typography/Typography";
import FlexContainer from "../containers/flex/FlexContainer";
import Button from "../button/Button";
import alertLine from "@iconify/icons-ri/alert-line";
import { isDarkTheme } from "../../../utils/appSetting";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const MessageDialog = ({
  id,
  open,
  variant,
  icon,
  title,
  description,
  onCloseLabel,
  onConfirmLabel,
  onClose,
  onConfirm,
  singleAction,
  disableOtherClose,
  className,
}) => {
  const muiStyle = makeStyles((theme) => ({
    paper: {
      boxShadow: isDark ? `0px 0px 8px ${theme.palette.grey.shade3}` : "",
    },
  }));

  const theme = useTheme();
  const isDark = isDarkTheme();
  const isDownXS = useBreakpointDown("xs");
  const muiStyles = muiStyle();
  const styles = style();

  return (
    <div id={id} className={className}>
      <Dialog
        open={open}
        keepMounted
        fullScreen={isDownXS}
        onClose={!disableOtherClose && onClose}
        classes={{
          paper: muiStyles.paper,
        }}
      >
        <div className={styles.root}>
          <div className={styles.body}>
            <FlexContainer
              alignItems={"center"}
              justifyContent={"center"}
              classNames={[styles.container]}
            >
              <div>
                <Space vertical space={2} />
                <Typography color={variant || "primary"} align={"center"}>
                  <Icon icon={icon || alertLine} height={80} />
                </Typography>
                <Space vertical space={3} />
                <Typography variant={"h5"} align={"center"}>
                  {title}
                </Typography>
                <Space vertical space={3} />
                <Typography align={"center"}>{description}</Typography>
                <Space vertical space={6} />
                <FlexContainer
                  alignItems={"center"}
                  justifyContent={singleAction ? "center" : "space-between"}
                >
                  {!singleAction && (
                    <>
                      <Button onClick={onClose}>
                        {onCloseLabel || "Cancel"}
                      </Button>
                      <Space space={3} />
                    </>
                  )}
                  <Button
                    variant={variant || "primary"}
                    size={singleAction && "medium"}
                    onClick={onConfirm}
                  >
                    {onConfirmLabel || "Confirm"}
                  </Button>
                </FlexContainer>
              </div>
            </FlexContainer>
          </div>
          <div
            className={styles.footer}
            style={{
              background: variant
                ? theme.palette[variant].gradient
                : theme.palette.primary.gradient,
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};

const style = makeStyles((theme) => ({
  root: {},
  body: {
    width: 360,
    minHeight: 0,
    padding: theme.spacing(5),
    background: theme.palette.background,
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 80px)",
      minHeight: "calc(100vh - 95px)",
    },
  },
  footer: {
    height: 15,
    width: "100%",
  },
  container: {
    minHeight: 0,
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 95px)",
    },
  },
}));

export default memo(MessageDialog);

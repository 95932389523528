import {CONTEXT_SET, CONTEXT_TOGGLE, CONTEXT_FORM_SET, CONTEXT_RESET} from "../types";
import {dispatch} from "./index";


export const toggle = (name, value) => {
    dispatch(CONTEXT_TOGGLE, {name, value})
}

export const setContext = (name, value) => {
    dispatch(CONTEXT_SET, {name, value})
}

export const setForm = (name, value) => {
    dispatch(CONTEXT_FORM_SET, {name, value})
}

export const clearContext = () => {
    dispatch(CONTEXT_RESET)
}
import {CONTEXT_FORM_SET, CONTEXT_RESET, CONTEXT_SET, CONTEXT_TOGGLE,} from "../types";

const init = {

    // location
    enteredPathname: '',
    prevPathname: '',

    toggles: {

        // misc
        isRedirecting: false,

        // authentication
        showPassword: false,
        isLoggingIn: false,
        errorLogout: false,
        openDataPrivacyDialog: true,
        isChangingPassword: false,
        isUpdatingContactInfo: false,
        failedToChangePassword: false,
        isResettingPass: false,
        failedToResetPass: false,
        passwordCanBeSubmitted: false,
        passwordShowPassword: false,
        isGettingSecurityQuestions: false,
        errorToGetSecurityQuestions: false,
        isUpdatingAccountDetails: false,

        // dashboard
        dashboardIsGettingDashboardInfo: false,
        dashboardErrorToGetDashboardInfo: false,

        // grades
        isGettingGrades: false,
        isGettingGradesSummary: false,

        // academic evaluation
        isGettingAcademicEvaluation: false,
        isReEvaluatingAcademicEvaluation: false,
        isFailedToReEvaluateAcademicEvaluation: false,

        // enrollment
        enrollmentActive: false,
        registrationIsUnderAdvising: false,
        registrationAdvisingHasChanges: false,
        registrationAdvisingIsValidated: false,
        registrationIsFHE: false,
        registrationSubjectNotInCurriWarning: false,

        registrationHasChanges: false,

        enrollmentIsLoadingRegistrations: false,
        enrollmentIsLoadingRegistrationDetails: false,
        enrollmentIsLoadingRegistrationValidationProcess: false,
        enrollmentIsLoadingRegistrationSchedules: false,
        enrollmentIsLoadingAvailableSubjects: false,
        enrollmentIsLoadingSections: false,
        enrollmentIsLoadingOfferedSubjects: false,
        enrollmentIsLoadingSchedules: false,
        enrollmentIsSubmittingRegistration: false,
        enrollmentIsSubmittingRegistrationForAdvising: false,
        enrollmentIsSavingRegistration: false,
        enrollmentIsGettingScheduleByCourse: false,
        enrollmentIsGettingRegIDUnderAdvising: false,
        enrollmentIsCheckingChangesOnRegAdvising: false,
        enrollmentIsCheckingRegAdvisingValidated: false,
        enrollmentIsSavingAdvising: false,
        enrollmentIsCheckingCanRegister: false,
        enrollmentCanRegisterHasCalled: false,
        registrationIsLoadingAdvisingRemarks: false,
        enrollmentIsEnrollmentSurvey: false,

        enrollmentErrorToLoadRegistrations: false,
        enrollmentErrorToLoadRegistrationDetails: false,
        enrollmentErrorToLoadRegistrationValidationProcess: false,
        enrollmentErrorToLoadRegistrationSchedules: false,
        enrollmentErrorToLoadAvailableSubjects: false,
        enrollmentErrorToLoadOfferedSubjects: false,
        enrollmentErrorToLoadSchedules: false,
        registrationErrorToLoadAdvisingRemarks: false,

        enrollmentRegIDUnderAdvisingHasCalled: false,
        enrollmentRegistrationHasFinalized: false,

        // accountability
        accountabilityChecked: false,
        isGettingAccountability: false,
        isErrorGettingAccountability: false,
        ignoreAccountabilityChecking: false,

        // university services evaluation
        evaluationChecked: false,
        hasCompletedFacultyEvaluation: false,
        hasCompletedStudentSatisfaction: false,

        // ADDED - Course Evaluation
        hasCompletedCourseEvaluation: false,

        univServicesEvaluationIsCheckingEvaluationStatus: false,
        univServicesEvaluationErrorToCheckEvaluationStatus: false,

        univServicesEvaluationIsGettingFacultyList: false,
        univServicesEvaluationErrorToGetFacultyList: false,

        // ADDED - Subject Evaluation
        univServicesEvaluationIsGettingSubjectList: false,
        univServicesEvaluationErrorToGetSubjectList: false,

        univServicesEvaluationIsGettingEvaluationForm: false,
        univServicesEvaluationErrorToGetEvaluationForm: false,
        univServicesEvaluationIsSavingEvaluationForm: false,

        univServicesEvaluationIsGettingOfficesToEvaluate: false,
        univServicesEvaluationFailedToGetOfficesToEvaluate: false,

        // profile
        profileOpenUpdateProfilePic: false
    },

    forms: {

        // Login
        campus: null,
        idNumber: '',
        password: '',

        // forgot password
        forgotPasswordCampus: null,
        forgotIDNumber: '',
        forgotMiddleName: '',
        forgotBirthdate: '',

        // change password
        changePasswordPresent: '',
        changePasswordNew: '',
        changePasswordConfirm: '',

        // update contact details
        updateEmail: '',
        updateMobileNumber: '',

        // security question
        selectedSecurityQuestionID: null,
        securityQuestionAnswer: '',

        // enrollment
        enrollmentRegisterAvailFHE: null,

        // profile
        profileAddress: '',
        profileMobile: '',
        profileEmail: '',
        profilePicture: null

    },

    // authentication
    loginAttemptsRemaining: null,
    loginCampusError: null,
    loginIDError: null,
    loginPasswordError: null,
    loginFormError: null,
    loginFormErrorDescription: null,
    forgotCampusError: null,
    forgotIDError: null,
    forgotBirthdateError: null,
    resetFormError: null,
    resetFormErrorDescription: null,
    validationStatus: [
        {
            id: 1,
            desc: `Minimum of 8 characters`,
            satisfied: false
        },
        {
            id: 2,
            desc: 'An uppercase character',
            satisfied: false
        },
        {
            id: 3,
            desc: 'A lowercase character',
            satisfied: false
        },
        {
            id: 4,
            desc: 'A number',
            satisfied: false
        },
        {
            id: 5,
            desc: 'A special character',
            satisfied: false
        },
    ],

    // grades
    gradesTermID: null,

    // academic evaluation
    academicEvaluationSemester: null,

    // enrollment
    courseIDForSchedule: null,
    selectedSection: null,
    currentLoad: 0,
    currentLecLoad: 0,
    currentLabLoad: 0,
    newlySavedRegistrationID: null,
    preAddSubjectData: {},
    registrationNumforView: null,
    regIDForAdvising: null,
    advisingIDForAdvising: null,
    adviserIDForAdvising: 1021,
    enrollmentAY: '',
    enrollmentTerm: '',
    enrollmentStartDate: '',
    enrollmentEndDate: '',
    origSchedules: [],
    registrationLastAdviserRemarks: {
        dateSubmitted: '2020-07-25T12:20:06.660Z',
        remarks: 'Added Capstone 2 and 3'
    },

    // university services evaluation
    facultyEvaluationFormData: [],
    studentSatisfactionFormData: {
        radioAnswers: [],
        paramQuestionAnswers: []
    },

    // ADDED  Subject Evaluation
    subjectEvaluationFormData: {
        radioAnswers: [],
        paramQuestionAnswers: []
    },

    // ADDED   Course Evaluation
    courseEvaluationFormData: {
        radioAnswers: [],
        paramQuestionAnswers: []
    },


    customerFeedbackFormData: {
        radioAnswers: [],
        paramQuestionAnswers: []
    },
}

export default function (state = init, action) {
    switch (action.type) {

        case CONTEXT_TOGGLE:
            return {
                ...state,
                toggles: {
                    ...state.toggles,
                    [action.payload.name]: action.payload.value
                }
            }

        case CONTEXT_SET:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }

        case CONTEXT_FORM_SET:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.payload.name]: action.payload.value
                }
            }

        case CONTEXT_RESET:
            return init

        default:
            return state
    }
}
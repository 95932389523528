import types from "../types";

const init = {
  // General

  // Login Page
  loginOpenMessage: false,
  loginOpenNewStudentDialog: false,
  loginShowPassword: false,
  loginIsSubmitting: false,

  // profile
  profileIsGettingData: false,

  // dashboard
};

export default function (state = init, action) {
  switch (action.type) {
    case types.TOGGLE:
      return {
        ...state,
        [action.payload.name]: !state[action.payload.name],
      };

    case types.TOGGLE_SET:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case types.TOGGLE_RESET:
      return init;

    default:
      return state;
  }
}

import Header from "./Header";
import Container from "@material-ui/core/Container";
import Footer from "./Footer";
import React from "react";
import Banner from "../components/custom/banner/Banner";
import HomeRoutes from "../routes/home";
import Confetti from "../components/core/Confetti";

export default function HomeLayout() {
  return (
    <>
      {/*<Confetti offset={0} />*/}
      <Header />
      <Banner />
      <Container fixed>
        <HomeRoutes />
        <Footer />
      </Container>
    </>
  );
}

import React from "react";
import { Helmet } from "react-helmet";

export default function ReactHelmet({ title }) {
  return (
    <Helmet>
      <title>{title} | USeP Student Portal</title>
    </Helmet>
  );
}

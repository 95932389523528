import React from 'react';
import './keyframes.scss'

/**
 * @return {string}
 */
export function BounceTopClass() {
    return 'bounce-top'
}


export default function BounceTop({children}) {
    return (
        <div className={'bounce-top'}> {children} </div>
    )
}

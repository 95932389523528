import { dispatch } from "./index";
import types from "../types";

export const toggleSet = (name, value) => {
  dispatch(types.TOGGLE_SET, { name, value });
};

export const toggle = (name) => {
  dispatch(types.TOGGLE, { name });
};

export const toggleReset = () => {
  dispatch(types.TOGGLE_RESET);
};

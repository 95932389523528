import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Banner from "../../static/media/img/banner.jpg";
import downcastFaceWithSweat from "@iconify/icons-emojione/downcast-face-with-sweat";
import Icon from "@iconify/react";
import Space from "../../components/core/Space";
import Typography from "../../components/core/typography/Typography";
import FlexItem from "../../components/core/containers/flex/FlexItem";
import FlexContainer from "../../components/core/containers/flex/FlexContainer";
import USePLogo from "../../static/media/img/USePLogo.png";
import SDMDLogo from "../../static/media/img/SDMDLogo.png";

export default function SystemMaintenanceMode() {
  const styles = style();

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <Icon icon={downcastFaceWithSweat} height={120} />
        <Space vertical space={6} />
        <Typography
          color={"primary"}
          variant={"h4"}
          weight={"medium"}
          align={"center"}
        >
          Sorry! We're under maintenance <br />
          at the moment.
        </Typography>
        <Space vertical space={6} />
        <Typography variant={"h6"} weight={"medium"} align={"center"}>
          We are improving our system to serve you better. <br />
          This may take some time, so please sit back, chill, and come back
          later. <br />
          Thanks for dropping by!
        </Typography>
        <Space vertical space={12} />
        <FlexItem>
          <FlexContainer alignItems={"center"}>
            <img src={USePLogo} alt={"USeP Logo"} height={36} />
            <Space space={1.5} />
            <img src={SDMDLogo} alt={"SDMD Logo"} height={36} />
          </FlexContainer>
        </FlexItem>
        <Space vertical space={2} />
        <FlexItem>
          <FlexContainer classNames={[styles.brandingText]}>
            <Typography size={24} color={"secondary"} weight={"semibold"}>
              One{" "}
              <Typography size={24} color={"primary"} weight={"semibold"} span>
                Data.{" "}
              </Typography>
              One{" "}
              <Typography size={24} color={"primary"} weight={"semibold"} span>
                USeP.{" "}
              </Typography>
            </Typography>
          </FlexContainer>
        </FlexItem>
        <Space vertical space={0.5} />
        <Typography variant={"h6"}>Student Portal</Typography>
      </div>
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  bannerImage: {
    height: 100,
    background: `linear-gradient(0deg, rgba(151, 57, 57, 0.1), rgba(151, 57, 57, 0.1)), url(${Banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: theme.transitions.main,
  },
  brandingText: {
    marginTop: "-2px",
  },
  body: {
    height: "calc(100vh)",
    minHeight: 750,
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

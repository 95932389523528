export const hasUpperCase = str => {
    return (/[A-Z]/.test(str));
}

export const hasLowerCase = str => {
    return (/[a-z]/.test(str));
}

export const hasNumber = str => {
    return /\d/.test(str)
}

export const hasSpecialCharacter = str => {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    return format.test(str)
}

export const isEmail = str => {
    const format = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return format.test(str)
}

export const isMobileNumber = str => {
    if (!str.startsWith('09')) return false
    if (str.length !== 11) return false
    return /^\d+$/.test(str)
}
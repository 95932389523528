import axios from "axios";

import { dispatch, getState } from "./index";
import { CONFIG } from "../../AppConfig";
import { isFunction } from "../../utils/misc";
import print from "../../utils/print";
import { toggle } from "./context";
import {
  UNIV_SERVICES_EVALUATION_AY_TERM_SET,
  UNIV_SERVICES_EVALUATION_CLEAR,
  UNIV_SERVICES_EVALUATION_FACULTY_FORM_SET,
  UNIV_SERVICES_EVALUATION_FACULTY_LIST_SET,

  UNIV_SERVICES_EVALUATION_SUBJECT_FORM_SET,
  UNIV_SERVICES_EVALUATION_SUBJECT_LIST_SET,

  UNIV_SERVICES_EVALUATION_STUDENT_CUSTOMER_FEEDBACK_SET,
  UNIV_SERVICES_EVALUATION_STUDENT_OFFICES_TO_EVALUATE_SET,
  UNIV_SERVICES_EVALUATION_STUDENT_SATISFACTION_FORM_SET,

  UNIV_SERVICES_EVALUATION_COURSE_EVALUATION_FORM_SET,

} from "../types";
import { sortByObjectKeyAsc } from "../../utils/sort";
import { getHTTPConfig } from "../../utils/requests";

const URL = CONFIG.URL_API;



//LIST FACULTY TO EVALUATE
export const getFacultyToEvaluate = (onSuccess, onFail) => {
  toggle("univServicesEvaluationIsGettingFacultyList", true);
  toggle("univServicesEvaluationErrorToGetFacultyList", false);

  const { studentID, campusID } = getState().AUTH;

  const url = `${URL}enrollment/getFacultyToEvaluate/${studentID}/${campusID}`;
  
  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET FACULTY TO EVALUATE", result);

      const data = result.data;
      const payload = {
        ayTerm: data.ayTerm,
        facultyList: [],
      };

      if (data.facultyList) {
        for (const item of data.facultyList) {
          payload.facultyList.push({
            scheduleID: item.scheduleID,
            name: item.name,
            subjectCode: item.subjectCode,
            subjectTitle: item.subjectTitle,
            isEvaluated: item.isEvaluated,
          });
        }
      }

      dispatch(UNIV_SERVICES_EVALUATION_AY_TERM_SET, payload.ayTerm);
      dispatch(UNIV_SERVICES_EVALUATION_FACULTY_LIST_SET, payload.facultyList);

      toggle("univServicesEvaluationIsGettingFacultyList", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("GET FACULTY TO EVALUATE", error);
      toggle("univServicesEvaluationErrorToGetFacultyList", true);
      toggle("univServicesEvaluationIsGettingFacultyList", false);
      if (isFunction(onFail)) onFail();
    });
};


//LIST SUBJECT TO EVALUATE
export const getSubjectToEvaluate = (onSuccess, onFail) => {
  toggle("univServicesEvaluationIsGettingSubjectList", true);
  toggle("univServicesEvaluationErrorToGetSubjectList", false);

  const { studentID, campusID } = getState().AUTH;

  const url = `${URL}enrollment/getSubjectToEvaluate/${studentID}/${campusID}`;
  
  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET SUBJECT TO EVALUATE", result);

      const data = result.data;
      const payload = {
        ayTerm: data.ayTerm,
        subjectList: [],
      };

      if (data.subjectList) {
        for (const item of data.subjectList) {
          payload.subjectList.push({
            scheduleID: item.scheduleID,
            name: item.name,
            subjectCode: item.subjectCode,
            subjectTitle: item.subjectTitle,
            isEvaluated: item.isEvaluated,
          });
        }
      }

      dispatch(UNIV_SERVICES_EVALUATION_AY_TERM_SET, payload.ayTerm);
      dispatch(UNIV_SERVICES_EVALUATION_SUBJECT_LIST_SET, payload.subjectList);

      toggle("univServicesEvaluationIsGettingSubjectList", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("GET SUBJECT TO EVALUATE", error);
      toggle("univServicesEvaluationErrorToGetSubjectList", true);
      toggle("univServicesEvaluationIsGettingSubjectList", false);
      if (isFunction(onFail)) onFail();
    });
};

//SUBJECT EVALUATION FORM COMPONENT
export const getSubjectEvaluationForm = (onSuccess, onFail) => {
  toggle("univServicesEvaluationIsGettingEvaluationForm", true);
  toggle("univServicesEvaluationErrorToGetEvaluationForm", false);

  const { campusID } = getState().AUTH;

  const url = `${URL}enrollment/getSubjectEvaluationForm/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET SUBJECT EVALUATION FORM", result);

      const data = result.data;
      const payload = {
        evalID: data.evalID,
        parameters: [],
      };

    const getRatings = (ratingTemplateID) => {
      let rating = [];
      for (const item of data.rating) {
        
        if (item.evalTemplateID === ratingTemplateID) {
          rating.push({
            id: item.id,
            description: item.description,
            rating: item.rating,
            isActive: item.isActive,
            alias: item.alias,
            sortOrder: item.sortOrder,
          });
        }
      }
      rating = sortByObjectKeyAsc(rating, "sortOrder");
      return rating;
    };

    const getStatements = (parameterID) => {
      const statements = [];
      // const statements = data.statements;
      for (const item of data.statements) {
        let ratingTemplateID = item.ratingTemplateID;
        if (item.parameterID === parameterID) {
          statements.push({
            id: item.id,
            statement: item.statement,
            sortOrder: item.sortOrder,
            parameterID: item.parameterID,
            ratingTemplateID: item.ratingTemplateID,
            ratings: sortByObjectKeyAsc(item.ratings, "sortOrder"),
            // ratings: getRatings(item.ratingTemplateID),
          });
          // statements.push(item);
        }
      }
      return statements;
    };

    const getParameterQuestions = (parameterID) => {
      const parameterQuestions = [];
      for (const item of data.parameterQuestions) {
        if (item.parameterID === parameterID) {
          parameterQuestions.push({
            id: item.id,
            question: item.question,
            sortOrderN: item.sortOrderN,
            parameterID: item.parameterID,
            parameterPlaceHolder: item.placeHolder,
          });
        }
      }
      return parameterQuestions;
    };

    for (const item of data.parameters) {
      payload.parameters.push({
        id: item.id,
        name: item.name,
        sortOrderA: item.sortOrderA,
        statements: getStatements(item.id),
        parameterQuestions: getParameterQuestions(item.id),
      });
    }

    dispatch(UNIV_SERVICES_EVALUATION_SUBJECT_FORM_SET, payload);

      toggle("univServicesEvaluationIsGettingEvaluationForm", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("GET SUBJECT EVALUATION FORM", error);
      toggle("univServicesEvaluationErrorToGetEvaluationForm", true);
      toggle("univServicesEvaluationIsGettingEvaluationForm", false);
      if (isFunction(onFail)) onFail();
    });
};




//FACULTY EVALUATION FORM COMPONENT
export const getFacultyEvaluationForm = (onSuccess, onFail) => {
  toggle("univServicesEvaluationIsGettingEvaluationForm", true);
  toggle("univServicesEvaluationErrorToGetEvaluationForm", false);

  const { campusID } = getState().AUTH;

  const url = `${URL}enrollment/getFacultyEvaluationForm/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET FACULTY EVALUATION FORM", result);

      const data = result.data;
      const payload = {
        evalID: data.evalID,
        parameters: [],
      };

      const getRatings = () => {
        let rating = [];
        for (const item of data.rating) {
          rating.push({
            id: item.id,
            description: item.description,
            rating: item.rating,
            isActive: item.isActive,
            alias: item.alias,
            sortOrder: item.sortOrder,
          });
        }
        rating = sortByObjectKeyAsc(rating, "sortOrder");
        return rating;
      };

      const getStatements = (parameterID) => {
        const statements = [];
        for (const item of data.statements) {
          if (item.parameterID === parameterID) {
            statements.push({
              id: item.id,
              statement: item.statement,
              sortOrder: item.sortOrder,
              parameterID: item.parameterID,
              ratings: getRatings(),
            });
          }
        }
        return statements;
      };

      for (const item of data.parameters) {
        payload.parameters.push({
          id: item.id,
          name: item.name,
          sortOrderA: item.sortOrderA,
          statements: getStatements(item.id),
        });
      }

      dispatch(UNIV_SERVICES_EVALUATION_FACULTY_FORM_SET, payload);

      toggle("univServicesEvaluationIsGettingEvaluationForm", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("GET FACULTY EVALUATION FORM", error);
      toggle("univServicesEvaluationErrorToGetEvaluationForm", true);
      toggle("univServicesEvaluationIsGettingEvaluationForm", false);
      if (isFunction(onFail)) onFail();
    });
};

//SAVE SUBJECT EVALUATION
export const saveSubjectEvaluation = (data, onSuccess, onFail) => {
  toggle("univServicesEvaluationIsSavingEvaluationForm", true);

  print("DATA: saveSubjectEvaluation", data);

  const url = `${URL}enrollment/saveSubjectEvaluation`;

  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("SAVE FACULTY EVALUATION", result);
      toggle("univServicesEvaluationIsSavingEvaluationForm", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("SAVE FACULTY EVALUATION", error);
      toggle("univServicesEvaluationIsSavingEvaluationForm", false);
      if (isFunction(onFail)) onFail();
    });
};




//STUDENT SATISFACTION FORM COMPONENT
export const getStudentSatisfactionForm = (onSuccess, onFail) => {
  toggle("univServicesEvaluationIsGettingEvaluationForm", true);
  toggle("univServicesEvaluationErrorToGetEvaluationForm", false);

  const { campusID } = getState().AUTH;

  const url = `${URL}enrollment/getStudentSatisfactionForm/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET STUDENT SATISFACTION FORM", result);

      const data = result.data;
      const payload = {
        evalID: data.evalID,
        parameters: [],
      };

      const getRatings = () => {
        let rating = [];
        for (const item of data.rating) {
          rating.push({
            id: item.id,
            description: item.description,
            rating: item.rating,
            isActive: item.isActive,
            alias: item.alias,
            sortOrder: item.sortOrder,
          });
        }
        rating = sortByObjectKeyAsc(rating, "sortOrder");
        return rating;
      };

      const getStatements = (parameterID) => {
        const statements = [];
        // const statements = data.statements;
        for (const item of data.statements) {
          if (item.parameterID === parameterID) {
            statements.push({
              id: item.id,
              statement: item.statement,
              sortOrder: item.sortOrder,
              parameterID: item.parameterID,
              ratings: sortByObjectKeyAsc(item.ratings, "sortOrder"),
              // ratings: getRatings(),
            });
            // statements.push(item);
          }
        }
        return statements;
      };

      const getParameterQuestions = (parameterID) => {
        const parameterQuestions = [];
        for (const item of data.parameterQuestions) {
          if (item.parameterID === parameterID) {
            parameterQuestions.push({
              id: item.id,
              question: item.question,
              sortOrderN: item.sortOrderN,
              parameterID: item.parameterID,
            });
          }
        }
        return parameterQuestions;
      };

      for (const item of data.parameters) {
        payload.parameters.push({
          id: item.id,
          name: item.name,
          sortOrderA: item.sortOrderA,
          statements: getStatements(item.id),
          parameterQuestions: getParameterQuestions(item.id),
        });
      }

      dispatch(UNIV_SERVICES_EVALUATION_STUDENT_SATISFACTION_FORM_SET, payload);

      toggle("univServicesEvaluationIsGettingEvaluationForm", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("GET STUDENT SATISFACTION FORM", error);
      toggle("univServicesEvaluationErrorToGetEvaluationForm", true);
      toggle("univServicesEvaluationIsGettingEvaluationForm", false);
      if (isFunction(onFail)) onFail();
    });
};



//COURSE EVALUATION FORM COMPONENT
export const getCourseEvaluationForm = (onSuccess, onFail) => {
  toggle("univServicesEvaluationIsGettingEvaluationForm", true);
  toggle("univServicesEvaluationErrorToGetEvaluationForm", false);

  const { campusID } = getState().AUTH;

  const url = `${URL}enrollment/getCourseEvaluationForm/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET COURSE EVALUATION FORM", result);

      const data = result.data;
      const payload = {
        evalID: data.evalID,
        parameters: [],
      };

      const getRatings = (ratingTemplateID) => {
        let rating = [];
        for (const item of data.rating) {
          
          if (item.evalTemplateID === ratingTemplateID) {
            rating.push({
              id: item.id,
              description: item.description,
              rating: item.rating,
              isActive: item.isActive,
              alias: item.alias,
              sortOrder: item.sortOrder,
            });
          }
        }
        rating = sortByObjectKeyAsc(rating, "sortOrder");
        return rating;
      };

      const getStatements = (parameterID) => {
        const statements = [];
        // const statements = data.statements;
        for (const item of data.statements) {
          let ratingTemplateID = item.ratingTemplateID;
          if (item.parameterID === parameterID) {
            statements.push({
              id: item.id,
              statement: item.statement,
              sortOrder: item.sortOrder,
              parameterID: item.parameterID,
              ratingTemplateID: item.ratingTemplateID,
              ratings: sortByObjectKeyAsc(item.ratings, "sortOrder"),
              // ratings: getRatings(item.ratingTemplateID),
            });
            // statements.push(item);
          }
        }
        return statements;
      };

      const getParameterQuestions = (parameterID) => {
        const parameterQuestions = [];
        for (const item of data.parameterQuestions) {
          if (item.parameterID === parameterID) {
            parameterQuestions.push({
              id: item.id,
              question: item.question,
              sortOrderN: item.sortOrderN,
              parameterID: item.parameterID,
              parameterPlaceHolder: item.placeHolder,
            });
          }
        }
        return parameterQuestions;
      };

      for (const item of data.parameters) {
        payload.parameters.push({
          id: item.id,
          name: item.name,
          sortOrderA: item.sortOrderA,
          statements: getStatements(item.id),
          parameterQuestions: getParameterQuestions(item.id),
        });
      }

      dispatch(UNIV_SERVICES_EVALUATION_COURSE_EVALUATION_FORM_SET, payload);

      toggle("univServicesEvaluationIsGettingEvaluationForm", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("GET COURSE EVALUATION FORM", error);
      toggle("univServicesEvaluationErrorToGetEvaluationForm", true);
      toggle("univServicesEvaluationIsGettingEvaluationForm", false);
      if (isFunction(onFail)) onFail();
    });
};



//CUSTOMER FEEDBACK FORM COMPONENT
export const getCustomerFeedbackForm = (onSuccess, onFail) => {
  toggle("univServicesEvaluationIsGettingEvaluationForm", true);
  toggle("univServicesEvaluationErrorToGetEvaluationForm", false);

  const { campusID } = getState().AUTH;

  const url = `${URL}enrollment/getCustomerFeedbackForm/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET CUSTOMER FEEDBACK FORM", result);

      const data = result.data;
      const payload = {
        evalID: data.evalID,
        purpose: [],
        parameters: [],
        otherParamQuestion: [],
      };

      for (const item of data.purpose) {
        if (item.desc === "others") continue;
        payload.purpose.push({
          id: item.id,
          name: item.name,
          desc: item.desc,
          sortOrderN: item.sortOrderN,
          sortOrderA: item.sortOrderA,
        });
      }

      for (const item of data.parameterQuestions) {
        if (item.parameterID === 0) {
          payload.otherParamQuestion.push({
            id: item.id,
            question: item.question,
            sortOrderN: item.sortOrderN,
            parameterID: item.parameterID,
          });
        }
      }

      const getRatings = () => {
        let rating = [];
        for (const item of data.rating) {
          rating.push({
            id: item.id,
            description: item.description,
            rating: item.rating,
            isActive: item.isActive,
            alias: item.alias,
            sortOrder: item.sortOrder,
          });
        }
        rating = sortByObjectKeyAsc(rating, "sortOrder");
        return rating;
      };

      const getStatements = (parameterID) => {
        const statements = [];
        for (const item of data.statements) {
          if (item.parameterID === parameterID) {
            statements.push({
              id: item.id,
              statement: item.statement,
              sortOrder: item.sortOrder,
              parameterID: item.parameterID,
              ratings: getRatings(),
            });
          }
          if (item.parameterID === 0) {
            payload.otherParamQuestion.push({
              id: item.id,
              statement: item.statement,
              sortOrder: item.sortOrder,
              parameterID: item.parameterID,
            });
          }
        }
        return statements;
      };

      const getParameterQuestions = (parameterID) => {
        const parameterQuestions = [];
        for (const item of data.parameterQuestions) {
          if (item.parameterID === parameterID) {
            parameterQuestions.push({
              id: item.id,
              question: item.question,
              sortOrderN: item.sortOrderN,
              parameterID: item.parameterID,
            });
          }
        }
        return parameterQuestions;
      };

      for (const item of data.parameters) {
        payload.parameters.push({
          id: item.id,
          name: item.name,
          sortOrderA: item.sortOrderA,
          statements: getStatements(item.id),
          parameterQuestions: getParameterQuestions(item.id),
        });
      }
      dispatch(UNIV_SERVICES_EVALUATION_STUDENT_CUSTOMER_FEEDBACK_SET, payload);

      toggle("univServicesEvaluationIsGettingEvaluationForm", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("GET CUSTOMER FEEDBACK FORM", error);
      toggle("univServicesEvaluationErrorToGetEvaluationForm", true);
      toggle("univServicesEvaluationIsGettingEvaluationForm", false);
      if (isFunction(onFail)) onFail();
    });
};



//CHECK OFFICE TO EVALUATE - INACTIVE
export const getOfficesToEvaluate = (onSuccess, onFail) => {
  toggle("univServicesEvaluationIsGettingOfficesToEvaluate", true);
  toggle("univServicesEvaluationFailedToGetOfficesToEvaluate", false);

  const { campusID } = getState().AUTH;

  const url = `${URL}enrollment/getOfficesToEvaluate/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET OFFICES TO EVALUATE", result);

      const data = result.data;
      const payload = {
        frontline: [],
        nonFrontline: [],
      };

      for (const item of data) {
        payload[item.officeTypeID === 1 ? "frontline" : "nonFrontline"].push({
          id: item.officeID,
          label: item.name,
        });
      }

      payload.frontline = sortByObjectKeyAsc(payload.frontline, "label");
      payload.nonFrontline = sortByObjectKeyAsc(payload.nonFrontline, "label");

      dispatch(
        UNIV_SERVICES_EVALUATION_STUDENT_OFFICES_TO_EVALUATE_SET,
        payload
      );

      toggle("univServicesEvaluationIsGettingOfficesToEvaluate", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("GET OFFICES TO EVALUATE", error);
      toggle("univServicesEvaluationFailedToGetOfficesToEvaluate", true);
      toggle("univServicesEvaluationIsGettingOfficesToEvaluate", false);
      if (isFunction(onFail)) onFail();
    });
};


//SAVE FACULTY EVALUATION
export const saveFacultyEvaluation = (data, onSuccess, onFail) => {
  toggle("univServicesEvaluationIsSavingEvaluationForm", true);

  print("DATA: saveFacultyEvaluation", data);

  const url = `${URL}enrollment/saveFacultyEvaluation`;

  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("SAVE FACULTY EVALUATION", result);
      toggle("univServicesEvaluationIsSavingEvaluationForm", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("SAVE FACULTY EVALUATION", error);
      toggle("univServicesEvaluationIsSavingEvaluationForm", false);
      if (isFunction(onFail)) onFail();
    });
};


//SAVE STUDENT SATISFACTION
export const saveStudentSatisfaction = (data, onSuccess, onFail) => {
  toggle("univServicesEvaluationIsSavingEvaluationForm", true);

  print("DATA: saveStudentSatisfaction", data);

  const url = `${URL}enrollment/saveStudentSatisfaction`;

  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("SAVE STUDENT SATISFACTION", result);
      if (result.status) {
        if (isFunction(onSuccess)) onSuccess();
      } else if (isFunction(onFail)) onFail();
      toggle("univServicesEvaluationIsSavingEvaluationForm", false);
    })
    .catch((error) => {
      print("SAVE STUDENT SATISFACTION", error);
      toggle("univServicesEvaluationIsSavingEvaluationForm", false);
      if (isFunction(onFail)) onFail();
    });
};


//SAVE COURSE EVALUATION
export const saveCourseEvaluation = (data, onSuccess, onFail) => {
  toggle("univServicesEvaluationIsSavingEvaluationForm", true);

  print("DATA: saveCourseEvaluation", data);

  const url = `${URL}enrollment/saveCourseEvaluation`;

  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("SAVE COURSE EVALUATION", result);
      if (result.status) {
        if (isFunction(onSuccess)) onSuccess();
      } else if (isFunction(onFail)) onFail();
      toggle("univServicesEvaluationIsSavingEvaluationForm", false);
    })
    .catch((error) => {
      print("SAVE COURSE EVALUATION", error);
      toggle("univServicesEvaluationIsSavingEvaluationForm", false);
      if (isFunction(onFail)) onFail();
    });
};

//SAVE CUSTOMER FEEDBACK
export const saveCustomerFeedback = (data, onSuccess, onFail) => {
  toggle("univServicesEvaluationIsSavingEvaluationForm", true);

  print("DATA: saveCustomerFeedback", data);

  const url = `${URL}enrollment/saveCustomerFeedback`;

  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("SAVE CUSTOMER FEEDBACK", result);
      toggle("univServicesEvaluationIsSavingEvaluationForm", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("SAVE CUSTOMER FEEDBACK", error);
      toggle("univServicesEvaluationIsSavingEvaluationForm", false);
      if (isFunction(onFail)) onFail();
    });
};


//CHECK EVALUATION STATUS
export const getEvaluationStatus = (onSuccess, onFail) => {
  toggle("univServicesEvaluationIsCheckingEvaluationStatus", true);
  toggle("univServicesEvaluationErrorToCheckEvaluationStatus", false);

  const { studentID, campusID } = getState().AUTH;

  const url = `${URL}enrollment/getEvaluationStatus/${studentID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET EVALUATION STATUS", result);
      const data = result.data;
      if (data.isFacEvalComplete) {
        toggle("hasCompletedFacultyEvaluation", true);
      }
      if (data.isStudSatisfactionComplete) {
        toggle("hasCompletedStudentSatisfaction", true);
      }

      // ADDED - Course Evaluation
      if (data.isCourseEvalComplete) {
        toggle("hasCompletedCourseEvaluation", true);
      }

      toggle("evaluationChecked", true);

      toggle("univServicesEvaluationIsCheckingEvaluationStatus", false);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("GET EVALUATION STATUS", error);
      toggle("univServicesEvaluationErrorToCheckEvaluationStatus", true);
      toggle("univServicesEvaluationIsCheckingEvaluationStatus", false);
      if (isFunction(onFail)) onFail();
    });
};

export const clearUnivServicesEvaluationData = () => {
  dispatch(UNIV_SERVICES_EVALUATION_CLEAR);
};

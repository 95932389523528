import { getEvaluationStatus } from "../redux/actions/universityServicesEvaluation";
import { getState } from "../redux/actions";
import { canRegister } from "../redux/actions/enrollment";

export const globalActions = () => {
  const toggles = getState().CONTEXT.toggles;

  if (getState().AUTH.isAuthenticated) {
    if (
      !toggles.evaluationChecked &&
      !toggles.univServicesEvaluationIsCheckingEvaluationStatus
    )
      getEvaluationStatus();

    // if (
    //   !toggles.enrollmentCanRegisterHasCalled &&
    //   !toggles.enrollmentIsCheckingCanRegister
    // )
    //   canRegister();
  }
};

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import Typography from "../../../components/core/typography/Typography";
import Space from "../../../components/core/Space";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import ProgressBar from "../../../components/custom/charts/ProgressBar";
import LineBreak from "../../../components/core/LineBreak";
import LineGraphDetailed from "../../../components/custom/charts/LineGraphDetailed";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import { getDashboardInfo } from "../../../redux/actions/dashboard";

export default function DashboardStatistics() {
  const styles = style();
  const isDownXS = useBreakpointDown("xs");
  const isDownSM = useBreakpointDown("sm");

  const {
    currUnitsEarned,
    currUnitsTotal,
    currNumCourses,
    currCoursesCreditUnits,
    currCoursesLecUnits,
    currCoursesLabUnits,
    currCreditedCourses,
    currCreditedUnits,
    currLecUnitsEarned,
    currLabUnitsEarned,
    currCreditCourseToEarn,
    currCreditUnitsToEarn,
    currCreditLecUnitsToEarn,
    currCreditLabUnitsToEarn,
  } = useSelector((state) => ({
    currUnitsEarned: state.DASHBOARD.currUnitsEarned,
    currUnitsTotal: state.DASHBOARD.currUnitsTotal,
    currNumCourses: state.DASHBOARD.currNumCourses,
    currCoursesCreditUnits: state.DASHBOARD.currCoursesCreditUnits,
    currCoursesLecUnits: state.DASHBOARD.currCoursesLecUnits,
    currCoursesLabUnits: state.DASHBOARD.currCoursesLabUnits,
    currCreditedCourses: state.DASHBOARD.currCreditedCourses,
    currCreditedUnits: state.DASHBOARD.currCreditedUnits,
    currLecUnitsEarned: state.DASHBOARD.currLecUnitsEarned,
    currLabUnitsEarned: state.DASHBOARD.currLabUnitsEarned,
    currCreditCourseToEarn: state.DASHBOARD.currCreditCourseToEarn,
    currCreditUnitsToEarn: state.DASHBOARD.currCreditUnitsToEarn,
    currCreditLecUnitsToEarn: state.DASHBOARD.currCreditLecUnitsToEarn,
    currCreditLabUnitsToEarn: state.DASHBOARD.currCreditLabUnitsToEarn,
  }));

  useEffect(() => {
    getDashboardInfo();
  }, []);

  const stats = {
    coursesInCurriculum: [
      {
        label: `Lecture Unit${currCoursesLecUnits > 1 ? "s" : ""}`,
        value: currCoursesLecUnits,
        color: "warning",
      },
      {
        label: `Laboratory Unit${currCoursesLabUnits > 1 ? "s" : ""}`,
        value: currCoursesLabUnits,
        color: "primary",
      },
    ],
    coursesInCurriculumCourses: currNumCourses,
    coursesInCurriculumCreditUnits: currCoursesCreditUnits,
    creditedCourses: [
      {
        label: "Lec Units Earned",
        value: currLecUnitsEarned,
        color: "warning",
      },
      {
        label: "Lab Units Earned",
        value: currLabUnitsEarned,
        color: "primary",
      },
    ],
    creditedCoursesCourses: currCreditedCourses,
    creditedCoursesCreditUnits: currCreditedUnits,
    creditCourseToEarn: [
      {
        label: "Lec Units to Earn",
        value: currCreditLecUnitsToEarn,
        color: "warning",
      },
      {
        label: "Lab Units to Earn",
        value: currCreditLabUnitsToEarn,
        color: "primary",
      },
    ],
    creditCourseToEarnCourses: currCreditCourseToEarn,
    creditCourseToEarnCreditUnitsToEarn: currCreditUnitsToEarn,
  };

  return (
    <div>
      <Typography variant={"h5"} weight={"semibold"}>
        Overall Progress
      </Typography>
      <Space vertical space={3} />
      <div className={styles.progressStat}>
        <FlexContainer alignItems={"flex-end"} justifyContent={"space-between"}>
          <Typography variant={"h5"} weight={"light"}>
            {currUnitsEarned}
            <Typography variant={"caption"} span>
              &nbsp;&nbsp; Unit{currUnitsEarned > 1 ? "s" : ""} Earned
            </Typography>
          </Typography>
          <Typography variant={"h5"} weight={"light"}>
            {currUnitsTotal}
            <Typography variant={"caption"} span>
              &nbsp;&nbsp; Total Unit{currUnitsTotal > 1 ? "s" : ""}
            </Typography>
          </Typography>
        </FlexContainer>
      </div>
      <Space vertical space={1.5} />
      <ProgressBar
        variant={"success"}
        value={currUnitsTotal ? (currUnitsEarned / currUnitsTotal) * 100 : 0}
      />
      <Space vertical space={isDownSM ? 3 : 4} />
      <LineBreak />
      <Space vertical space={isDownSM ? 2 : 3} />
      <LineGraphDetailed
        title={"Courses in Curriculum"}
        stats={stats.coursesInCurriculum}
        minWidth={0}
        headerRightElement={
          <div>
            <FlexContainer>
              <Space space={5} />
              <Typography variant={"h5"} weight={"medium"}>
                {stats.coursesInCurriculumCourses}
                <Typography variant={"caption"} weight={"bold"} span>
                  &nbsp;&nbsp; Course
                  {stats.coursesInCurriculumCourses > 1 ? "s" : ""}
                </Typography>
              </Typography>
              {!isDownXS && (
                <FlexItem>
                  <FlexContainer>
                    <Space space={2} />
                    <Typography variant={"h5"} weight={"medium"}>
                      {stats.coursesInCurriculumCreditUnits}
                      <Typography variant={"caption"} weight={"bold"} span>
                        &nbsp;&nbsp; Credit Unit
                        {stats.coursesInCurriculumCreditUnits > 1 ? "s" : ""}
                      </Typography>
                    </Typography>
                  </FlexContainer>
                </FlexItem>
              )}
            </FlexContainer>
          </div>
        }
      />
      <Space vertical space={isDownSM ? 2 : 3} />
      <LineGraphDetailed
        title={"Credited Courses"}
        stats={stats.creditedCourses}
        minWidth={0}
        headerRightElement={
          <div>
            <FlexContainer>
              <Space space={5} />
              <Typography variant={"h5"} weight={"medium"}>
                {stats.creditedCoursesCourses}
                <Typography variant={"caption"} weight={"bold"} span>
                  &nbsp;&nbsp; Course
                  {stats.creditedCoursesCourses > 1 ? "s" : ""}
                </Typography>
              </Typography>
              {!isDownXS && (
                <FlexItem>
                  <FlexContainer>
                    <Space space={2} />
                    <Typography variant={"h5"} weight={"medium"}>
                      {stats.creditedCoursesCreditUnits}
                      <Typography variant={"caption"} weight={"bold"} span>
                        &nbsp;&nbsp; Credit Unit
                        {stats.creditedCoursesCreditUnits > 1 ? "s" : ""}
                      </Typography>
                    </Typography>
                  </FlexContainer>
                </FlexItem>
              )}
            </FlexContainer>
          </div>
        }
      />
      <Space vertical space={isDownSM ? 2 : 3} />
      <LineGraphDetailed
        title={"Credit Course to Earn"}
        stats={stats.creditCourseToEarn}
        minWidth={0}
        headerRightElement={
          <div>
            <FlexContainer>
              <Space space={3} />
              <Typography variant={"h5"} weight={"medium"}>
                {stats.creditCourseToEarnCourses}
                <Typography variant={"caption"} weight={"bold"} span>
                  &nbsp;&nbsp; Course
                  {stats.creditCourseToEarnCourses > 1 ? "s" : ""}
                </Typography>
              </Typography>
              {!isDownXS && (
                <FlexItem>
                  <FlexContainer>
                    <Space space={2} />
                    <Typography variant={"h5"} weight={"medium"}>
                      {stats.creditCourseToEarnCreditUnitsToEarn}
                      <Typography variant={"caption"} weight={"bold"} span>
                        &nbsp;&nbsp; Unit
                        {stats.creditCourseToEarnCreditUnitsToEarn > 1
                          ? "s"
                          : ""}{" "}
                        to Earn
                      </Typography>
                    </Typography>
                  </FlexContainer>
                </FlexItem>
              )}
            </FlexContainer>
          </div>
        }
      />
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  genInfoItem: {
    // marginBottom: 8
  },
  infoLabel: {
    width: "30%",
  },
  infoValue: {
    width: "calc(70% - 24px)",
    marginTop: "-2px",
  },
  progressStat: {
    padding: "0 16px",
  },
}));

import React from "react";
import Typography from "../core/typography/Typography";
import Space from "../core/Space";
import Button from "../core/button/Button";
import FlexContainer from "../core/containers/flex/FlexContainer";

export default function EmptyContent({
  reload,
  noButton,
  title,
  description,
  noDescription,
}) {
  return (
    <>
      <FlexContainer
        height={300}
        alignItems={"center"}
        justifyContent={"center"}
        direction={"column"}
      >
        <div>
          <Typography variant={"h5"} align={"center"}>
            {title || "No results found"}
          </Typography>
          <Space vertical space={1} />
          <Typography align={"center"}>
            {!noDescription && (description || "Please try to refresh")}
          </Typography>
          {!noButton && (
            <div>
              <Space vertical space={3} />
              <Button onClick={reload} fullwidth>
                Refresh
              </Button>
            </div>
          )}
        </div>
      </FlexContainer>
    </>
  );
}

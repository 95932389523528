import {MESSAGE_ADD, MESSAGE_CLEAR, MESSAGE_REMOVE} from "../types";
import {dispatch} from "./index";

export const addMessage = (message, action = 'enqueue', variant = 'info', persist = false) => {
    dispatch(
        MESSAGE_ADD, {
            message, action, variant, persist
        }
    )
}

export const removeMessage = message => {
    dispatch(MESSAGE_REMOVE, message)
}

export const clearMessage = () => {
    dispatch(MESSAGE_CLEAR)
}
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "../components/core/typography/Typography";
import Space from "../components/core/Space";
import Input from "../components/core/input/Input";
import FlexContainer from "../components/core/containers/flex/FlexContainer";
import Checkbox from "../components/core/input/Checkbox";
import Button from "../components/core/button/Button";
import zxcvbn from "zxcvbn";
import { useSelector } from "react-redux";
import FlexItem from "../components/core/containers/flex/FlexItem";
import MessageDialog from "../components/core/dialogs/MessageDialog";
import checkboxCircleLine from "@iconify/icons-ri/checkbox-circle-line";
import LineBreak from "../components/core/LineBreak";
import IconMini from "../components/core/icons/IconMini";
import checkLine from "@iconify/icons-ri/check-line";
import { useForm } from "react-hook-form";
import useTheme from "@material-ui/core/styles/useTheme";
import { isFunction } from "../utils/misc";
import { changePassword } from "../redux/actions/auth";

export const passwordMinLength = 8;

export default function ChangePassword({
  fullWidthButton,
  onCancel,
  showCancel,
  fullHeight,
  noTitle,
  align,
  noSubmit,
  noPresentPass,
  getForm,
}) {
  // const { form.register, form.handleSubmit, form.watch, form.errors, form.reset } = useForm();
  const form = useForm();
  const oldPass = form.watch("oldPass", "");
  const newPass = form.watch("newPass", "");
  const confirmPass = form.watch("confirmPass", "");

  const styles = style();
  const theme = useTheme();

  const [passwordStrength, setPasswordStrength] = useState(1);

  const [submitSuccessDialog, setSubmitSuccessDialog] = useState(false);
  const [submitErrorDialog, setSubmitErrorDialog] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { studentID, campusID, isChangingPassword } = useSelector((state) => ({
    studentID: state.AUTH.studentID,
    campusID: state.AUTH.campusID,
    isChangingPassword: state.CONTEXT.toggles.isChangingPassword,
  }));
  if (oldPass || newPass || confirmPass) showCancel = true;

  const submit = (data) => {
    closeError();
    const payload = {
      studentID,
      campusID,
      oldPass: data.oldPass,
      newPass: data.newPass,
      confirmPass: data.confirmPass,
    };
    const onSuccess = () => {
      openSuccess();
      cancel();
    };
    const onFail = (message) => {
      setErrorTitle(message.title);
      setErrorMessage(message.description);
      openError();
    };
    changePassword(payload, onSuccess, onFail);
  };

  const cancel = () => {
    form.reset({
      oldPass: "",
      newPass: "",
      confirmPass: "",
    });
    if (isFunction(onCancel)) onCancel();
  };

  const onEnter = (event) => {
    if (event.key === "Enter") form.handleSubmit(submit)();
  };

  const openSuccess = () => setSubmitSuccessDialog(true);
  const closeSuccess = () => setSubmitSuccessDialog(false);
  const openError = () => setSubmitErrorDialog(true);
  const closeError = () => setSubmitErrorDialog(false);

  const checkPasswordStrength = (password) => {
    return zxcvbn(password).score + 1;
  };
  const passStrength = checkPasswordStrength(newPass);
  if (newPass && passwordStrength !== passStrength)
    setPasswordStrength(passStrength);

  const validationStatus = [
    {
      id: 1,
      desc: `Minimum of 8 characters`,
      satisfied: newPass && newPass.length >= passwordMinLength,
    },
    {
      id: 2,
      desc: "An uppercase character",
      satisfied: newPass && /[A-Z]/.test(newPass),
    },
    {
      id: 3,
      desc: "A lowercase character",
      satisfied: newPass && /[a-z]/.test(newPass),
    },
    {
      id: 4,
      desc: "A number",
      satisfied: newPass && /[0-9]/.test(newPass),
    },
    {
      id: 5,
      desc: "A special character",
      satisfied:
        newPass && /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPass),
    },
  ];

  if (isFunction(getForm)) getForm(form);

  return (
    <div
      className={styles.root}
      style={{ height: fullHeight && "calc(100vh - 64px - 15px)" }}
    >
      <FlexContainer direction={"column"} justifyContent={"space-between"}>
        <FlexItem>
          {!noTitle && (
            <>
              <Typography variant={"h5"} weight={"semibold"}>
                Change Password
              </Typography>
              <Space vertical space={3} />
            </>
          )}
          {!noPresentPass && (
            <>
              <Input
                inputRef={form.register({
                  required: "Present password required",
                })}
                name={"oldPass"}
                type={"password"}
                placeholder={"Present Password"}
                error={form.errors.oldPass?.message}
                onKeyDown={onEnter}
              />
              <Space vertical space={2} />
              <LineBreak />
              <Space vertical space={1} />
            </>
          )}
          <Typography>New password must contain the following:</Typography>
          <Space vertical space={1} />
          {validationStatus.map((item, index) => (
            <div key={item.id}>
              {index > 0 && <Space vertical space={0.5} />}
              <FlexContainer>
                <FlexItem>
                  {item.satisfied ? (
                    <IconMini
                      icon={checkLine}
                      height={18}
                      padding={2}
                      filled
                      variant={"success"}
                    />
                  ) : (
                    <IconMini height={18} padding={2} filled />
                  )}
                </FlexItem>
                <Space space={2} />
                <Typography align={"justify"}>{item.desc}</Typography>
              </FlexContainer>
            </div>
          ))}

          <Space vertical space={2} />

          <Input
            inputRef={form.register({
              required: "Password required",
              minLength: {
                value: passwordMinLength,
                message: "Minimum of 8 characters",
              },
              validate: {
                lowercase: (value) =>
                  /[a-z]/.test(value) || "Must contain a lowercase character",
                uppercase: (value) =>
                  /[A-Z]/.test(value) || "Must contain an uppercase character",
                number: (value) =>
                  /[0-9]/.test(value) || "Must contain a number",
                special: (value) =>
                  /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value) ||
                  "Must contain a special character",
              },
            })}
            name={"newPass"}
            type={showPassword ? "text" : "password"}
            placeholder={"New Password"}
            align={"center"}
            error={form.errors.newPass?.message}
            onKeyDown={onEnter}
          />

          <>
            <Space vertical space={1.5} />
            <div className={styles.passwordMeterBarBase}>
              <div
                className={styles.passwordMeterBar}
                style={{
                  width: newPass ? `${passwordStrength * 20}%` : 0,
                  background:
                    passwordStrength &&
                    theme.palette[
                      passwordMeter["score" + passwordStrength].color
                    ].gradient,
                }}
              />
            </div>
            {newPass && (
              <>
                <Space vertical space={0.5} />
                <Typography variant={"caption"} align={align}>
                  Password {passwordMeter["score" + passwordStrength].message}.
                </Typography>
              </>
            )}
          </>
          <div>
            <Space vertical space={2} />
            <Input
              inputRef={form.register({
                required: "Confirm your password",
                validate: {
                  match: (confirmPass) =>
                    confirmPass === newPass || "Password does not match",
                },
              })}
              name={"confirmPass"}
              type={showPassword ? "text" : "password"}
              placeholder={"Confirm Password"}
              error={form.errors.confirmPass?.message}
              onKeyDown={onEnter}
            />
          </div>
          <Space vertical space={2} />
          <div>
            <FlexContainer
              alignItems={"center"}
              justifyContent={align}
              onClick={() => setShowPassword((showPassword) => !showPassword)}
            >
              <Checkbox checked={showPassword} color={"primary"} />
              <Space space={2} />
              <Typography>Show Password</Typography>
            </FlexContainer>
          </div>
        </FlexItem>
        {!noSubmit && (
          <FlexItem>
            <Space vertical space={3} />
            <Button
              variant={"primary"}
              size={"medium"}
              fullwidth
              onClick={form.handleSubmit(submit)}
              isLoading={isChangingPassword}
            >
              Change Password
            </Button>
            {showCancel && (
              <div>
                <Space vertical space={1} />
                <Button fullwidth onClick={cancel}>
                  Cancel
                </Button>
              </div>
            )}
          </FlexItem>
        )}
      </FlexContainer>

      <MessageDialog
        open={submitSuccessDialog}
        variant={"success"}
        title={"Password Changed"}
        singleAction
        icon={checkboxCircleLine}
        onConfirmLabel={"Okay"}
        description={
          "Your password has been changed successfully! Please remember your new password."
        }
        onClose={closeSuccess}
        onConfirm={closeSuccess}
      />

      <MessageDialog
        open={submitErrorDialog}
        variant={"error"}
        title={errorTitle}
        onCloseLabel={"Close"}
        onConfirmLabel={"Retry"}
        description={errorMessage}
        onClose={closeError}
        onConfirm={form.handleSubmit(submit)}
      />
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  passwordMeterBarBase: {
    height: 15,
    width: "100%",
    background: theme.palette.grey.shade5,
    borderRadius: theme.shape.borderRadiusNormal,
  },
  passwordMeterBar: {
    height: 15,
    borderRadius: theme.shape.borderRadiusNormal,
    transition: "width .5s ease-in-out",
  },
}));

export const passwordMeter = {
  score1: {
    message: "is too weak",
    color: "error",
  },
  score2: {
    message: "is weak",
    color: "error",
  },
  score3: {
    message: "has moderate strength",
    color: "warning",
  },
  score4: {
    message: "is strong",
    color: "success",
  },
  score5: {
    message: "is very strong",
    color: "success",
  },
};

import React from "react";
import Grid from "@material-ui/core/Grid";

export default function GridContainer(props) {
  return (
    <Grid
      container
      style={{
        width: `calc(100% + ${props.spacing * 8}px)`,
      }}
      {...props}
    >
      {props.children}
    </Grid>
  );
}

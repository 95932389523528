import React, { Fragment, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "./index";
import LoadingComponents from "../components/core/loader/LoadingComponents";
import Dashboard from "../pages/menu/dashboard/Dashboard";
import Grades from "../pages/menu/Grades";
import ProgramEvaluation from "../pages/menu/programEvaluation/ProgramEvaluation";
import Enrollment from "../pages/menu/enrollment/Enrollment";
import Accountabilities from "../pages/menu/Accountabilities";
import UniversityServicesEvaluation from "../pages/menu/servicesEvaluation/UniversityServicesEvaluation";
import Profile from "../pages/menu/Profile";

export default function HomeRoutes() {
  return (
    <Fragment>
      <Suspense fallback={<LoadingComponents mini />}>
        <Switch>
          <Route exact path={routes.dashboard} component={Dashboard} />
          <Route exact path={routes.grades} component={Grades} />
          <Route
            exact
            path={routes.programEvaluation}
            component={ProgramEvaluation}
          />
          <Route exact path={routes.enrollment} component={Enrollment} />
          <Route
            exact
            path={routes.accountabilities}
            component={Accountabilities}
          />
          <Route
            exact
            path={routes.universityServicesEvaluation}
            component={UniversityServicesEvaluation}
          />
          <Route exact path={routes.profile} component={Profile} />
        </Switch>
      </Suspense>
    </Fragment>
  );
}

import React, { Fragment, useEffect } from "react";
import ReactHelmet from "../../../components/core/Helmet";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import Typography from "../../../components/core/typography/Typography";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import Space from "../../../components/core/Space";
import Button from "../../../components/core/button/Button";
import refreshLine from "@iconify/icons-ri/refresh-line";
import Select from "../../../components/core/input/Select";
import {
  useBreakpointBetween,
  useBreakpointDown,
} from "../../../utils/hooks/mediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { getRemarks, gradeSerializer } from "../../../utils/grades";
import {
  getAcademicEvaluation,
  reEvaluate,
} from "../../../redux/actions/programEvaluation";
import { useSelector } from "react-redux";
import { setContext } from "../../../redux/actions/context";
import Table from "../../../components/core/table/Table";
import { useHistory } from "react-router";
import { routes } from "../../../routes";
import MessageDialog from "../../../components/core/dialogs/MessageDialog";
import { getEvaluationStatus } from "../../../redux/actions/universityServicesEvaluation";
import filePaperLine from "@iconify/icons-ri/file-paper-line";

export default function ProgramEvaluation() {
  const styles = style();
  const history = useHistory();
  const isDownSM = useBreakpointDown("sm");
  const isMD = useBreakpointBetween("md", "md");

  const {
    academicEvaluationSemester,
    isGettingAcademicEvaluation,
    isReEvaluatingAcademicEvaluation,
    evaluationChecked,
    hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction,

    // ADDED - Course Evaluation
    hasCompletedCourseEvaluation,

    univServicesEvaluationIsCheckingEvaluationStatus,
    semesters,
    academicEvaluationData,
  } = useSelector((state) => ({
    academicEvaluationSemester: state.CONTEXT.academicEvaluationSemester,
    isGettingAcademicEvaluation:
      state.CONTEXT.toggles.isGettingAcademicEvaluation,
    isReEvaluatingAcademicEvaluation:
      state.CONTEXT.toggles.isReEvaluatingAcademicEvaluation,
    evaluationChecked: state.CONTEXT.toggles.evaluationChecked,
    hasCompletedFacultyEvaluation:
      state.CONTEXT.toggles.hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction:
      state.CONTEXT.toggles.hasCompletedStudentSatisfaction,

      // ADDED - Course Evaluation
      hasCompletedCourseEvaluation :
        state.CONTEXT.toggles.hasCompletedCourseEvaluation ,
  
    univServicesEvaluationIsCheckingEvaluationStatus:
      state.CONTEXT.toggles.univServicesEvaluationIsCheckingEvaluationStatus,
    semesters: state.ACADEMIC_EVALUATION.semesters,
    academicEvaluationData: state.ACADEMIC_EVALUATION,
  }));

  let semesterCourses = [];
  if (academicEvaluationSemester === "all")
    semesters.forEach((item) => {
      semesterCourses = semesterCourses.concat(academicEvaluationData[item.id]);
    });
  else semesterCourses = academicEvaluationData[academicEvaluationSemester];

  useEffect(() => {
    if (!evaluationChecked) getEvaluationStatus();
  }, [evaluationChecked]);

  useEffect(() => {
    if (
      semesters.length < 1 &&
      hasCompletedFacultyEvaluation &&
      hasCompletedStudentSatisfaction
      
      &&
      hasCompletedCourseEvaluation 
    )
      getAcademicEvaluation();
  }, [
    hasCompletedFacultyEvaluation,
    hasCompletedStudentSatisfaction,

    hasCompletedCourseEvaluation, 

    semesters.length,
  ]);

  const enterFullscreen = () => {
    history.push(routes.programEvaluationFullscreen);
  };
  const changeSemester = (id) => {
    setContext("academicEvaluationSemester", id);
  };
  const goToFacultyEvaluation = () => {
    history.push(routes.universityServicesEvaluationFaculty);
  };
  const goToStudentSatisfaction = () => {
    history.push(routes.universityServicesEvaluationStudentSatisfaction);
  };

  // ADDED - Subject Evaluation
  const goToSubjectEvaluation = () => {
    history.push(routes.universityServicesEvaluationSubject);
  };

  // ADDED - Course Evaluation
  const goToCourseEvaluation = () => {
    history.push(routes.universityServicesEvaluationCourseEvaluation);
  };

  const goBack = () => {
    history.goBack();
  };

  const semestersList = [
    {
      id: "all",
      label: "Show All",
      data: {},
    },
  ].concat(semesters);

  const evaluationColumns = [
    {
      label: "Year / Term",
      align: "left",
      minWidth: 180,
      hidden: academicEvaluationSemester !== "all",
    },
    {
      label: "Code",
      align: "left",
      weight: "semibold",
      noWrap: true,
    },
    {
      label: "Title",
      align: "left",
      minWidth: 250,
    },
    {
      label: "Credit Units",
      align: "center",
    },
    {
      label: "Final",
      align: "center",
      weight: "semibold",
      wrapper: gradeSerializer,
    },
    {
      label: "Re Exam",
      align: "center",
      weight: "semibold",
      wrapper: gradeSerializer,
    },
    {
      label: "Remarks",
      align: "center",
      wrapper: getRemarks,
    },
  ];

  const evaluationData = [];
  if (semesterCourses && semesterCourses.length > 0) {
    for (let i of semesterCourses) {
      evaluationData.push([
        i.semester,
        i.courseCode,
        i.courseTitle,
        i.creditUnits,
        i.final,
        i.reExam,
        i.remarks,
      ]);
    }
  }

  return (
    <Fragment>
      <ReactHelmet title={"Program Evaluation"} />
      {!isDownSM ? (
        <Fragment>
          <FlexContainer justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant={"h5"} weight={"semibold"}>
              Academic Program Evaluation
            </Typography>
            <FlexItem>
              <FlexContainer alignItems={"center"}>
                <Select
                  value={academicEvaluationSemester}
                  onSelect={changeSemester}
                  items={semestersList}
                  placeholder={"Year & Term"}
                  width={isMD ? 250 : 250}
                />
                <Space space={2} />
                <Button
                  variant={"warning"}
                  icon={refreshLine}
                  isLoading={
                    isGettingAcademicEvaluation ||
                    univServicesEvaluationIsCheckingEvaluationStatus ||
                    isReEvaluatingAcademicEvaluation
                  }
                  onClick={reEvaluate}
                >
                  <Typography color={"white"} nowrap>
                    Re-Evaluate
                  </Typography>
                </Button>
                <Space space={2} />
                <Button
                  icon={filePaperLine}
                  onClick={enterFullscreen}
                  variant={"primary"}
                >
                  <Typography color={"white"} nowrap>
                    See Full Evaluation
                  </Typography>
                </Button>
              </FlexContainer>
            </FlexItem>
          </FlexContainer>
        </Fragment>
      ) : (
        <Fragment>
          <Typography variant={"h5"} weight={"semibold"} align={"left"}>
            Academic Program Evaluation
          </Typography>
          <Space vertical space={3} />
          <Select
            value={academicEvaluationSemester}
            onSelect={changeSemester}
            items={semestersList}
            placeholder={"Year & Term"}
          />
          <Space vertical space={2} />
          <FlexContainer direction={"column"} alignItems={"center"}>
            <FlexItem classNames={[styles.headingItem]}>
              <Button
                variant={"warning"}
                icon={refreshLine}
                isLoading={
                  isGettingAcademicEvaluation ||
                  univServicesEvaluationIsCheckingEvaluationStatus ||
                  isReEvaluatingAcademicEvaluation
                }
                onClick={reEvaluate}
                fullwidth
              >
                <Typography color={"white"} nowrap>
                  Re-Evaluate
                </Typography>
              </Button>
            </FlexItem>
            <FlexItem classNames={[styles.headingItem]}>
              <Button
                icon={filePaperLine}
                onClick={enterFullscreen}
                variant={"primary"}
                fullwidth
              >
                <Typography color={"white"} nowrap>
                  See Full Evaluation
                </Typography>
              </Button>
            </FlexItem>
          </FlexContainer>
        </Fragment>
      )}

      <Space vertical space={isDownSM ? 3 : 4} />

      <Table
        columns={evaluationColumns}
        data={evaluationData}
        scrollY
        initSortIndex={0}
        spacing={!isDownSM && "enlarged"}
      />

      <MessageDialog
          open={
            (!hasCompletedFacultyEvaluation ||
              !hasCompletedStudentSatisfaction
            
              ||
              !hasCompletedCourseEvaluation )
                &&  evaluationChecked
          }
          variant={"primary"}
          title={
            !hasCompletedFacultyEvaluation
              ? "Faculty Evaluation"
              : !hasCompletedStudentSatisfaction ? "Student Satisfaction" : "Course Evaluation"
          }
          onCloseLabel={"Back"}
          onClose={goBack}
          description={`
            Please complete the 
            ${
              !hasCompletedFacultyEvaluation
                ? "Faculty Evaluation"
                : !hasCompletedStudentSatisfaction ? "Student Satisfaction" : "Course Evaluation"
            } first to see Academic Program Evaluation.
          `}
          onConfirmLabel={
            !hasCompletedFacultyEvaluation
              ? "Faculty Evaluation"
              : !hasCompletedStudentSatisfaction ? "Student Satisfaction" : "Course Evaluation"
          }
          onConfirm={
            !hasCompletedFacultyEvaluation
              ? goToFacultyEvaluation
              : !hasCompletedStudentSatisfaction ? goToStudentSatisfaction : goToSubjectEvaluation
          }
      />
    </Fragment>
  );
}

const style = makeStyles((theme) => ({
  headingItem: {
    marginBottom: theme.spacing(1.5),
    width: "100%",
  },
}));

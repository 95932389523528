import makeStyles from "@material-ui/core/styles/makeStyles";

const globalStyle = makeStyles((theme) => ({
  root: {
    // height: '100vh',
    minHeight: "100vh",
    margin: 0,
    padding: 0,
    // transition: theme.transitions.main,
  },
  app: {
    backgroundColor: theme.palette.background,
    color: theme.palette.default.main,
  },
  ColorPaletteCircles: {
    height: 24,
    width: 24,
    borderRadius: 48,
    margin: 8,
    // transition: theme.transitions.main,
  },
  rotateRight90: {
    transition: theme.transitions.main,
    transform: "rotate(90deg)",
  },
  rotateLeft90: {
    transform: "rotate(-90deg)",
  },
  rotateRight180: {
    transform: "rotate(180deg)",
  },
  rotateLeft180: {
    transition: theme.transitions.faster,
    transform: "rotate(-180deg)",
  },
  stickyTop: {
    position: "-webkit-sticky",
    width: "100%",
    marginLeft: "-8px",
    paddingLeft: 8,
    paddingRight: 8,
    top: 0,
    background: theme.palette.background,
    zIndex: 2,
    "&": {
      position: "sticky",
    },
  },
  textNoSelect: {
    "-webkit-touch-callout": "none" /* iOS Safari */,
    "-webkit-user-select": "none" /* Safari */,
    "-khtml-user-select": "none" /* Konqueror HTML */,
    "-moz-user-select": "none" /* Old versions of Firefox */,
    "-ms-user-select": "none" /* Internet Explorer/Edge */,
    "user-select":
      "none" /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */,
  },
  pointerCursor: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default globalStyle;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FlexContainer from "../components/core/containers/flex/FlexContainer";
import FlexItem from "../components/core/containers/flex/FlexItem";

import USePLogo from "../static/media/img/USePLogo.png";
import SDMDLogo from "../static/media/img/SDMDLogo.png";
import Space from "../components/core/Space";
import LineBreak from "../components/core/LineBreak";
import Typography from "../components/core/typography/Typography";

export default function LoginHeader() {
  const styles = style();

  return (
    <div className={styles.root}>
      <div className={styles.topHeader}> </div>
      <Container fixed>
        <FlexContainer
          height={58}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <FlexItem>
            <FlexContainer alignItems={"center"}>
              <img src={USePLogo} alt={"USeP Logo"} height={36} />
              <Space space={2} />
              <LineBreak vertical length={32} />
              <Space space={2} />
              <FlexContainer direction={"column"}>
                <FlexContainer classNames={[styles.brandingText]}>
                  <Typography size={18} color={"secondary"} weight={"semibold"}>
                    One{" "}
                    <Typography
                      size={18}
                      color={"primary"}
                      weight={"semibold"}
                      span
                    >
                      Data.{" "}
                    </Typography>
                    One{" "}
                    <Typography
                      size={18}
                      color={"primary"}
                      weight={"semibold"}
                      span
                    >
                      USeP.{" "}
                    </Typography>
                  </Typography>
                </FlexContainer>
                <Typography size={14} weight={"semibold"}>
                  Student Portal
                </Typography>
              </FlexContainer>
            </FlexContainer>
          </FlexItem>
          <FlexItem>
            <FlexContainer alignItems={"center"}>
              <FlexContainer alignItems={"flex-end"} direction={"column"}>
                {/*<FlexContainer*/}
                {/*  justifyContent={"flex-end"}*/}
                {/*  classNames={[styles.brandingText]}*/}
                {/*>*/}
                {/*  <Typography size={18} weight={"semibold"}>*/}
                {/*    Systems and Data Management Division*/}
                {/*  </Typography>*/}
                {/*</FlexContainer>*/}
                {/*<Typography size={14} color={"grey1"} weight={"semibold"}>*/}
                {/*  University of Southeastern Philippines*/}
                {/*</Typography>*/}
                <Typography size={16} weight={"semibold"}>
                  Systems and Data
                </Typography>
                <Typography size={16} weight={"semibold"}>
                  Management Division
                </Typography>
              </FlexContainer>
              <Space space={2} />
              <LineBreak vertical length={32} />
              <Space space={2} />
              <img src={SDMDLogo} alt={"SDMD Logo"} height={36} />
            </FlexContainer>
          </FlexItem>
        </FlexContainer>
      </Container>
      <LineBreak />
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 10,
    background: theme.palette.background,
  },
  brandingText: {
    marginTop: "-2px",
  },
  topHeader: {
    height: 15,
    background: theme.palette.primary.gradient,
  },
}));

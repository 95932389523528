import React, { memo, useEffect, useState } from "react";
import ReactHelmet from "../../../components/core/Helmet";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import Typography from "../../../components/core/typography/Typography";
import Button from "../../../components/core/button/Button";
import Space from "../../../components/core/Space";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import LineBreak from "../../../components/core/LineBreak";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import { useSelector } from "react-redux";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import arrowLeftLine from "@iconify/icons-ri/arrow-left-line";
import sendPlaneLine from "@iconify/icons-ri/send-plane-line";
import Card from "../../../components/custom/card/Card";
import NoticeContainer from "../../../components/core/containers/NoticeContainer";
import MessageDialog from "../../../components/core/dialogs/MessageDialog";
import globalStyle from "../../../static/styles";
import checkboxCircleLine from "@iconify/icons-ri/checkbox-circle-line";
import Input from "../../../components/core/input/Input";
import {
  getEvaluationStatus,
  getFacultyEvaluationForm,
  getFacultyToEvaluate,
  saveFacultyEvaluation,
} from "../../../redux/actions/universityServicesEvaluation";
import RadioButtonNative from "../../../components/core/input/RadioButtonNative";
import LoadingComponents from "../../../components/core/loader/LoadingComponents";
import ErrorLoad from "../../../components/custom/ErrorLoad";
import { setContext } from "../../../redux/actions/context";
import { isEmptyObject } from "../../../utils/misc";
import EmptyContent from "../../../components/custom/EmptyContent";
import { unstable_next } from "scheduler";
import { useForm } from "react-hook-form";

export default function FacultyEvaluation() {
  const { register, watch, handleSubmit } = useForm();
  const styles = style();
  const globalStyles = globalStyle();
  const history = useHistory();
  const isDownSM = useBreakpointDown("sm");

  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [selectedFacultyDetails, setSelectedFacultyDetails] = useState({});
  const [
    openChangeFacultyConfirmation,
    setOpenChangeFacultyConfirmation,
  ] = useState(false);
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [openSubmitSuccess, setOpenSubmitSuccess] = useState(false);
  const [openSubmitError, setOpenSubmitError] = useState(false);
  const [openRequiredFieldsError, setOpenRequiredFieldsError] = useState(false);
  const [errors, setErrors] = useState({});
  const [comments, setComments] = useState("");

  const {
    facultyEvaluationFormData,
    studentID,
    campusID,
    univServicesEvaluationIsGettingEvaluationForm,
    univServicesEvaluationErrorToGetEvaluationForm,
    univServicesEvaluationIsSavingEvaluationForm,
    univServicesEvaluationIsGettingFacultyList,
    univServicesEvaluationErrorToGetFacultyList,
    facultyEvaluationForm,
    ayTerm,
    facultyList,
  } = useSelector((state) => ({
    facultyEvaluationFormData: state.CONTEXT.facultyEvaluationFormData,
    studentID: state.AUTH.studentID,
    campusID: state.AUTH.campusID,
    univServicesEvaluationIsGettingEvaluationForm:
      state.CONTEXT.toggles.univServicesEvaluationIsGettingEvaluationForm,
    univServicesEvaluationErrorToGetEvaluationForm:
      state.CONTEXT.toggles.univServicesEvaluationErrorToGetEvaluationForm,
    univServicesEvaluationIsSavingEvaluationForm:
      state.CONTEXT.toggles.univServicesEvaluationIsSavingEvaluationForm,
    univServicesEvaluationIsGettingFacultyList:
      state.CONTEXT.toggles.univServicesEvaluationIsGettingFacultyList,
    univServicesEvaluationErrorToGetFacultyList:
      state.CONTEXT.toggles.univServicesEvaluationErrorToGetFacultyList,
    facultyEvaluationForm: state.UNIV_SERVICES_EVALUATION.facultyEvaluationForm,
    ayTerm: state.UNIV_SERVICES_EVALUATION.ayTerm,
    facultyList: state.UNIV_SERVICES_EVALUATION.facultyList,
  }));

  useEffect(() => {
    if (isEmptyObject(facultyEvaluationForm)) getFacultyEvaluationForm();
  }, [facultyEvaluationForm]);

  useEffect(() => {
    if (facultyList.length < 1) getFacultyToEvaluate();
  }, [facultyList.length]);

  const facultyCards = [];
  for (const faculty of facultyList) {
    facultyCards.push({
      scheduleID: faculty.scheduleID,
      subjectCode: faculty.subjectCode,
      subjectTitle: faculty.subjectTitle,
      faculty: faculty.name,
      evaluated: faculty.isEvaluated,
    });
  }

  const facultyDetails = [
    {
      label: "Faculty",
      value: selectedFacultyDetails.faculty,
    },
    {
      label: "Subject Code",
      value: selectedFacultyDetails.subjectCode,
    },
    {
      label: "Subject Title",
      value: selectedFacultyDetails.subjectTitle,
    },
    {
      label: "AY / Term",
      value: ayTerm,
    },
  ];

  const collectAnswers = () => {
    const answers = [];
    const comment = document.getElementById(`faculty-evaluation=comments`)
      .value;
    if (!facultyEvaluationForm.parameters) return;
    for (const parameter of facultyEvaluationForm.parameters) {
      for (const statement of parameter.statements) {
        for (const rating of statement.ratings) {
          const element = document.getElementById(
            `radio-${statement.id}-${rating.id}`
          );
          if (element && element.checked) {
            answers.push({
              statementID: statement.id,
              ratingID: rating.id,
            });
          }
        }
      }
    }
    return { answers, comment };
  };
  const isFormValid = (answers) => {
    let errors = {};
    for (const parameter of facultyEvaluationForm.parameters) {
      for (const statement of parameter.statements) {
        let contains = false;
        for (const answer of answers)
          if (statement.id === answer.statementID) contains = true;
        if (!contains) {
          errors = {
            ...errors,
            [statement.id]: "Required field",
          };
        }
      }
    }
    const isValid = isEmptyObject(errors);
    return { isValid, errors };
  };
  const validate = () => {
    const { answers, comment } = collectAnswers();
    const { isValid, errors } = isFormValid(answers);
    if (isValid) setOpenSubmitConfirmation(true);
    else setOpenRequiredFieldsError(true);
    unstable_next(() => {
      setErrors(errors);
      setContext("facultyEvaluationFormData", answers);
      setComments(comment);
    });
  };
  const onConfirm = () => {
    setOpenSubmitConfirmation(false);
    unstable_next(() => {
      onSubmit();
    });
  };
  const resubmit = () => {
    setOpenSubmitError(false);
    unstable_next(() => {
      onSubmit();
    });
  };
  const onSubmit = () => {
    const data = {
      studentID,
      campusID,
      scheduleID: selectedFaculty,
      answers: facultyEvaluationFormData,
      comment: comments,
    };
    const onSuccess = () => {
      setOpenSubmitSuccess(true);
      unstable_next(() => {
        getEvaluationStatus();
        setContext("facultyEvaluationFormData", []);
        getFacultyToEvaluate();
      });
    };
    const onFail = () => {
      setOpenSubmitError(true);
    };
    saveFacultyEvaluation(data, onSuccess, onFail);
  };
  const toggleChangeFaculty = () => {
    setOpenChangeFacultyConfirmation(true);
    unstable_next(() => {
      const { answers, comment } = collectAnswers();
      setContext("facultyEvaluationFormData", answers);
      setComments(comment);
    });
  };
  const changeFaculty = () => {
    setOpenChangeFacultyConfirmation(false);
    unstable_next(() => {
      clearSelected();
      setContext("facultyEvaluationFormData", []);
      setComments("");
      setOpenSubmitSuccess(false);
      setErrors([]);
    });
  };
  const selectFaculty = (details) => {
    setSelectedFaculty(details.scheduleID);
    unstable_next(() => {
      setSelectedFacultyDetails({
        subjectCode: details.subjectCode,
        subjectTitle: details.subjectTitle,
        faculty: details.faculty || "-",
      });
    });
  };
  const ratingIsChecked = (statemenID, ratingID) => {
    for (const answer of facultyEvaluationFormData)
      if (answer.statementID === statemenID && answer.ratingID === ratingID)
        return true;
    return false;
  };
  const goBack = () => history.goBack();
  const clearSelected = () => setSelectedFaculty(null);
  const closeSubmitConfirmation = () => setOpenSubmitConfirmation(false);
  const closeChangeFacultyConfirmation = () =>
    setOpenChangeFacultyConfirmation(false);
  const closeSubmitError = () => setOpenSubmitError(false);
  const closeRequiredFieldsError = () => setOpenRequiredFieldsError(false);

  const ListOfFaculty = memo(({ facultyCards }) => {
    return (
      <>
        <Typography variant={"h5"} weight={"semibold"}>
          List of Faculty
        </Typography>
        <Space vertical space={3} />
        <GridContainer spacing={2}>
          {facultyCards.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Card
                onClick={() => (item.evaluated ? null : selectFaculty(item))}
                height={"100%"}
              >
                <FlexContainer
                  direction={"column"}
                  justifyContent={"space-between"}
                >
                  <FlexItem>
                    <Typography variant={"h6"} weight={"semibold"}>
                      {item.faculty || "-"}
                    </Typography>
                    <Space vertical space={1} />
                    <Typography>
                      {item.subjectCode} &nbsp;•&nbsp; {item.subjectTitle}
                    </Typography>
                    <Space vertical space={3} />
                  </FlexItem>
                  <FlexItem>
                    {item.evaluated ? (
                      <Button
                        variant={"success"}
                        disableHover
                        fullwidth={isDownSM}
                      >
                        Evaluated
                      </Button>
                    ) : (
                      <Button variant={"primary"} fullwidth={isDownSM}>
                        Evaluate Now
                      </Button>
                    )}
                  </FlexItem>
                </FlexContainer>
              </Card>
            </Grid>
          ))}
        </GridContainer>
      </>
    );
  });
  const Parameter = memo((props) => {
    return (
      <>
        {facultyEvaluationForm.parameters &&
          facultyEvaluationForm.parameters.map((parameter) => (
            <div key={parameter.id}>
              <Space vertical space={4} />
              <Typography variant={"h6"} weight={"medium"}>
                {parameter.sortOrderA}. {parameter.name}
              </Typography>
              {parameter.statements &&
                parameter.statements.map((statement) => (
                  <div key={statement.id}>
                    <Statement statement={statement} />
                  </div>
                ))}
            </div>
          ))}
      </>
    );
  });
  const Statement = memo(({ statement }) => (
    <>
      <Space vertical space={2} />
      <Card
        paddingCustom={"32px 32px 24px 32px"}
        variant={"error"}
        error={errors[statement.id]}
        noHover
      >
        <Typography>
          {statement.sortOrder}. {statement.statement}
          <Typography
            size={12}
            color={"error"}
            weight={"semibold"}
            style={"italic"}
            span
          >
            &nbsp; REQUIRED
          </Typography>
        </Typography>
        <Space vertical space={2} />
        <GridContainer spacing={1}>
          {statement.ratings.map((rating) => (
            <Grid item key={rating.id} xs={12} sm={6} lg={3}>
              <Rating statement={statement} rating={rating} />
            </Grid>
          ))}
        </GridContainer>
      </Card>
    </>
  ));
  const Rating = memo(({ statement, rating }) => (
    <>
      {rating.isActive && (
        <RadioButtonNative
          checked={ratingIsChecked(statement.id, rating.id)}
          id={`radio-${statement.id}-${rating.id}`}
          name={`radio-${statement.id}`}
          label={rating.description}
        />
      )}
    </>
  ));
  const EvaluationForm = ({ facultyEvaluationForm }) => {
    return (
      <GridContainer spacing={isDownSM ? 1 : 6}>
        <Grid item md={4} lg={3} className={styles.firstRowGridItem}>
          <div
            className={isDownSM ? null : globalStyles.stickyTop}
            style={{ top: 140 }}
          >
            <div>
              <FlexContainer
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant={"h5"} weight={"semibold"}>
                  Information
                </Typography>
              </FlexContainer>
              <Space vertical space={3} />
              <Grid container spacing={2} className={styles.gridContainer}>
                {facultyDetails.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <div key={index}>
                      <FlexContainer justifyContent={"start"}>
                        <FlexItem classNames={[styles.infoLabel]}>
                          <Typography variant={"caption"}>
                            {item.label}
                          </Typography>
                        </FlexItem>
                        <Space space={1} />
                        <FlexItem classNames={[styles.infoValue]}>
                          <Typography weight={"semibold"}>
                            {item.value}
                          </Typography>
                        </FlexItem>
                      </FlexContainer>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
            <div>
              <Space vertical space={4} />
              <NoticeContainer
                type={"Caution"}
                align={"justify"}
                variant={"warning"}
                padding={4}
                noAnimation
                text={
                  <span>
                    Entered data will be lost when you
                    <strong> change faculty </strong> or when you leave/refresh
                    the page without submitting.
                  </span>
                }
              />
            </div>
          </div>
        </Grid>
        <Grid item md={8} lg={9} className={styles.firstRowGridItem}>
          <div>
            <FlexContainer
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant={"h5"} weight={"semibold"}>
                Evaluation Form
              </Typography>
            </FlexContainer>
            <Space vertical space={3} />
            <NoticeContainer
              type={"Instruction"}
              align={"justify"}
              variant={"warning"}
              padding={4}
              noAnimation
              text={
                <span>
                  Please answer the evaluation form honestly! Evaluate the
                  faculty’s performance from the
                  <strong>
                    &nbsp;start of the 2nd semester of SY 2019-2020 until the
                    last day of face to face session
                  </strong>
                  . Items with
                  <Typography
                    size={12}
                    color={"error"}
                    weight={"semibold"}
                    style={"italic"}
                    span
                  >
                    {" "}
                    &nbsp;REQUIRED&nbsp;{" "}
                  </Typography>
                  badges are required fields. The evaluation form cannot be
                  submitted unless all required fields are answered. Once done
                  in answering the form, click/tap the <strong>
                    {" "}
                    Submit{" "}
                  </strong>{" "}
                  button on the top-right corner of the page to submit the
                  evaluation.
                </span>
              }
            />

            {facultyEvaluationForm.parameters &&
              facultyEvaluationForm.parameters.map((parameter) => (
                <div key={parameter.id}>
                  <Space vertical space={4} />
                  <Typography variant={"h6"} weight={"medium"}>
                    {parameter.sortOrderA}. {parameter.name}
                  </Typography>
                  {parameter.statements &&
                    parameter.statements.map((statement) => (
                      <div key={statement.id}>
                        <Space vertical space={2} />
                        <Card
                          paddingCustom={"32px 32px 24px 32px"}
                          variant={"error"}
                          error={errors[statement.id]}
                          noHover
                        >
                          <Typography>
                            {statement.sortOrder}. {statement.statement}
                            <Typography
                              size={12}
                              color={"error"}
                              weight={"semibold"}
                              style={"italic"}
                              span
                            >
                              &nbsp; REQUIRED
                            </Typography>
                          </Typography>
                          <Space vertical space={2} />
                          <GridContainer spacing={1}>
                            {statement.ratings.map((rating) => (
                              <Grid item key={rating.id} xs={12} sm={6} lg={3}>
                                {rating.isActive && (
                                  <RadioButtonNative
                                    ref={register}
                                    checked={ratingIsChecked(
                                      statement.id,
                                      rating.id
                                    )}
                                    id={`radio-${statement.id}-${rating.id}`}
                                    name={`radio-${statement.id}`}
                                    label={rating.description}
                                  />
                                )}
                              </Grid>
                            ))}
                          </GridContainer>
                        </Card>
                      </div>
                    ))}
                </div>
              ))}

            <Space vertical space={4} />

            <Typography variant={"h6"} weight={"medium"}>
              Comment / Suggestion / Complaint
            </Typography>
            <Space vertical space={2} />
            <Input
              id={"faculty-evaluation=comments"}
              defaultValue={comments}
              type={"textarea"}
            />
          </div>
        </Grid>
      </GridContainer>
    );
  };

  return (
    <div className={styles.root}>
      <ReactHelmet
        title={"Faculty Evaluation | University Services Evaluation"}
      />

      {isDownSM ? (
        <>
          <Space vertical space={3} />
          <FlexItem classNames={[styles.headItem]}>
            <Typography variant={"h4"} align={"left"} weight={"medium"}>
              Faculty Evaluation
            </Typography>
          </FlexItem>
          <div className={!selectedFaculty ? null : globalStyles.stickyTop}>
            <Space vertical space={1} />
            <FlexContainer
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FlexItem classNames={[styles.headItem]} width={"100%"}>
                <FlexContainer
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {selectedFaculty ? (
                    <FlexContainer
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Button variant={"warning"} onClick={toggleChangeFaculty}>
                        Change Faculty
                      </Button>
                      <Button
                        variant={"primary"}
                        icon={sendPlaneLine}
                        onClick={validate}
                        isLoading={univServicesEvaluationIsSavingEvaluationForm}
                      >
                        Submit
                      </Button>
                    </FlexContainer>
                  ) : (
                    <Button icon={arrowLeftLine} onClick={goBack}>
                      Back
                    </Button>
                  )}
                  <FlexItem>
                    {selectedFaculty ? (
                      isDownSM ? null : (
                        <FlexContainer alignItems={"center"}>
                          <Space space={3} />
                          <Button
                            variant={"warning"}
                            onClick={toggleChangeFaculty}
                          >
                            Change Faculty
                          </Button>{" "}
                          :
                          <Button
                            variant={"primary"}
                            icon={sendPlaneLine}
                            onClick={validate}
                            isLoading={
                              univServicesEvaluationIsSavingEvaluationForm
                            }
                          >
                            Submit
                          </Button>
                        </FlexContainer>
                      )
                    ) : null}
                  </FlexItem>
                </FlexContainer>
              </FlexItem>
            </FlexContainer>
          </div>
          <Space vertical space={1} />
          <LineBreak />
        </>
      ) : (
        <>
          <div className={!selectedFaculty ? null : globalStyles.stickyTop}>
            <Space vertical space={3} />
            <Space vertical space={1} />
            <FlexContainer
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FlexItem classNames={[styles.headItem]}>
                <Typography variant={"h4"} weight={"medium"}>
                  Faculty Evaluation
                </Typography>
              </FlexItem>
              <FlexItem classNames={[styles.headItem]}>
                <FlexContainer alignItems={"center"}>
                  {selectedFaculty ? (
                    <FlexContainer alignItems={"center"}>
                      <Space space={3} />
                      <Button variant={"warning"} onClick={toggleChangeFaculty}>
                        Change Faculty
                      </Button>
                      <FlexItem>
                        <FlexContainer alignItems={"center"}>
                          <Space space={3} />
                          <Button
                            variant={"primary"}
                            icon={sendPlaneLine}
                            onClick={validate}
                            isLoading={
                              univServicesEvaluationIsSavingEvaluationForm
                            }
                          >
                            Submit
                          </Button>
                        </FlexContainer>
                      </FlexItem>
                    </FlexContainer>
                  ) : (
                    <Button icon={arrowLeftLine} onClick={goBack}>
                      Back
                    </Button>
                  )}
                </FlexContainer>
              </FlexItem>
            </FlexContainer>
            <Space vertical space={1} />
            <LineBreak />
          </div>
        </>
      )}

      <Space vertical space={4} />

      {univServicesEvaluationIsGettingEvaluationForm ||
      univServicesEvaluationIsGettingFacultyList ? (
        <FlexContainer direction={"column"} alignItems={"center"}>
          <LoadingComponents mini label={"Loading Faculty"} />
          <Space vertical space={4} />
        </FlexContainer>
      ) : univServicesEvaluationErrorToGetEvaluationForm ||
        univServicesEvaluationErrorToGetFacultyList ? (
        <ErrorLoad
          reload={() => {
            if (isEmptyObject(facultyEvaluationForm))
              getFacultyEvaluationForm();
            if (facultyList.length === 0) getFacultyToEvaluate();
          }}
        />
      ) : facultyList.length === 0 ? (
        <EmptyContent reload={getFacultyToEvaluate} />
      ) : selectedFaculty ? (
        <EvaluationForm facultyEvaluationForm={facultyEvaluationForm} />
      ) : (
        <ListOfFaculty facultyCards={facultyCards} />
      )}

      <MessageDialog
        open={openSubmitConfirmation}
        variant={"warning"}
        title={"Submit Confirmation"}
        description={
          <Typography span>
            Upon confirmation, your evaluation form will be submitted. Once
            done, you can <strong> NO LONGER </strong> update this evaluation.
          </Typography>
        }
        onClose={closeSubmitConfirmation}
        onConfirm={onConfirm}
      />

      <MessageDialog
        open={openSubmitSuccess}
        variant={"success"}
        title={"Evaluation Submitted"}
        singleAction
        icon={checkboxCircleLine}
        onConfirmLabel={"Go Back"}
        description={
          "Your evaluation form has been submitted successfully. " +
          "We appreciate your time and effort taking the evaluation. Thank you!"
        }
        onClose={changeFaculty}
        onConfirm={changeFaculty}
      />

      <MessageDialog
        open={openSubmitError}
        variant={"error"}
        title={"Submission Failed"}
        onCloseLabel={"Close"}
        onConfirmLabel={"Resubmit"}
        description={
          "Your evaluation form was not submitted successfully. " +
          "Please try again!"
        }
        onClose={closeSubmitError}
        onConfirm={resubmit}
      />

      <MessageDialog
        open={openChangeFacultyConfirmation}
        variant={"warning"}
        title={"Change Faculty Confirmation"}
        description={
          <Typography span>
            All input data will be cleared upon confirmation. Be careful,
            there's
            <Typography weight="bold" span>
              {" "}
              NO WAY{" "}
            </Typography>
            to retrieve them once done.
          </Typography>
        }
        onClose={closeChangeFacultyConfirmation}
        onConfirm={changeFaculty}
      />

      <MessageDialog
        open={openRequiredFieldsError}
        variant={"error"}
        title={"Required Field"}
        onConfirmLabel={"Go Back"}
        singleAction
        description={
          "Some required fields were left unfilled. " +
          "Please answer them and submit the form again."
        }
        onClose={closeRequiredFieldsError}
        onConfirm={closeRequiredFieldsError}
      />
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  gridContainer: {
    width: "calc(100% + 16px)",
  },
  genInfoItem: {
    marginBottom: 8,
  },
  infoLabel: {
    width: "30%",
  },
  infoValue: {
    width: "calc(70% - 16px)",
    marginTop: "-2px",
  },
  statItem: {
    marginBottom: theme.spacing(2),
  },
  headItem: {
    marginBottom: theme.spacing(3),
  },
  firstRowGridItem: {
    marginBottom: theme.spacing(3),
  },
  stickyTop: {
    position: "-webkit-sticky",
    width: "100%",
    marginLeft: "-8px",
    paddingLeft: 8,
    paddingRight: 8,
    top: 0,
    background: theme.palette.background,
    zIndex: 2,
    "&": {
      position: "sticky",
    },
  },
  headingItem: {
    marginBottom: theme.spacing(1.5),
  },
}));

import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { isFunction } from "../utils/misc";
import Banner from "../static/media/img/banner.jpg";
import Container from "@material-ui/core/Container";
import HomeFullscreenRoutes from "../routes/homeFullscreen";

export default function HomeFullScreenLayout({ open, onClose, children }) {
  const styles = style();

  return (
    <Dialog open={true} fullScreen onClose={isFunction(onClose) && onClose}>
      <div className={styles.root}>
        <div className={styles.bannerImage} />
        <Container maxWidth={false}>
          <div className={styles.body}>
            <HomeFullscreenRoutes />
          </div>
        </Container>
      </div>
    </Dialog>
  );
}

const style = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    background: theme.palette.background,
  },
  body: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  bannerImage: {
    height: 40,
    background: `linear-gradient(0deg, rgba(151, 57, 57, 0.1), rgba(151, 57, 57, 0.1)), url(${Banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const useBreakpointUp = (key) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(key));
};

export const useBreakpointDown = (key) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key));
};

export const useBreakpointOnly = (key) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(key));
};

export const useBreakpointBetween = (start, end) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between(start, end));
};

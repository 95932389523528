import React, { useEffect, useState } from "react";
import ReactHelmet from "../../../components/core/Helmet";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import Typography from "../../../components/core/typography/Typography";
import Button from "../../../components/core/button/Button";
import Space from "../../../components/core/Space";
import { useHistory } from "react-router";
import NoticeContainer from "../../../components/core/containers/NoticeContainer";
import Grid from "@material-ui/core/Grid";
import LineBreak from "../../../components/core/LineBreak";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import { useSelector } from "react-redux";
import Select from "../../../components/core/input/Select";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import CardFilled from "../../../components/custom/card/CardFilled";
import BounceTop from "../../../components/core/animation/attention/bounceTop/bounceTop";
import checkboxCircleLine from "@iconify/icons-ri/checkbox-circle-line";
import arrowLeftLine from "@iconify/icons-ri/arrow-left-line";
import sendPlaneLine from "@iconify/icons-ri/send-plane-line";
import {
  clearBlockSectionCourses,
  clearCourseSchedule,
  getAdvisingRemarks,
  getAvailableScheduleBySection,
  getEnrollmentStatus,
  getOfferedBlockSections,
  getOfferedCourses,
  getRegisteredScheduleDetails,
  getRegistrations,
  saveAdvisedReg,
  saveRegistration,
  setAdditionalCourses,
} from "../../../redux/actions/enrollment";
import { setContext, setForm, toggle } from "../../../redux/actions/context";
import Card from "../../../components/custom/card/Card";
import { routes } from "../../../routes";
import SkeletonLoader from "../../../components/core/loader/SkeletonLoader";
import ErrorLoad from "../../../components/custom/ErrorLoad";
import EmptyContent from "../../../components/custom/EmptyContent";
import refreshLine from "@iconify/icons-ri/refresh-line";
import MessageDialog from "../../../components/core/dialogs/MessageDialog";
import { setAuthData } from "../../../redux/actions/auth";
import { isInObjectArray } from "../../../utils/misc";
import globalStyle from "../../../static/styles";
import DialogContainer from "../../../components/core/dialogs/DialogContainer";
import checkLine from "@iconify/icons-ri/check-line";
import IconMini from "../../../components/core/icons/IconMini";
import Input from "../../../components/core/input/Input";
import { formatDateTime } from "../../../utils/date";
import CircularProgress from "@material-ui/core/CircularProgress";
import print from "../../../utils/print";

export default function Register() {
  const style = makeStyles((theme) => ({
    root: {},
    genInfoItem: {
      marginBottom: 8,
    },
    infoLabel: {
      width: "30%",
    },
    infoValue: {
      width: "calc(70%)",
      marginTop: "-2px",
    },
    statItem: {
      marginBottom: theme.spacing(2),
    },
    headItem: {
      marginBottom: theme.spacing(3),
    },
    remarkAdviser: {
      padding: 12,
      borderRadius: 5,
      border: "1px solid " + theme.palette.grey.shade4,
    },
    remarkStudent: {
      padding: 12,
      borderRadius: 5,
      background: theme.palette.grey.shade5,
    },
    remarkStudentAvatar: {
      height: 14,
      width: 14,
      background: theme.palette.primary.main,
      borderRadius: "100%",
    },
    remarkStudentAdviser: {
      height: 14,
      width: 14,
      background: theme.palette.grey.shade4,
      borderRadius: "100%",
    },
  }));

  const { root, headItem } = style();
  const cls = style();
  const { stickyTop } = globalStyle();

  const history = useHistory();
  const isDownSM = useBreakpointDown("sm");
  const isDownXS = useBreakpointDown("xs");

  let {
    selectedSection,
    currentLoad,
    currentLecLoad,
    currentLabLoad,
    newlySavedRegistrationID,
    advisingIDForAdvising,
    regIDForAdvising,
    origSchedules,
    enrollmentAY,
    enrollmentTerm,
    registrationNumforView,
  } = useSelector((state) => state.CONTEXT);
  const {
    blockSectionCourses,
    additionalCourses,
    offeredCourses,
    sections,
    advisingRemarks,
  } = useSelector((state) => state.ENROLLMENT);
  let {
    hasAnsweredTheEnrollmentSurvey,
    canRegister,
    isUndergrad,
    isRegular,
    maxUnitsLoad,
    progID,
    studentID,
    termID,
    campusID,
  } = useSelector((state) => state.AUTH);
  const SoLProgID = 212;
  isRegular = !isUndergrad
    ? progID === SoLProgID
      ? isRegular
      : false
    : studentID.startsWith("2020")
    ? true
    : isRegular;

  const {
    hasCompletedStudentSatisfaction,
    hasCompletedFacultyEvaluation,
    enrollmentIsLoadingAvailableSubjects,
    enrollmentErrorToLoadAvailableSubjects,
    enrollmentIsLoadingOfferedSubjects,
    enrollmentErrorToLoadOfferedSubjects,
    enrollmentIsLoadingSections,
    enrollmentIsSubmittingRegistration,
    accountabilityChecked,
    registrationAdvisingHasChanges,
    registrationAdvisingIsValidated,
    enrollmentIsLoadingRegistrationSchedules,
    enrollmentErrorToLoadRegistrationSchedules,
    enrollmentIsSavingAdvising,
    ignoreAccountabilityChecking,
    registrationIsLoadingAdvisingRemarks,
    registrationErrorToLoadAdvisingRemarks,
    registrationIsUnderAdvising,
    registrationSubjectNotInCurriWarning,
  } = useSelector((state) => state.CONTEXT.toggles);
  const { hasAccountability } = useSelector((state) => state.ACCOUNTABILITY);
  const { enrollmentRegisterAvailFHE } = useSelector(
    (state) => state.CONTEXT.forms
  );

  // const [isRegular, setIsRegular] = useState(false)
  const [insufficientUnit, setInsufficientUnit] = useState(false);
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [openSubmitSuccess, setOpenSubmitSuccess] = useState(false);
  const [openSubmitError, setOpenSubmitError] = useState(false);
  const [
    openSubmitForAdvisingDialog,
    setOpenSubmitForAdvisingDialog,
  ] = useState(false);
  const [
    openSubmitForAdvisingConfirmation,
    setOpenSubmitForAdvisingConfirmation,
  ] = useState(false);
  const [openSubmitForAdvisingError, setOpenSubmitForAdvisingError] = useState(
    false
  );
  const [
    openFreeHigherEducationConfirm,
    setOpenFreeHigherEducationConfirm,
  ] = useState(isUndergrad ? enrollmentRegisterAvailFHE === null : null);
  const [openOPTOUTWaiver, setOpenOPTOUTWaiver] = useState(false);
  const [openFHEIneligible, setOpenFHEIneligible] = useState(false);
  const [openSubmitOption, setOpenSubmitOption] = useState(false);
  const [remarksError, setRemarksError] = useState("");
  const [openReEvaluateNotice, setOpenReEvaluateNotice] = useState("");

  useEffect(() => {
    if (
      // !hasAnsweredTheEnrollmentSurvey ||
      (!canRegister && !registrationIsUnderAdvising) ||
      (registrationIsUnderAdvising
        ? !(registrationAdvisingHasChanges || registrationAdvisingIsValidated)
        : null) ||
      (canRegister
        ? !accountabilityChecked && !ignoreAccountabilityChecking
        : null) ||
      (canRegister
        ? hasAccountability && !ignoreAccountabilityChecking
        : null) ||
      (canRegister ? !hasCompletedFacultyEvaluation : null) ||
      (canRegister ? !hasCompletedStudentSatisfaction : null)
    ) {
      print(
        "EXIT REGISTRATION:",
        "canRegister:",
        canRegister,
        "| advisingIDForAdvising:",
        advisingIDForAdvising,
        "| accountabilityChecked:",
        accountabilityChecked,
        "| hasAccountability:",
        hasAccountability,
        "| ignoreAccountabilityChecking:",
        ignoreAccountabilityChecking,
        "| hasCompletedFacultyEvaluation:",
        hasCompletedFacultyEvaluation,
        "| hasCompletedStudentSatisfaction:",
        hasCompletedStudentSatisfaction
      );
      goBack();
    }
  });
  useEffect(() => {
    if (isRegular && blockSectionCourses.length < 1)
      getAvailableScheduleBySection(selectedSection);
  }, [selectedSection, blockSectionCourses.length, isRegular]);
  useEffect(() => {
    if (isRegular && sections.length < 1) getOfferedBlockSections();
  }, [isRegular, sections.length]);
  useEffect(() => {
    if (
      !isRegular &&
      offeredCourses.length < 1 &&
      additionalCourses.length === 0
    )
      getOfferedCourses();
  }, [additionalCourses.length, isRegular, offeredCourses.length]);
  useEffect(() => {
    if (advisingRemarks.length < 1 && advisingIDForAdvising)
      getAdvisingRemarks(advisingIDForAdvising, regIDForAdvising, campusID);
  }, [isRegular]);

  const goBack = () => {
    getRegistrations();
    history.goBack();
  };

  const hasChanges = () => {
    if (additionalCourses.length !== origSchedules.length) return true;
    const scheds = [];
    additionalCourses.forEach((item) => {
      scheds.push(item.schedID);
    });
    for (let i of scheds) {
      if (!origSchedules.includes(i)) return true;
    }
    return false;
  };

  const canBeSubmitted = () => {
    // if (registrationIsUnderAdvising || !isUndergrad || campusID !== 1) {
    if (!registrationAdvisingIsValidated) return false;
    // }
    if (isRegular) return selectedSection;
    else return additionalCourses.length > 0;
  };
  const canBeSubmittedForAdvising = () => {
    if (registrationAdvisingIsValidated) return false;
    else return additionalCourses.length > 0;
  };
  const isNotAdded = (courseID) => {
    for (let i of additionalCourses) if (i.courseID === courseID) return false;
    return true;
  };

  const onSubmit = () => {
    setOpenSubmitConfirmation(false);
    setOpenSubmitError(false);
    const subjects = isRegular ? blockSectionCourses : additionalCourses;
    const data = {
      studentID,
      termID,
      campusID,
      isFreeHigh: isUndergrad ? enrollmentRegisterAvailFHE || false : false,
      schedules: [],
      isFinalized: !isRegular && advisingIDForAdvising,
    };
    subjects.forEach((item) => {
      data.schedules.push(item.schedID);
    });
    const onSuccess = () => {
      history.push(routes.enrollmentRegistration);
      setContext("advisingIDForAdvising", null);
      setAuthData("canRegister", false);
      toggle("registrationIsUnderAdvising", false);
      getEnrollmentStatus(registrationNumforView);
    };
    const onFail = () => {
      setOpenSubmitError(true);
    };
    saveRegistration(data, onSuccess, onFail);
  };
  const onSubmitForAdvising = () => {
    setOpenSubmitForAdvisingConfirmation(false);
    setOpenSubmitForAdvisingError(false);
    const remarks = document.getElementById("register-submit-advising-remarks")
      .value;
    const data = {
      advising: true,
      studentID,
      termID,
      progID,
      campusID,
      isFreeHigh: isUndergrad ? enrollmentRegisterAvailFHE || false : false,
      schedules: [],
      remarks: remarks || "Initial",
    };
    additionalCourses.forEach((item) => {
      data.schedules.push(item.schedID);
    });
    const onSuccess = () => {
      toggle("enrollmentRegistrationHasFinalized", true);
      history.push(routes.enrollmentRegistration);
      setAuthData("canRegister", false);
    };
    const onFail = () => {
      setOpenSubmitForAdvisingError(true);
    };
    saveRegistration(data, onSuccess, onFail);
  };
  const onResubmitAdvising = () => {
    const remarks = document.getElementById("register-submit-advising-remarks")
      .value;
    if (remarks) setRemarksError("");
    else {
      setRemarksError("Required Field");
      return;
    }
    setOpenSubmitForAdvisingDialog(false);
    setOpenSubmitForAdvisingError(false);
    const data = {
      advisingID: advisingIDForAdvising,
      studentID: studentID,
      regID: regIDForAdvising,
      campusID: campusID,
      schedules: [],
      remarks,
      isAccepted: true,
    };
    additionalCourses.forEach((item) => {
      data.schedules.push(item.schedID);
    });
    const onSuccess = () => {
      toggle("registrationAdvisingHasChanges", false);
      history.replace(routes.enrollmentRegistration);
    };
    const onFail = () => {
      setOpenSubmitForAdvisingError(true);
    };
    saveAdvisedReg(data, onSuccess, onFail);
  };
  const onAcceptAdvising = () => {
    setOpenSubmitForAdvisingDialog(false);
    setOpenSubmitForAdvisingError(false);
    const data = {
      advisingID: advisingIDForAdvising,
      studentID: studentID,
      regID: regIDForAdvising,
      campusID: campusID,
      schedules: [],
      remarks: "Accepted",
      isAccepted: true,
    };
    additionalCourses.forEach((item) => {
      data.schedules.push(item.schedID);
    });
    const onSuccess = () => {
      toggle("registrationAdvisingHasChanges", false);
      history.replace(routes.enrollmentRegistration);
    };
    const onFail = () => {
      setOpenSubmitForAdvisingError(true);
    };
    saveAdvisedReg(data, onSuccess, onFail);
  };

  const submitSuccessOnClose = () => {
    setOpenSubmitSuccess(false);
    getRegistrations();
    history.replace(routes.enrollment);
  };
  const seeDetails = () => {
    setContext("registrationNumforView", newlySavedRegistrationID);
    history.push(routes.enrollmentRegistration);
  };
  const clearAdditionalCourses = () => setAdditionalCourses([]);
  const reloadAvailableSubjects = () => changeSection(selectedSection);
  const removeSubject = (subjectID) => {
    setAdditionalCourses(
      additionalCourses.filter((item) => item.courseID !== subjectID)
    );
    toggle("registrationHasChanges", true);
  };
  const availFHE = () => {
    // setForm('enrollmentRegisterAvailFHE', true)
    setOpenFHEIneligible(true);
    setOpenFreeHigherEducationConfirm(false);
  };
  const optOut = () => {
    setOpenFreeHigherEducationConfirm(false);
    setOpenOPTOUTWaiver(true);
  };
  const optOutBack = () => {
    setOpenOPTOUTWaiver(false);
    setOpenFreeHigherEducationConfirm(true);
  };
  const optOutProceed = () => {
    setForm("enrollmentRegisterAvailFHE", false);
    setOpenOPTOUTWaiver(false);
    setOpenReEvaluateNotice(true);
  };
  const eligibilityBack = () => {
    setOpenFHEIneligible(false);
    setOpenFreeHigherEducationConfirm(true);
  };
  const eligibilityProceed = () => {
    setForm("enrollmentRegisterAvailFHE", true);
    setOpenFHEIneligible(false);
    setOpenReEvaluateNotice(true);
  };
  const changeSection = (sectionID) => {
    setContext("currentLoad", 0);
    setContext("currentLecLoad", 0);
    setContext("currentLabLoad", 0);
    clearBlockSectionCourses();
    setContext("selectedSection", sectionID);
    getAvailableScheduleBySection(sectionID);
  };
  const changeSchedule = (item) => {
    if (
      !isInObjectArray(additionalCourses, "courseID", item.courseID) &&
      currentLoad + item.creditUnits > maxUnitsLoad
    ) {
      setInsufficientUnit(true);
      return;
    }
    clearCourseSchedule();
    setContext("preAddSubjectData", {
      courseID: item.courseID,
      courseCode: item.courseCode,
      courseTitle: item.courseTitle,
      creditUnits: item.creditUnits,
      labUnits: item.labUnits,
      lecUnits: item.lecUnits,
      isPreReqPassed: item.isPreReqPassed,
      yearStanding: item.yearStanding,
      isInCurriculum: item.isInCurriculum,
      selectedSchedID: item.schedID || null,
      progID: isUndergrad ? progID : item.progID,
    });
    setContext("courseIDForSchedule", item.courseID);
    history.push(routes.enrollmentRegistrationRegisterSchedule);
  };

  // const ActionsSubjectOffered = ({item}) => {
  //     return (
  //         <FlexContainer direction={''} justifyContent={'center'}>
  //             <FlexItem>
  //                 <Button variant={'success'} fullwidth
  //                         onClick={() => changeSchedule(item)}
  //                 >
  //                     View Schedule</Button>
  //             </FlexItem>
  //         </FlexContainer>
  //     )
  // }

  // const subjectOfferedColumns = [
  //     {
  //         label: 'Program',
  //         align: 'left',
  //         noWrap: true,
  //         hidden: isUndergrad
  //     },
  //     {
  //         label: 'Code',
  //         align: 'left',
  //         noWrap: true,
  //         weight: 'semibold',
  //     },
  //     {
  //         label: 'Title',
  //         align: 'left',
  //         // minWidth: 200
  //     },
  //     {
  //         label: 'Lec',
  //         align: 'center',
  //     },
  //     {
  //         label: 'Lab',
  //         align: 'center',
  //     },
  //     {
  //         label: 'Credit',
  //         align: 'center',
  //     },
  //     {
  //         label: '',
  //         align: 'center',
  //         noPaddingRight: true
  //     },
  // ]
  //
  // const subjectOfferedData = []
  // if (offeredCourses.length > 0) {
  //     for (let item of offeredCourses) {
  //         if (!isNotAdded(item.courseID)) continue
  //         subjectOfferedData.push([
  //             item.programCode,
  //             item.courseCode,
  //             item.courseTitle,
  //             item.lecUnits,
  //             item.labUnits,
  //             item.lecUnits + item.labUnits,
  //             <ActionsSubjectOffered item={item}/>
  //         ])
  //     }
  // }

  const Statistics = ({ isRegular }) => {
    let totalSubjects = isRegular
      ? blockSectionCourses.length
      : additionalCourses.length;
    let lecUnits = isRegular ? currentLecLoad : 0;
    let labUnits = isRegular ? currentLabLoad : 0;

    if (!isRegular) {
      for (let i of additionalCourses) {
        lecUnits += i.lecUnits;
        labUnits += i.labUnits;
      }
      const creditUnits = lecUnits + labUnits;
      if (currentLoad !== creditUnits) setContext("currentLoad", creditUnits);
    }

    const creditUnitsStats = [
      {
        label: `Lec Unit${lecUnits > 1 ? "s" : ""}`,
        value: lecUnits,
        color: "warning",
      },
      {
        label: `Lab Unit${labUnits > 1 ? "s" : ""}`,
        value: labUnits,
        color: "primary",
      },
    ];

    return (
      <>
        <Card paddingCustom={"32px 32px 40px 32px"} height={"100%"}>
          <FlexContainer direction={"column"} alignItems={"center"}>
            <Typography variant={"h3"} weight={"light"}>
              {totalSubjects}
            </Typography>
            <Space vertical space={1} />
            <Typography align={"center"}>No. of Subjects</Typography>
          </FlexContainer>
        </Card>
        <Space vertical space={4} />
        <Card paddingCustom={"32px 32px 40px 32px"} height={"100%"}>
          <FlexContainer direction={"column"} alignItems={"center"}>
            <Typography variant={"h3"} weight={"light"}>
              {currentLoad}
              {isRegular ? null : (
                <Typography variant={"h5"} weight={"light"} span>
                  {" "}
                  / {maxUnitsLoad}
                </Typography>
              )}
            </Typography>
            <Space vertical space={1} />
            <Typography align={"center"}>
              Credit Units{" "}
              {isRegular ? null : (
                <Typography span>
                  / <br />
                  Max Load
                </Typography>
              )}
            </Typography>
          </FlexContainer>
        </Card>
        {/*<GridContainer spacing={2}>*/}
        {/*    <Grid item xs={6} md={3}>*/}
        {/*        <Card paddingCustom={'32px 32px 40px 32px'} height={'100%'}>*/}
        {/*            <FlexContainer direction={'column'} alignItems={'center'}>*/}
        {/*                <Typography variant={'h3'} weight={'light'}>{totalSubjects}</Typography>*/}
        {/*                <Space vertical space={1}/>*/}
        {/*                <Typography align={'center'}>No. of Subjects</Typography>*/}
        {/*            </FlexContainer>*/}
        {/*        </Card>*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={6} md={3}>*/}
        {/*        <Card paddingCustom={'32px 32px 40px 32px'} height={'100%'}>*/}
        {/*            <FlexContainer direction={'column'} alignItems={'center'}>*/}
        {/*                <Typography variant={'h3'} weight={'light'}>{currentLoad}*/}
        {/*                    {isRegular ? null :*/}
        {/*                        <Typography variant={'h5'} weight={'light'} span> / {maxUnitsLoad}</Typography>*/}
        {/*                    }*/}
        {/*                </Typography>*/}
        {/*                <Space vertical space={1}/>*/}
        {/*                <Typography align={'center'}>Credit Units {isRegular ? null :*/}
        {/*                    <Typography span>/ <br/>Max Load</Typography>*/}
        {/*                }</Typography>*/}
        {/*            </FlexContainer>*/}
        {/*        </Card>*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={12} md={6}>*/}
        {/*        <Card paddingCustom={'32px 32px 40px 32px'} height={'100%'}>*/}
        {/*            <LineGraphDetailed minWidth={1} title={'Credit Units'} stats={creditUnitsStats}/>*/}
        {/*        </Card>*/}
        {/*    </Grid>*/}
        {/*</GridContainer>*/}
      </>
    );
  };
  const InstructionForRegular = () => {
    return (
      <span>
        Select your desired <strong>Section</strong> from the available list in
        the selection box located below the
        <strong> General Information</strong> section. By default, the first
        section in the list is selected and a set of subjects with their
        corresponding schedule will be loaded in the <strong>Subjects</strong>{" "}
        section. Review your subject schedules and change the section as you
        wish. Upon change, another set of subject schedules will be loaded. Once
        selection of section is final, click/tap the <strong>Submit</strong>{" "}
        button on the top-right corner to submit registration for validation.
      </span>
    );
  };
  const InstructionForIrregular = () => {
    return (
      <span>
        To be guided on the subjects you can enroll, please visit
        <strong> Academic Program Evaluation </strong> before going to
        registration. Choose desired subjects to take in the{" "}
        <strong>Offered Subjects</strong> section. To add a subject, ckick/tap{" "}
        <strong>View Schedule</strong> then <strong>Check Conflict</strong> to
        check if the schedule conflicts with the others in the{" "}
        <strong>Added Subjects</strong>. If no conflicts found, click/tap{" "}
        <strong>Select Schedule</strong>. Added subjects will be available in
        the
        <strong> Added Subjects </strong> section. You may{" "}
        <strong>Remove</strong> subjects or alter their schedules by
        clicking/tapping
        <strong> Change Schedule</strong>. Before submitting, review your
        subject schedules carefully. Once the subject schedules are final,
        click/tap the <strong>Submit</strong> button on the top-right corner.
        You may seek for advise from your adviser by clicking/tapping
        <strong> For Advising</strong>, otherwise, click/tap{" "}
        <strong>For Final Submission</strong> to submit registration to the
        Student Accounts for the validation. If submitted for advising, please
        attentively check the status in the
        <strong> Enrollment > Registration</strong>, click/tap{" "}
        <strong>Edit</strong> to see again your registration. Your adviser may
        add/remove schedules and return your registration. Once satisfied with
        the schedules, click/tap <strong>Accept</strong>, otherwise, modify the
        schedule then click/tap
        <strong> Resubmit</strong> until the adviser validates your
        registration. Once validated, you need to finally submit the
        registration by clicking/tapping
        <strong> For Final Submission</strong>.
      </span>
    );
  };
  const InstructionForGrad = () => {
    return (
      <span>
        To be guided on the subjects you can enroll, please visit
        <strong> Academic Program Evaluation </strong> before going to
        registration. Choose desired subjects to take in the{" "}
        <strong>Offered Subjects</strong> section. To add a subject, ckick/tap{" "}
        <strong>View Schedule</strong> then <strong>Check Conflict</strong> to
        check if the schedule conflicts with the others in the{" "}
        <strong>Added Subjects</strong>. If no conflicts found, click/tap{" "}
        <strong>Select Schedule</strong>. Added subjects will be available in
        the
        <strong> Added Subjects </strong> section. You may{" "}
        <strong>Remove</strong> subjects or alter their schedules by
        clicking/tapping
        <strong> Change Schedule</strong>. Before submitting, review your
        subject schedules carefully. Once the subject schedules are final,
        click/tap the <strong>Submit</strong> button on the top-right corner
        then click/tap <strong> For Advising</strong>. Please attentively check
        the status in the
        <strong> Enrollment > Registration</strong>, click/tap{" "}
        <strong>Edit</strong> to see again your registration. Your adviser may
        add/remove schedules and return your registration. Once satisfied with
        the schedules, click/tap <strong>Accept</strong>, otherwise, modify the
        schedule then click/tap
        <strong> Resubmit</strong> until the adviser validates your
        registration. Once validated, you need to finally submit the
        registration by clicking/tapping
        <strong> For Final Submission</strong>.
      </span>
    );
  };

  return (
    <div className={root}>
      <ReactHelmet title={"Register | Enrollment"} />

      <Space vertical space={4} />

      {isDownSM ? (
        <>
          <FlexItem classNames={[headItem]}>
            <Typography variant={"h4"} align={"left"} weight={"medium"}>
              Registration
            </Typography>
          </FlexItem>
          <div className={stickyTop}>
            <Space vertical space={1} />
            <FlexContainer
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FlexItem classNames={[headItem]} width={"100%"}>
                <FlexContainer
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <FlexContainer
                    justifyContent={isDownXS ? "space-between" : "flex-end"}
                  >
                    <Button icon={arrowLeftLine} onClick={goBack}>
                      Back
                    </Button>
                    <Space space={3} />
                    {registrationAdvisingIsValidated ? (
                      <Button
                        variant={"primary"}
                        icon={sendPlaneLine}
                        onClick={() => {
                          if (isRegular) setOpenSubmitConfirmation(true);
                          else setOpenSubmitOption(true);
                        }}
                        disabled={
                          !canBeSubmitted() &&
                          (isRegular ? null : !canBeSubmittedForAdvising())
                        }
                        isLoading={enrollmentIsSubmittingRegistration}
                      >
                        Submit
                      </Button>
                    ) : registrationAdvisingHasChanges ? (
                      hasChanges() ? (
                        <Button
                          variant={"primary"}
                          icon={sendPlaneLine}
                          onClick={() => setOpenSubmitForAdvisingDialog(true)}
                          disabled={!canBeSubmittedForAdvising()}
                          isLoading={enrollmentIsSavingAdvising}
                        >
                          Resubmit
                        </Button>
                      ) : (
                        <Button
                          variant={"primary"}
                          icon={sendPlaneLine}
                          onClick={onAcceptAdvising}
                          disabled={!canBeSubmittedForAdvising()}
                          isLoading={enrollmentIsSavingAdvising}
                        >
                          Accept
                        </Button>
                      )
                    ) : (
                      <Button
                        variant={"primary"}
                        icon={sendPlaneLine}
                        onClick={() => {
                          if (isRegular) setOpenSubmitConfirmation(true);
                          else setOpenSubmitOption(true);
                        }}
                        disabled={
                          !canBeSubmitted() && !canBeSubmittedForAdvising()
                        }
                        isLoading={enrollmentIsSubmittingRegistration}
                      >
                        Submit
                      </Button>
                    )}
                  </FlexContainer>
                </FlexContainer>
              </FlexItem>
            </FlexContainer>
          </div>
          <Space vertical space={1} />
        </>
      ) : (
        <>
          <div className={stickyTop}>
            <Space vertical space={1} />
            <FlexContainer
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FlexItem classNames={[headItem]}>
                <Typography variant={"h4"} weight={"medium"}>
                  Registration
                </Typography>
              </FlexItem>
              <FlexItem classNames={[headItem]}>
                <FlexContainer
                  alignItems={"center"}
                  justifyContent={isDownXS ? "space-between" : null}
                >
                  {/*{CONFIG.ENV_DEV || CONFIG.ENV_STAGING ?*/}
                  {/*    <>*/}
                  {/*        <Button*/}
                  {/*            variant={isRegular ? 'primary' : null}*/}
                  {/*            onClick={() => setAuthData('isRegular', !isRegular)}>isRegular</Button>*/}
                  {/*        <Space space={3}/>*/}
                  {/*    </> : null*/}
                  {/*}*/}
                  <Button icon={arrowLeftLine} onClick={goBack}>
                    Back
                  </Button>
                  <Space space={3} />
                  {registrationAdvisingIsValidated ? (
                    <Button
                      variant={"primary"}
                      icon={sendPlaneLine}
                      onClick={() => {
                        if (isRegular) setOpenSubmitConfirmation(true);
                        else setOpenSubmitOption(true);
                      }}
                      disabled={
                        !canBeSubmitted() &&
                        (isRegular ? null : !canBeSubmittedForAdvising())
                      }
                      isLoading={enrollmentIsSubmittingRegistration}
                    >
                      Submit
                    </Button>
                  ) : registrationAdvisingHasChanges ? (
                    hasChanges() ? (
                      <Button
                        variant={"primary"}
                        icon={sendPlaneLine}
                        onClick={() => setOpenSubmitForAdvisingDialog(true)}
                        disabled={!canBeSubmittedForAdvising()}
                        isLoading={enrollmentIsSavingAdvising}
                      >
                        Resubmit
                      </Button>
                    ) : (
                      <Button
                        variant={"warning"}
                        icon={sendPlaneLine}
                        onClick={onAcceptAdvising}
                        disabled={!canBeSubmittedForAdvising()}
                        isLoading={enrollmentIsSavingAdvising}
                      >
                        Accept
                      </Button>
                    )
                  ) : (
                    <Button
                      variant={"primary"}
                      icon={sendPlaneLine}
                      onClick={() => {
                        if (isRegular) setOpenSubmitConfirmation(true);
                        else setOpenSubmitOption(true);
                      }}
                      disabled={
                        !canBeSubmitted() &&
                        (isRegular ? null : !canBeSubmittedForAdvising())
                      }
                      isLoading={enrollmentIsSubmittingRegistration}
                    >
                      Submit
                    </Button>
                  )}
                </FlexContainer>
              </FlexItem>
            </FlexContainer>
          </div>
          <Space vertical space={1} />
        </>
      )}

      <LineBreak />
      <Space vertical space={4} />
      <NoticeContainer
        padding={3}
        type={"Instruction"}
        variant={"warning"}
        align={"justify"}
        height={"100%"}
        text={
          isRegular ? (
            <InstructionForRegular />
          ) : isUndergrad ? (
            <InstructionForIrregular />
          ) : (
            <InstructionForGrad />
          )
        }
      />
      <Space vertical space={4} />

      <GridContainer spacing={4}>
        <Grid item xs={12} md={3}>
          <div>
            {isUndergrad ? (
              <>
                <Card padding={4}>
                  <FlexContainer direction={"column"} alignItems={"center"}>
                    <Typography align={"center"}>I will</Typography>
                    <Space vertical space={0.5} />
                    <Typography
                      color={"primary"}
                      weight={"normal"}
                      align={"center"}
                      variant={"h4"}
                    >
                      {enrollmentRegisterAvailFHE === null
                        ? "-"
                        : enrollmentRegisterAvailFHE
                        ? "AVAIL"
                        : "OPT OUT"}
                    </Typography>
                    <Space vertical space={0.5} />
                    <Typography align={"center"}>
                      of Free Higher Education for this semester.
                    </Typography>
                    <Space vertical space={3} />
                    <Button
                      variant={"primary"}
                      onClick={() => setOpenFreeHigherEducationConfirm(true)}
                    >
                      Change
                    </Button>
                  </FlexContainer>
                </Card>
                <Space vertical space={4} />
              </>
            ) : null}

            <Typography variant={"h5"} weight={"semibold"}>
              Statistics
            </Typography>
            <Space vertical space={3} />
            <Statistics isRegular={isRegular} />

            <Space vertical space={4} />
            {registrationIsUnderAdvising ? (
              <>
                <FlexContainer
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant={"h5"} weight={"semibold"}>
                    Remarks
                  </Typography>
                  {registrationErrorToLoadAdvisingRemarks ? (
                    <Button
                      icon={refreshLine}
                      variant={"error"}
                      size={"small"}
                      onClick={() =>
                        getAdvisingRemarks(
                          advisingIDForAdvising,
                          regIDForAdvising,
                          campusID
                        )
                      }
                    >
                      Reload
                    </Button>
                  ) : registrationIsLoadingAdvisingRemarks ? (
                    <CircularProgress
                      color={"primary"}
                      thickness={5}
                      size={20}
                    />
                  ) : (
                    <Button
                      icon={refreshLine}
                      size={"small"}
                      onClick={() =>
                        getAdvisingRemarks(
                          advisingIDForAdvising,
                          regIDForAdvising,
                          campusID
                        )
                      }
                    >
                      Refresh
                    </Button>
                  )}
                </FlexContainer>
                <Space vertical space={3} />
                {advisingRemarks.map((item, index) => (
                  <>
                    <FlexContainer
                      justifyContent={
                        item.userType === "adviser" ? "flex-start" : "flex-end"
                      }
                      alignItems={"flex-end"}
                    >
                      {item.userType === "adviser" ? (
                        <>
                          <FlexItem>
                            <div className={cls.remarkStudentAdviser} />
                          </FlexItem>
                          <Space space={0.75} />
                        </>
                      ) : null}
                      <FlexItem>
                        <div
                          className={
                            item.userType === "adviser"
                              ? cls.remarkAdviser
                              : cls.remarkStudent
                          }
                        >
                          <Typography>{item.remarks}</Typography>
                          <Space vertical space={1} />
                          <Typography size={12}>
                            {formatDateTime(item.dateSubmitted)}
                          </Typography>
                        </div>
                      </FlexItem>
                      {item.userType === "adviser" ? null : (
                        <>
                          <Space space={0.75} />
                          <FlexItem>
                            <div className={cls.remarkStudentAvatar} />
                          </FlexItem>
                        </>
                      )}
                    </FlexContainer>
                    <Space vertical space={1} />
                  </>
                ))}
              </>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={9}>
          <div>
            {/* SELECT SECTION */}
            {isRegular ? (
              <>
                <BounceTop>
                  <CardFilled>
                    <FlexContainer
                      direction={isDownSM ? "column" : "row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography align={"center"}>
                        <Typography color={"warning"} weight={"bold"} span>
                          HEY!{" "}
                        </Typography>
                        Select your desired section.
                      </Typography>
                      <Space vertical={isDownSM} space={3} />
                      <Select
                        value={selectedSection}
                        items={sections}
                        onSelect={changeSection}
                        placeholder={"Section"}
                        width={isDownSM ? "100%" : 250}
                      />
                      <Space vertical={isDownSM} space={3} />
                      <Button
                        variant={"success"}
                        icon={refreshLine}
                        onClick={getOfferedBlockSections}
                        isLoading={enrollmentIsLoadingSections}
                      >
                        Refetch List
                      </Button>
                    </FlexContainer>
                  </CardFilled>
                </BounceTop>
                <Space vertical space={4} />
              </>
            ) : null}

            {/*<GridContainer spacing={6}>*/}
            {/*    <Grid item xs={12} md={12}>*/}
            {/*        <Statistics isRegular={isRegular}/>*/}
            {/*    </Grid>*/}
            {/*</GridContainer>*/}

            {isRegular ? (
              // SUBJECTS for REGULAR STUDENTS
              <>
                <Typography variant={"h5"} weight={"semibold"}>
                  Subject Schedule
                </Typography>
                <Space vertical space={3} />

                {enrollmentIsLoadingAvailableSubjects ? (
                  <SubjectsLoader />
                ) : enrollmentErrorToLoadAvailableSubjects ? (
                  <ErrorLoad reload={reloadAvailableSubjects} />
                ) : blockSectionCourses.length > 0 ? (
                  <GridContainer spacing={2}>
                    {blockSectionCourses.map((item, index) => (
                      <Grid item key={index} xs={12} sm={6} lg={4}>
                        <Card height={"100%"}>
                          <FlexContainer
                            direction={"column"}
                            justifyContent={"space-between"}
                          >
                            <FlexItem>
                              <Typography weight={"light"} variant={"h6"}>
                                {item.subjectCode}
                              </Typography>
                              <Typography weight={"semibold"} variant={"h6"}>
                                {item.subjectTitle}
                              </Typography>
                              <Space vertical space={2} />
                              <Typography>
                                <Typography weight={"bold"} span>
                                  {item.lecUnits}{" "}
                                </Typography>
                                Lec Unit{item.lecUnits > 1 ? "s" : ""} &nbsp;|
                                &nbsp;
                                <Typography weight={"bold"} span>
                                  {item.labUnits}{" "}
                                </Typography>
                                Lab Unit{item.labUnits > 1 ? "s" : ""}
                              </Typography>
                              {item.schedule1 ? (
                                <>
                                  <Space vertical space={2} />
                                  <Typography weight={"medium"}>
                                    {item.schedule1} - {item.room1}
                                  </Typography>
                                  <Space vertical space={0.5} />
                                </>
                              ) : null}
                              {item.schedule2 ? (
                                <>
                                  <Typography weight={"medium"}>
                                    {item.schedule2} - {item.room2}
                                  </Typography>
                                  <Space vertical space={0.5} />
                                </>
                              ) : null}
                              {item.schedule3 ? (
                                <>
                                  <Typography weight={"medium"}>
                                    {item.schedule3} - {item.room3}
                                  </Typography>
                                  <Space vertical space={0.5} />
                                </>
                              ) : null}
                              {item.schedule4 ? (
                                <>
                                  <Typography weight={"medium"}>
                                    {item.schedule4} - {item.room4}
                                  </Typography>
                                  <Space vertical space={0.5} />
                                </>
                              ) : null}
                              {item.schedule5 ? (
                                <>
                                  <Typography weight={"medium"}>
                                    {item.schedule5} - {item.room5}
                                  </Typography>
                                  <Space vertical space={0.5} />
                                </>
                              ) : null}
                            </FlexItem>
                          </FlexContainer>
                        </Card>
                      </Grid>
                    ))}
                  </GridContainer>
                ) : (
                  <EmptyContent reload={reloadAvailableSubjects} />
                )}
              </>
            ) : (
              // SUBJECTS for IRREGULAR STUDENTS
              <>
                <FlexContainer
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant={"h5"} weight={"semibold"}>
                    {registrationAdvisingIsValidated
                      ? "Subject Schedule"
                      : "Added Subjects"}
                  </Typography>
                  {registrationAdvisingIsValidated ? null : (
                    <FlexItem>
                      <FlexContainer alignItems={"center"}>
                        <Button onClick={clearAdditionalCourses}>
                          Remove All
                        </Button>
                      </FlexContainer>
                    </FlexItem>
                  )}
                </FlexContainer>

                <Space vertical space={3} />

                {enrollmentIsLoadingRegistrationSchedules ? (
                  <AdditionalSubjectsLoader />
                ) : enrollmentErrorToLoadRegistrationSchedules ? (
                  <ErrorLoad
                    reload={() =>
                      getRegisteredScheduleDetails(regIDForAdvising)
                    }
                  />
                ) : additionalCourses.length > 0 ? (
                  // isDownSM ?
                  <GridContainer spacing={2}>
                    {additionalCourses.map((item, index) => (
                      <Grid item key={index} xs={12} sm={6} lg={4}>
                        <Card height={"100%"}>
                          <FlexContainer
                            direction={"column"}
                            justifyContent={"space-between"}
                          >
                            <FlexItem>
                              <Typography weight={"light"} variant={"h6"}>
                                {item.courseCode}
                              </Typography>
                              <Typography weight={"semibold"} variant={"h6"}>
                                {item.courseTitle}
                              </Typography>
                              <Space vertical space={2} />
                              <Typography>
                                <Typography weight={"bold"} span>
                                  {item.lecUnits}{" "}
                                </Typography>
                                Lec Unit{item.lecUnits > 1 ? "s" : ""} &nbsp;|
                                &nbsp;
                                <Typography weight={"bold"} span>
                                  {item.labUnits}{" "}
                                </Typography>
                                Lab Unit{item.labUnits > 1 ? "s" : ""}
                              </Typography>
                              {item.schedule1 ? (
                                <>
                                  <Space vertical space={2} />
                                  <Typography weight={"medium"}>
                                    {item.schedule1} - {item.room1}
                                  </Typography>
                                  <Space vertical space={0.5} />
                                </>
                              ) : null}
                              {item.schedule2 ? (
                                <>
                                  <Typography weight={"medium"}>
                                    {item.schedule2} - {item.room2}
                                  </Typography>
                                  <Space vertical space={0.5} />
                                </>
                              ) : null}
                              {item.schedule3 ? (
                                <>
                                  <Typography weight={"medium"}>
                                    {item.schedule3} - {item.room3}
                                  </Typography>
                                  <Space vertical space={0.5} />
                                </>
                              ) : null}
                              {item.schedule4 ? (
                                <>
                                  <Typography weight={"medium"}>
                                    {item.schedule4} - {item.room4}
                                  </Typography>
                                  <Space vertical space={0.5} />
                                </>
                              ) : null}
                              {item.schedule5 ? (
                                <>
                                  <Typography weight={"medium"}>
                                    {item.schedule5} - {item.room5}
                                  </Typography>
                                  <Space vertical space={0.5} />
                                </>
                              ) : null}
                            </FlexItem>
                            {registrationAdvisingIsValidated ? null : (
                              <FlexItem>
                                <Space vertical space={3} />
                                <Button
                                  fullwidth
                                  onClick={() => changeSchedule(item)}
                                >
                                  Change Schedule
                                </Button>
                                <Space vertical space={1} />
                                <Button
                                  variant={"error"}
                                  fullwidth
                                  onClick={() => removeSubject(item.courseID)}
                                >
                                  Remove
                                </Button>
                              </FlexItem>
                            )}
                          </FlexContainer>
                        </Card>
                      </Grid>
                    ))}
                  </GridContainer>
                ) : (
                  <Card>
                    <FlexContainer
                      height={200}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography>No subjects added</Typography>
                    </FlexContainer>
                  </Card>
                )}
              </>
            )}

            {/* OFFERED SUBJECTS */}
            <>
              {isRegular || registrationAdvisingIsValidated ? null : (
                <>
                  <Space vertical space={4} />

                  {additionalCourses.length === offeredCourses.length &&
                  additionalCourses.length > 0 ? null : (
                    <>
                      <FlexContainer
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography variant={"h5"} weight={"semibold"}>
                          Offered Subjects
                        </Typography>
                      </FlexContainer>
                      <Space vertical space={3} />
                    </>
                  )}

                  {enrollmentIsLoadingOfferedSubjects ? (
                    <AdditionalSubjectsLoader />
                  ) : enrollmentErrorToLoadOfferedSubjects ? (
                    <ErrorLoad reload={getOfferedCourses} />
                  ) : offeredCourses.length > 0 ? (
                    // isDownSM ?
                    <GridContainer spacing={2}>
                      {offeredCourses.map((item, index) => {
                        if (isNotAdded(item.courseID)) {
                          return (
                            <Grid item key={index} xs={12} sm={6} lg={4}>
                              <Card height={"100%"}>
                                <FlexContainer
                                  direction={"column"}
                                  justifyContent={"space-between"}
                                >
                                  <FlexItem>
                                    <Typography weight={"light"} variant={"h6"}>
                                      {item.courseCode}
                                    </Typography>
                                    <Typography
                                      weight={"semibold"}
                                      variant={"h6"}
                                    >
                                      {item.courseTitle}
                                    </Typography>
                                    <Space vertical space={2} />
                                    <Typography>
                                      <Typography weight={"bold"} span>
                                        {item.lecUnits}{" "}
                                      </Typography>
                                      Lec Unit{item.lecUnits > 1 ? "s" : ""}{" "}
                                      &nbsp;| &nbsp;
                                      <Typography weight={"bold"} span>
                                        {item.labUnits}{" "}
                                      </Typography>
                                      Lab Unit{item.labUnits > 1 ? "s" : ""}
                                    </Typography>
                                    <Space vertical space={3} />
                                  </FlexItem>
                                  <FlexItem>
                                    <Button
                                      variant={"success"}
                                      fullwidth
                                      onClick={() => changeSchedule(item)}
                                    >
                                      View Schedule
                                    </Button>
                                  </FlexItem>
                                </FlexContainer>
                              </Card>
                            </Grid>
                          );
                        } else return null;
                      })}
                    </GridContainer>
                  ) : (
                    // <Table columns={subjectOfferedColumns} data={subjectOfferedData}
                    //        initSortIndex={2}/> :

                    <EmptyContent reload={getOfferedCourses} />
                  )}
                </>
              )}
            </>
          </div>
        </Grid>
      </GridContainer>

      <MessageDialog
        open={openSubmitConfirmation}
        variant={"warning"}
        title={"Submit Confirmation"}
        description={
          <Typography span>
            Upon confirmation, your registration will be submitted to the
            Student Accounts for validation. Once submitted, you{" "}
            <strong>CANNOT MODIFY</strong> your registration anymore.
          </Typography>
        }
        onClose={() => setOpenSubmitConfirmation(false)}
        onConfirm={onSubmit}
      />

      <MessageDialog
        open={openSubmitSuccess}
        variant={"success"}
        title={"Registration Submitted"}
        icon={checkboxCircleLine}
        onCloseLabel={"Close"}
        onConfirmLabel={"See Details"}
        description={
          "Your registration is now pending for validation. " +
          "We will notify you once the validation has concluded."
        }
        onClose={submitSuccessOnClose}
        onConfirm={seeDetails}
      />

      <MessageDialog
        open={openSubmitError}
        variant={"error"}
        title={"Submission Failed"}
        onCloseLabel={"Close"}
        onConfirmLabel={"Resubmit"}
        description={
          "Your registration was not submitted successfully. " +
          "Please try to submit again."
        }
        onClose={() => setOpenSubmitError(false)}
        onConfirm={() => {
          setOpenSubmitError(false);
          setOpenSubmitConfirmation(true);
        }}
      />

      <MessageDialog
        open={openSubmitForAdvisingConfirmation}
        variant={"warning"}
        title={"Submit Confirmation"}
        description={
          <Typography span>
            Upon confirmation, your registration will be submitted to your
            adviser and is pending for advising and validation. No further
            modifications are allowed until registration is returned by the
            adviser.
          </Typography>
        }
        onClose={() => setOpenSubmitForAdvisingConfirmation(false)}
        onConfirm={onSubmitForAdvising}
      />

      <MessageDialog
        open={openSubmitForAdvisingError}
        variant={"error"}
        title={"Submission Failed"}
        onCloseLabel={"Close"}
        onConfirmLabel={"Resubmit"}
        description={
          "Your registration was not submitted successfully. " +
          "Please try again."
        }
        onClose={() => setOpenSubmitForAdvisingError(false)}
        onConfirm={() => {
          setOpenSubmitForAdvisingError(false);
          onSubmitForAdvising();
        }}
      />

      <MessageDialog
        open={insufficientUnit}
        variant={"error"}
        title={"Insufficient Load Allowance"}
        onConfirmLabel={"Okay"}
        singleAction
        description={
          "Adding this subject will cause exceeding load to your maximum load units. " +
          "If you wish to take this subject, try removing others and add this again."
        }
        onClose={() => setInsufficientUnit(false)}
        onConfirm={() => setInsufficientUnit(false)}
      />

      <MessageDialog
        open={registrationSubjectNotInCurriWarning}
        variant={"warning"}
        title={"Not in Curriculum"}
        onConfirmLabel={"Okay"}
        singleAction
        description={
          "Warning! You are adding a subject that is not included in your curriculum."
        }
        onConfirm={() => toggle("registrationSubjectNotInCurriWarning", false)}
      />

      <DialogContainer open={openFreeHigherEducationConfirm} disableClose>
        <Typography variant={"h5"} weight={"semibold"} align={"center"}>
          Free Higher Education
        </Typography>
        <Space vertical space={3} />
        <Typography align={"justify"}>
          The University of Southeastern Philippines, as a state university, has
          implemented the
          <strong> Free Higher Education </strong> stated in the
          <strong> Republic Act 10931</strong> or also known as the
          <strong>
            {" "}
            Universal Access to Quality Tertiary Education Act of 2017
          </strong>{" "}
          since Academic Year 2018-2019. The law provides free tuition and other
          related school fees to the eligible students. The students, however,
          may voluntarily opt-out and waive the benefits of the said provision.
        </Typography>
        <Space vertical space={4} />
        <Button
          variant={enrollmentRegisterAvailFHE === true ? "primary" : null}
          fullwidth
          onClick={availFHE}
        >
          I want to avail of the free higher education this semester (I will not
          pay for my school fees).
        </Button>
        <Space vertical space={1} />
        <Button
          variant={enrollmentRegisterAvailFHE === false ? "primary" : null}
          fullwidth
          onClick={optOut}
        >
          I want to voluntarily opt-out (I will pay for my school fees) and
          waive all the benefits for this semester.
        </Button>
        <Space vertical space={1} />
        <Button fullwidth onClick={goBack}>
          Get me out of here
        </Button>
      </DialogContainer>

      <DialogContainer open={openFHEIneligible} disableClose>
        <Typography variant={"h5"} weight={"semibold"} align={"center"}>
          Please Read Carefully!
        </Typography>
        <Space vertical space={3} />
        <Typography variant={"h6"} align={"justify"}>
          The following students are not eligible to avail of the Free Higher
          Education:
        </Typography>
        <Space vertical space={1} />
        <Typography align={"justify"}>
          A. Students who have <strong>already obtained</strong> a bachelor’s
          degree or comparable undergraduate degree from any public or private
          HEI;
        </Typography>
        <Space vertical space={1} />
        <Typography align={"justify"}>
          B. Students who <strong>fail to comply</strong> with the admission and
          / or retention policies of the LUCs or SUCs;
        </Typography>
        <Space vertical space={1} />
        <Typography align={"justify"}>
          C. Students who <strong>fail to complete</strong> their bachelor’s
          degree or comparable undergraduate degree{" "}
          <strong>within a year after the period </strong>
          prescribed in their program; and
        </Typography>
        <Space vertical space={1} />
        <Typography align={"justify"}>
          D. Students who <strong>voluntarily opt out</strong> of the free
          higher education provision.
        </Typography>
        <Space vertical space={2} />
        <Typography align={"justify"}>
          In case you have availed even you're not eligible, the Free Higher
          Education will not cover your tuition fee and other school fees.
          Please confirm if you are sure you are eligible.
        </Typography>
        <Space vertical space={4} />
        <Button variant={"primary"} fullwidth onClick={eligibilityProceed}>
          Yes, I am eligible. Please proceed.
        </Button>
        <Space vertical space={1} />
        <Button fullwidth onClick={eligibilityBack}>
          No, I am NOT eligible. Please go back.
        </Button>
        {/*<FlexContainer justifyContent={'space-between'}>*/}
        {/*    <Button onClick={eligibilityBack}>No, I'm NOT ELIGIBLE</Button>*/}
        {/*    <Button variant={'primary'} onClick={eligibilityProceed}>Yes, I'm ELIGIBLE</Button>*/}
        {/*</FlexContainer>*/}
      </DialogContainer>

      <DialogContainer open={openOPTOUTWaiver} disableClose>
        <Typography variant={"h5"} weight={"semibold"} align={"center"}>
          Opt-Out Waiver
        </Typography>
        <Space vertical space={3} />
        <Typography align={"justify"}>
          I hereby certify that I am fully cognizant of{" "}
          <strong>Republic Act 10931 </strong>
          (Universal Access to Quality Tertiary Education Act of 2017) and all
          the privileges and responsibilities accorded and mandated to me under
          this Act. However, I chose to voluntarily opt out of the free higher
          education provision. Thus, I hereby waive all my benefits for the
          <strong> {enrollmentTerm}</strong> of the
          <strong> School Year {enrollmentAY}</strong> for the reason that I am
          capable of paying for my tuition and other related school fees.
        </Typography>
        <Space vertical space={4} />
        <FlexContainer justifyContent={"space-between"}>
          <Button onClick={optOutBack}>Back</Button>
          <Button variant={"primary"} onClick={optOutProceed}>
            Proceed
          </Button>
        </FlexContainer>
      </DialogContainer>

      <DialogContainer
        open={openSubmitOption}
        onClose={() => setOpenSubmitOption(false)}
      >
        <Typography variant={"h5"} weight={"semibold"} align={"left"}>
          Submit Registration
        </Typography>
        <Space vertical space={3} />
        <Card
          padding={3}
          borderHover
          onClick={() => {
            setOpenSubmitOption(false);
            setOpenSubmitForAdvisingDialog(true);
          }}
          disabled={!canBeSubmittedForAdvising()}
        >
          <FlexContainer justifyContent={""} alignItems={"center"}>
            <FlexItem>
              <Typography
                color={canBeSubmittedForAdvising() ? "primary" : null}
                weight={"bold"}
                align={"left"}
              >
                FOR ADVISING
              </Typography>
            </FlexItem>
            <Space space={2} />
            {registrationAdvisingIsValidated ? (
              <FlexItem>
                <IconMini
                  icon={checkLine}
                  height={18}
                  padding={2}
                  filled
                  variant={"success"}
                />
              </FlexItem>
            ) : null}
          </FlexContainer>
          <Space vertical space={1.5} />
          <Typography align={""}>
            Submit to your adviser. This allows you to contact your adviser to
            raise queries regarding your registration.
          </Typography>
        </Card>
        <Space vertical space={1.5} />
        <Card
          padding={3}
          borderHover
          onClick={() => {
            setOpenSubmitOption(false);
            setOpenSubmitConfirmation(true);
          }}
          disabled={!canBeSubmitted()}
        >
          <Typography
            color={canBeSubmitted() ? "primary" : null}
            weight={"bold"}
            align={"left"}
          >
            FOR FINAL SUBMISSION
          </Typography>
          <Space vertical space={1.5} />
          <Typography align={""}>
            Submit to the Student Accounts. This directly forwards your
            registration for validation of the asessment fees. Once submitted,
            you <strong>CANNOT MODIFY</strong> your registration anymore.
          </Typography>
        </Card>
        <Space vertical space={3} />
        <Button onClick={() => setOpenSubmitOption(false)} fullwidth>
          Cancel
        </Button>
      </DialogContainer>

      <DialogContainer
        open={openSubmitForAdvisingDialog}
        variant={"primary"}
        onClose={() => setOpenSubmitForAdvisingDialog(false)}
      >
        <Typography variant={"h5"} weight={"semibold"}>
          {advisingIDForAdvising ? "Resubmit" : "Submit"} for Advising
        </Typography>
        <Space vertical space={3} />
        <Typography align={"justify"}>
          Please add your remarks below.
        </Typography>
        <Space vertical space={2} />
        <Input
          id={"register-submit-advising-remarks"}
          type={"textarea"}
          error={remarksError}
        />
        <Space vertical space={3} />
        <Button
          variant={"primary"}
          fullwidth
          onClick={() => {
            setOpenSubmitForAdvisingDialog(false);
            if (advisingIDForAdvising) onResubmitAdvising();
            else setOpenSubmitForAdvisingConfirmation(true);
          }}
        >
          Submit
        </Button>
        <Space vertical space={1} />
        <Button fullwidth onClick={() => setOpenSubmitForAdvisingDialog(false)}>
          Cancel
        </Button>
      </DialogContainer>

      <DialogContainer open={openReEvaluateNotice} variant={"primary"}>
        <Typography variant={"h5"} weight={"semibold"} align={"center"}>
          Important Notice!
        </Typography>
        <Space vertical space={3} />
        <Typography align={"justify"}>
          If you think something is wrong in the list of subjects you can take,
          please <strong>RE EVALUATE</strong> first your Academic Program
          Evaluation. To do this, go to the{" "}
          <strong> Program Evaluation </strong> tab, then click{" "}
          <strong> Re-Evaluate</strong>.
        </Typography>
        <Space vertical space={3} />
        <Button
          variant={"primary"}
          fullwidth
          size={"medium"}
          onClick={() => setOpenReEvaluateNotice(false)}
        >
          {" "}
          Okay{" "}
        </Button>
      </DialogContainer>
    </div>
  );
}

const SubjectsLoader = () => {
  return (
    <div>
      <GridContainer spacing={2}>
        {[1, 2, 3].map((item, index) => (
          <Grid item key={index} xs={12} sm={6} lg={4}>
            <Card variant={"light"}>
              <SkeletonLoader width={100} height={36} />
              <SkeletonLoader height={36} />
              <SkeletonLoader width={"75%"} height={36} />
              <Space vertical space={2} />
              <SkeletonLoader height={24} />
              <SkeletonLoader width={"40%"} height={24} />
              <SkeletonLoader width={"75%"} height={24} />
              <Space vertical space={2} />
              <SkeletonLoader width={80} height={40} />
            </Card>
          </Grid>
        ))}
      </GridContainer>
    </div>
  );
};

const AdditionalSubjectsLoader = () => {
  return (
    <div>
      <GridContainer spacing={2}>
        {[1, 2, 3].map((item, index) => (
          <Grid item key={index} xs={12} sm={6} lg={4}>
            <Card variant={"light"}>
              <SkeletonLoader width={100} height={36} />
              <SkeletonLoader height={36} />
              <SkeletonLoader width={"75%"} height={36} />
              <Space vertical space={2} />
              <SkeletonLoader height={24} />
              <SkeletonLoader width={"40%"} height={24} />
              <SkeletonLoader width={"75%"} height={24} />
              <Space vertical space={2} />
              <SkeletonLoader width={80} height={40} />
            </Card>
          </Grid>
        ))}
      </GridContainer>
    </div>
  );
};

import axios from 'axios'

import {dispatch, getState} from "./index";
import {CONFIG} from "../../AppConfig";
import {isFunction} from "../../utils/misc";
import print from "../../utils/print";
import {setContext, toggle} from "./context";
import {GRADES_CLEAR, GRADES_SET, GRADES_SUMMARY_SET} from "../types";
import {sortByObjectKeyDesc} from "../../utils/sort";
import {getHTTPConfig} from "../../utils/requests";

const URL = CONFIG.URL_API

export const getGrades = (onSuccess, onFail) => {
    toggle('isGettingGrades', true)

    const payload = {
        AYTerms: []
    }
    const {studentID, campusID} = getState().AUTH

    const url = `${URL}student/getGrades/${studentID}/${campusID}`

    axios.get(url, getHTTPConfig()).then(result => {
        print('GET GRADES', result)

        for (let i of result.data) {

            // get AYTerms and put in the payload
            let containsAYTerm = false
            for (let term of payload.AYTerms) {
                if (term.id === i.termID) containsAYTerm = true
            }
            if (!containsAYTerm) {
                payload.AYTerms.push({
                    id: i.termID,
                    label: i.ayTerm,
                    data: {}
                })
                payload[i.termID] = []
            }
            payload.AYTerms = sortByObjectKeyDesc(payload.AYTerms, 'label')

            payload[i.termID].push({
                courseCode: i.courseCode,
                courseTitle: i.courseTitle,
                creditUnits: i.creditUnits,
                final: i.final,
                reExam: i.reExam,
                gradeRemarks: i.gradeRemarks,
                datePosted: i.datePosted
            })
        }

        // set initial term id
        if (!getState().CONTEXT.gradesTermID) setContext('gradesTermID', payload.AYTerms[0].id)

        dispatch(GRADES_SET, payload)
        toggle('isGettingGrades', false)
        if (isFunction(onSuccess)) onSuccess()

    }).catch(error => {
        print('GET GRADES', error)
        toggle('isGettingGrades', false)
        if (isFunction(onFail)) onFail()
    })

}

export const getGradesSummary = (termID, onSuccess, onFail) => {
    toggle('isGettingGradesSummary', true)

    const {studentID, campusID} = getState().AUTH

    const url = `${URL}student/getGradeSummary/${studentID}/${termID}/${campusID}`

    axios.get(url, getHTTPConfig()).then(result => {
        print('GET GRADES SUMMARY', result)

        const data = result.data[0]
        const payload = {
            GWA: data.gwa || 0,
            units: data.unitsEarned || 0,
            CQPAs: data.cqpaWithSummer || 0,
            CQPA: data.cqpa || 0
        }

        dispatch(GRADES_SUMMARY_SET, payload)
        toggle('isGettingGradesSummary', false)
        if (isFunction(onSuccess)) onSuccess()

    }).catch(error => {
        print('GET GRADES SUMMARY', error)

        const payload = {
            GWA: 0,
            units: 0,
            CQPAs: 0,
            CQPA: 0
        }

        dispatch(GRADES_SUMMARY_SET, payload)
        toggle('isGettingGradesSummary', false)
        if (isFunction(onFail)) onFail()
    })
}

export const clearGrades = () => {
    dispatch(GRADES_CLEAR)
}
import {SETTING_TOGGLE_DARK_THEME} from "../types";

const init = {
    IsDarkTheme: false
}

export default function (state = init, action) {
    switch (action.type) {

        case SETTING_TOGGLE_DARK_THEME:
            return {
                ...state,
                IsDarkTheme: !state.IsDarkTheme
            }

        default:
            return state
    }
}
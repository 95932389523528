import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
  withReduxStateSync,
} from "redux-state-sync";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import rootReducer from "./reducers";
import { CONFIG } from "../AppConfig";
import { persistWhiteList, syncWhiteList } from "./config";

const persistLocalStorageConfig = {
  key: "root",
  storage: storage,
  whitelist: persistWhiteList,
  stateReconciler: autoMergeLevel2,
};

const stateSyncConfig = {
  channel: "USePStudentPortal",
  whitelist: syncWhiteList,
};

const middleware = [createStateSyncMiddleware(stateSyncConfig)];
let reducers = persistReducer(
  persistLocalStorageConfig,
  withReduxStateSync(rootReducer)
);

export const store = createStore(
  reducers,
  {},
  CONFIG.ENV_DEV
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
);

initStateWithPrevTab(store);
export const persistor = persistStore(store);

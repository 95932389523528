import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import Space from "../components/space";
import { formatCurrency } from "../../utils/currency";

const styles = StyleSheet.create({
  container: {
    fontFamily: "Arial Narrow",
    width: "100%",
  },
  titleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  title: {
    letterSpacing: 10,
    fontSize: 8,
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
  },
  textNormal: {
    fontSize: 7,
  },
  textItalic: {
    fontSize: 7,
    fontStyle: "italic",
  },
  textBold: {
    fontSize: 7,
    fontWeight: "bold",
  },
  table: {
    display: "table",
    border: 0,
  },
  tableHeader: {
    flexDirection: "row",
    borderStyle: "solid",
    padding: "1px 0",
    borderWidth: 0,
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
  },
  tableRow: {
    // margin: "auto",
    flexDirection: "row",
  },
  tableCell: {
    margin: 2,
    fontSize: 7,
  },
  lastRow: {
    fontSize: 8,
    paddingTop: 0,
    marginTop: 4,
    lineHeight: 1,
    borderStyle: "solid",
    borderWidth: 0,
    borderTopWidth: 0.5,
  },
});

export default function Billing({ billing }) {
  const columns = [
    {
      label: "ACCOUNTS",
      textAlign: "left",
      celStyles: [styles.tableCell],
      colStyles: [{ width: "65%" }],
    },
    {
      label: "AMOUNT",
      textAlign: "right",
      wrapper: formatCurrency,
      celStyles: [styles.tableCell],
      colStyles: [{ width: "35%" }],
    },
  ];

  const data = billing;
  let totalAssessment = 0;
  billing.forEach((item) => (totalAssessment += item[1]));

  const lastRow = [
    {
      label: "TOTAL ASSESSMENT",
      textAlign: "left",
      celStyles: [styles.tableCell, styles.textBold],
      colStyles: [{ width: "65%" }],
    },
    {
      label: totalAssessment,
      textAlign: "right",
      wrapper: formatCurrency,
      celStyles: [styles.tableCell, styles.textBold],
      colStyles: [{ width: "35%" }],
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={[styles.title]}>BILLING</Text>
      </View>
      <Space vertical space={3} />
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          {columns.map((item) => (
            <View style={item.colStyles}>
              <Text
                style={[
                  item.celStyles,
                  styles.textBold,
                  {
                    textAlign: item.textAlign || "left",
                  },
                ]}
              >
                {item.label}
              </Text>
            </View>
          ))}
        </View>
        {data.map((row, rowIndex) => (
          <View style={styles.tableRow}>
            {row.map((cell, cellIndex) => (
              <View
                style={[
                  columns[cellIndex].colStyles,
                  {
                    textAlign: columns[cellIndex].textAlign || "left",
                  },
                ]}
              >
                <Text
                  style={[
                    columns[cellIndex].celStyles,
                    {
                      marginBottom: 0,
                      lineHeight: 1,
                    },
                  ]}
                >
                  {columns[cellIndex].wrapper
                    ? columns[cellIndex].wrapper(cell)
                    : cell.toUpperCase()}
                </Text>
              </View>
            ))}
          </View>
        ))}
        <View style={styles.tableRow}>
          {lastRow.map((cell, index) => (
            <View
              style={[
                cell.colStyles,
                styles.lastRow,
                {
                  textAlign: cell.textAlign || "left",
                },
              ]}
            >
              <Text style={[cell.celStyles]}>
                {cell.wrapper ? cell.wrapper(cell.label) : cell.label}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

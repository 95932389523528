import React from "react";
import {StyleSheet, Text, View} from '@react-pdf/renderer'
import Space from "../components/space";
import {gradeSerializer} from "../../utils/grades";

const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    titleContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    title: {
        letterSpacing: 10,
        fontSize: 8,
        // fontWeight: 'bold'
    },
    row: {
        flexDirection: 'row'
    },
    textNormal: {
        fontSize: 10
    },
    textItalic: {
        fontSize: 10,
        fontStyle: 'italic'
    },
    textBold: {
        fontSize: 10,
        fontWeight: 'bold'
    },
    table: {
        display: "table",
        border: 0,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0.5
    },
    tableHeader: {
        flexDirection: "row",
        borderStyle: "solid",
        padding: '1px 0',
        borderWidth: 0,
        borderTopWidth: 1,
        borderBottomWidth: 1
    },
    tableRow: {
        // margin: "auto",
        flexDirection: "row",
        alignItems: 'center',
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0.5
    },
    tableCell: {
        padding: '8px 6px',
        fontSize: 10,
    },
});

export default function Grades({subjects}) {

    const unitWrapper = (unit, force) => {
        return force ? unit.toFixed(1) : unit ? unit.toFixed(1) : ''
    }

    const columns = [
        {
            label: 'CODE',
            textAlign: 'left',
            celStyles: [
                styles.tableCell,
            ],
            colStyles: [
                {width: '15%'}
            ],
        },
        {
            label: 'SUBJECT TITLE',
            textAlign: 'left',
            celStyles: [
                styles.tableCell,
            ],
            colStyles: [
                {width: '38%'}
            ],
        },
        {
            label: 'UNIT',
            textAlign: 'center',
            wrapper: unitWrapper,
            celStyles: [
                styles.tableCell,
            ],
            colStyles: [
                {width: '10%'}
            ],
        },
        {
            label: 'FINAL',
            textAlign: 'center',
            wrapper: gradeSerializer,
            celStyles: [
                styles.tableCell,
            ],
            colStyles: [
                {width: '10%'}
            ],
        },
        {
            label: 'RE EXAM',
            textAlign: 'center',
            wrapper: gradeSerializer,
            celStyles: [
                styles.tableCell,
            ],
            colStyles: [
                {width: '12%'}
            ],
        },
        {
            label: 'REMARKS',
            textAlign: 'center',
            celStyles: [
                styles.tableCell,
            ],
            colStyles: [
                {width: '15%'}
            ],
        },
    ]

    const data = [
        // ['CS 420', 'THESIS 2', 3, 2, 0, 'Passed'],
        // ['CS 420', 'THESIS 2', 3, 2, 0, 'Passed'],
        // ['CS 420', 'THESIS 2', 3, 2, 0, 'Passed'],
        // ['CS 420', 'THESIS 2', 3, 2, 0, 'Passed'],
        // ['CS 420', 'THESIS 2', 3, 2, 0, 'Passed'],
        // ['CS 420', 'THESIS 2', 3, 2, 0, 'Passed'],
    ]

    if (subjects && subjects.length > 0) {
        for (let i of subjects){
            data.push([
                i.courseCode,
                i.courseTitle,
                i.creditUnits,
                gradeSerializer(i.final),
                gradeSerializer(i.reExam) || '-',
                i.gradeRemarks,
            ])
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.table}>
                <View style={styles.tableHeader}>
                    {columns.map(item => (
                        <View style={[item.colStyles,{
                            textAlign: item.textAlign || 'left'
                        }]}>
                            <Text style={[item.celStyles, styles.textBold]}>{item.label}</Text>
                        </View>
                    ))}
                </View>
                {data.map((row, rowIndex) => (
                    <View style={styles.tableRow}>
                        {row.map((cell, cellIndex) => (
                            <View style={[columns[cellIndex].colStyles,{
                                textAlign: columns[cellIndex].textAlign || 'left'
                            }]}>
                                <Text style={[columns[cellIndex].celStyles,{
                                    // paddingBottom: 0,
                                    lineHeight: 1,
                                }]}>{
                                    columns[cellIndex].wrapper ? columns[cellIndex].wrapper(cell) :
                                        cell
                                }</Text>
                            </View>
                        ))}
                    </View>
                ))}
            </View>
        </View>
    )
}
import {DASHBOARD_CLEAR, DASHBOARD_SET} from "../types";

const init = {
    cqpa: 0,
    programCompletionPercent: 0,
    latestRegID: '-',
    latestRegStatus: '-',
    currUnitsEarned: 0,
    currUnitsTotal: 0,
    currNumCourses: 0,
    currCoursesCreditUnits: 0,
    currCoursesLecUnits: 0,
    currCoursesLabUnits: 0,
    currCreditedCourses: 0,
    currCreditedUnits: 0,
    currLecUnitsEarned: 0,
    currLabUnitsEarned: 0,
    currCreditCourseToEarn: 0,
    currCreditUnitsToEarn: 0,
    currCreditLecUnitsToEarn: 0,
    currCreditLabUnitsToEarn: 0,
    // campus: "Obrero Campus, Davao City",
    // "college": "CIC",
    // "program": "BSIT",
    // "programMajor": "BTM",
    // "yearLevel": "1st Year",
    // "curriculum": "BSIT-BTM AY 2018"
}

export default function (state = init, action) {
    switch (action.type) {

        case DASHBOARD_SET:
            return action.payload

        case DASHBOARD_CLEAR:
            return init

        default:
            return state
    }
}
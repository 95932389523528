import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { formatDateTime } from "../../utils/date";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textNormal: {
    fontSize: 7,
  },
  textBold: {
    fontSize: 7,
    fontWeight: "bold",
  },
  textBoldItalic: {
    fontSize: 7,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  textItalic: {
    fontSize: 9,
    fontStyle: "italic",
  },
  portalBadge: {
    width: 100,
    padding: 4,
    fontSize: 10,
    textAlign: "center",
    borderStyle: "solid",
    borderWidth: 1,
  },
});

export default function Footer(props) {
  const date = new Date();
  return (
    <View>
      <View style={styles.container}>
        <View style={{ width: "50%" }}>
          <View style={styles.portalBadge}>
            <Text style={{ fontWeight: "bold" }}>STUDENT PORTAL</Text>
            <Text style={{ letterSpacing: 0.7, fontSize: 9 }}>
              portal.usep.edu.ph
            </Text>
          </View>
        </View>
        <View style={{ width: "50%" }}>
          <Text style={[styles.textItalic, { textAlign: "right" }]}>
            *This is a system generated report. No signature required.
          </Text>
          <Text
            style={[styles.textNormal, { textAlign: "right", fontSize: 8 }]}
          >
            Date Generated: {date.toDateString()}
          </Text>
        </View>
      </View>
    </View>
  );
}

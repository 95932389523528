import React, { Fragment, useEffect, useState } from "react";
import ReactHelmet from "../../../components/core/Helmet";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import Typography from "../../../components/core/typography/Typography";
import Button from "../../../components/core/button/Button";
import Space from "../../../components/core/Space";
import { useHistory } from "react-router";
import Card from "../../../components/custom/card/Card";
import Grid from "@material-ui/core/Grid";
import LineBreak from "../../../components/core/LineBreak";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import arrowLeftLine from "@iconify/icons-ri/arrow-left-line";
import { useSelector } from "react-redux";
import {
  checkIsSchedConflict,
  getScheduleByCourse,
  setAdditionalCourses,
} from "../../../redux/actions/enrollment";
import SkeletonLoader from "../../../components/core/loader/SkeletonLoader";
import ErrorLoad from "../../../components/custom/ErrorLoad";
import EmptyContent from "../../../components/custom/EmptyContent";
import { routes } from "../../../routes";
import Badge from "../../../components/core/Badge";
import { toggle } from "../../../redux/actions/context";
import globalStyle from "../../../static/styles";

export default function Schedule() {
  const style = makeStyles((theme) => ({
    root: {},
    genInfoItem: {
      marginBottom: 8,
    },
    infoLabel: {
      width: 100,
    },
    infoValue: {
      width: "calc(100% - 100px - 16px)",
      marginTop: "-2px",
    },
  }));

  const { root, genInfoItem, infoLabel, infoValue } = style();
  const history = useHistory();
  const { stickyTop } = globalStyle();

  const [checkResults, setCheckResults] = useState({});
  const [isCheckingConflict, setIsCheckingConflict] = useState(false);
  const [schedIDToCheck, setSchedIDToCheck] = useState(null);

  const { campusID, isUndergrad } = useSelector((state) => state.AUTH);
  const { courseIDForSchedule, preAddSubjectData } = useSelector(
    (state) => state.CONTEXT
  );
  const {
    enrollmentIsLoadingSchedules,
    enrollmentErrorToLoadSchedules,
  } = useSelector((state) => state.CONTEXT.toggles);
  const { courseSchedules, additionalCourses } = useSelector(
    (state) => state.ENROLLMENT
  );

  useEffect(() => {
    if (!courseIDForSchedule)
      history.replace(routes.enrollmentRegistrationRegister);
  });
  useEffect(() => {
    if (courseIDForSchedule) {
      fetchSchedules();
    }
  }, [courseIDForSchedule]);
  useEffect(() => {
    if (additionalCourses.length === 0 && courseSchedules.length > 0) {
      const autoAdd = {};
      courseSchedules.forEach((item) => {
        autoAdd[item.id] = {
          isConflict: false,
        };
      });
      setCheckResults(autoAdd);
    }
  }, [additionalCourses, courseSchedules]);

  const fetchSchedules = () => {
    getScheduleByCourse(courseIDForSchedule, preAddSubjectData.progID);
  };

  const goBack = () => history.goBack();

  const checkConflict = (schedID, success) => {
    setSchedIDToCheck(schedID);
    setIsCheckingConflict(true);
    const data = {
      schedToCheck: schedID,
      schedules: [],
      campusID,
    };
    additionalCourses.forEach((item) => data.schedules.push(item.schedID));
    const onSuccess = (data) => {
      if (data.conflict) {
        setCheckResults({
          ...checkResults,
          [schedID]: {
            isConflict: true,
            subjCode: data.conflictSubjCode,
            subjTitle: data.conflictSubjTitle,
          },
        });
      } else {
        success();
        setCheckResults({
          ...checkResults,
          [schedID]: {
            isConflict: false,
          },
        });
      }
      setIsCheckingConflict(false);
    };
    const onFail = () => {
      setIsCheckingConflict(false);
    };
    checkIsSchedConflict(data, onSuccess, onFail);
  };

  const addSchedule = (schedule) => {
    const onSuccess = () => {
      selectSchedule(schedule);
    };
    checkConflict(schedule.id, onSuccess);
  };

  const selectSchedule = (schedule) => {
    // if (!preAddSubjectData.isInCurriculum && !isUndergrad) {
    //     toggle('registrationSubjectNotInCurriWarning', true)
    // }
    toggle("registrationHasChanges", true);
    let subjectSched = additionalCourses;
    if (preAddSubjectData.selectedSchedID) {
      subjectSched = subjectSched.filter(
        (item) => item.courseID !== preAddSubjectData.courseID
      );
    }
    setAdditionalCourses(
      subjectSched.concat([
        {
          courseID: preAddSubjectData.courseID,
          courseCode: preAddSubjectData.courseCode,
          courseTitle: preAddSubjectData.courseTitle,
          creditUnits: preAddSubjectData.creditUnits,
          labUnits: preAddSubjectData.labUnits,
          lecUnits: preAddSubjectData.lecUnits,
          progID: preAddSubjectData.progID,

          room1: schedule.room1,
          schedule1: schedule.sched1,
          room2: schedule.room2,
          schedule2: schedule.sched2,
          room3: schedule.room3,
          schedule3: schedule.sched3,
          room4: schedule.room4,
          schedule4: schedule.sched4,
          room5: schedule.room5,
          schedule5: schedule.sched5,
          schedID: schedule.id,
        },
      ])
    );
    goBack();
  };

  const generalInformation = [
    {
      label: "Subject Title",
      value: preAddSubjectData.courseTitle,
      size: {
        xs: 12,
        sm: 12,
        md: 8,
      },
    },
    {
      label: "Subject Code",
      value: preAddSubjectData.courseCode,
      size: {
        xs: 12,
        sm: 6,
        md: 4,
      },
    },
    {
      label: "Lecture Units",
      value: preAddSubjectData.lecUnits,
      size: {
        xs: 12,
        sm: 6,
        md: 4,
      },
    },
    {
      label: "Laboratory Units",
      value: preAddSubjectData.labUnits,
      size: {
        xs: 12,
        sm: 6,
        md: 4,
      },
    },
    {
      label: "Credit Units",
      value: preAddSubjectData.creditUnits,
      size: {
        xs: 12,
        sm: 6,
        md: 4,
      },
    },
  ];

  return (
    <div className={root}>
      <ReactHelmet title={"Schedule | Register | Enrollment"} />

      <Space vertical space={3} />
      <div className={stickyTop}>
        <Space vertical space={1} />
        <FlexContainer alignItems={"center"} justifyContent={"space-between"}>
          <FlexItem>
            <Typography variant={"h4"} weight={"medium"}>
              Schedule
            </Typography>
          </FlexItem>
          <FlexItem>
            <FlexContainer alignItems={"center"}>
              <Button onClick={goBack} icon={arrowLeftLine}>
                Back
              </Button>
            </FlexContainer>
          </FlexItem>
        </FlexContainer>
        <Space vertical space={1} />
      </div>

      <Space vertical space={3} />
      <LineBreak />
      <Space vertical space={4} />

      {/*GENERAL INFORMATION*/}
      <Fragment>
        <FlexContainer justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"h5"} weight={"medium"}>
            Subject Information
          </Typography>
        </FlexContainer>
        <Space vertical space={3} />
        <GridContainer spacing={2}>
          {generalInformation.map((item, index) => (
            <Grid
              key={index}
              item
              xs={item.size ? item.size.xs : 12}
              sm={item.size ? item.size.sm : 6}
              md={item.size ? item.size.md : 4}
              lg={item.size ? item.size.lg : 3}
            >
              <FlexContainer
                justifyContent={"start"}
                classNames={[genInfoItem]}
              >
                <FlexItem classNames={[infoLabel]}>
                  <Typography variant={"caption"}>{item.label}</Typography>
                </FlexItem>
                <Space space={1} />
                <FlexItem classNames={[infoValue]}>
                  <Typography weight={"semibold"}>{item.value}</Typography>
                </FlexItem>
              </FlexContainer>
            </Grid>
          ))}
        </GridContainer>
      </Fragment>

      <Space vertical space={4} />

      <FlexContainer justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant={"h5"} weight={"medium"}>
          Schedules
        </Typography>
      </FlexContainer>
      <Space vertical space={3} />

      {enrollmentIsLoadingSchedules ? (
        <ScheduleLoader />
      ) : enrollmentErrorToLoadSchedules ? (
        <ErrorLoad reload={fetchSchedules} />
      ) : courseSchedules.length > 0 ? (
        <GridContainer spacing={2}>
          {courseSchedules.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Card height={"100%"}>
                {item.isSpecialClass ? (
                  <>
                    <Badge variant={"primary"} size={"small"}>
                      <Typography color={"white"}>Special Class</Typography>
                    </Badge>
                    <Space vertical space={3} />
                  </>
                ) : null}
                <Typography weight={"semibold"} variant={"h6"}>
                  {item.sched1} - {item.room1}
                </Typography>
                <Space vertical space={0.5} />
                {item.sched2 ? (
                  <>
                    <Typography weight={"semibold"} variant={"h6"}>
                      {item.sched2} - {item.room2}
                    </Typography>
                    <Space vertical space={0.5} />
                  </>
                ) : null}
                {item.sched3 ? (
                  <>
                    <Typography weight={"semibold"} variant={"h6"}>
                      {item.sched3} - {item.room3}
                    </Typography>
                    <Space vertical space={0.5} />
                  </>
                ) : null}
                {item.sched4 ? (
                  <>
                    <Typography weight={"semibold"} variant={"h6"}>
                      {item.sched4} - {item.room4}
                    </Typography>
                    <Space vertical space={0.5} />
                  </>
                ) : null}
                {item.sched5 ? (
                  <>
                    <Typography weight={"semibold"} variant={"h6"}>
                      {item.sched5} - {item.room5}
                    </Typography>
                    <Space vertical space={0.5} />
                  </>
                ) : null}
                <Space vertical space={2} />
                <Typography>Section: {item.section}</Typography>
                {item.isSpecialClass ? (
                  <Typography>Special Class</Typography>
                ) : null}
                <Space vertical space={3} />
                {
                  preAddSubjectData.selectedSchedID === item.id ? (
                    <div>
                      <Button variant={"primary"} fullwidth>
                        Selected
                      </Button>
                    </div>
                  ) : item.id in checkResults &&
                    checkResults[item.id].isConflict ? (
                    <div>
                      <Button variant={"error"} fullwidth disableHover>
                        Conflict
                      </Button>
                      <Space vertical space={3} />
                      <Typography>
                        Conflicts on the subject
                        <strong>
                          {" "}
                          {checkResults[item.id].subjTitle}{" "}
                        </strong>{" "}
                        with a code
                        <strong> {checkResults[item.id].subjCode}</strong>.
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Button
                        variant={"success"}
                        fullwidth
                        disabled={
                          isCheckingConflict && schedIDToCheck !== item.id
                        }
                        isLoading={
                          isCheckingConflict && schedIDToCheck === item.id
                        }
                        onClick={() => addSchedule(item)}
                      >
                        Select Schedule
                      </Button>
                      {/*<Space vertical space={3}/>*/}
                      {/*<Typography>No conflicts found</Typography>*/}
                    </div>
                  )
                  // :
                  //     <Button variant={'warning'} fullwidth
                  //             disabled={isCheckingConflict && schedIDToCheck !== item.id}
                  //             isLoading={isCheckingConflict && schedIDToCheck === item.id}
                  //             onClick={() => checkConflict(item.id)}>Check Conflict</Button>
                }
              </Card>
            </Grid>
          ))}
        </GridContainer>
      ) : (
        <EmptyContent reload={fetchSchedules} />
      )}

      <Space vertical space={10} />
    </div>
  );
}

const ScheduleLoader = () => {
  return (
    <div>
      <GridContainer spacing={2}>
        {[1, 2, 3].map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card borderColor={"light"}>
              <SkeletonLoader width={100} height={36} />
              <SkeletonLoader height={36} />
              <SkeletonLoader width={"75%"} height={36} />
              <Space vertical space={2} />
              <SkeletonLoader height={24} />
              <SkeletonLoader width={"40%"} height={24} />
              <SkeletonLoader width={"75%"} height={24} />
              <Space vertical space={2} />
              <SkeletonLoader width={80} height={40} />
            </Card>
          </Grid>
        ))}
      </GridContainer>
    </div>
  );
};

import { Font } from "@react-pdf/renderer";

import LatoRegular from "./fonts/Lato/Lato-Regular.ttf";
import LatoRegularItalic from "./fonts/Lato/Lato-Italic.ttf";
import LatoBold from "./fonts/Lato/Lato-Bold.ttf";
import LatoBoldItalic from "./fonts/Lato/Lato-BoldItalic.ttf";
import LatoBlack from "./fonts/Lato/Lato-Black.ttf";
import LatoBlackItalic from "./fonts/Lato/Lato-BlackItalic.ttf";

import Arial from "./fonts/Arial/ARIAL.TTF";
import ArialBold from "./fonts/Arial/ARIALBD.TTF";
import ArialNarrow from "./fonts/Arial/ARIALN.TTF";
import ArialNarrowBold from "./fonts/Arial/ARIALNB.TTF";
import ArialNarrowItalic from "./fonts/Arial/ARIALNI.TTF";

export const registerFontLato = () => {
  Font.register({
    family: "Lato",
    fonts: [
      {
        src: LatoRegular,
        fontStyle: "normal",
        fontWeight: "normal",
      },
      {
        src: LatoRegularItalic,
        fontStyle: "italic",
        fontWeight: "normal",
      },
      {
        src: LatoBold,
        fontStyle: "normal",
        fontWeight: "bold",
      },
      {
        src: LatoBoldItalic,
        fontStyle: "italic",
        fontWeight: "bold",
      },
      {
        src: LatoBlack,
        fontStyle: "normal",
        fontWeight: "heavy",
      },
      {
        src: LatoBlackItalic,
        fontStyle: "italic",
        fontWeight: "heavy",
      },
    ],
  });
};

export const registerFontArial = () => {
  Font.register({
    family: "Arial",
    fonts: [
      {
        src: Arial,
        fontStyle: "normal",
        fontWeight: "normal",
      },
      {
        src: ArialBold,
        fontStyle: "normal",
        fontWeight: "bold",
      },
    ],
  });
  Font.register({
    family: "Arial Narrow",
    fonts: [
      {
        src: ArialNarrow,
        fontStyle: "normal",
        fontWeight: "normal",
      },
      {
        src: ArialNarrowBold,
        fontStyle: "normal",
        fontWeight: "bold",
      },
      {
        src: ArialNarrowItalic,
        fontStyle: "italic",
        fontWeight: "normal",
      },
    ],
  });
};

import React from "react";
import {getState} from "../redux/actions";

export const getHTTPConfig = (token) => {
    return {
        headers: {
            'Authorization': token || getState().AUTH.token,
            'Content-Type': 'application/json'
        }
    }
}
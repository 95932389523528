import React from "react";
import {StyleSheet, View} from '@react-pdf/renderer'

export default function Space({vertical, space}) {
    const styles = StyleSheet.create({
        root: {
            height: vertical ? space : 1,
            width: !vertical ? space : 1,
        },
    });

    return (
        <View style={styles.root}>
        </View>
    )
}
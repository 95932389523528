import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Space from "../Space";
import Typography from "../typography/Typography";
import FlexContainer from "../containers/flex/FlexContainer";
import Icon from "@iconify/react";
import arrowDropDownLine from "@iconify/icons-ri/arrow-drop-down-line";
import { isDarkTheme } from "../../../utils/appSetting";
import { isFunction } from "../../../utils/misc";
import clsx from "clsx";
import globalStyle from "../../../static/styles";
import SimplebarScrollbar from "../containers/scrollbars/simplebar/SimplebarScrollbar";
import useTheme from "@material-ui/core/styles/useTheme";

export default function Select({
  items,
  name,
  value,
  placeholder,
  align,
  disabled,
  maxLength,
  minLength,
  error,
  onChange,
  onSelect,
  customItemElement,
  width,
  size,
  errorAlign,
  maxHeight,
}) {
  const isDark = isDarkTheme();
  const theme = useTheme();
  items = items ? items : [];
  const padding =
    size === "medium" ? "12px 13px 12px 20px" : "8px 12px 8px 16px";

  const globalStyles = globalStyle();
  const styles = style();

  const [isListOpen, setIsListOpen] = useState(false);
  const containerRef = React.createRef();

  const openListPanel = () => setIsListOpen(true);
  const closeListPanel = () => setIsListOpen(false);
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target))
      closeListPanel();
  };
  const getInputValue = (id) => {
    for (let i of items) {
      if (i.id === id) return i.label;
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return function cleanup() {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div
      className={clsx(styles.root, globalStyles.textNoSelect)}
      style={{ width }}
      ref={containerRef}
    >
      <div className={styles.container}>
        <div
          className={styles.inputContainer}
          style={{
            padding,
            borderColor: disabled
              ? theme.palette.grey.shade3
              : error
              ? theme.palette.error.main
              : theme.palette.grey.shade2,
          }}
          onClick={!disabled && openListPanel}
        >
          <FlexContainer alignItems={"center"} justifyContent={"space-between"}>
            <input
              className={styles.input}
              name={name}
              value={getInputValue(value)}
              placeholder={placeholder}
              align={align}
              readOnly
              maxLength={maxLength}
              minLength={minLength}
              onChange={onChange}
            />
            <Space space={2} />
            <div
              className={clsx(
                styles.icon,
                isListOpen && globalStyles.rotateLeft180
              )}
            >
              <Icon
                icon={arrowDropDownLine}
                width={24}
                color={theme.palette.default.main}
              />
            </div>
          </FlexContainer>
        </div>
        {isListOpen &&
          (items.length > 0 ? (
            <div
              className={styles.list}
              style={{
                boxShadow: isDark
                  ? "0px 0px 8px rgba(142, 142, 142, 0.5)"
                  : "0px 0px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <SimplebarScrollbar maxHeight={maxHeight}>
                {items.map((item) => (
                  <div
                    key={item.id}
                    className={clsx(
                      styles.listItem,
                      styles.listItemHover,
                      item.id === value && styles.active
                    )}
                    style={{ padding }}
                    onClick={() => {
                      if (item.id !== value) {
                        if (isFunction(onSelect)) onSelect(item.id);
                        closeListPanel();
                      }
                    }}
                  >
                    {isFunction(customItemElement) ? (
                      customItemElement(item)
                    ) : (
                      <Typography>{item.label}</Typography>
                    )}
                  </div>
                ))}
              </SimplebarScrollbar>
            </div>
          ) : (
            <div className={styles.list}>
              <div className={styles.listItem}>
                <FlexContainer justifyContent={"center"}>
                  <Typography variant={"caption"}>No Suggestion</Typography>
                </FlexContainer>
              </div>
            </div>
          ))}
      </div>

      {(error || disabled) && (
        <div>
          <Space vertical space={1} />
          <FlexContainer justifyContent={errorAlign}>
            {disabled ? (
              <Typography color={"grey2"}> Disabled </Typography>
            ) : (
              <Typography color={"error"}> {error} </Typography>
            )}
          </FlexContainer>
        </div>
      )}
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  container: {
    position: "relative",
    display: "inline-block",
    width: "100%",
  },
  inputContainer: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: theme.shape.borderRadiusSmall,
  },
  input: {
    background: "transparent",
    border: 0,
    padding: 0,
    outline: 0,
    caretColor: theme.palette.default.main,
    "&::placeholder": {
      color: theme.palette.default.main,
    },
    "&:hover": {
      cursor: "pointer",
    },
    fontSize: theme.typography.body.fontSize,
    color: theme.palette.default.main,
    width: "100%",
  },
  list: {
    padding: "8px 0",
    position: "absolute",
    top: "102%",
    left: 0,
    width: "100%",
    zIndex: 2,
    borderRadius: theme.shape.borderRadiusSmall,
    background: theme.palette.background,
  },
  listItem: {
    borderRadius: theme.shape.borderRadiusSmall,
  },
  listItemHover: {
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.grey.shade5,
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
  },
  active: {
    background: theme.palette.grey.shade5,
  },
}));

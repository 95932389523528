import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { isFunction } from "../../../utils/misc";

export default function Card({
  variant,
  children,
  onClick,
  borderColor,
  padding,
  minHeight,
  height,
  paddingCustom,
  error,
  noHover,
  borderHover,
  disabled,
}) {
  const theme = useTheme();

  noHover = noHover || !isFunction(onClick);
  borderColor =
    borderColor === "light"
      ? theme.palette.grey.shade5
      : theme.palette.grey.shade4;

  const style = makeStyles((theme) => ({
    root: {
      minHeight: height ? `calc(${height} - 80px)` : null,
      height: height ? `calc(${height} - 80px)` : null,
      padding: theme.spacing(padding || 3),
      paddingTop: theme.spacing(padding || 5),
      paddingBottom: theme.spacing(padding || 4),
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: error ? theme.palette.error.main : borderColor,
      borderRadius: theme.shape.borderRadiusSmall,
      // boxShadow: `0px 0px 4px ${variant === 'light' ? theme.palette.grey.shade4 : theme.palette.grey.shade3}`,

      "&:hover": {
        borderColor: disabled
          ? null
          : borderHover
          ? theme.palette.primary.main
          : noHover
          ? null
          : theme.palette.background,
        boxShadow: disabled
          ? null
          : borderHover
          ? null
          : noHover
          ? null
          : `0px 0px 10px ${theme.palette.grey.shade4}`,
        cursor: disabled
          ? null
          : noHover
          ? null
          : onClick
          ? "pointer"
          : "unset",
        transition: theme.transitions.faster,
      },

      "&": {
        padding: paddingCustom,
      },
    },
    hover: {},
  }));

  const { root } = style();

  return (
    <div className={root} onClick={disabled ? null : onClick}>
      {" "}
      {children}{" "}
    </div>
  );
}

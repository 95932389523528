import axios from "axios";
import { CONFIG } from "../../AppConfig";
import { dispatch, getState } from "./index";
import print from "../../utils/print";
import { formatDateTime } from "../../utils/date";
import {
  ENROLLMENT_CLEAR,
  ENROLLMENT_REGISTRATION_ADDITIONAL_COURSES_SET,
  ENROLLMENT_REGISTRATION_ADVISING_REMARKS_SET,
  ENROLLMENT_REGISTRATION_BILLING_SET,
  ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_CLEAR,
  ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_SET,
  ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_CLEAR,
  ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_SET,
  ENROLLMENT_REGISTRATION_DETAILS_SET,
  ENROLLMENT_REGISTRATION_INFO_CLEAR,
  ENROLLMENT_REGISTRATION_OFFERED_COURSES_SET,
  ENROLLMENT_REGISTRATION_SCHEDULE_SET,
  ENROLLMENT_REGISTRATION_SECTIONS_SET,
  ENROLLMENT_REGISTRATION_SET,
  ENROLLMENT_REGISTRATION_VALIDATION_PROCESS_SET,
} from "../types";
import { isFunction } from "../../utils/misc";
import { setContext, toggle } from "./context";
import { retry } from "../../utils/actions";
import { sortByObjectKeyAsc } from "../../utils/sort";
import { getHTTPConfig } from "../../utils/requests";
import { setAuthData } from "./auth";

const URL = CONFIG.URL_API + "enrollment/";
const URL_ROOT = CONFIG.URL_API;

const pendingRequests = {
  saveRegistration: false,
};

export const getRegistrations = (onSuccess, onFail) => {
  toggle("enrollmentIsLoadingRegistrations", true);
  toggle("enrollmentErrorToLoadRegistrations", false);

  const { studentID, campusID } = getState().AUTH;

  const url = `${URL}getRegistrations/${studentID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET REGISTRATIONS", result);

      let payload = [];

      for (let i of result.data) {
        payload.push({
          regNum: i.regID,
          regDate: formatDateTime(i.regDate),
          term: i.acadTerm,
          AY: i.acadYear,
          campus: i.campus,
          program: i.progName,
          major: i.major,
          yearLevel: i.yearLevel,
          status: i.validationStatus,
          isOfficiallyEnrolled: i.isOfficiallyEnrolled,

          pendingOffice: i.pendingOffice,
          isUnderAdvising: i.isUnderAdvising,
          hasChanges: i.hasChanges,
          lastAdviserRemarks: i.lastAdviserRemarks,
          isAdvisingValidated: i.isAdvisingValidated,
          isUnderStudentAccounts: i.isUnderStudentAccounts,
          isFHE: i.isFHE,
          isValidatedByStudentAccounts: i.isValidatedByStudentAccounts,
        });
      }

      payload = payload.reverse();
      dispatch(ENROLLMENT_REGISTRATION_SET, payload);
      toggle("enrollmentIsLoadingRegistrations", false);
    })
    .catch((error) => {
      print("GET REGISTRATIONS", error);
      toggle("enrollmentErrorToLoadRegistrations", true);
      toggle("enrollmentIsLoadingRegistrations", false);
    });
};

export const getAvailableScheduleBySection = (sectionID, onSuccess, onFail) => {
  if (!sectionID) return;
  const { campusID } = getState().AUTH;

  toggle("enrollmentIsLoadingAvailableSubjects", true);
  toggle("enrollmentErrorToLoadAvailableSubjects", false);

  const url = `${URL}getAvailableScheduleBySection/${sectionID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET AVAILABLE SCHEDULE BY SECTION", result);

      let payload = [];
      let [sumLecLoad, sumLabLoad] = [0, 0, 0];

      for (let i of result.data) {
        sumLecLoad += i.AcadUnits;
        sumLabLoad += i.LabUnits;
        payload.push({
          subjectID: i.SubjectID,
          subjectCode: i.SubjectCode,
          subjectTitle: i.SubjectTitle,
          labUnits: i.LabUnits,
          labHrs: i.LabHrs,
          lecUnits: i.AcadUnits,
          lecHrs: i.LectHrs,
          isSpecialClass: i.IsSpecialClass,

          faculty: i.Faculty1,
          room: i.Room1,
          sched: i.Sched_1,
          room2: i.Room2,
          sched2: i.Sched_2,
          room3: i.Room3,
          sched3: i.Sched_3,
          room4: i.Room4,
          sched4: i.Sched_4,
          room5: i.Room5,
          sched5: i.Sched_5,
          schedID: i.ScheduleID,
        });
      }

      const sumLoad = sumLecLoad + sumLabLoad;

      setContext("currentLoad", sumLoad);
      setContext("currentLecLoad", sumLecLoad);
      setContext("currentLabLoad", sumLabLoad);

      dispatch(ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_SET, payload);

      if (isFunction(onSuccess)) onSuccess(sumLoad);
      toggle("enrollmentIsLoadingAvailableSubjects", false);
    })
    .catch((error) => {
      print("GET AVAILABLE SCHEDULE BY SECTION", error);
      toggle("enrollmentErrorToLoadAvailableSubjects", true);
      toggle("enrollmentIsLoadingAvailableSubjects", false);
    });
};

export const getOfferedCourses = (onSuccess, onFail) => {
  toggle("enrollmentIsLoadingOfferedSubjects", true);
  toggle("enrollmentErrorToLoadOfferedSubjects", false);

  let { studentID, campusID, isUndergrad, collegeID, termID } = getState().AUTH;

  const url = isUndergrad
    ? `${URL}getOfferedCourses/${studentID}/${campusID}`
    : `${URL}getOfferedCoursesGrad/${studentID}/${termID}/${collegeID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET OFFERED COURSES", result);

      let payload = [];

      for (let i of result.data) {
        payload.push({
          courseCode: i.courseCode,
          courseID: i.courseID,
          courseTitle: i.courseTitle,
          creditUnits: i.creditUnits,
          labUnits: i.labUnits,
          lecUnits: i.lecUnits,
          isPreReqPassed: i.preReqPassed,
          yearStanding: i.yearStanding,
          programCode: i.programCode || null,
          isInCurriculum: i.isInCurriculum,
          progID: i.progID || null,
        });
      }

      dispatch(ENROLLMENT_REGISTRATION_OFFERED_COURSES_SET, payload);
      toggle("enrollmentIsLoadingOfferedSubjects", false);
    })
    .catch((error) => {
      print("GET OFFERED COURSES", error);
      toggle("enrollmentErrorToLoadOfferedSubjects", true);
      toggle("enrollmentIsLoadingOfferedSubjects", false);
    });
};

export const getScheduleByCourse = (courseID, progID, onSuccess, onFail) => {
  toggle("enrollmentIsLoadingSchedules", true);
  toggle("enrollmentErrorToLoadSchedules", false);

  const { campusID } = getState().AUTH;

  const url = `${URL}getScheduleByCourse/${courseID}/${progID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET SCHEDULE BY COURSE", result);

      let payload = [];

      for (let i of result.data) {
        payload.courseCode = i.courseCode;
        payload.courseTitle = i.courseTitle;
        payload.push({
          id: i.ID,
          room1: i.Room1,
          sched1: i.Sched_1,
          room2: i.Room2,
          sched2: i.Sched_2,
          room3: i.Room3,
          sched3: i.Sched_3,
          room4: i.Room4,
          sched4: i.Sched_4,
          room5: i.Room5,
          sched5: i.Sched_5,
          faculty: i.Faculty,
          sectionID: i.SectionID,
          section: i.SectionName,
          isSpecialClass: i.IsSpecialClass,
        });
      }

      dispatch(ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_SET, payload);

      if (isFunction(onSuccess)) onSuccess();

      toggle("enrollmentIsLoadingSchedules", false);
    })
    .catch((error) => {
      print("GET SCHEDULE BY COURSE", error);

      toggle("enrollmentIsLoadingSchedules", false);
      toggle("enrollmentErrorToLoadSchedules", true);
      if (isFunction(onFail)) onFail();
    });
};

export const checkIsSchedConflict = (data, onSuccess, onFail) => {
  const url = `${URL}isSchedConflict`;

  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("CHECK SCHEDULE CONFLICT", result);
      if (isFunction(onSuccess)) onSuccess(result.data);
    })
    .catch((error) => {
      print("CHECK SCHEDULE CONFLICT", error);
      if (isFunction(onFail)) onFail();
    });
};

export const checkIsRegular = (onSuccess, onFail) => {
  const { studentID, campusID } = getState().AUTH;
  const url = `${URL_ROOT}student/isRegular/${studentID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("CHECK IS REGULAR", result);
      setAuthData("isRegular", result.data);
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("CHECK IS REGULAR", error);
      checkIsRegular();
      if (isFunction(onFail)) onFail();
    });
};

export const getOfferedBlockSections = (onSuccess, onFail) => {
  let retries;
  const { studentID, campusID } = getState().AUTH;

  toggle("enrollmentIsLoadingSections", true);

  const url = `${URL}getOfferedBlockSections/${studentID}/${campusID}`;
  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET SECTION", result);

      let payload = [];

      for (let i of result.data) {
        payload.push({
          id: i.SectionID,
          label: i.SectionName,
          data: {
            isBlock: i.ISBlock,
          },
        });
      }

      if (!getState().CONTEXT.selectedSection) {
        const initSectionID = payload[0].id;
        setContext("selectedSection", initSectionID);
      }

      dispatch(ENROLLMENT_REGISTRATION_SECTIONS_SET, payload);

      if (isFunction(onSuccess)) onSuccess();
      toggle("enrollmentIsLoadingSections", false);
    })
    .catch((error) => {
      print("GET SECTION", error);
      retries = retry(getOfferedBlockSections, retries);
    });
};

export const saveRegistration = (data, onSuccess, onFail) => {
  toggle("enrollmentIsSubmittingRegistration", true);

  print(
    "DATA:",
    data.advising ? `saveRegistrationForAdvising` : `saveRegistration`,
    data
  );

  const url = data.advising
    ? `${URL}saveRegistrationForAdvising`
    : `${URL}saveRegistration`;

  if (pendingRequests.saveRegistration) {
    toggle("enrollmentIsSubmittingRegistration", false);
    return;
  }
  pendingRequests.saveRegistration = true;

  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("SAVE REGISTRATION", result);
      if (result.data.status)
        setContext("registrationNumforView", result.data.regID);
      toggle("enrollmentIsSubmittingRegistration", false);
      if (isFunction(onSuccess)) onSuccess();
      pendingRequests.saveRegistration = false;
    })
    .catch((error) => {
      print("SAVE REGISTRATION", error);
      toggle("enrollmentIsSubmittingRegistration", false);
      if (isFunction(onFail)) onFail();
      pendingRequests.saveRegistration = false;
    });
};

export const getRegistrationDetails = (regID, onSuccess, onFail) => {
  toggle("enrollmentIsLoadingRegistrationDetails", true);
  toggle("enrollmentErrorToLoadRegistrationDetails", false);

  const { campusID, isUndergrad } = getState().AUTH;

  const url = `${URL}getRegistrationDetails/${regID}/${campusID}`;
  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET REGISTRATION DETAILS", result);

      const data = result.data;

      const payload = {
        details: {
          gender:
            data.Gender === "M"
              ? "Male"
              : data.Gender === "F"
              ? "Female"
              : null,
          citizenship: data.Citizenship,
          section: data.Section,
          isFreeHigh: isUndergrad ? data.isFreeHigh : false,
          campus: data.CampusName,
          college: data.CollegeName,
          collegeCode: data.CollegeCode,
          program: data.ProgramName,
          programCode: data.ProgramCode,
          major: data.MajorName,
          majorCode: data.MajorCode,
          yearLevel: data.YearLevel,
          classType: data.classType,
          academicYear: data.SchoolYear,
          academicTerm: data.SchoolTerm,
          scholarship: data.ScholarshipProvidername,
          scholarshipType: data.SchoProviderType,
          lecUnits: data.TotalLecUnits,
          labUnits: data.TotalLabUnits,
          registrationDate: data.RegDate,
          validationDate: data.ValidationDate,
          totalAssessment: data.billingTotal,
          feeTemplate: data.FeeTemplate,
          status: data.status,
        },
        billing: [],
      };

      data.billingAssessment.forEach((item) => {
        payload.billing.push({
          acct: `${item.AcctName} ${item.Remarks}`,
          amount: item.AssessedFee,
        });
      });

      dispatch(ENROLLMENT_REGISTRATION_DETAILS_SET, payload.details);
      dispatch(ENROLLMENT_REGISTRATION_BILLING_SET, payload.billing);

      if (isFunction(onSuccess)) onSuccess();
      toggle("enrollmentIsLoadingRegistrationDetails", false);
    })
    .catch((error) => {
      print("GET REGISTRATION DETAILS", error);
      toggle("enrollmentErrorToLoadRegistrationDetails", true);
      toggle("enrollmentIsLoadingRegistrationDetails", false);
    });
};

export const getRegisteredScheduleDetails = (regID, onSuccess, onFail) => {
  toggle("enrollmentIsLoadingRegistrationSchedules", true);
  toggle("enrollmentErrorToLoadRegistrationSchedules", false);

  const { campusID } = getState().AUTH;
  const { regIDForAdvising } = getState().CONTEXT;

  const url = `${URL}getRegisteredScheduleDetails/${regID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET REGISTRATION SCHEDULES", result);

      const payload = [];

      for (let i of result.data) {
        payload.push({
          courseID: i.SubjectID,
          courseCode: i.SubjectCode,
          courseTitle: i.SubjectTitle,
          lecUnits: i.LecUnits,
          labUnits: i.LabUnits,
          creditUnits: i.CreditUnits,
          progID: i.ProgramID,

          schedID: i.ScheduleID,
          schedule1: i.Sched_1,
          room1: i.Room1_Name,
          schedule2: i.Sched_2,
          room2: i.Room2_Name,
          schedule3: i.Sched_3,
          room3: i.Room3_Name,
          schedule4: i.Sched_4,
          room4: i.Room4_Name,
          schedule5: i.Sched_5,
          room5: i.Room5_Name,
          faculty1: i.FacultyName,
          faculty2: i.FacultyName2,
          faculty3: i.FacultyName3,
          faculty4: i.FacultyName4,
          faculty5: i.FacultyName5,
        });
      }

      dispatch(ENROLLMENT_REGISTRATION_SCHEDULE_SET, payload);

      const scheds = [];
      payload.forEach((item) => {
        scheds.push(item.schedID);
      });
      setContext("origSchedules", scheds);

      if (regIDForAdvising) {
        dispatch(ENROLLMENT_REGISTRATION_ADDITIONAL_COURSES_SET, payload);
      }

      if (isFunction(onSuccess)) onSuccess();
      toggle("enrollmentIsLoadingRegistrationSchedules", false);
    })
    .catch((error) => {
      print("GET REGISTRATION SCHEDULES", error);
      toggle("enrollmentErrorToLoadRegistrationSchedules", true);
      toggle("enrollmentIsLoadingRegistrationSchedules", false);
    });
};

export const getEnrollmentStatus = (regID, onSuccess, onFail) => {
  toggle("enrollmentIsLoadingRegistrationValidationProcess", true);
  toggle("enrollmentErrorToLoadRegistrationValidationProcess", false);

  const { campusID } = getState().AUTH;

  const url = `${URL}getEnrollmentStatus/${regID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET REGISTRATION VALIDATION STATUS", result);

      let payload = [];

      for (let i of result.data) {
        payload.push({
          id: i.id,
          regID: i.regID,
          stepOrder: i.StepOrder,
          enrollmentStep: i.EnrollmentStep,
          alias: i.alias,
          status: i.status,
          dateEntered: i.dateEntered,
          dateCompleted: i.dateCompleted,
          dateModified: i.dateModified,
        });
      }

      payload = sortByObjectKeyAsc(payload, "stepOrder");

      dispatch(ENROLLMENT_REGISTRATION_VALIDATION_PROCESS_SET, payload);

      if (isFunction(onSuccess)) onSuccess();
      toggle("enrollmentIsLoadingRegistrationValidationProcess", false);
    })
    .catch((error) => {
      print("GET REGISTRATION VALIDATION STATUS", error);
      toggle("enrollmentErrorToLoadRegistrationValidationProcess", true);
      toggle("enrollmentIsLoadingRegistrationValidationProcess", false);
    });
};

export const regHasChangesInAdvising = (regID, onSuccess, onFail) => {
  toggle("enrollmentIsCheckingChangesOnRegAdvising", true);

  const { campusID } = getState().AUTH;

  const url = `${URL}regHasChangesInAdvising/${regID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("CHECK REGISTRATION HAS CHANGES IN ADVISING", result);

      toggle("advisingHasChange", result.data);

      if (isFunction(onSuccess)) onSuccess();
      toggle("enrollmentIsCheckingChangesOnRegAdvising", false);
    })
    .catch((error) => {
      print("CHECK REGISTRATION HAS CHANGES IN ADVISING", error);
      regHasChangesInAdvising(regID);
    });
};

export const isAdvisingValidated = (regID, onSuccess, onFail) => {
  toggle("enrollmentIsCheckingRegAdvisingValidated", true);

  const { campusID } = getState().AUTH;

  const url = `${URL}isAdvisingValidated/${regID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("CHECK REGISTRATION ADVISING IS VALIDATED", result);

      toggle("advisingIsValidated", result.data);

      if (isFunction(onSuccess)) onSuccess();
      toggle("enrollmentIsCheckingRegAdvisingValidated", false);
    })
    .catch((error) => {
      print("CHECK REGISTRATION ADVISING IS VALIDATED", error);
      isAdvisingValidated(regID);
    });
};

export const getStudentRegUnderAdvising = (onSuccess, onFail) => {
  // toggle('enrollmentRegIDUnderAdvisingHasCalled', true)
  toggle("enrollmentIsGettingRegIDUnderAdvising", true);

  const { studentID, campusID } = getState().AUTH;

  const url = `${URL}getStudentRegUnderAdvising/${studentID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET STUDENT REG UNDER ADVISING", result);

      if (result.data.regID) {
        setContext("regIDForAdvising", result.data.regID);
        setContext("advisingIDForAdvising", result.data.advisingID);
        setContext("adviserIDForAdvising", result.data.adviserID);
      }

      if (isFunction(onSuccess)) onSuccess();
      toggle("enrollmentIsGettingRegIDUnderAdvising", false);
    })
    .catch((error) => {
      print("GET STUDENT REG UNDER ADVISING", error);
      if (isFunction(onFail)) onFail();
    });
};

export const saveAdvisedReg = (data, onSuccess, onFail) => {
  toggle("enrollmentIsSavingAdvising", true);

  print("DATA: saveAdvisedReg", data);

  const url = `${URL}saveAdvisedReg`;
  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("SAVE REGISTRATION ADVISING", result);

      if (isFunction(onSuccess)) onSuccess();
      toggle("enrollmentIsSavingAdvising", false);
    })
    .catch((error) => {
      print("SAVE REGISTRATION ADVISING", error);

      if (isFunction(onFail)) onFail();
      toggle("enrollmentIsSavingAdvising", false);
    });
};

export const canRegister = (onSuccess, onFail) => {
  toggle("enrollmentCanRegisterHasCalled", true);
  toggle("enrollmentIsCheckingCanRegister", true);

  const { studentID, campusID } = getState().AUTH;

  const url = `${URL}canRegister/${studentID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("CHECK CAN REGISTER", result);

      const data = result.data;
      if (data) {
        setAuthData("canRegister", data.status);
        setAuthData(
          "hasAnsweredTheEnrollmentSurvey",
          data.hasAnsweredTheEnrollmentSurvey
        );
        toggle("enrollmentActive", data.status);
        setContext("enrollmentAY", data.academicYear);
        setContext("enrollmentTerm", data.academicTerm);
        setContext("enrollmentStartDate", data.enrollmentStartDate);
        setContext("enrollmentEndDate", data.enrollmentEndDate);
      }

      if (isFunction(onSuccess)) onSuccess();
      toggle("enrollmentIsCheckingCanRegister", false);
    })
    .catch((error) => {
      print("CHECK CAN REGISTER", error);
      canRegister();
    });
};

export const getAdvisingRemarks = (
  advisingID,
  regID,
  campusID,
  onSuccess,
  onFail
) => {
  toggle("registrationIsLoadingAdvisingRemarks", true);
  toggle("registrationErrorToLoadAdvisingRemarks", false);

  const url = `${URL}getAdvisingRemarks/${advisingID}/${regID}/${campusID}`;

  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET ADVISING REMARKS", result);

      let payload = [];
      for (let i of result.data) {
        payload.push({
          userType: i.userType,
          dateSubmitted: i.dateSubmitted,
          remarks: i.remarks,
        });
      }
      dispatch(ENROLLMENT_REGISTRATION_ADVISING_REMARKS_SET, payload);

      if (isFunction(onSuccess)) onSuccess();
      toggle("registrationIsLoadingAdvisingRemarks", false);
    })
    .catch((error) => {
      print("GET ADVISING REMARKS", error);
      toggle("registrationIsLoadingAdvisingRemarks", false);
      toggle("registrationErrorToLoadAdvisingRemarks", true);
      if (isFunction(onFail)) onFail();
    });
};

export const saveSurveyAnswers = (data, onSuccess, onFail) => {
  toggle("enrollmentIsEnrollmentSurvey", true);

  print("DATA: saveSurveyAnswers", data);

  const url = `${URL}saveSurveyAnswers`;
  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("SAVE ENROLLMENT SURVEY", result);

      if (isFunction(onSuccess)) onSuccess();
      toggle("enrollmentIsEnrollmentSurvey", false);
    })
    .catch((error) => {
      print("SAVE ENROLLMENT SURVEY", error);

      if (isFunction(onFail)) onFail();
      toggle("enrollmentIsEnrollmentSurvey", false);
    });
};

export const clearRegistrationDetails = (courses) => {
  dispatch(ENROLLMENT_REGISTRATION_INFO_CLEAR);
};

export const setAdditionalCourses = (courses) => {
  dispatch(ENROLLMENT_REGISTRATION_ADDITIONAL_COURSES_SET, courses);
};

export const setOfferedCourses = (courses) => {
  dispatch(ENROLLMENT_REGISTRATION_OFFERED_COURSES_SET, courses);
};

export const clearCourseSchedule = () => {
  dispatch(ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_CLEAR);
};

export const clearBlockSectionCourses = () => {
  dispatch(ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_CLEAR);
};

export const clearEnrollmentdata = () => {
  dispatch(ENROLLMENT_CLEAR);
};

export const clearValidationProcess = () => {
  dispatch(ENROLLMENT_REGISTRATION_VALIDATION_PROCESS_SET, []);
};

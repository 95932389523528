import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FlexContainer from "../containers/flex/FlexContainer";
import Typography from "../typography/Typography";
import Space from "../Space";
import smilingFaceWithSmilingEyes from "@iconify/icons-emojione/smiling-face-with-smiling-eyes";
import Icon from "@iconify/react";
import ReactHelmet from "../Helmet";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import useTheme from "@material-ui/core/styles/useTheme";

const LoadingPortal = () => {
  const styles = style();
  const theme = useTheme();
  return (
    <div className={styles.root}>
      <ReactHelmet title={"Preparing"} />
      <FlexContainer
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        height={"calc(100vh - 80px)"}
      >
        <Space vertical space={4} />
        <Icon icon={smilingFaceWithSmilingEyes} height={120} />
        <Space vertical space={5} />
        <Typography variant={"h4"} align={"center"} weight={"medium"}>
          We're preparing the portal for you.
        </Typography>
        <Space vertical space={2} />
        <Typography variant={"h6"} align={"center"} weight={"normal"}>
          Please do not close or reload the page.
        </Typography>
        <Space vertical space={10} />
        <Loader
          type="ThreeDots"
          color={theme.palette.primary.main}
          height={60}
          width={60}
        />
      </FlexContainer>
      <FlexContainer
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        height={24}
      >
        <Typography align={"center"}>
          This may take a while. Please Wait.
        </Typography>
      </FlexContainer>
    </div>
  );
};

const style = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

export default memo(LoadingPortal);

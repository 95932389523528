import axios from "axios";

import { dispatch, getState } from "./index";
import {
  AUTH_LOG_IN,
  AUTH_LOG_OUT,
  AUTH_RESET,
  AUTH_SET,
  CONTEXT_RESET,
  AUTH_UPDATE_PROFILE,
} from "../types";
import { CONFIG } from "../../AppConfig";
import { isFunction } from "../../utils/misc";
import print from "../../utils/print";
import { clearContext, setContext, toggle } from "./context";
import { clearEnrollmentdata } from "./enrollment";
import { clearGrades } from "./grades";
import { clearAcademicEvaluationData } from "./programEvaluation";
import { clearUnivServicesEvaluationData } from "./universityServicesEvaluation";
import { clearAccountability } from "./accountability";
import { clearDashboardInfo } from "./dashboard";
import { getHTTPConfig } from "../../utils/requests";
import { campuses } from "../../constants/campuses";
import { toggleSet } from "./toggle";

const URL = CONFIG.URL_API;

export const login = (idNumber, password, campusID, onSuccess, onFail) => {
  if (!validateLogin({ campusID, idNumber, password })) return;

  toggleSet("loginIsSubmitting", true);
  clearAuthData();

  const data = {
    username: idNumber,
    password,
    campusID,
  };

  const payload = {};

  let url = `${URL}user/auth`;
  axios
    .post(url, data)
    .then((result) => {
      print("LOGIN", result);
      toggleSet("loginIsSubmitting", false);

      if (result.data.success) {
        const token = result.data.token;
        payload["forceChangePass"] = result.data.forceChangePass;
        payload["forceReEvaluateProgramEvaluation"] =
          result.data.forceReEvaluateProgramEvaluation;

        toggleSet("profileIsGettingData", true);

        url = `${URL}student/getProfilePic/${idNumber}/${campusID}`;
        axios
          .get(url, getHTTPConfig(token))
          .then((result) => {
            print("GET PROFILE PIC", result);

            if (result.data) {
              payload["profilePicture"] = result.data;
            }

            url = `${URL}student/getProfile/${idNumber}/${campusID}`;
            axios
              .get(url, getHTTPConfig(token))
              .then((result) => {
                print("GET PROFILE", result);
                toggleSet("profileIsGettingData", false);

                const data = result.data;

                for (let i of campuses) {
                  if (i.id === data.campusID) {
                    payload["campus"] = i.label?.toLowerCase();
                  }
                }

                payload["isAuthenticated"] = true;
                payload["token"] = token;
                payload["studentID"] = idNumber;
                payload["name"] = data.fullName;
                payload["firstName"] = data.FirstName;
                payload["lastName"] = data.LastName;
                payload["presentAddress"] = data.presentAddress;
                payload["officialEmail"] = data.officialEmail;
                payload["age"] = data.age;
                payload["yearLevel"] = data.yearLevel;
                payload["college"] = data.college;
                payload["program"] = data.program;
                payload["major"] = data.major;
                payload["curriculum"] = data.curriculumCode;
                payload["expectedGradYear"] = data.expectedGradYear;
                payload["status"] = data.status;
                payload["maxUnitsLoad"] = data.maxUnitsLoad;

                payload["mobileNo"] = data.mobileNo;
                payload["personalEmail"] = data.personalEmail;

                payload["campusID"] = data.campusID;
                payload["collegeID"] = data.collegeID;
                payload["majorID"] = data.majorID;
                payload["progID"] = data.progID;
                payload["statusID"] = data.statusID;
                payload["termID"] = data.termID;
                payload["lastRegTermID"] = data.lastRegTermID;
                payload["lastRegAYTerm"] = data.lastRegAYTerm;
                payload["lastRegAYTermForEval"] = data.lastRegAYTermForEval;

                payload["isRegular"] = data.isRegular;
                payload["isUndergrad"] = data.isUndergrad;
                // payload['isUndergrad'] = false
                payload["isFreeHighEduc"] = data.isFreeHighEduc;
                payload["canRegister"] = false;
                payload["hasAnsweredTheEnrollmentSurvey"] = true;

                payload["ms365Username"] = data.Ms365Username;
                payload["ms365Password"] = data.Ms365Password;

                dispatch(AUTH_LOG_IN, payload);

                // Clear Data
                clearContext();
                clearDashboardInfo();
                clearGrades();
                clearAcademicEvaluationData();
                clearEnrollmentdata();
                clearAccountability();
                clearUnivServicesEvaluationData();

                if (isFunction(onSuccess)) onSuccess();

                // const callReEvaluate = () => {
                //     reEvaluate(() => {
                //         setAuthData('forceReEvaluateProgramEvaluation', false)
                //     }, callReEvaluate, idNumber, campusID, token)
                // }
                // if (payload.forceReEvaluateProgramEvaluation) callReEvaluate()
              })
              .catch((error) => {
                print("GET PROFILE", error);
                toggleSet("profileIsGettingData", false);
                if (isFunction(onFail)) onFail();
                setContext("loginFormError", "Failed to Prepare");
                setContext(
                  "loginFormErrorDescription",
                  "Please reload the page and try " + "logging in again."
                );
              });
          })
          .catch((error) => {
            print("GET PROFILE PIC", error);
            toggleSet("profileIsGettingData", false);
            if (isFunction(onFail)) onFail();
            setContext("loginFormError", "Failed to Prepare");
            setContext(
              "loginFormErrorDescription",
              "Please reload the page and try " + "logging in again."
            );
          });
      } else {
        setContext(
          "loginAttemptsRemaining",
          result.data.loginAttemptsRemaining
        );
        setContext("loginFormError", "Login Failed");
        setContext("loginFormErrorDescription", result.data.message);
        toggleSet("loginIsSubmitting", false);
      }
    })
    .catch((error) => {
      print("LOGIN", error);
      setContext("loginFormError", "Login Failed");
      setContext(
        "loginFormErrorDescription",
        "Cannot connect to the server at the moment. " +
          "Please try again in a little bit."
      );
      toggleSet("loginIsSubmitting", false);
      if (isFunction(onFail)) onFail();
    });
};

export const logout = (onSuccess) => {
  dispatch(CONTEXT_RESET);
  if (isFunction(onSuccess)) onSuccess();
  dispatch(AUTH_LOG_OUT);
};

export const checkAuthentication = (onSuccess) => {
  // const payload = {
  //     isAuthenticated: true,
  //     studentID: '2015-12345',
  //     name: 'Samantha Smith',
  //     program: 'Bachelor of Science in Information Technology',
  // }
  // dispatch(AUTH_LOG_IN, payload)
  onSuccess();
};

export const changePassword = (data, onSuccess, onFail) => {
  toggle("isChangingPassword", true);

  const message = {
    title: "Failed to Change",
    description:
      "Sorry! We can't change your password at the moment. Please try again!",
  };

  print("DATA: Change Password", data);

  let url = `${URL}user/changePass`;
  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("CHANGE PASSWORD", result);
      toggle("isChangingPassword", false);

      const data = result.data;
      if (data.success) {
        if (isFunction(onSuccess)) onSuccess();
      } else {
        if (
          data.message ===
          "Change password failed. The password you entered is incorrect."
        ) {
          message.title = "Incorrect Password";
          message.description =
            "The present password you entered is incorrect. Please try again!";
        }
        if (
          data.message ===
          "Change password failed. Cannot use current password."
        ) {
          message.title = "Present Password";
          message.description =
            "You cannot use the present password. Please try another!";
        }

        if (isFunction(onFail)) onFail(message);
      }
    })
    .catch((error) => {
      print("CHANGE PASSWORD", error);
      toggle("isChangingPassword", false);
      if (isFunction(onFail)) onFail(message);
    });
};

export const updateContactInfo = (data, onSuccess, onFail) => {
  toggle("isUpdatingContactInfo", true);

  const payload = {};
  const message = {
    title: "Failed to Update",
    description:
      "Sorry! We can't update your Mobile Number at the moment. Please try again!",
  };

  print("DATA: Update Contact Info", data);
  
  const updatedMobileNo = data.mobileNo;
  console.log('Updating:', updatedMobileNo);

  let url = `${URL}user/updateContact`;
  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("UPDATE CONTACT", result);
      toggle("isUpdatingContactInfo", false);

      const responseData = result.data;

      if (responseData.success) {

          fetchUpdatedProfile(data.studentID, data.campusID, onSuccess, onFail);
          // if (isFunction(onSuccess)) onSuccess();
        
        } else {
          if (isFunction(onFail)) onFail(message);
        }
      })
    .catch((error) => {
      print("UPDATE CONTACT", error);
      toggle("isUpdatingContactInfo", false);
      if (isFunction(onFail)) onFail(message);
    });
};


const fetchUpdatedProfile = (studentID, campusID, onSuccess, onFail) => {

  const url = `${URL}student/getProfile/${studentID}/${campusID}`;
  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET UPDATED PROFILE", result);
      toggleSet("profileIsGettingData", false);

      const data = result.data;
      const payload = {

        mobileNo: data.mobileNo,
      };

      dispatch(AUTH_UPDATE_PROFILE, payload);
      
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("GET UPDATED PROFILE", error);
      toggleSet("profileIsGettingData", false);
      if (isFunction(onFail)) onFail();
    });
};

export const resetPassword = (data, onSuccess, onFail) => {
  if (!validateReset(data)) return;

  toggle("isResettingPass", true);

  const message = {
    title: "Failed to Reset",
    description:
      "Sorry! We can't reset your password at the moment. Please try again later!",
  };

  print("DATA: Reset Password", data);

  let url = `${URL}user/resetPass`;
  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("RESET PASSWORD", result);
      toggle("isResettingPass", false);

      const data = result.data;
      if (data.success) {
        if (isFunction(onSuccess)) onSuccess();
      } else {
        message.description = data.message;
        if (isFunction(onFail)) onFail(message);
      }
    })
    .catch((error) => {
      print("RESET PASSWORD", error);
      toggle("isResettingPass", false);
      if (isFunction(onFail)) onFail(message);
    });
};

export const getSecurityQuestions = (onSuccess, onFail) => {
  toggle("isGettingSecurityQuestions", true);
  toggle("errorToGetSecurityQuestions", false);

  const { campusID } = getState().AUTH;

  let url = `${URL}user/getSecurityQuestions/${campusID}`;
  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET SECURITY QUESTIONS", result);
      toggle("isGettingSecurityQuestions", false);

      const payload = {
        name: "securityQuestions",
        value: [],
      };

      if (result.data) {
        for (let i of result.data) {
          if (!i.isActive) continue;
          payload.value.push({
            id: i.id,
            label: i.question,
          });
        }
      }

      dispatch(AUTH_SET, payload);
    })
    .catch((error) => {
      print("GET SECURITY QUESTIONS", error);
      toggle("errorToGetSecurityQuestions", true);
      toggle("isGettingSecurityQuestions", false);
      if (isFunction(onFail)) onFail();
    });
};

export const updateAccountDetails = (data, onSuccess, onFail) => {
  if (!validateReset(data)) return;

  toggle("isUpdatingAccountDetails", true);

  print("DATA: Update Account Details", data);

  let message =
    "Sorry! We can't process your request at the moment. Please try again later!";

  let url = `${URL}user/updateAccountDetails`;
  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("UPDATE ACCOUNT DETAILS", result);
      toggle("isUpdatingAccountDetails", false);

      const data = result.data;
      if (data.success) {
        if (isFunction(onSuccess)) onSuccess();
      } else {
        message = data.message;
        if (isFunction(onFail)) onFail(message);
      }
    })
    .catch((error) => {
      print("UPDATE ACCOUNT DETAILS", error);
      toggle("isUpdatingAccountDetails", false);
      if (isFunction(onFail)) onFail(message);
    });
};

export const updateProfilePicture = (file, onSuccess, onFail) => {
  toggle("isUpdatingProfilePic", true);

  // let message = 'Sorry! We can\'t process your request at the moment. Please try again later!'

  const AUTH = getState().AUTH;

  let url = `${URL}student/upload/photo`;
  const data = new FormData();
  data.set("profpic", file);
  data.set("studentID", AUTH.studentID);
  data.set("campusID", AUTH.campusID);

  axios
    .post(url, data, getHTTPConfig())
    .then((result) => {
      print("UPDATE ACCOUNT DETAILS", result);
      toggle("isUpdatingProfilePic", false);
      // getProfilePicture()
      if (isFunction(onSuccess)) onSuccess();
    })
    .catch((error) => {
      print("UPDATE ACCOUNT DETAILS", error);
      toggle("isUpdatingProfilePic", false);
      if (isFunction(onFail)) onFail();
    });
};

export const getProfilePicture = () => {
  toggle("isGettingProfilePic", true);

  const AUTH = getState().AUTH;
  const url = `${URL}student/getProfilePic/${AUTH.studentID}/${AUTH.campusID}`;
  axios
    .get(url, getHTTPConfig())
    .then((result) => {
      print("GET PROFILE PIC", result);
      toggle("isGettingProfilePic", false);

      if (result.data) {
        setAuthData("profilePicture", result.data);
      }
    })
    .catch((error) => {
      print("GET PROFILE PIC", error);
      toggle("isGettingProfilePic", false);
    });
};

export const setAuthData = (name, value) => {
  dispatch(AUTH_SET, { name, value });
};

export const clearAuthData = () => {
  dispatch(AUTH_RESET);
};

const validateLogin = (form) => {
  let valid = true;
  switch (form.campusID) {
    case null:
      setContext("loginCampusError", "Required Field");
      valid = false;
      break;
    default:
      break;
  }
  switch (form.idNumber) {
    case "":
      setContext("loginIDError", "Required Field");
      valid = false;
      break;
    default:
      break;
  }
  switch (form.password) {
    case "":
      setContext("loginPasswordError", "Required Field");
      valid = false;
      break;
    default:
      break;
  }

  return valid;
};

const validateReset = (form) => {
  let valid = true;
  switch (form.campusID) {
    case null:
      setContext("forgotCampusError", "Required Field");
      valid = false;
      break;
    default:
      break;
  }
  switch (form.studentID) {
    case "":
      setContext("forgotIDError", "Required Field");
      valid = false;
      break;
    default:
      break;
  }
  switch (form.birthday) {
    case "":
      setContext("forgotBirthdateError", "Required Field");
      valid = false;
      break;
    default:
      break;
  }
  return valid;
};

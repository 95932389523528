import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@iconify/react";
import FlexContainer from "../containers/flex/FlexContainer";
import Space from "../Space";
import Typography from "../typography/Typography";
import FlexItem from "../containers/flex/FlexItem";
import useTheme from "@material-ui/core/styles/useTheme";

export default function IconMini({
  icon,
  height = 24,
  variant,
  color,
  onClick,
  label,
  filled,
  padding,
}) {
  const styles = style();
  const theme = useTheme();

  return (
    <div className={styles.root}>
      <div
        onClick={onClick}
        className={styles.iconContainer}
        style={{
          height,
          width: height,
          background: filled
            ? !variant
              ? theme.palette.grey.shade5
              : theme.palette[variant].gradient ||
                theme.palette.primary.gradient
            : null,
          color: filled && (color || "white"),
          padding: padding || 6,
        }}
      >
        <Icon
          icon={icon}
          height={height || 20}
          style={{
            color:
              !filled &&
              (!variant
                ? theme.palette.default.main
                : theme.palette[variant].main),
          }}
        />
      </div>
      {label && (
        <FlexItem>
          <FlexContainer alignItems={"center"}>
            <Space space={1} />
            <Typography>{label}</Typography>
          </FlexContainer>
        </FlexItem>
      )}
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
  },
}));

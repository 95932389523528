import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function Link(props) {
  const styles = style();
  return (
    <a className={styles.root} {...props}>
      {props.children}
    </a>
  );
}

const style = makeStyles((theme) => ({
  root: {
    color: theme.palette.default.main,
    textDecoration: "unset",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

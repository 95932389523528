import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "../components/core/typography/Typography";
import Space from "../components/core/Space";
import Input from "../components/core/input/Input";
import FlexContainer from "../components/core/containers/flex/FlexContainer";
import Checkbox from "../components/core/input/Checkbox";
import Button from "../components/core/button/Button";
import zxcvbn from "zxcvbn";
import { useSelector } from "react-redux";
import FlexItem from "../components/core/containers/flex/FlexItem";
import MessageDialog from "../components/core/dialogs/MessageDialog";
import checkboxCircleLine from "@iconify/icons-ri/checkbox-circle-line";
import LineBreak from "../components/core/LineBreak";
import IconMini from "../components/core/icons/IconMini";
import checkLine from "@iconify/icons-ri/check-line";
import { useForm } from "react-hook-form";
import useTheme from "@material-ui/core/styles/useTheme";
import { isFunction } from "../utils/misc";
import { updateContactInfo } from "../redux/actions/auth";

export const mobileNumberMin = 11;
export const mobileNumberMax = 11;

export default function UpdateContact({
  fullWidthButton,
  onCancel,
  showCancel,
  fullHeight,
  noTitle,
  align,
  noSubmit,
  noPresentPass,
  getForm,

  
}) {

  // let {
  //   mobileNumber,
  //   personalEmail,

  // } = useSelector((state) => ({
  //   mobileNumber: state.AUTH.mobileNo,
  //   personalEmail: state.AUTH.personalEmail,
  // }));

  const form = useForm();
  const mobileNo = form.watch("mobileNo", "");
  // const emailAdd = form.watch("emailAdd", "");

  const styles = style();
  const theme = useTheme();

  const [submitSuccessDialog, setSubmitSuccessDialog] = useState(false);
  const [submitErrorDialog, setSubmitErrorDialog] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const { studentID, campusID, isUpdatingContactInfo } = useSelector((state) => ({
    studentID: state.AUTH.studentID,
    campusID: state.AUTH.campusID,
    isUpdatingContactInfo: state.CONTEXT.toggles.isUpdatingContactInfo,
  }));
  // if (mobileNo || emailAdd) showCancel = true;
  if (mobileNo) showCancel = true;

  const submit = (data) => {
    closeError();
    const payload = {
      studentID,
      campusID,
      mobileNo: data.mobileNo,
      // emailAdd: data.emailAdd,
    };
    const onSuccess = () => {
      openSuccess();
      cancel();
    };
    const onFail = (message) => {
      setErrorTitle(message.title);
      setErrorMessage(message.description);
      openError();
    };
    updateContactInfo(payload, onSuccess, onFail);
  };

  const cancel = () => {
    form.reset({
      mobileNo: "",
      // emailAdd: "",
    });
    if (isFunction(onCancel)) onCancel();
  };

  const onEnter = (event) => {
    if (event.key === "Enter") form.handleSubmit(submit)();
  };

  const openSuccess = () => setSubmitSuccessDialog(true);
  const closeSuccess = () => setSubmitSuccessDialog(false);
  const openError = () => setSubmitErrorDialog(true);
  const closeError = () => setSubmitErrorDialog(false);


  const handleNumberInput = (event) => {
    const { value } = event.target;
  
    if (!/^\d*$/.test(value) || value.length > mobileNumberMax) {
      event.preventDefault(); 
      return;
    }
  
    form.setValue("mobileNo", value.slice(0, mobileNumberMax)); 
  };


  if (isFunction(getForm)) getForm(form);

  return (
    <div
      className={styles.root}
      style={{ height: fullHeight && "calc(100vh - 64px - 15px)" }}
    >
      <FlexContainer direction={"column"} justifyContent={"space-between"}>
        <FlexItem>
          {!noTitle && (
            <>
              <Typography variant={"h5"} weight={"semibold"}>
                Update Contact Info
              </Typography>
              <Space vertical space={3} />
            </>
          )}
          
            <>
              <Input
                inputRef={form.register({
                  required: "Mobile Number is required",
                  minLength: {
                    value: mobileNumberMin,
                    message: "Must be eleven digits",
                  },
                  maxLength: {
                    value: mobileNumberMax,
                    message: "Must be eleven digits",
                  },
                  validate: (value) =>
                    value.startsWith("09") || "Must start with '09'",
                })}
                name={"mobileNo"}
                type={"number"}
                placeholder={"Mobile Number"}
                error={form.errors.mobileNo?.message}
                value={form.watch("mobileNo", "").slice(0, mobileNumberMax)} 
                onKeyDown={onEnter}
                onInput={handleNumberInput} 
              />
              <Space vertical space={1} />
            </>
            
          {/* <Space vertical space={2} /> */}

          {/* <Input
            inputRef={form.register({
              required: "Email Address is required",
              validate: (value) =>
                !value.endsWith("usep.edu.ph") || "Must not USeP Email!",
            })}
            name={"emailAdd"}
            type={"email"}
            placeholder={"Personal Email Address"}
            align={"center"}
            error={form.errors.emailAdd?.message}
            onKeyDown={onEnter}
          /> */}
          
          {/* <Space vertical space={2} /> */}
        </FlexItem>
          <FlexItem>
            <Space vertical space={3} />
            <Button
              variant={"primary"}
              size={"medium"}
              fullwidth
              onClick={form.handleSubmit(submit)}
              isLoading={isUpdatingContactInfo}
            >
              Update Info
            </Button>
            {showCancel && (
              <div>
                <Space vertical space={1} />
                <Button fullwidth onClick={cancel}>
                  Cancel
                </Button>
              </div>
            )}
          </FlexItem>
      </FlexContainer>

      <MessageDialog
        open={submitSuccessDialog}
        variant={"success"}
        title={"Contact Info Updated"}
        singleAction
        icon={checkboxCircleLine}
        onConfirmLabel={"Okay"}
        description={
          "Your contact info has been updated successfully!"
        }
        onClose={closeSuccess}
        onConfirm={closeSuccess}
      />

      <MessageDialog
        open={submitErrorDialog}
        variant={"error"}
        title={errorTitle}
        onCloseLabel={"Close"}
        onConfirmLabel={"Retry"}
        description={errorMessage}
        onClose={closeError}
        onConfirm={form.handleSubmit(submit)}
      />
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  passwordMeterBarBase: {
    height: 15,
    width: "100%",
    background: theme.palette.grey.shade5,
    borderRadius: theme.shape.borderRadiusNormal,
  },
  passwordMeterBar: {
    height: 15,
    borderRadius: theme.shape.borderRadiusNormal,
    transition: "width .5s ease-in-out",
  },
}));


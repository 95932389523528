export const isFunction = func => {
    return typeof func === 'function'
}

export const isInObjectArray = (array, key, value) => {
    for (const i of array) {
        if (i[key] === value) return true
    }
    return false
}

export const isEmptyObject = object => {
    return Object.keys(object).length === 0 && object.constructor === Object
}

export const isObjectEquivalent = (a, b) => {
    console.log(a, b)
    const aProps = Object.getOwnPropertyNames(a)
    const bProps = Object.getOwnPropertyNames(b)
    if (aProps.length !== bProps.length) return false
    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i]
        if (a[propName] !== b[propName]) return false
    }
    return true
}
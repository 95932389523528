import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import Space from "../components/space";

const styles = StyleSheet.create({
  container: {
    fontFamily: "Arial Narrow",
    fontWeight: "normal",
    width: "100%",
  },
  titleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  title: {
    letterSpacing: 10,
    fontSize: 8,
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
  },
  textNormal: {
    fontSize: 7,
  },
  textItalic: {
    fontSize: 7,
    fontStyle: "italic",
  },
  textBold: {
    fontSize: 7,
    fontWeight: "bold",
  },
  table: {
    display: "table",
    border: 0,
  },
  tableHeader: {
    flexDirection: "row",
    borderStyle: "solid",
    padding: "1px 0",
    borderWidth: 0,
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
  },
  tableRow: {
    // margin: "auto",
    flexDirection: "row",
  },
  tableCell: {
    margin: 2,
    fontSize: 7,
  },
});

export default function Subjects({ data: { subjects, lecUnits, labUnits } }) {
  const unitWrapper = (unit, force) => {
    return force ? unit.toFixed(1) : unit ? unit.toFixed(1) : "";
  };

  const columns = [
    {
      label: "CODE",
      textAlign: "left",
      celStyles: [styles.tableCell],
      colStyles: [{ width: "10%" }],
    },
    {
      label: "SUBJECT TITLE",
      textAlign: "left",
      celStyles: [styles.tableCell],
      colStyles: [{ width: "35%" }],
    },
    {
      label: "LEC",
      textAlign: "center",
      wrapper: unitWrapper,
      celStyles: [styles.tableCell],
      colStyles: [{ width: "5%" }],
    },
    {
      label: "LAB",
      textAlign: "center",
      wrapper: unitWrapper,
      celStyles: [styles.tableCell],
      colStyles: [{ width: "5%" }],
    },
    {
      label: "",
      textAlign: "center",
      wrapper: unitWrapper,
      celStyles: [styles.tableCell],
      colStyles: [{ width: "5%" }],
    },
    {
      label: "SCHEDULE",
      textAlign: "left",
      celStyles: [styles.tableCell],
      colStyles: [{ width: "25%" }],
    },
    {
      label: "ROOM",
      textAlign: "left",
      celStyles: [styles.tableCell],
      colStyles: [{ width: "15%" }],
    },
  ];

  const data = [
    // ['CS 420', 'THESIS 2', 3, 0, 0, '', ''],
    // ['CS 420', 'THESIS 2', 3, 0, 0, '', ''],
    // ['CS 420', 'THESIS 2', 3, 0, 0, '', ''],
    // ['CS 420', 'THESIS 2', 3, 0, 0, '', ''],
  ];
  for (let item of subjects) {
    let sched = (
      <View>
        {item[4].map((i, index) => (
          <View>
            {index ? <Text>, &nbsp;</Text> : null}
            <Text>{i.sched}</Text>
          </View>
        ))}
      </View>
    );
    let room = (
      <View>
        {item[4].map((i, index) => (
          <View>
            {index ? <Text>, &nbsp;</Text> : null}
            <Text>{i.room}</Text>
          </View>
        ))}
      </View>
    );
    data.push([
      item[0],
      item[1].toUpperCase(),
      item[2],
      item[3],
      null,
      sched,
      room,
    ]);
  }

  const lastRow = [
    {
      label: "",
      textAlign: "left",
      celStyles: [styles.tableCell],
      colStyles: [{ width: "10%" }],
    },
    {
      label: "TOTAL:",
      textAlign: "right",
      celStyles: [styles.tableCell, styles.textBold],
      colStyles: [{ width: "35%" }],
    },
    {
      label: lecUnits,
      textAlign: "center",
      wrapper: unitWrapper,
      celStyles: [
        styles.tableCell,
        styles.textBold,
        {
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 0.5,
        },
      ],
      colStyles: [{ width: "5%" }],
    },
    {
      label: labUnits,
      textAlign: "center",
      wrapper: unitWrapper,
      celStyles: [
        styles.tableCell,
        styles.textBold,
        {
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 0.5,
        },
      ],
      colStyles: [{ width: "5%" }],
    },
    {
      label: lecUnits + labUnits,
      textAlign: "center",
      wrapper: unitWrapper,
      celStyles: [
        styles.tableCell,
        styles.textBold,
        {
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 0.5,
        },
      ],
      colStyles: [{ width: "5%" }],
    },
    {
      label: "",
      textAlign: "left",
      celStyles: [styles.tableCell],
      colStyles: [{ width: "25%" }],
    },
    {
      label: "",
      textAlign: "left",
      celStyles: [styles.tableCell],
      colStyles: [{ width: "15%" }],
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>SUBJECT ENROLLED</Text>
      </View>
      <Space vertical space={3} />
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          {columns.map((item) => (
            <View style={item.colStyles}>
              <Text style={[item.celStyles, styles.textBold]}>
                {item.label}
              </Text>
            </View>
          ))}
        </View>
        {data.map((row, rowIndex) => (
          <View style={styles.tableRow}>
            {row.map((cell, cellIndex) => (
              <View
                style={[
                  columns[cellIndex].colStyles,
                  {
                    textAlign: columns[cellIndex].textAlign || "left",
                  },
                ]}
              >
                <Text
                  style={[
                    columns[cellIndex].celStyles,
                    {
                      marginBottom: 0,
                      lineHeight: 1,
                    },
                  ]}
                >
                  {columns[cellIndex].wrapper
                    ? columns[cellIndex].wrapper(cell)
                    : cell}
                </Text>
              </View>
            ))}
          </View>
        ))}
        <View style={styles.tableRow}>
          {lastRow.map((cell, index) => (
            <View
              style={[
                cell.colStyles,
                {
                  textAlign: cell.textAlign || "left",
                },
              ]}
            >
              <Text
                style={[
                  cell.celStyles,
                  {
                    paddingTop: 2,
                    marginTop: 4,
                    lineHeight: 1,
                  },
                ]}
              >
                {cell.wrapper ? cell.wrapper(cell.label, true) : cell.label}
              </Text>
            </View>
          ))}
        </View>
      </View>
      <Space vertical space={6} />
      <Text style={[styles.textItalic, { fontSize: 6, marginLeft: 8 }]}>
        Note: Subject marked with " * " is Special Subject.
      </Text>
    </View>
  );
}

import React from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { isDarkTheme } from "../../../../../utils/appSetting";
import { useBreakpointDown } from "../../../../../utils/hooks/mediaQuery";

export default function SimplebarScrollbar({
  maxHeight,
  maxWidth,
  hidden,
  children,
  alwaysShow,
  nodisplay,
}) {
  const style = makeStyles((theme) => ({
    root: {
      display: nodisplay && "none !important",
      maxHeight: maxHeight || "100%",
      width: maxWidth || "100%",
    },
  }));

  const { root } = style();
  const theme = useTheme();
  const isDownSM = useBreakpointDown("sm");

  generateCustomCSS(theme, hidden, isDownSM);

  return (
    <SimpleBar className={root} id={"scrollsss"} autoHide={!alwaysShow}>
      {children}
    </SimpleBar>
  );
}

const generateCustomCSS = (theme, hidden, isDownSM) => {
  let style = document.createElement("style");
  style.innerHTML = `
        .simplebar-scrollbar:before {
            position: absolute;
            content: '';
            background: ${
              hidden ? "transparent" : isDarkTheme() ? "white" : "black"
            };
            border-radius: 7px;
            left: 2px;
            right: 2px;
            opacity: 0;
        }
        
        .simplebar-track.simplebar-vertical {
            top: 0;
            width: ${isDownSM ? 6 : 11}px;
        }
        
        .simplebar-track.simplebar-horizontal {
            left: 0;
            height: ${isDownSM ? 6 : 11}px;
        }
         `;
  document.getElementsByTagName("head")[0].appendChild(style);
};

import React from "react";
import Space from "../components/core/Space";
import Typography from "../components/core/typography/Typography";
import Click from "../components/core/link/Click";
import Link from "../components/core/Link";
import { CONFIG } from "../AppConfig";

export default function LoginFooter() {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <div>
      <Space vertical space={8} />
      <Typography variant={"caption"} align={"left"}>
        Copyright © {year}. All Rights Reserved.
      </Typography>
      <Typography variant={"caption"} align={"left"}>
        <Click>
          <Typography
            variant={"caption"}
            weight={"bold"}
            decoration={"underline"}
            span
          >
            Terms of Use
          </Typography>
        </Click>
        &nbsp; | &nbsp;
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={CONFIG.URL_USeP_Data_Privacy}
        >
          <Typography
            variant={"caption"}
            weight={"bold"}
            decoration={"underline"}
            span
          >
            Privacy Policy
          </Typography>
        </Link>
      </Typography>
    </div>
  );
}

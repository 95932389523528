import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@iconify/react";
import clsx from "clsx";

export default function IconClick({
  id,
  icon,
  height,
  color,
  isToggled,
  disableHover,
  onClick,
}) {
  const styles = style();

  return (
    <div
      id={id}
      onClick={onClick}
      className={clsx(
        styles.root,
        isToggled && styles.toggled,
        disableHover && styles.disabledHover
      )}
      style={{
        color: isToggled && (color || "white"),
      }}
    >
      <Icon icon={icon} height={height || 20} />
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    "&:hover": {
      background: theme.palette.grey.shade5,
    },
    height: 36,
    width: 36,
  },
  disabledHover: {
    "&:hover": {
      background: theme.palette.background,
    },
  },
  toggled: {
    background: theme.palette.primary.gradient,
    borderRadius: "100%",
  },
}));

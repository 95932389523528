export const routes = {
  login: "/login",
  forgotPassword: "/forgot-password",

  updateAccount: "/welcome",

  dashboard: "/dashboard",

  grades: "/grades",

  programEvaluation: "/program-evaluation",
  programEvaluationFullscreen: "/program-evaluation/full",

  enrollment: "/enrollment",
  enrollmentSurvey: "/enrollment/survey",
  enrollmentRegistration: "/enrollment/registration",
  enrollmentRegistrationRegister: "/enrollment/register",
  enrollmentRegistrationRegisterSchedule: "/enrollment/register/schedule",

  accountabilities: "/accountabilities",

  universityServicesEvaluation: "/university-services-evaluation",
  universityServicesEvaluationFaculty:
    "/university-services-evaluation/faculty",
  universityServicesEvaluationStudentSatisfaction:
    "/university-services-evaluation/student-satisfaction",

  // ADDED - Course Evaluation
  universityServicesEvaluationCourseEvaluation:
  "/university-services-evaluation/course-evaluation",
  // ADDED - Subject Evaluation
  universityServicesEvaluationSubject:
    "/university-services-evaluation/subject",

  universityServicesEvaluationClientsFeedback:
    "/university-services-evaluation/clients-feedback",
  universityServicesEvaluationClientsFeedbackExternal:
    "https://feedback.usep.edu.ph",
    // "https://docs.google.com/forms/d/e/1FAIpQLSda8E7cNv57ML_SzdAaJ295lQPr49r3uMAoA6STC4WN5W7ZWw/viewform",

  profile: "/profile",

  components: "/components",
  protectedPage: "/protected-page",
};

export const secretRoutes = [
  routes.updateAccount,
  routes.dashboard,
  routes.grades,
  routes.programEvaluation,
  routes.programEvaluationFullscreen,
  routes.enrollment,
  routes.enrollmentSurvey,
  routes.enrollmentRegistration,
  routes.enrollmentRegistrationRegister,
  routes.enrollmentRegistrationRegisterSchedule,
  routes.accountabilities,
  routes.universityServicesEvaluation,
  routes.universityServicesEvaluationFaculty,
  routes.universityServicesEvaluationStudentSatisfaction,

    // ADDED - Course Evaluation
  routes.universityServicesEvaluationCourseEvaluation,
  // ADDED - Subject Evaluation
  routes.universityServicesEvaluationSubject,

  routes.universityServicesEvaluationClientsFeedback,
  routes.universityServicesEvaluationClientsFeedbackExternal,
  routes.profile,
];

import { Document, Page, StyleSheet, View } from "@react-pdf/renderer";
import React from "react";
import Space from "../components/space";
import { registerFontArial } from "../registerFonts";
import Details from "./Details";
import Header from "./Header";
import Subjects from "./Subjects";
import Billing from "./Billing";
import Footer from "./Footer";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 24,
    // paddingLeft: 36,
    // paddingRight: 18,
    width: "100%",
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  subjectsBox: {
    width: "64.75%",
  },
  spaceBox: {
    width: "0.5%",
  },
  billingBox: {
    width: "34.75%",
  },
});

export default function CertificateOfRegistration({ data }) {
  registerFontArial();
  return (
    <Document
      title={"Certificate of Registration"}
      author={"University of Southeastern Philippines"}
    >
      <Page size={"A4"} wrap style={styles.page}>
        <Header data={data} />
        <Space vertical space={3} />
        <Details data={data} />
        <Space vertical space={3} />
        <View style={styles.container}>
          <View style={styles.subjectsBox}>
            <Subjects data={data} />
          </View>
          <View style={styles.spaceBox}></View>
          <View style={styles.billingBox}>
            <Billing billing={data.billing} />
          </View>
        </View>
        <Space vertical space={30} />
        <Footer data={data} />
      </Page>
    </Document>
  );
}

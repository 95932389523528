import React from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { DarkTheme, LightTheme } from "./theme";
import { useSelector } from "react-redux";

export default function ThemeProvider(props) {
  const { isDark } = useSelector((state) => ({
    isDark: state.APP_SETTING.IsDarkTheme,
  }));

  const theme = isDark ? DarkTheme : LightTheme;
  const body = document.getElementById("root-body");
  if (body.style) body.style.backgroundColor = theme.palette.background;

  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

export default function CardFilled({ children, onClick }) {
  const styles = style();

  return (
    <div
      className={clsx(styles.root, onClick && styles.hoverCursor)}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    background: theme.palette.grey.shade5,
    borderRadius: theme.shape.borderRadiusSmall,
  },
  hoverCursor: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

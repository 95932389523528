import React, { useState } from "react";
import ReactHelmet from "../components/core/Helmet";
import Button from "../components/core/button/Button";
import { useHistory } from "react-router";
import { routes } from "../routes";
import { useSelector } from "react-redux";
import LoginLayout from "../layout/LoginLayout";
import Typography from "../components/core/typography/Typography";
import Space from "../components/core/Space";
import Select from "../components/core/input/Select";
import Input from "../components/core/input/Input";
import Click from "../components/core/link/Click";
import { makeStyles } from "@material-ui/core/styles";
import FlexContainer from "../components/core/containers/flex/FlexContainer";
import { isDarkTheme } from "../utils/appSetting";
import { useBreakpointDown } from "../utils/hooks/mediaQuery";
import { setContext, setForm, toggle } from "../redux/actions/context";
import MessageDialog from "../components/core/dialogs/MessageDialog";
import LoginFooter from "../layout/LoginFooter";
import { CONFIG } from "../AppConfig";
import IconClick from "../components/core/icons/IconClick";
import mailSendLine from "@iconify/icons-ri/mail-send-line";
import Link from "../components/core/Link";
import { resetPassword } from "../redux/actions/auth";
import checkboxCircleLine from "@iconify/icons-ri/checkbox-circle-line";
import { campuses } from "../constants/campuses";

export default function ForgotPassword() {
  const isDownMD = useBreakpointDown("md");

  const style = makeStyles((theme) => ({
    root: {
      overflowX: "hidden",
    },
    vector: {
      width: `calc(100% + ${isDownMD ? 180 : 380}px)`,
      marginLeft: `-${isDownMD ? 140 : 300}px`,
    },
  }));

  const { root, vector } = style();

  const history = useHistory();
  const date = new Date();
  const year = date.getFullYear();
  const isDark = isDarkTheme();
  const mailto = `mailto:${CONFIG.EMAIL_KMD}`;

  const {
    forgotCampusError,
    forgotIDError,
    forgotBirthdateError,
  } = useSelector((state) => state.CONTEXT);
  const { isResettingPass } = useSelector((state) => state.CONTEXT.toggles);
  const {
    forgotPasswordCampus,
    forgotIDNumber,
    forgotMiddleName,
    forgotBirthdate,
  } = useSelector((state) => state.CONTEXT.forms);
  const [submitSuccessDialog, setSubmitSuccessDialog] = useState(false);
  const [submitErrorDialog, setSubmitErrorDialog] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const reset = () => {
    closeError();

    const data = {
      studentID: forgotIDNumber,
      campusID: forgotPasswordCampus,
      middleName: forgotMiddleName,
      birthday: forgotBirthdate,
    };

    const onSuccess = () => {
      openSuccess();
      setForm("forgotPasswordCampus", "");
      setForm("forgotIDNumber", "");
      setForm("forgotMiddleName", "");
      setForm("forgotBirthdate", "");
    };

    const onFail = (message) => {
      setErrorTitle(message.title);
      setErrorMessage(message.description);
      openError();
    };

    resetPassword(data, onSuccess, onFail);
  };

  const onEnter = (event) => {
    if (event.key === "Enter") reset();
  };

  const handleFormChange = (event) => {
    event.persist();
    const name = event.target.name;
    setForm(name, event.target.value);

    switch (name) {
      case "forgotIDNumber":
        setContext("forgotIDError", null);
        break;
      case "forgotBirthdate":
        setContext("forgotBirthdateError", null);
        break;
      default:
        break;
    }
  };

  const campusOnSelect = (id) => {
    setContext("forgotCampusError", null);
    setForm("forgotPasswordCampus", id);
  };

  const login = () => history.push(routes.login);

  const openSuccess = () => setSubmitSuccessDialog(true);
  const closeSuccess = () => setSubmitSuccessDialog(false);
  const openError = () => setSubmitErrorDialog(true);
  const closeError = () => setSubmitErrorDialog(false);

  return (
    <LoginLayout classNames={[root]}>
      <ReactHelmet title={"Forgot Password"} />
      <Space vertical space={12} />
      <Typography variant={"h3"} weight={"normal"}>
        Forgot Password?
      </Typography>
      <Space vertical space={1} />
      <Typography variant={"h5"} weight={"light"}>
        Please enter your information below to reset your password.
        <br />
        After successful reset, your new password will be your birth date.
      </Typography>
      <Space vertical space={4} />
      <Select
        onSelect={campusOnSelect}
        value={forgotPasswordCampus}
        items={campuses}
        placeholder={"Campus"}
        size={"medium"}
        width={300}
        error={forgotCampusError}
      />
      <Space vertical space={2} />
      <Input
        name={"forgotIDNumber"}
        value={forgotIDNumber}
        onChange={handleFormChange}
        placeholder={"ID Number"}
        size={"medium"}
        width={300}
        error={forgotIDError}
        onKeyDown={onEnter}
      />
      <Space vertical space={2} />
      <Input
        name={"forgotMiddleName"}
        value={forgotMiddleName}
        onChange={handleFormChange}
        placeholder={"Middle Name"}
        size={"medium"}
        width={300}
        onKeyDown={onEnter}
      />
      <Space vertical space={2} />
      <Input
        name={"forgotBirthdate"}
        value={forgotBirthdate}
        onChange={handleFormChange}
        placeholder={"Date of Birth (mm-dd-yyyy)"}
        size={"medium"}
        width={300}
        error={forgotBirthdateError}
        onKeyDown={onEnter}
      />

      <Space vertical space={6} />

      <FlexContainer alignItems={"center"}>
        <Button
          variant={"primary"}
          size={"medium"}
          isLoading={isResettingPass}
          disableUppercase
          onClick={reset}
        >
          Reset Password
        </Button>
        <Space space={3} />
        <Typography>or</Typography>
        <Space space={3} />
        <Click onClick={login}>
          <Typography weight={"bold"}>Login instead</Typography>
        </Click>
      </FlexContainer>

      {/*<Space vertical space={12}/>*/}

      {/*<Typography variant={'h6'} weight={'medium'}>*/}
      {/*    No email address associated with your account?*/}
      {/*</Typography>*/}
      {/*<Typography variant={'h6'} weight={'light'}>*/}
      {/*    Message us for further assistance.*/}
      {/*</Typography>*/}
      {/*<Space vertical space={2}/>*/}
      {/*<Link target={'_blank'} rel={'noopener noreferrer'} href={mailto}>*/}
      {/*    <Button variant={'primary'} size={'medium'} disableUppercase>Message Us</Button>*/}
      {/*</Link>*/}

      <LoginFooter />

      <MessageDialog
        open={submitSuccessDialog}
        variant={"success"}
        title={"Password Reset"}
        icon={checkboxCircleLine}
        onCloseLabel={"Close"}
        onConfirmLabel={"Login Now"}
        description={
          "Your password has been successfully reset to default! " +
          "Your new password is your birth date in mm-dd-yyyy format."
        }
        onClose={closeSuccess}
        onConfirm={login}
      />

      <MessageDialog
        open={submitErrorDialog}
        variant={"error"}
        title={errorTitle}
        onCloseLabel={"Close"}
        onConfirmLabel={"Retry"}
        description={errorMessage}
        onClose={closeError}
        onConfirm={reset}
      />
    </LoginLayout>
  );
}

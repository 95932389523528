import React, { Fragment, useEffect } from "react";
import ReactHelmet from "../../components/core/Helmet";
import Typography from "../../components/core/typography/Typography";
import Space from "../../components/core/Space";
import { useBreakpointDown } from "../../utils/hooks/mediaQuery";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/custom/card/Card";
import FlexContainer from "../../components/core/containers/flex/FlexContainer";
import GridContainer from "../../components/core/containers/grid/GridContainer";
import CardInfo from "../../components/custom/card/CardInfo";
import Button from "../../components/core/button/Button";
import FlexItem from "../../components/core/containers/flex/FlexItem";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../utils/currency";
import { getAccountabilities } from "../../redux/actions/accountability";
import CardFilled from "../../components/custom/card/CardFilled";
import ErrorLoad from "../../components/custom/ErrorLoad";
import EmptyContent from "../../components/custom/EmptyContent";
import refreshLine from "@iconify/icons-ri/refresh-line";
import { CONFIG } from "../../AppConfig";
import { Link, List, ListItem, ListItemText } from "@material-ui/core";
import LoadingComponents from "../../components/core/loader/LoadingComponents";
import { toggle } from "../../redux/actions/context";

export default function Accountabilities() {
  const isDownSM = useBreakpointDown("sm");

  const {
    isGettingAccountability,
    isErrorGettingAccountability,
    ignoreAccountabilityChecking,
    hasAccountability,
    outstandingBalance,
    campusID,
    isUndergrad,
    isFreeHighEduc,
  } = useSelector((state) => ({
    isGettingAccountability: state.CONTEXT.toggles.isGettingAccountability,
    isErrorGettingAccountability:
      state.CONTEXT.toggles.isErrorGettingAccountability,
    ignoreAccountabilityChecking:
      state.CONTEXT.toggles.ignoreAccountabilityChecking,
    hasAccountability: state.ACCOUNTABILITY.hasAccountability,
    outstandingBalance: state.ACCOUNTABILITY.outstandingBalance,
    campusID: state.AUTH.campusID,
    isUndergrad: state.AUTH.isUndergrad,
    isFreeHighEduc: state.AUTH.isFreeHighEduc,
  }));

  const campus =
    campusID === 1
      ? "obrero"
      : campusID === 6
      ? "mintal"
      : campusID === 8
      ? "tagum"
      : campusID === 7
      ? "mabini"
      : null;
  const bankName = campus ? CONFIG.CAMPUS_CREDENTIALS[campus].bankName : "";
  const accountName = campus
    ? CONFIG.CAMPUS_CREDENTIALS[campus].bankAccountName
    : "";
  const accountNumber = campus
    ? CONFIG.CAMPUS_CREDENTIALS[campus].bankAccountNumber
    : "";
  const paymentEmail = campus
    ? CONFIG.CAMPUS_CREDENTIALS[campus].paymentEmail
    : "";
  const studentAccountsEmail = campus
    ? CONFIG.CAMPUS_CREDENTIALS[campus].studentAccountsEmail
    : paymentEmail;
  const inquiryEmail = campus
    ? CONFIG.CAMPUS_CREDENTIALS[campus].inquiryEmail
    : "";
  const inquiryMobile = campus
    ? CONFIG.CAMPUS_CREDENTIALS[campus].inquiryMobile
    : "";

  const mailtoPayment = `mailto:${paymentEmail}`;
  const mailtoStudentAccounts = `mailto:${studentAccountsEmail}`;
  const mailtoInquiry = `mailto:${inquiryEmail}`;

  useEffect(() => {
    if (!hasAccountability) getAccountabilities();
  }, [hasAccountability]);

  const bankDetails = [
    {
      label: "Bank:",
      value: bankName,
    },
    {
      label: "Account Name:",
      value: accountName,
    },
    {
      label: "Account Number:",
      value: accountNumber,
    },
  ];

  return (
    <Fragment>
      <ReactHelmet title={"Accountabilities"} />

      <FlexContainer
        justifyContent={"space-between"}
        alignItems={"center"}
        wrap
      >
        <Typography variant={"h5"} weight={"semibold"}>
          Accountabilities
        </Typography>
        <Button
          variant={"success"}
          icon={refreshLine}
          onClick={getAccountabilities}
          isLoading={isGettingAccountability}
        >
          {isErrorGettingAccountability ? "Reload" : "Refresh"}
        </Button>
      </FlexContainer>

      <Space vertical space={isDownSM ? 3 : 4} />

      {isGettingAccountability ? (
        <LoadingComponents mini />
      ) : isErrorGettingAccountability ? (
        <ErrorLoad reload={getAccountabilities} noButton />
      ) : hasAccountability ? (
        <div>
          <GridContainer spacing={isDownSM ? 4 : 3}>
            <Grid
              item
              xs={12}
              md={7}
              lg={8}
              style={{ order: isDownSM ? 2 : 1 }}
            >
              <Typography variant={"h5"} weight={"semibold"} color={"success"}>
                How do we check if we still have balances from prior semesters to settle?
              </Typography>
              <Space vertical space={1} />
              <Typography align={"justify"}>
                For paying undergraduate and graduate students, accumulated balances may be 
                viewed in the&nbsp; <Typography display={'inline'} weight={'bold'}>Student Portal.</Typography>
              </Typography>           
              <Space vertical space={3} />
              <Typography variant={"h5"} weight={"semibold"} color={"success"}>
                To whom will we inquire on the details (such as old accounts or breakdown)?
              </Typography>
              <Space vertical space={1} />
              <Typography align={"justify"}>
                Details and breakdown of their account may be inquired from the Student Accounts of the
                Finance Division of the University.
              </Typography>
              <Space vertical space={2} />
              <Typography align={"justify"}>
                You may reach us via the following contact details:&nbsp;
              </Typography>
              <Space vertical space={2} />
                <CardInfo variant={"warning"}>
                  <GridContainer>
                    <Grid item xs={12}>
                      <FlexContainer justifyContent={"start"} wrap>
                        <Typography weight={"bold"}>OBRERO CAMPUS</Typography>
                        <Space space={1} />
                        <List component="nav">
                          <ListItem>
                            <Typography>Telephone Number:&nbsp;</Typography>
                            <Link><strong>(082) 227-8192 local 236-238</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Mobile Number (Smart):&nbsp;</Typography>
                            <Link><strong>0939-889-5921</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Mobile Number (Globe):&nbsp;</Typography>
                            <Link><strong>0977-249-0491</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Email Address:&nbsp;</Typography>
                            <Link><strong>studentaccounts_obrero@usep.edu.ph</strong></Link>
                          </ListItem>
                        </List>
                      </FlexContainer>
                    </Grid>
                  </GridContainer>
                </CardInfo>
                <Space vertical space={2} />
                <CardInfo variant={"warning"}>
                  <GridContainer>
                    <Grid item xs={12}>
                      <FlexContainer justifyContent={"start"} wrap>
                        <Typography weight={"bold"}>MINTAL CAMPUS</Typography>
                        <Space space={1} />
                        <List component="nav">
                          <ListItem>
                            <Typography>Telephone Number:&nbsp;</Typography>
                            <Link><strong>(082) 227-8192 local 412</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Mobile Number (Smart):&nbsp;</Typography>
                            <Link><strong>0947-579-3929</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Mobile Number (Globe):&nbsp;</Typography>
                            <Link><strong>0995-614-4337</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Email Address:&nbsp;</Typography>
                            <Link><strong>usepmintal_studentaccounts@usep.edu.ph</strong></Link>
                          </ListItem>
                        </List>
                      </FlexContainer>
                    </Grid>
                  </GridContainer>
                </CardInfo>
                <Space vertical space={2} />
                <CardInfo variant={"warning"}>
                  <GridContainer>
                    <Grid item xs={12}>
                      <FlexContainer justifyContent={"start"} wrap>
                        <Typography weight={"bold"}>TAGUM CAMPUS</Typography>
                        <Space space={1} />
                        <List component="nav">
                          <ListItem>
                            <Typography>Telephone Number:&nbsp;</Typography>
                            <Link><strong>(082) 227-8192 local 501 or 517</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Mobile Number (Smart):&nbsp;</Typography>
                            <Link><strong>0947-547-4085</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Mobile Number (Globe):&nbsp;</Typography>
                            <Link><strong>0956-201-1447</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Email Address:&nbsp;</Typography>
                            <Link><strong>payment.tagummabini@usep.edu.ph</strong></Link>
                          </ListItem>
                        </List>
                      </FlexContainer>
                    </Grid>
                  </GridContainer>
                </CardInfo>
                <Space vertical space={2} />
                <CardInfo variant={"warning"}>
                  <GridContainer>
                    <Grid item xs={12}>
                      <FlexContainer justifyContent={"start"} wrap>
                        <Typography weight={"bold"}>MABINI CAMPUS</Typography>
                        <Space space={1} />
                        <List component="nav">
                          <ListItem>
                            <Typography>Telephone Number:&nbsp;</Typography>
                            <Link><strong>(082) 227-8192 local 501 or 517</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Mobile Number (Smart):&nbsp;</Typography>
                            <Link><strong>0961-710-9854</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Mobile Number (Globe):&nbsp;</Typography>
                            <Link><strong>0956-715-0480</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Email Address:&nbsp;</Typography>
                            <Link><strong>payment.tagummabini@usep.edu.ph</strong></Link>
                          </ListItem>
                        </List>
                      </FlexContainer>
                    </Grid>
                  </GridContainer>
                </CardInfo>
                <Space vertical space={2} />
                <CardInfo variant={"warning"}>
                  <GridContainer>
                    <Grid item xs={12}>
                      <FlexContainer justifyContent={"start"} wrap>
                        <Typography weight={"bold"}>BISLIG CAMPUS</Typography>
                        <Space space={1} />
                        <List component="nav">
                          <ListItem>
                            <Typography>Mobile Number (Smart):&nbsp;</Typography>
                            <Link><strong>0951-249-3719</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Mobile Number (Globe):&nbsp;</Typography>
                            <Link><strong>0905-607-8300</strong></Link>
                          </ListItem>
                          <ListItem>
                            <Typography>Email Address:&nbsp;</Typography>
                            <Link><strong>bislig_accounting@usep.edu.ph</strong></Link>
                          </ListItem>
                        </List>
                      </FlexContainer>
                    </Grid>
                  </GridContainer>
                </CardInfo>

              <Space vertical space={3} />

              <Typography variant={"h5"} weight={"semibold"} color={"success"}>
                What are our payment options?
              </Typography>
              <Space vertical space={1} />
              <CardInfo variant={"warning"}>
                <GridContainer>
                  <Grid item xs={12}>
                    <FlexContainer justifyContent={"start"} wrap>
                      <Typography weight={"bold"}>Option 1:</Typography>
                      <Space space={1} />
                      <Typography>
                        Through&nbsp;
                        <Link
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                          href={CONFIG.URL_LBP_LINKBIZ}
                        >
                          <strong>Land Bank LinkBiz Portal</strong>
                        </Link>
                      </Typography>
                      <Space vertical space={3} />
                      <Typography>
                        For clients, you may utilize the e-Payment facility of Landbank of the Philippines, Link.BizPortal through the following
                        eligible client source accounts:
                      </Typography>
                      <GridContainer>
                        <Grid item xs={12}>
                          <List component="a" dense>
                            <ListItem >
                              <ListItemText primary="LANDBANK ATM Savings Account" />
                            </ListItem>
                            <ListItem >
                              <ListItemText primary="LANDBANK VISA Debit Cards" />
                            </ListItem>
                            <ListItem >
                              <ListItemText primary="LANDBANK Current Account with ATM Access" />
                            </ListItem>
                            <ListItem >
                              <ListItemText primary="BancNet-Member Banks ATM Cards" />
                            </ListItem>
                            <ListItem >
                              <ListItemText primary="International Credit/Debit/Prepaid Cards" />
                            </ListItem>
                            <ListItem >
                              <ListItemText primary="GCash" />
                            </ListItem>
                            <ListItem >
                              <ListItemText primary="Cash payment option through LANDBANK payment partners through 7-eleven and ECPAY" />
                            </ListItem>
                          </List>
                        </Grid>
                      </GridContainer>
                    </FlexContainer>
                  </Grid>
                </GridContainer>
              </CardInfo>
              <Space vertical space={2} />
              <CardInfo variant={"success"}>
                <GridContainer>
                  <Grid item xs={12}>
                    <FlexContainer justifyContent={"start"} wrap>
                      <Typography weight={"bold"}>Option 2:&nbsp;</Typography>
                      <Typography style={{fontSize:"20px"}}>Walk-in</Typography>
                      <Space space={1} />
                      <Space vertical space={3} />
                      <Typography>
                        If you are having difficulties accessing the Landbank LinkBiz portal, the cashiering unit accepts payments from walk-in clients 
                        in a limited capacity to ensure social distancing and other health safety protocols are still maintained 
                        from <strong>8:00 AM to 4:00 PM, Mondays to Fridays</strong>.
                      </Typography>
                    </FlexContainer>
                  </Grid>
                </GridContainer>
              </CardInfo>

              {isUndergrad && !isFreeHighEduc && (
                <div>
                  <Space vertical space={3} />
                  <Typography
                    variant={"h5"}
                    weight={"semibold"}
                    color={"primary"}
                  >
                    Why am I seeing this?
                  </Typography>
                  <Space vertical space={1} />
                  <Typography align={"justify"}>
                    You have voluntarily opted in your previous registration/s
                    not to avail of the
                    <strong> free higher education </strong> provision stated in
                    the
                    <strong> Republic Act 10931 </strong> or also known as the
                    <strong>
                      {" "}
                      Universal Access to Quality Tertiary Education Act of 2017{" "}
                    </strong>
                    and waived the benefits of the said Act. Therefore, you're
                    hereby enjoined to pay for your tuition fee and other
                    related school fees.
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              lg={4}
              style={{ order: isDownSM ? 1 : 2 }}
            >
              <Card padding={4} height={100}>
                <FlexContainer direction={"column"}>
                  <Space vertical space={2} />
                  <Typography
                    variant={"h3"}
                    weight={"normal"}
                    color={"error"}
                    align={"center"}
                  >
                    P {formatCurrency(outstandingBalance)}
                  </Typography>
                  <Space vertical space={1} />
                  <Typography
                    variant={"h5"}
                    weight={"semibold"}
                    align={"center"}
                  >
                    Outstanding Balance
                  </Typography>
                  <Space vertical space={1} />
                  <Typography align={"center"}>
                    Settle your balance as soon as possible.
                  </Typography>
                  <Space vertical space={1} />
                </FlexContainer>
              </Card>
              {/* {!isDownSM && (
                <FlexItem>
                  <Space vertical space={4} />
                  <Typography align={"center"}>
                    Do you have any inquiry?
                  </Typography>
                  <Space vertical space={2} />
                  <Link
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    href={mailtoInquiry}
                  >
                    <FlexContainer justifyContent={"center"}>
                      <Button size={"medium"} variant={"primary"}>
                        Contact Us
                      </Button>
                    </FlexContainer>
                  </Link>
                  <Space vertical space={2} />
                  <Typography align={"center"}>
                    or email us at <br />
                    <strong> {inquiryEmail}</strong>.
                  </Typography>
                </FlexItem>
              )} */}
            </Grid>
          </GridContainer>

          {/* <Space vertical space={4} />
          {isDownSM && (
            <CardFilled>
              <Typography align={"center"}>Do you have any inquiry?</Typography>
              <Space vertical space={2} />
              <Link
                target={"_blank"}
                rel={"noopener noreferrer"}
                href={mailtoInquiry}
              >
                <FlexContainer justifyContent={"center"}>
                  <Button size={"medium"} variant={"primary"}>
                    Contact Us
                  </Button>
                </FlexContainer>
              </Link>
              <Space vertical space={2} />
              <Typography align={"center"}>
                or email us at <br />
                <strong> {inquiryEmail}</strong>.
              </Typography>
            </CardFilled>
          )} */}


          <Space vertical space={4} />

          {CONFIG.ENV_DEV ||
            (CONFIG.ENV_STAGING && (
              <>
                <Card padding={4}>
                  <FlexContainer alignItems={"center"}>
                    <Typography>Ignore accountabilities?</Typography>
                    <Space space={3} />
                    <Button
                      size={"small"}
                      variant={ignoreAccountabilityChecking && "success"}
                      onClick={() => {
                        toggle(
                          "ignoreAccountabilityChecking",
                          !ignoreAccountabilityChecking
                        );
                      }}
                    >
                      {ignoreAccountabilityChecking ? "TRUE" : "FALSE"}
                    </Button>
                  </FlexContainer>
                </Card>
              </>
            ))}
        </div>
      ) : (
        <EmptyContent title={"No Accountabilities"} noDescription noButton />
      )}
    </Fragment>
  );
}

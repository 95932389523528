import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../core/button/Button";
import { useHistory, useLocation } from "react-router";
import FlexContainer from "../../core/containers/flex/FlexContainer";
import clsx from "clsx";
import Scrollbar from "../../core/containers/scrollbars/Scrollbar";
import Space from "../../core/Space";
import $ from "jquery";
import FlexItem from "../../core/containers/flex/FlexItem";
import Typography from "../../core/typography/Typography";
import arrowLeftSLine from "@iconify/icons-ri/arrow-left-s-line";
import arrowRightSLine from "@iconify/icons-ri/arrow-right-s-line";
import Icon from "@iconify/react";

export default function MenuScroll({ rootClassName, size, menu }) {
  const { root, menuItem } = style();
  const location = useLocation();
  const history = useHistory();
  const menuScrollLeft = window.sessionStorage.getItem("menuScrollLeft") || 0;

  const isActive = (path) => {
    return location.pathname === path || location.pathname === path + "/";
  };
  const getElementId = (pathname) => {
    let activeElement = "menu-item-";
    for (let [index, i] of menu.entries()) {
      if (pathname === i.path) activeElement += index.toString();
    }
    return activeElement;
  };
  const scroll = (element, scrollElement, animate = false) => {
    try {
      const itemElement = document.getElementById(element);
      const scrollWidth = scrollElement.offsetWidth;
      const itemWidth = itemElement.offsetWidth;
      const scrollLeftPos = scrollElement.offsetLeft;

      $("#menu-scroll").animate(
        {
          scrollLeft:
            itemElement.offsetLeft -
            scrollLeftPos -
            scrollWidth / 2 +
            itemWidth / 2,
        },
        {
          duration: animate ? 500 : 0,
        }
      );
    } catch (e) {}
  };

  useEffect(() => {
    const scrollElement = document.getElementById("menu-scroll");
    const firstElement = document.getElementById("menu-item-0");
    const lastElement = document.getElementById(
      "menu-item-" + (menu.length - 1)
    );
    const activeElement = getElementId(location.pathname);
    const paddingLeft = document.getElementById("menu-padding-left");
    const paddingRight = document.getElementById("menu-padding-right");

    scrollElement.scrollLeft = menuScrollLeft;
    scrollElement.style.visibility = "visible";

    let paddingLeftWidth;
    let paddingRightWidth;

    const setPadding = () => {
      paddingLeftWidth =
        scrollElement.offsetWidth / 2 - firstElement.offsetWidth / 2 - 8;
      paddingLeft.style.width = paddingLeftWidth + "px";
      paddingRightWidth =
        scrollElement.offsetWidth / 2 - lastElement.offsetWidth / 2 - 8;
      paddingRight.style.width = paddingRightWidth + "px";
    };

    setPadding();

    scroll(activeElement, scrollElement, true);

    window.sessionStorage.setItem("menuScrollLeft", scrollElement.scrollLeft);

    $("#menu-scroll").on("scroll", () => {
      window.sessionStorage.setItem("menuScrollLeft", scrollElement.scrollLeft);
    });
    $(window).resize(() => {
      setPadding();
    });
  });

  return (
    <div className={clsx(root, rootClassName)}>
      <Scrollbar id={"menu-scroll"} hidden hiddenScroll>
        <FlexContainer
          alignItems={"center"}
          justifyContent={"flex-start"}
          wrap={"nowrap"}
        >
          <FlexItem visibility={""}>
            <div id={"menu-padding-left"} style={{ width: 1000 }}>
              <FlexContainer alignItems={"center"} justifyContent={"flex-end"}>
                <Icon icon={arrowLeftSLine} width={18} color={"#aaa"} />
                <Space space={0.5} />
                <Typography variant={"caption"} color={"grey1"}>
                  SLIDE
                </Typography>
                <Space space={3} />
              </FlexContainer>
            </div>
          </FlexItem>
          {menu.map((item, index) => (
            <div id={"menu-item-" + index} className={menuItem} key={index}>
              <Button
                variant={isActive(item.path) ? "primary" : "default"}
                disableHover={isActive(item.path)}
                nowrap
                size={size}
                onClick={() => {
                  if (location.pathname !== item.path) history.push(item.path);
                }}
              >
                {item.name}
              </Button>
            </div>
          ))}
          <FlexItem visibility={""}>
            <div id={"menu-padding-right"} style={{ width: 1000 }}>
              <FlexContainer alignItems={"center"}>
                <Space space={3} />
                <Typography variant={"caption"} color={"grey1"} align={"right"}>
                  SLIDE
                </Typography>
                <Space space={0.5} />
                <Icon icon={arrowRightSLine} width={18} color={"#aaa"} />
              </FlexContainer>
            </div>
          </FlexItem>
        </FlexContainer>
      </Scrollbar>
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  menuItem: {
    margin: 8,
  },
}));

import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "../../components/core/typography/Typography";
import Space from "../../components/core/Space";
import FlexContainer from "../../components/core/containers/flex/FlexContainer";
import {useBreakpointDown} from "../../utils/hooks/mediaQuery";
import arrowLeftLine from "@iconify/icons-ri/arrow-left-line";
import Button from "../../components/core/button/Button";
import Container from "@material-ui/core/Container";
import SimplebarScrollbar from "../../components/core/containers/scrollbars/simplebar/SimplebarScrollbar";
import Click from "../../components/core/link/Click";
import FlexItem from "../../components/core/containers/flex/FlexItem";
import IconMini from "../../components/core/icons/IconMini";
import doorLockLine from "@iconify/icons-ri/door-lock-line";


export default function NotificationsDropdown({open, onClose}) {

    const isDownSM = useBreakpointDown('sm')
    const isDownXS = useBreakpointDown('xs')

    const style = makeStyles(theme => ({
        root: {
            background: theme.palette.background,
            minHeight: isDownSM ? '100vh' : 0,
            borderRadius: theme.shape.borderRadiusSmall,
            width: isDownSM ? '100%' : 400
        },
        container: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            borderRadius: theme.shape.borderRadiusSmall,
        },
        stickyTop: {
            position: '-webkit-sticky',
            width: '100%',
            top: 0,
            background: theme.palette.background,
            zIndex: 2,
            '&': {
                position: 'sticky',
            }
        },
        menuContainer: {
            background: theme.palette.background,
            borderRadius: theme.shape.borderRadiusSmall,
        },
        menuItem: {
            background: theme.palette.grey.shade5,
            padding: isDownSM ? '10px 16px 10px 16px' : '8px 16px 8px 16px',
            borderRadius: theme.shape.borderRadiusSmall,
            '&:hover': {
                // background: isDownSM ? null : theme.palette.buttonOverlay + theme.palette.default.gradient
            }
        },
    }))

    const {root, container, stickyTop, menuContainer, menuItem} = style()

    const maxHeight = 'calc(100vh - 70px - 8px)'

    const notifications = [
        {
            variant: 'warning',
            icon: doorLockLine,
            title: 'Hello, Juan Dela Cruz!',
            description: 'Welcome to the new USeP student portal. Enjoy the brand new look and whole new user experience.',
            timestamp: '8 hours ago',
            isRead: false
        },
        {
            variant: 'success',
            icon: doorLockLine,
            title: 'Hello, Juan Dela Cruz!',
            description: 'Welcome to the new USeP student portal. Enjoy the brand new look and whole new user experience.',
            timestamp: '8 hours ago',
            isRead: true
        },
    ]

    return (
        <div className={root}>
            <SimplebarScrollbar maxHeight={isDownSM ? '100vh' : maxHeight} nodisplay={!open}>
                <div className={container}>
                    <Container fixed={isDownSM} disableGutters={isDownXS || !isDownSM}>
                        <div className={stickyTop}>
                            <div className={menuContainer}>
                                <Space vertical space={2}/>
                                <FlexContainer alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant={isDownSM ? 'h5' : 'h6'}
                                                weight={'semibold'}>Notifications</Typography>
                                    {isDownSM ? <Button icon={arrowLeftLine} onClick={onClose}>Back</Button> : null}
                                </FlexContainer>
                                <Space vertical space={2}/>
                            </div>
                        </div>

                        <div className={menuContainer}>
                            {notifications.map((item, index) => (
                                <div key={index}>
                                    <Click>
                                        <div className={menuItem}>
                                            <FlexContainer alignItems={'flex-start'}>
                                                <FlexItem>
                                                    <Space vertical space={1}/>
                                                    <IconMini variant={item.variant} icon={item.icon} filled height={24}/>
                                                </FlexItem>
                                                <Space space={6}/>
                                                <FlexItem>
                                                    <Space vertical space={1}/>
                                                    <Typography variant={'h6'} weight={item.isRead ? 'medium' : 'bold'}>
                                                        {item.title}
                                                    </Typography>
                                                    <Space vertical space={0.5}/>
                                                    <Typography>{item.description}</Typography>
                                                    <Space vertical space={1.5}/>
                                                    <Typography color={item.isRead ? null : item.variant}
                                                                weight={item.isRead ? null : 'bold'}>
                                                        {item.timestamp}
                                                    </Typography>
                                                    <Space vertical space={1}/>
                                                </FlexItem>
                                            </FlexContainer>
                                        </div>
                                        <Space vertical space={1}/>
                                    </Click>
                                </div>
                            ))}
                        </div>
                        <Space vertical space={1}/>
                    </Container>
                </div>
            </SimplebarScrollbar>
        </div>
    )
}

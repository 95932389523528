import { useBreakpointDown } from "../../utils/hooks/mediaQuery";
import Card from "../../components/custom/card/Card";
import FlexContainer from "../../components/core/containers/flex/FlexContainer";
import FlexItem from "../../components/core/containers/flex/FlexItem";
import Space from "../../components/core/Space";
import IconMini from "../../components/core/icons/IconMini";
import checkLine from "@iconify/icons-ri/check-line";
import Typography from "../../components/core/typography/Typography";
import React from "react";
import Button from "../../components/core/button/Button";

const Success = ({ onBegin }) => {
  const isDownXS = useBreakpointDown("xs");
  return (
    <>
      <Card padding={isDownXS ? 4 : 5}>
        <FlexContainer direction={"column"} alignItems={"center"}>
          <FlexItem style={{ width: isDownXS ? "100%" : 320 }}>
            <Space vertical space={1} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconMini
                icon={checkLine}
                height={40}
                padding={4}
                filled
                variant={"success"}
              />
            </div>
            <Space vertical space={1.5} />
            <Typography variant={"h4"} align={"center"} weight={"semibold"}>
              Success
            </Typography>
            <Space vertical space={4} />
            <Typography align={"center"}>
              You have successfully updated your account details. You may now
              start using the Student Portal.
            </Typography>
          </FlexItem>
          <Space vertical space={4} />
          <FlexItem>
            <Button variant={"primary"} size={"medium"} onClick={onBegin}>
              Start Now
            </Button>
          </FlexItem>
        </FlexContainer>
        <Space vertical space={1} />
      </Card>
    </>
  );
};

export default Success;

import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import Space from "../components/space";

const styles = StyleSheet.create({
  container: {
    fontFamily: "Arial Narrow",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
  },
  leftBox: {
    padding: 4,
    width: "45%",
  },
  middleBox: {
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    padding: 4,
    width: "20%",
  },
  rightBox: {
    padding: 4,
    width: "35%",
  },
  row: {
    flexDirection: "row",
  },
  textNormal: {
    fontSize: 7,
  },
  textBold: {
    fontSize: 7,
    fontWeight: "bold",
  },
});

export default function Details({ data }) {
  return (
    <View style={styles.container}>
      <View style={styles.leftBox}>
        <View style={styles.row}>
          <Text style={styles.textNormal}>Student ID:</Text>
          <Space space={5} />
          <Text style={styles.textBold}>{data.studentID}</Text>
          <Space space={15} />
          <Text style={styles.textNormal}>Gender:</Text>
          <Space space={5} />
          <Text style={styles.textNormal}>{data.gender}</Text>
          <Space space={15} />
          <Text style={styles.textNormal}>Citizenship:</Text>
          <Space space={5} />
          <Text style={styles.textNormal}>{data.citizenship}</Text>
        </View>
        <Space vertical space={3} />
        <View style={styles.row}>
          <Text style={styles.textNormal}>Student Name:</Text>
          <Space space={5} />
          <Text style={styles.textBold}>{data.name}</Text>
        </View>
        <Space vertical space={3} />
        <View style={styles.row}>
          <Text style={styles.textNormal}>Year Level:</Text>
          <Space space={5} />
          <Text style={styles.textBold}>{data.yearLevel || ""}</Text>
          <Space space={15} />
          <Text style={styles.textNormal}>Status:</Text>
          <Space space={5} />
          <Text style={styles.textNormal}>{data.status || ""}</Text>
          <Space space={15} />
          <Text style={styles.textNormal}>Type:</Text>
          <Space space={5} />
          <Text style={styles.textNormal}>{data.type || ""}</Text>
          <Space space={15} />
          <Text style={styles.textNormal}>Section:</Text>
          <Space space={5} />
          <Text style={styles.textNormal}>{data.section || ""}</Text>
        </View>
      </View>
      <View style={styles.middleBox}>
        <View>
          <Text style={styles.textNormal}>
            College: &nbsp;&nbsp;&nbsp;
            {data.college}
          </Text>
        </View>
        <Space vertical space={3} />
        <View>
          <Text style={styles.textNormal}>
            Course: &nbsp;&nbsp;&nbsp;
            {data.course}
          </Text>
        </View>
        <Space vertical space={3} />
        <View>
          <Text style={styles.textNormal}>
            Major: &nbsp;&nbsp;&nbsp;
            {data.major}
          </Text>
        </View>
      </View>
      <View style={styles.rightBox}>
        <View>
          <Text style={styles.textBold}>
            Acad. Year/Term: &nbsp;&nbsp;&nbsp;
            {data.AYTerm}
          </Text>
        </View>
        <Space vertical space={3} />
        <View>
          <Text style={styles.textNormal}>
            Fee Template: &nbsp;&nbsp;&nbsp;
            {data.feeTemplate}
          </Text>
        </View>
        <Space vertical space={3} />
        <View>
          <Text style={styles.textNormal}>
            Scholarship: &nbsp;&nbsp;&nbsp;
            {data.scholarship}
          </Text>
        </View>
      </View>
    </View>
  );
}

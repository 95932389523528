import React, { memo, useEffect, useState } from "react";
import ReactHelmet from "../../../components/core/Helmet";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import Typography from "../../../components/core/typography/Typography";
import Button from "../../../components/core/button/Button";
import Space from "../../../components/core/Space";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import LineBreak from "../../../components/core/LineBreak";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import { useSelector } from "react-redux";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import arrowLeftLine from "@iconify/icons-ri/arrow-left-line";
import sendPlaneLine from "@iconify/icons-ri/send-plane-line";
import NoticeContainer from "../../../components/core/containers/NoticeContainer";
import MessageDialog from "../../../components/core/dialogs/MessageDialog";
import globalStyle from "../../../static/styles";
import Input from "../../../components/core/input/Input";
import {
  getCustomerFeedbackForm,
  getFacultyEvaluationForm,
  getOfficesToEvaluate,
  saveCustomerFeedback,
} from "../../../redux/actions/universityServicesEvaluation";
import RadioButtonNative from "../../../components/core/input/RadioButtonNative";
import LoadingComponents from "../../../components/core/loader/LoadingComponents";
import ErrorLoad from "../../../components/custom/ErrorLoad";
import { setContext } from "../../../redux/actions/context";
import { isEmptyObject } from "../../../utils/misc";
import Select from "../../../components/core/input/Select";
import checkboxCircleLine from "@iconify/icons-ri/checkbox-circle-line";
import Card from "../../../components/custom/card/Card";
import CheckBoxNative from "../../../components/core/input/CheckBoxNative";
import { unstable_next } from "scheduler";

export default function CustomersFeedback() {
  const style = makeStyles((theme) => ({
    root: {},
    gridContainer: {
      width: "calc(100% + 16px)",
    },
    genInfoItem: {
      marginBottom: 8,
    },
    infoLabel: {
      width: "30%",
    },
    infoValue: {
      width: "calc(70% - 16px)",
      marginTop: "-2px",
    },
    statItem: {
      marginBottom: theme.spacing(2),
    },
    headItem: {
      marginBottom: theme.spacing(3),
    },
    firstRowGridItem: {
      marginBottom: theme.spacing(3),
    },
    stickyTop: {
      position: "-webkit-sticky",
      width: "100%",
      marginLeft: "-8px",
      paddingLeft: 8,
      paddingRight: 8,
      top: 0,
      background: theme.palette.background,
      zIndex: 2,
      "&": {
        position: "sticky",
      },
    },
    headingItem: {
      marginBottom: theme.spacing(1.5),
    },
  }));

  const { root, headItem, gridContainer, firstRowGridItem } = style();
  const { stickyTop } = globalStyle();

  const history = useHistory();
  const isDownSM = useBreakpointDown("sm");

  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [openSubmitSuccess, setOpenSubmitSuccess] = useState(false);
  const [openSubmitError, setOpenSubmitError] = useState(false);
  const [openRequiredFieldsError, setOpenRequiredFieldsError] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedOfficeType, setSelectedOfficeType] = useState(null);

  const {
    customerFeedbackFormData: {
      radioAnswers,
      paramQuestionAnswers,
      otherPurpose,
      purposeAnswers,
    },
    customerFeedbackFormData,
  } = useSelector((state) => state.CONTEXT);
  const { studentID, campusID, lastRegTermID } = useSelector(
    (state) => state.AUTH
  );
  const {
    univServicesEvaluationIsGettingEvaluationForm,
    univServicesEvaluationErrorToGetEvaluationForm,
    univServicesEvaluationIsSavingEvaluationForm,
  } = useSelector((state) => state.CONTEXT.toggles);
  const { customerFeedbackForm, officesToEvaluate } = useSelector(
    (state) => state.UNIV_SERVICES_EVALUATION
  );

  useEffect(() => {
    if (isEmptyObject(customerFeedbackForm)) getCustomerFeedbackForm();
  }, [customerFeedbackForm]);
  useEffect(() => {
    if (isEmptyObject(officesToEvaluate)) getOfficesToEvaluate();
  }, [officesToEvaluate]);

  const officeTypes = [
    {
      id: 1,
      label: "Frontline",
    },
    {
      id: 2,
      label: "Non Frontline",
    },
  ];
  const officeList =
    selectedOfficeType === 1
      ? officesToEvaluate.frontline
      : selectedOfficeType === 2
      ? officesToEvaluate.nonFrontline
      : [];
  const purposeList = [];
  if (customerFeedbackForm.purpose) {
    customerFeedbackForm.purpose.forEach((item) => {
      purposeList.push({
        id: item.id,
        label: item.name,
      });
    });
  }

  const collectAnswers = () => {
    const radioAnswers = [];
    const paramQuestionAnswers = [];
    const purposeAnswers = [];
    const otherPurpose = document.getElementById(
      `customer-feedback-purpose-other`
    ).value;
    if (!customerFeedbackForm.parameters) return;
    for (const parameter of customerFeedbackForm.parameters) {
      for (const statement of parameter.statements) {
        for (const rating of statement.ratings) {
          const element = document.getElementById(
            `radio-${statement.id}-${rating.id}`
          );
          if (element && element.checked) {
            radioAnswers.push({
              statementID: statement.id,
              ratingID: rating.id,
            });
          }
        }
      }
      for (const paramQuestion of parameter.parameterQuestions) {
        const element = document.getElementById(
          `customer-feedback-paramQuestion-${paramQuestion.id}`
        );
        if (element && element.value) {
          paramQuestionAnswers.push({
            paramQID: paramQuestion.id,
            answer: element.value,
          });
        }
      }
    }
    if (!customerFeedbackForm.otherParamQuestion) return;
    for (const parameter of customerFeedbackForm.otherParamQuestion) {
      const element = document.getElementById(
        `customer-feedback-other-param-question-${parameter.id}`
      );
      if (element && element.value) {
        paramQuestionAnswers.push({
          paramQID: parameter.id,
          answer: element.value,
        });
      }
    }
    for (const purpose of purposeList) {
      const element = document.getElementById(`checkbox-${purpose.id}`);
      if (element && element.checked) {
        purposeAnswers.push(purpose.id);
      }
    }

    return { radioAnswers, paramQuestionAnswers, otherPurpose, purposeAnswers };
  };
  const isFormValid = (radioAnswers, purposeAnswers) => {
    let errors = {
      purpose: false,
      statements: [],
    };
    for (const parameter of customerFeedbackForm.parameters) {
      for (const statement of parameter.statements) {
        let contains = false;
        for (const answer of radioAnswers)
          if (statement.id === answer.statementID) contains = true;
        if (!contains) {
          errors.statements.push(statement.id);
        }
      }
    }
    const otherPurposeElement = document.getElementById(
      `customer-feedback-purpose-other`
    );
    if (!(purposeAnswers.length > 0 || otherPurposeElement.value !== ""))
      errors.purpose = true;

    const isValid = errors.purpose === false && errors.statements.length === 0;
    return { isValid, errors };
  };
  const validate = () => {
    const {
      radioAnswers,
      paramQuestionAnswers,
      otherPurpose,
      purposeAnswers,
    } = collectAnswers();
    const { isValid, errors } = isFormValid(radioAnswers, purposeAnswers);
    if (isValid) setOpenSubmitConfirmation(true);
    else setOpenRequiredFieldsError(true);
    unstable_next(() => {
      setErrors(errors);
      setContext("customerFeedbackFormData", {
        radioAnswers,
        paramQuestionAnswers,
        otherPurpose,
        purposeAnswers,
      });
    });
  };
  const onConfirm = () => {
    setOpenSubmitConfirmation(false);
    unstable_next(() => {
      onSubmit();
    });
  };
  const resubmit = () => {
    setOpenSubmitError(false);
    unstable_next(() => {
      onSubmit();
    });
  };
  const onSubmit = () => {
    const data = {
      studentID,
      campusID,
      termID: lastRegTermID,
      officeID: selectedOffice,
      otherPurpose,
      purpose: purposeAnswers,
      answers: radioAnswers,
      paramQAnswers: paramQuestionAnswers,
    };
    const onSuccess = () => {
      setOpenSubmitSuccess(true);
      unstable_next(() => {
        setContext("customerFeedbackFormData", {
          radioAnswers: [],
          paramQuestionAnswers: [],
        });
        setSelectedOffice(null);
        setSelectedOfficeType(null);
      });
    };
    const onFail = () => {
      setOpenSubmitError(true);
    };
    saveCustomerFeedback(data, onSuccess, onFail);
  };
  const ratingIsChecked = (statemenID, ratingID) => {
    for (const answer of radioAnswers)
      if (answer.statementID === statemenID && answer.ratingID === ratingID)
        return true;
    return false;
  };
  const purposeIsChecked = (purposeID) => {
    return purposeAnswers && purposeAnswers.includes(purposeID);
  };
  const getParamQuestionDefaultValue = (id) => {
    for (const answer of paramQuestionAnswers)
      if (answer.paramQID === id) return answer.answer;
    return null;
  };
  const goBack = () => history.goBack();
  const closeSubmitConfirmation = () => setOpenSubmitConfirmation(false);
  const closeSubmitError = () => setOpenSubmitError(false);
  const closeRequiredFieldsError = () => setOpenRequiredFieldsError(false);
  const changeOfficeType = (id) => {
    setSelectedOfficeType(id);
    setSelectedOffice(null);
  };

  const Purpose = memo(({ purposeList }) => (
    <>
      <Card padding={4} noHover error={errors.purpose}>
        <Typography variant={"h6"} weight={"medium"}>
          Transaction/Purpose
          <Typography
            size={12}
            color={"error"}
            weight={"semibold"}
            style={"italic"}
            span
          >
            &nbsp; REQUIRED
          </Typography>
        </Typography>
        <Space vertical space={3} />
        <GridContainer spacing={1} wrap>
          {purposeList.map((purpose, index) => (
            <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
              <CheckBoxNative
                checked={purposeIsChecked(purpose.id)}
                id={`checkbox-${purpose.id}`}
                label={purpose.label}
              />
            </Grid>
          ))}
        </GridContainer>
        <div>
          <Space vertical space={2} />
          <Input
            id={"customer-feedback-purpose-other"}
            type={"textarea"}
            defaultValue={otherPurpose}
            rows={isDownSM ? 3 : 2}
            placeholder={"Other transaction/purpose"}
          />
        </div>
      </Card>
    </>
  ));
  const Parameter = memo(({ customerFeedbackForm }) => (
    <>
      {customerFeedbackForm.parameters
        ? customerFeedbackForm.parameters.map((parameter) => (
            <div key={parameter.id}>
              <Space vertical space={4} />
              <Typography variant={"h6"} weight={"medium"}>
                {parameter.sortOrderA}. {parameter.name}
              </Typography>
              {parameter.statements &&
                parameter.statements.map((statement) => (
                  <div key={statement.id}>
                    <Statement statement={statement} />
                  </div>
                ))}
              <Space vertical space={4} />
              {parameter.parameterQuestions &&
                parameter.parameterQuestions.map((parameterQuestion, index) => (
                  <div key={parameterQuestion.id}>
                    {index ? <Space vertical space={4} /> : null}
                    <ParameterQuestions parameterQuestion={parameterQuestion} />
                  </div>
                ))}
            </div>
          ))
        : null}
    </>
  ));
  const Statement = memo(({ statement }) => (
    <>
      <Space vertical space={2} />
      <Card
        paddingCustom={"32px 32px 24px 32px"}
        variant={"error"}
        error={errors.statements && errors.statements.includes(statement.id)}
        noHover
      >
        <Typography>
          {statement.sortOrder}. {statement.statement}
          <Typography
            size={12}
            color={"error"}
            weight={"semibold"}
            style={"italic"}
            span
          >
            &nbsp; REQUIRED
          </Typography>
        </Typography>
        <Space vertical space={2} />
        <GridContainer spacing={1}>
          {statement.ratings.map((rating) => (
            <Grid item key={rating.id} xs={12} sm={6} lg={3}>
              <Rating statement={statement} rating={rating} />
            </Grid>
          ))}
        </GridContainer>
      </Card>
    </>
  ));
  const Rating = memo(({ statement, rating }) => (
    <>
      {rating.isActive ? (
        <RadioButtonNative
          checked={ratingIsChecked(statement.id, rating.id)}
          id={`radio-${statement.id}-${rating.id}`}
          name={`radio-${statement.id}`}
          label={rating.description}
        />
      ) : null}
    </>
  ));
  const ParameterQuestions = memo(({ parameterQuestion }) => (
    <>
      <Typography variant={"h6"} weight={"medium"}>
        {parameterQuestion.question}
      </Typography>
      <Space vertical space={2} />
      <Input
        id={`customer-feedback-paramQuestion-${parameterQuestion.id}`}
        defaultValue={getParamQuestionDefaultValue(parameterQuestion.id)}
        type={"textarea"}
      />
    </>
  ));
  const OtherParameterQuestions = memo(({ customerFeedbackForm }) => (
    <>
      {customerFeedbackForm.otherParamQuestion
        ? customerFeedbackForm.otherParamQuestion.map((item, index) => (
            <div key={index}>
              <Space vertical space={4} />
              <div>
                <Typography variant={"h6"} weight={"medium"}>
                  {item.question}
                </Typography>
                <Space vertical space={2} />
                <Input
                  id={`customer-feedback-other-param-question-${item.id}`}
                  defaultValue={getParamQuestionDefaultValue(item.id)}
                  type={"textarea"}
                  rows={10}
                />
              </div>
            </div>
          ))
        : null}
    </>
  ));
  const EvaluationForm = memo((props) => {
    return (
      <GridContainer spacing={isDownSM ? 1 : 6}>
        <Grid item xs={12} md={4} lg={3} className={firstRowGridItem}>
          <div className={isDownSM ? null : stickyTop} style={{ top: 140 }}>
            <div>
              <FlexContainer
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant={"h5"} weight={"semibold"}>
                  Office to Evaluate
                </Typography>
              </FlexContainer>
              <Space vertical space={3} />
              <Grid container spacing={2} className={gridContainer}>
                <Grid item xs={12}>
                  <Select
                    items={officeTypes}
                    value={selectedOfficeType}
                    onSelect={changeOfficeType}
                    placeholder={"Office Type"}
                  />
                  <Space vertical space={2} />
                  <Select
                    items={officeList}
                    value={selectedOffice}
                    maxHeight={300}
                    onSelect={(id) => setSelectedOffice(id)}
                    placeholder={"Select Office"}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <Space vertical space={4} />
              <NoticeContainer
                type={"Caution"}
                align={"justify"}
                variant={"warning"}
                padding={4}
                noAnimation
                text={
                  <span>
                    Entered data will be lost when you
                    <strong> change Office to evaluate</strong> or when you
                    leave/refresh the page without submitting.
                  </span>
                }
              ></NoticeContainer>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={8} lg={9} className={firstRowGridItem}>
          {selectedOffice ? (
            <div>
              <FlexContainer
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant={"h5"} weight={"semibold"}>
                  Evaluation Form
                </Typography>
              </FlexContainer>
              <Space vertical space={3} />
              <NoticeContainer
                type={"Instruction"}
                align={"justify"}
                variant={"warning"}
                padding={4}
                noAnimation
                text={
                  <span>
                    Please answer the evaluation form honestly! Evaluate the
                    offices based on the level of your satisfaction in their
                    respective services. Items with
                    <Typography
                      size={12}
                      color={"error"}
                      weight={"semibold"}
                      style={"italic"}
                      span
                    >
                      {" "}
                      &nbsp;REQUIRED&nbsp;{" "}
                    </Typography>
                    badges are required fields. The evaluation form cannot be
                    submitted unless all required fields are answered. Once done
                    in answering the form, click/tap the
                    <strong> Submit </strong> button on the top-right corner of
                    the page to submit the evaluation.
                  </span>
                }
              >
                {" "}
              </NoticeContainer>

              <Space vertical space={4} />
              <Purpose purposeList={purposeList} />
              <Parameter customerFeedbackForm={customerFeedbackForm} />
              <OtherParameterQuestions
                customerFeedbackForm={customerFeedbackForm}
              />
            </div>
          ) : (
            <div style={{ height: "100%", minHeight: 300 }}>
              <FlexContainer
                direction={"center"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography align={"center"}>
                  Please select an office
                </Typography>
              </FlexContainer>
            </div>
          )}
        </Grid>
      </GridContainer>
    );
  });

  return (
    <div className={root}>
      <ReactHelmet
        title={"Customer's Feedback | University Services Evaluation"}
      />

      {isDownSM ? (
        <>
          <Space vertical space={3} />
          <FlexItem classNames={[headItem]}>
            <Typography variant={"h4"} align={"left"} weight={"medium"}>
              Customer's Feedback
            </Typography>
          </FlexItem>
          <div className={stickyTop}>
            <Space vertical space={1} />
            <FlexContainer
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FlexItem classNames={[headItem]} width={"100%"}>
                <FlexContainer
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <FlexContainer
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Button icon={arrowLeftLine} onClick={goBack}>
                      Back
                    </Button>
                    <Button
                      variant={"primary"}
                      icon={sendPlaneLine}
                      onClick={validate}
                      disabled={!selectedOffice}
                      isLoading={univServicesEvaluationIsSavingEvaluationForm}
                    >
                      Submit
                    </Button>
                  </FlexContainer>
                </FlexContainer>
              </FlexItem>
            </FlexContainer>
          </div>
          <Space vertical space={1} />
          <LineBreak />
        </>
      ) : (
        <>
          <div className={stickyTop}>
            <Space vertical space={3} />
            <Space vertical space={1} />
            <FlexContainer
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FlexItem classNames={[headItem]}>
                <Typography variant={"h4"} weight={"medium"}>
                  Customer's Feedback
                </Typography>
              </FlexItem>
              <FlexItem classNames={[headItem]}>
                <FlexContainer alignItems={"center"}>
                  <FlexContainer alignItems={"center"}>
                    <Space space={3} />
                    <Button icon={arrowLeftLine} onClick={goBack}>
                      Back
                    </Button>
                    <FlexItem>
                      <FlexContainer alignItems={"center"}>
                        <Space space={3} />
                        <Button
                          variant={"primary"}
                          icon={sendPlaneLine}
                          onClick={validate}
                          disabled={!selectedOffice}
                          isLoading={
                            univServicesEvaluationIsSavingEvaluationForm
                          }
                        >
                          Submit
                        </Button>
                      </FlexContainer>
                    </FlexItem>
                  </FlexContainer>
                </FlexContainer>
              </FlexItem>
            </FlexContainer>
            <Space vertical space={1} />
            <LineBreak />
          </div>
        </>
      )}

      <LineBreak />

      <Space vertical space={4} />

      {univServicesEvaluationIsGettingEvaluationForm ? (
        <FlexContainer direction={"column"} alignItems={"center"}>
          <LoadingComponents mini label={"Loading Evaluation Form"} />
          <Space vertical space={4} />
        </FlexContainer>
      ) : univServicesEvaluationErrorToGetEvaluationForm ? (
        <ErrorLoad
          reload={() => {
            if (isEmptyObject(customerFeedbackForm)) getFacultyEvaluationForm();
          }}
        />
      ) : (
        <EvaluationForm customerFeedbackFormData={customerFeedbackFormData} />
      )}

      <MessageDialog
        open={openSubmitConfirmation}
        variant={"warning"}
        title={"Submit Confirmation"}
        description={
          <Typography span>
            Upon confirmation, your evaluation form will be submitted. Once
            done, you can <strong> NO LONGER </strong> update this evaluation.
          </Typography>
        }
        onClose={closeSubmitConfirmation}
        onConfirm={onConfirm}
      />

      <MessageDialog
        open={openSubmitSuccess}
        variant={"success"}
        title={"Evaluation Submitted"}
        singleAction
        icon={checkboxCircleLine}
        onConfirmLabel={"Go Back"}
        description={
          "Your evaluation form has been submitted successfully. " +
          "We appreciate your time and effort taking the evaluation. Thank you!"
        }
        onClose={goBack}
        onConfirm={goBack}
      />

      <MessageDialog
        open={openSubmitError}
        variant={"error"}
        title={"Submission Failed"}
        onCloseLabel={"Close"}
        onConfirmLabel={"Resubmit"}
        description={
          "Your evaluation form was not submitted successfully. " +
          "Please try again!"
        }
        onClose={closeSubmitError}
        onConfirm={resubmit}
      />

      <MessageDialog
        open={openRequiredFieldsError}
        variant={"error"}
        title={"Required Field"}
        onConfirmLabel={"Go Back"}
        singleAction
        description={
          "Some required fields were left unfilled. " +
          "Please answer them and submit the form again."
        }
        onClose={closeRequiredFieldsError}
        onConfirm={closeRequiredFieldsError}
      />
    </div>
  );
}

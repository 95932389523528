import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../core/button/Button";
import { useHistory, useLocation } from "react-router";
import FlexContainer from "../../core/containers/flex/FlexContainer";
import clsx from "clsx";

export default function Menu({ rootClassName, size, menu }) {
  const { root, menuItem } = style();
  const location = useLocation();
  const history = useHistory();

  const isActive = (path) => {
    return location.pathname === path || location.pathname === path + "/";
  };

  return (
    <div className={clsx(root, rootClassName)}>
      <FlexContainer alignItems={"center"} justifyContent={"center"} wrap>
        {menu.map((item, index) => (
          <div className={menuItem} key={index}>
            <Button
              variant={isActive(item.path) ? "primary" : "default"}
              disableHover={isActive(item.path)}
              disableUppercase
              size={size}
              onClick={() => {
                if (location.pathname !== item.path) history.push(item.path);
              }}
            >
              {item.name}
            </Button>
          </div>
        ))}
      </FlexContainer>
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  menuItem: {
    margin: 8,
  },
}));

import React from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { formatDateTime } from "../../utils/date";
import Space from "../components/space";
import { formatDateSlash } from "../../utils/date";

const styles = StyleSheet.create({
  container: {
    fontFamily: "Arial Narrow",
    width: "100%",
    // flexDirection: "row",
    // justifyContent: "space-between",
    // alignItems: "flex-end",
  },
  textNormal: {
    fontSize: 7,
  },
  textBold: {
    fontSize: 7,
    fontWeight: "bold",
  },
  textItalic: {
    fontSize: 7,
    fontStyle: "italic",
  },
  FHEContainer: {
    width: 134,
    padding: 2,
    fontSize: 10,
    textAlign: "center",
    // fontWeight: 'bold',
    borderStyle: "solid",
    borderWidth: 1,
  },
  thickBorder: {
    borderStyle: "solid",
    borderWidth: 1,
  },
  lightBorderBottom: {
    borderBottomStyle: "solid",
    borderBottomWidth: 0.5,
  },
  lightBorderLeft: {
    borderLeftStyle: "solid",
    borderLeftWidth: 0.5,
  },
});

const FHEMark = () => (
  <View
    style={{
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <Text style={{ fontFamily: "Arial", fontSize: 8 }}>X</Text>
  </View>
);

export default function Footer({ data }) {
  const date = new Date();

  return (
    <View>
      <View style={styles.container}>
        {/* <View style={{ width: "100%" }}>
          {data.isFreeHigh ? (
            <View style={styles.FHEContainer}>
              <Text style={{ fontWeight: "bold" }}>FREE HIGHER EDUCATION</Text>
              <Text
                style={[
                  { textAlign: "center", fontSize: 8, letterSpacing: 3.2 },
                ]}
              >
                Republic Act 10931
              </Text>
            </View>
          ) : null}
        </View>
        <View style={{ width: "100%" }}>
          <Text style={[styles.textItalic, { textAlign: "right" }]}>
            *This is a system generated document. No signature required.
          </Text>
          <Text
            style={[styles.textNormal, { textAlign: "right", fontSize: 6 }]}
          >
            Date Generated: {date.toDateString()}
          </Text>
        </View> */}

        <Text style={[styles.textItalic, { marginBottom: 4 }]}>
          Note: Assessment is subject to review and change without prior notice.
        </Text>
        <View style={[styles.thickBorder]}>
          <View
            style={[
              styles.lightBorderBottom,
              {
                padding: 4,
                flexDirection: "row",
              },
            ]}
          >
            <View style={{ width: "35%" }}>
              <Text style={styles.textNormal}>
                Present Address:{" "}
                <Text style={styles.textItalic}>
                  If Employed, name & address of employer
                </Text>
              </Text>
            </View>
            <View style={{ width: "65%" }}>
              <Text style={styles.textNormal}>{data.presentAddress}</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "80%" }}>
              <View style={[styles.lightBorderBottom, { padding: 4 }]}>
                <Text style={styles.textItalic}>
                  Subject added in this program without prior approval and
                  verification from the office of the Registrar will not be
                  given credit.
                </Text>
              </View>
              <View style={{ padding: 4 }}>
                <Text style={[styles.textBold, { marginBottom: 4 }]}>
                  STUDENT'S PLEDGE:
                </Text>
                <Text style={[styles.textItalic, { textIndent: 16 }]}>
                  In consideration of my admission to the UNIVERSITY OF
                  SOUTHEASTERN PHILIPPINES and of the privileges of a student in
                  this institution. I hereby promise and pledge to abide by and
                  comply with all the rules and regulations laid down by
                  competent authorities in the University and in the College or
                  School in which I enrolled.
                </Text>
              </View>
              <View style={{ padding: 4 }}>
                <Text
                  style={[styles.textBold, { marginBottom: 4, fontSize: 8 }]}
                >
                  DATA PRIVACY CONSENT:
                </Text>
                <Text
                  style={[styles.textItalic, { textIndent: 16, fontSize: 8 }]}
                >
                  I have read the University of Southeastern Philippines' Data
                  Privacy Statement and hereby allow the University to collect,
                  use, process and store my personal information through its
                  official channels for legitimate purposes
                </Text>
                <Text
                  style={[styles.textItalic, { textIndent: 16, fontSize: 8 }]}
                >
                  I affirm my fundamental right to privacy and my constitutional
                  data privacy rights as stated in the Republic Act No. 10173 of
                  the Philippines. This consent is hereby given on the guarantee
                  that these rights shall be upheld at all times.
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.lightBorderBottom,
                styles.lightBorderLeft,
                {
                  width: "20%",
                },
              ]}
            >
              <View
                style={[
                  styles.lightBorderBottom,
                  {
                    paddingTop: 30,
                    paddingBottom: 2,
                  },
                ]}
              >
                <Text style={[styles.textNormal, { textAlign: "center" }]}>
                  STUDENT PORTAL
                </Text>
              </View>
              <View
                style={[
                  styles.lightBorderBottom,
                  {
                    paddingTop: 2,
                    paddingBottom: 10,
                  },
                ]}
              >
                <Text style={[styles.textItalic, { textAlign: "center" }]}>
                  Computer Operator
                </Text>
              </View>
              <View
                style={{
                  paddingTop: 10,
                }}
              >
                <Text style={[styles.textBold, { textAlign: "center" }]}>
                  Date of Registration: {formatDateSlash(data.regDate)}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              padding: 4,
              flexDirection: "row",
            }}
          >
            <View>
              <Image src={data.barcode} style={{ height: 40, width: 120 }} />
            </View>
            <View>
              <View
                style={{
                  flexDirection: "column",
                  width: 100,
                  left: 170,
                  top: 20,
                }}
              >
                <View style={[styles.lightBorderBottom, { width: 100 }]}></View>
                <Text style={[styles.textNormal, { textAlign: "center" }]}>
                  Signature
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Space vertical space={8} />
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <View style={[styles.thickBorder, { width: "80%", padding: 8 }]}>
            <Text
              style={{
                textAlign: "center",
                fontSize: 8,
                fontWeight: "bold",
                marginBottom: 4,
              }}
            >
              OPT OUT WAIVER
            </Text>
            <Text style={{ textIndent: 16, fontSize: 8 }}>
              I hereby certify that I am fully cognizant of Republic Act 10931
              (Universal Access to Quality Tertiary Education Act of 2017) and
              all the privileges and responsibilities accorded and mandated to
              me under this Act. However, I chose to voluntarily opt out of the
              free higher education provision. Thus, I hereby waive all my
              benefits for the ______ Semester of School Year ______ for the
              reason that I am capable of paying for my tuition and other
              related school fees.
            </Text>
            <View style={{ flexDirection: "row", justifyContent: "center" }}>
              <View>
                <View
                  style={{
                    flexDirection: "column",
                    width: 100,
                    marginTop: 12,
                    // marginBottom: 4,
                  }}
                >
                  <View
                    style={[styles.lightBorderBottom, { width: 100 }]}
                  ></View>
                  <Text style={[styles.textNormal, { textAlign: "center" }]}>
                    Signature
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <Space vertical space={8} />
          <View>
            <View
              style={[
                {
                  width: "80%",
                  flexDirection: "row",
                  justifyContent: "space-around",
                },
              ]}
            >
              <View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    width: 200,
                  }}
                >
                  <View
                    style={[
                      styles.thickBorder,
                      { width: 20, height: 20, marginBottom: 4 },
                    ]}
                  >
                    {data.isFreeHigh && <FHEMark />}
                  </View>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    NON-PAYING STUDENT
                  </Text>
                </View>
              </View>
              <View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    width: 200,
                  }}
                >
                  <View
                    style={[
                      styles.thickBorder,
                      { width: 20, height: 20, marginBottom: 4 },
                    ]}
                  >
                    {!data.isFreeHigh && <FHEMark />}
                  </View>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    PAYING STUDENT
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}


export const isDashboardLocation = (pathname, exact) => {
    const paths = ['/dashboard', '/dashboard/']
    if (exact) {
        for (let item of paths) {
            if (pathname === item) return true
        }
    }
    else return pathname.startsWith('/dashboard')
}

export const isProfileLocation = (pathname, exact) => {
    const paths = ['/profile', '/profile/']
    if (exact) {
        for (let item of paths) {
            if (pathname === item) return true
        }
    }
    else return pathname.startsWith('/profile')
}
import React from "react";
import clsx from "clsx";

export default function FlexItem({
  children,
  order,
  grow,
  shrink,
  basis,
  flex,
  alignSelf,
  width,
  height,
  visibility,
  classNames = [],
  style,
  marginBottom,
}) {
  return (
    <div
      className={clsx(...classNames)}
      style={{
        width,
        height,
        visibility,
        marginBottom,
        order: order || "unset",
        flexGrow: grow || "unset",
        flexShrink: shrink || "unset",
        flexBasis: basis || "unset",
        flex: flex || "unset",
        alignSelf: alignSelf || "unset",
        ...style,
      }}
    >
      {children}
    </div>
  );
}

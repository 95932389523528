import { createMuiTheme } from "@material-ui/core";

export const DefaultTheme = {
  palette: {
    default: {
      // main: '#575757',
      main: "#575757",
      gradient: "linear-gradient(277.38deg, #E5E5E5 0%, #F5F5F5 100%)",
    },
    secondary: {
      main: "#E59C24",
    },
    primary: {
      main: "#973939",
      gradient: "linear-gradient(275.52deg, #973939 0.28%, #DC7171 100%)",
    },
    error: {
      main: "#EB3A3A",
      gradient: "linear-gradient(91.62deg, #F06363 0.09%, #B51313 99.88%)",
    },
    warning: {
      main: "#FF9F00",
      gradient: "linear-gradient(90.06deg, #FFC464 0%, #FF9F00 100%)",
    },
    success: {
      main: "#29CC0E",
      gradient: "linear-gradient(275.52deg, #1A9F04 0.28%, #29CC0E 100%)",
    },
    grey: {
      shade1: "#707070",
      shade2: "#AAAAAA",
      shade3: "#CFCFCF",
      shade4: "#E5E5E5",
      shade5: "#F5F5F5",
      gradient1: "linear-gradient(277.38deg, #AAAAAA 0%, #EEEEEE 100%)",
      gradient2: "linear-gradient(277.38deg, #707070 0%, #CFCFCF 100%)",
      gradient3: "linear-gradient(277.38deg, #575757 0%, #AAAAAA 100%)",
      gradient4: "linear-gradient(277.38deg, #656565 0%, #8F8F8F 100%)",
      gradient5: "linear-gradient(277.38deg, #474747 0%, #707070 100%)",
    },
    background: "#FFFFFF",
    buttonOverlay:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ",
  },
  shape: {
    borderRadiusSmall: 8,
    borderRadiusNormal: 16,
  },
  typography: {
    fontFamily: '"Quicksand", sans-serif',
    body: {
      fontSize: "1rem",
    },
  },
  transitions: {
    main: ".5s ease-in-out",
    faster: ".3s ease-in-out",
    fastest: ".1s ease-in-out",
  },
};

export const LightTheme = createMuiTheme({ ...DefaultTheme });

export const DarkTheme = createMuiTheme({
  ...DefaultTheme,
  palette: {
    ...DefaultTheme.palette,
    default: {
      main: "#FFFFFF",
      gradient: "linear-gradient(277.38deg, #1C1C1C 0%, #373737 100%)",
      // gradient: 'linear-gradient(277.38deg, #373737 0%, #575757 100%)',
    },
    primary: {
      main: "#d75d5f",
      gradient: "linear-gradient(275.52deg, #973939 0.28%, #DC7171 100%)",
    },
    grey: {
      shade1: "#CFCFCF",
      // shade1: '#E5E5E5',
      shade2: "#AAAAAA",
      // shade2: '#CFCFCF',
      shade3: "#707070",
      // shade3: '#AAAAAA',
      shade4: "#575757",
      // shade4: '#707070',
      shade5: "#373737",
      // shade5: '#575757',
      gradient1: "linear-gradient(277.38deg, #373737 0%, #575757 100%)",
      // gradient1: 'linear-gradient(277.38deg, #474747 0%, #707070 100%)',
      gradient2: "linear-gradient(277.38deg, #575757 0%, #707070 100%)",
      // gradient2: 'linear-gradient(277.38deg, #575757 0%, #AAAAAA 100%)',
      gradient3: "linear-gradient(277.38deg, #707070 0%, #AAAAAA 100%)",
      // gradient3: 'linear-gradient(277.38deg, #707070 0%, #CFCFCF 100%)',
      gradient4: "linear-gradient(277.38deg, #AAAAAA 0%, #CFCFCF 100%)",
      // gradient4: 'linear-gradient(277.38deg, #AAAAAA 0%, #EEEEEE 100%)',
      gradient5: "linear-gradient(277.38deg, #CFCFCF 0%, #FFFFFF 100%)",
      // gradient5: 'linear-gradient(277.38deg, #CFCFCF 0%, #FFFFFF 100%)',
    },
    // background: '#121212',
    background: "#242526",
    // background: '#242526',
    // background: '#000',
    buttonOverlay:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), ",
  },
});

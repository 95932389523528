import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { isDarkTheme } from "../../utils/appSetting";
import { useBreakpointDown } from "../../utils/hooks/mediaQuery";
// import DataPrivacyVector from "../../static/media/img/SecureDataPana.png";
import DataPrivacyVector from "../../static/media/img/SecureDataStudents.png";
import Space from "../core/Space";
import Button from "../core/button/Button";
import FlexContainer from "../core/containers/flex/FlexContainer";
import Typography from "../core/typography/Typography";
import { Link } from "@material-ui/core";
import { CONFIG } from "../../AppConfig";

export default function DataPrivacyDialog() {
  const isDownXS = useBreakpointDown("xs");
  const styles = style();

  const [openDataPrivacyDialog, setOpenDataPrivacyDialog] = useState(true);
  const onClose = () => setOpenDataPrivacyDialog(false);

  return (
    <Dialog
      open={openDataPrivacyDialog}
      keepMounted
      fullScreen={isDownXS}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        paper: styles.paper,
      }}
    >
      <div className={styles.root}>
        <div className={styles.body}>
          <FlexContainer
            alignItems={"center"}
            justifyContent={"center"}
            classNames={[styles.container]}
          >
            <div>
              <img
                src={DataPrivacyVector}
                alt={"Data Privacy Policy of USeP"}
                className={styles.img}
              />
              <Space vertical space={4} />
              <Typography align={"center"}>
                By continuing to use the <strong> Student Portal</strong>, you
                agree to the&nbsp;
                <Link target={"_blank"} href={CONFIG.URL_USeP_Data_Privacy}>
                  <strong>
                    University of Southeastern Philippines’ Data Privacy
                    Statement
                  </strong>
                </Link>
                .
              </Typography>
              <Space vertical space={5} />
              <FlexContainer justifyContent={"center"}>
                <Button variant={"primary"} size={"medium"} onClick={onClose}>
                  CONTINUE
                </Button>
              </FlexContainer>
            </div>
          </FlexContainer>
        </div>
        <div className={styles.footer} />
      </div>
    </Dialog>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  body: {
    width: 400,
    minHeight: 0,
    padding: theme.spacing(8),
    background: theme.palette.background,
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 64px)",
      minHeight: "calc(100vh - 79px)",
      padding: theme.spacing(4),
    },
  },
  footer: {
    height: 15,
    width: "100%",
    background: theme.palette.primary.gradient,
  },
  container: {
    minHeight: 0,
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 79px)",
    },
  },
  img: {
    width: "100%",
  },
  paper: {
    boxShadow: isDarkTheme() ? `0px 0px 16px ${theme.palette.grey.shade3}` : "",
  },
}));

import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Banner from "../static/media/img/banner.jpg";
import Header from "./Header";
import Container from "@material-ui/core/Container";
import Footer from "./Footer";
import HomeSubRoutes from "../routes/homeSub";

export default function HomeSubLayout() {
  const styles = style();
  return (
    <>
      <Header />
      <div className={styles.bannerImage} />
      <Container fixed>
        <HomeSubRoutes />
        <Footer />
      </Container>
    </>
  );
}

const style = makeStyles((theme) => ({
  bannerImage: {
    height: 70,
    background: `linear-gradient(0deg, rgba(151, 57, 57, 0.1), rgba(151, 57, 57, 0.1)), url(${Banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

import React from "react";
import globalStyle from "../../../static/styles";
import clsx from "clsx";

export default function Click({ children, onClick }) {
  const globalStyles = globalStyle();
  return (
    <span
      className={clsx(
        globalStyles.textNoSelect,
        onClick && globalStyles.pointerCursor
      )}
      onClick={onClick}
    >
      {children}
    </span>
  );
}

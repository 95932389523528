import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import closeLine from "@iconify/icons-ri/close-line";
import IconClick from "../icons/IconClick";
import FlexContainer from "./flex/FlexContainer";
import Typography from "../typography/Typography";
import Click from "../link/Click";
import { BounceTopClass } from "../animation/attention/bounceTop/bounceTop";
import clsx from "clsx";
import { isFunction } from "../../../utils/misc";
import useTheme from "@material-ui/core/styles/useTheme";

export default function NoticeContainer({
  variant,
  type,
  height,
  text,
  onClose,
  children,
  animationClass,
  align,
  padding,
  border,
  noAnimation,
}) {
  const theme = useTheme();
  const styles = style();

  return (
    <div
      className={clsx(
        styles.root,
        !noAnimation && animationClass
          ? isFunction(animationClass) && animationClass()
          : BounceTopClass()
      )}
      style={{
        borderColor: theme.palette[variant].main,
        height: height && `calc(${height} - 80px)`,
        background: border
          ? theme.palette.background
          : theme.palette.grey.shade5,
        padding: theme.spacing(padding || 5),
        borderWidth: border ? 1 : 0,
      }}
    >
      {onClose && (
        <div className={styles.closeButton}>
          <Typography color={"grey2"}>
            <Click onClick={onClose}>
              <IconClick height={20} icon={closeLine} />
            </Click>
          </Typography>
        </div>
      )}

      <FlexContainer
        direction={"column"}
        alignItems={align === "left" ? "flex-start" : "center"}
      >
        <Typography align={align || "center"}>
          <Typography
            color={variant}
            weight={"bold"}
            transform={"uppercase"}
            span
          >
            {type ? type : "Notice"}!
          </Typography>{" "}
          {text}
        </Typography>
        <div>{children}</div>
      </FlexContainer>
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadiusSmall,
    borderStyle: "solid",
  },
  closeButton: {
    marginTop: "-28px",
    marginRight: "-28px",
    float: "right",
    height: 0,
  },
}));

export function NoticeContainerCustom({ children }) {
  const styles = customStyle();
  return <div className={styles.root}> {children} </div>;
}

const customStyle = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadiusSmall,
    background: theme.palette.grey.shade5,
    padding: theme.spacing(3),
  },
}));

import React from "react";
import { useSelector } from "react-redux";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import Typography from "../../../components/core/typography/Typography";
import Space from "../../../components/core/Space";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlexItem from "../../../components/core/containers/flex/FlexItem";

export default function DashboardGeneralInfo() {
  const styles = style();
  const {
    campus,
    college,
    program,
    major,
    yearLevel,
    curriculum,
    lastRegAYTerm,
  } = useSelector((state) => ({
    campus: state.AUTH.campus,
    college: state.AUTH.college,
    program: state.AUTH.program,
    major: state.AUTH.major,
    yearLevel: state.AUTH.yearLevel,
    curriculum: state.AUTH.curriculum,
    lastRegAYTerm: state.AUTH.lastRegAYTerm,
  }));

  const generalInformation = [
    {
      label: "Campus",
      value: campus,
    },
    {
      label: "College",
      value: college,
    },
    {
      label: "Academic Program",
      value: program,
    },
    {
      label: "Major Study",
      value: major || "None",
    },
    {
      label: "Year Level",
      value: yearLevel,
    },
    {
      label: "Curriculum",
      value: curriculum,
    },
    {
      label: "Term",
      value: lastRegAYTerm,
    },
  ];
  return (
    <div>
      <Typography variant={"h5"} weight={"semibold"}>
        General Information
      </Typography>
      <Space vertical space={3} />
      <GridContainer spacing={2}>
        {generalInformation.map((item, index) => (
          <Grid key={index} item xs={12}>
            <FlexContainer
              justifyContent={"start"}
              classNames={[styles.genInfoItem]}
            >
              <FlexItem classNames={[styles.infoLabel]}>
                <Typography variant={"caption"}>{item.label}</Typography>
              </FlexItem>
              <Space space={1} />
              <FlexItem classNames={[styles.infoValue]}>
                <Typography weight={"semibold"}>{item.value}</Typography>
              </FlexItem>
            </FlexContainer>
          </Grid>
        ))}
      </GridContainer>
    </div>
  );
}

const style = makeStyles((theme) => ({
  genInfoItem: {
    // marginBottom: 8
  },
  infoLabel: {
    width: "30%",
  },
  infoValue: {
    width: "calc(70% - 24px)",
    marginTop: "-2px",
  },
}));

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isFunction } from "../../../../utils/misc";
import clsx from "clsx";

export default function FlexContainer({
  id,
  children,
  display,
  direction,
  wrap,
  justifyContent,
  alignItems,
  alignContent,
  height,
  width,
  onClick,
  overflow,
  classNames = [],
}) {
  const styles = style();
  return (
    <div
      id={id}
      className={clsx(onClick && styles.hover, ...classNames)}
      style={{
        height: height || "100%",
        width: width || "100%",
        display: display || "flex",
        flexDirection: direction || "unset",
        flexWrap: wrap === true ? "wrap" : wrap ? wrap : "unset",
        justifyContent: justifyContent || "unset",
        alignItems: alignItems || "unset",
        alignContent: alignContent || "unset",
        overflow: overflow || "unset",
      }}
      onClick={() => {
        if (isFunction(onClick)) onClick();
      }}
    >
      {children}
    </div>
  );
}

const style = makeStyles((theme) => ({
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

import React, { Fragment } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import { isFunction } from "../../../utils/misc";
import Typography from "../typography/Typography";

export default function RadioButtonNative({
  ref,
  id,
  name,
  checked,
  value,
  disabled,
  onChange,
  label,
  key,
}) {
  const theme = useTheme();
  generateCustomCSS(theme, disabled);
  return (
    <Fragment>
      <label
        className="radio-container"
        onClick={!disabled && isFunction(onChange) && onChange}
        key={key}
      >
        <Typography>{label}</Typography>
        <input
          ref={ref}
          type="radio"
          defaultChecked={checked}
          id={id}
          name={name}
          value={value}
        />
        <span className="radio-checkmark" />
      </label>
    </Fragment>
  );
}

const generateCustomCSS = (theme, disabled) => {
  let style = document.createElement("style");
  style.innerHTML = `
      .radio-container {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default radio button */
      .radio-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      /* Create a custom radio button */
      .radio-checkmark {
        position: absolute;
        top: -2;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: ${theme.palette.grey.shade5};
        border-radius: 50%;
      }
      
      /* On mouse-over, add a grey background color */
      .radio-container:hover input ~ .radio-checkmark {
        background-color: ${
          disabled ? theme.palette.grey.shade5 : theme.palette.grey.shade4
        };
      }
      
      /* When the radio button is checked, add a blue background */
      .radio-container input:checked ~ .radio-checkmark {
        background: ${theme.palette.primary.gradient};
      }
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .radio-checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the indicator (dot/circle) when checked */
      .radio-container input:checked ~ .radio-checkmark:after {
        display: block;
      }
      
      /* Style the indicator (dot/circle) */
      .radio-container .radio-checkmark:after {
          top: 8px;
          left: 8px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: white;
      }
   `;
  document.getElementsByTagName("head")[0].appendChild(style);
};

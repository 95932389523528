import React, { memo } from "react";
import useTheme from "@material-ui/core/styles/useTheme";

const Space = ({ space, vertical, customSpace }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        height: vertical
          ? customSpace
            ? customSpace
            : theme.spacing(space)
          : 0,
        width: !vertical
          ? customSpace
            ? customSpace
            : theme.spacing(space)
          : 0,
      }}
    />
  );
};

export default memo(Space);

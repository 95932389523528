import React, { memo, useEffect, useState } from "react";
import ReactHelmet from "../../../components/core/Helmet";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import Typography from "../../../components/core/typography/Typography";
import Button from "../../../components/core/button/Button";
import Space from "../../../components/core/Space";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import LineBreak from "../../../components/core/LineBreak";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import { useSelector } from "react-redux";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import arrowLeftLine from "@iconify/icons-ri/arrow-left-line";
import sendPlaneLine from "@iconify/icons-ri/send-plane-line";
import NoticeContainer from "../../../components/core/containers/NoticeContainer";
import MessageDialog from "../../../components/core/dialogs/MessageDialog";
import globalStyle from "../../../static/styles";
import Input from "../../../components/core/input/Input";
import {
  getEvaluationStatus,
  getFacultyEvaluationForm,
  getStudentSatisfactionForm,
  getCourseEvaluationForm,
  saveStudentSatisfaction,
  saveCourseEvaluation,
} from "../../../redux/actions/universityServicesEvaluation";
import RadioButtonNative from "../../../components/core/input/RadioButtonNative";
import LoadingComponents from "../../../components/core/loader/LoadingComponents";
import ErrorLoad from "../../../components/custom/ErrorLoad";
import { setContext, toggle } from "../../../redux/actions/context";
import { isEmptyObject, isObjectEquivalent } from "../../../utils/misc";
import checkboxCircleLine from "@iconify/icons-ri/checkbox-circle-line";
import clsx from "clsx";
import Card from "../../../components/custom/card/Card";
import { unstable_next } from "scheduler";

export default function FacultyEvaluation() {
  const style = makeStyles((theme) => ({
    root: {},
    gridContainer: {
      width: "calc(100% + 16px)",
    },
    genInfoItem: {
      marginBottom: 8,
    },
    infoLabel: {
      width: "30%",
    },
    infoValue: {
      width: "calc(70% - 16px)",
      marginTop: "-2px",
    },
    statItem: {
      marginBottom: theme.spacing(2),
    },
    headItem: {
      marginBottom: theme.spacing(3),
    },
    firstRowGridItem: {
      marginBottom: theme.spacing(3),
    },
    stickyTop: {
      position: "-webkit-sticky",
      width: "100%",
      marginLeft: "-8px",
      paddingLeft: 8,
      paddingRight: 8,
      top: 0,
      background: theme.palette.background,
      zIndex: 2,
      "&": {
        position: "sticky",
      },
    },
    headingItem: {
      marginBottom: theme.spacing(1.5),
    },
  }));

  const {
    root,
    infoLabel,
    infoValue,
    headItem,
    gridContainer,
    firstRowGridItem,
  } = style();
  const { stickyTop } = globalStyle();
  const history = useHistory();
  const isDownSM = useBreakpointDown("sm");

  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [openSubmitSuccess, setOpenSubmitSuccess] = useState(false);
  const [openSubmitError, setOpenSubmitError] = useState(false);
  const [openRequiredFieldsError, setOpenRequiredFieldsError] = useState(false);
  const [errors, setErrors] = useState({});

  const {
    courseEvaluationFormData: { radioAnswers, paramQuestionAnswers },
    courseEvaluationFormData,
  } = useSelector((state) => state.CONTEXT);
  const {
    program,
    yearLevel,
    college,
    studentID,
    campusID,
    lastRegTermID,
  } = useSelector((state) => state.AUTH);
  const {
    univServicesEvaluationIsGettingEvaluationForm,
    univServicesEvaluationErrorToGetEvaluationForm,
    univServicesEvaluationIsSavingEvaluationForm,
    hasCompletedCourseEvaluation,
  } = useSelector((state) => state.CONTEXT.toggles);
  const { courseEvaluationForm } = useSelector(
    (state) => state.UNIV_SERVICES_EVALUATION
  );

  useEffect(() => {
    if (isEmptyObject(courseEvaluationForm)) getCourseEvaluationForm();
  }, [courseEvaluationForm]);

  useEffect(() => {
    if (hasCompletedCourseEvaluation) goBack();
  }, []);

  const details = [
    {
      label: "Course / Program",
      value: program,
    },
    {
      label: "Year Level",
      value: yearLevel,
    },
    {
      label: "College",
      value: college,
    },
  ];

  const collectAnswers = () => {
    const radioAnswers = [];
    const paramQuestionAnswers = [];
    if (!courseEvaluationForm.parameters) return;
    for (const parameter of courseEvaluationForm.parameters) {
      for (const statement of parameter.statements) {
        for (const rating of statement.ratings) {
          const element = document.getElementById(
            `radio-${statement.id}-${rating.id}`
          );
          if (element && element.checked) {
            radioAnswers.push({
              statementID: statement.id,
              ratingID: rating.id,
              statementSortOrder: statement.sortOrder,
            });
          }
        }
      }
      for (const paramQuestion of parameter.parameterQuestions) {
        const element = document.getElementById(
          `course-evaluation-paramQuestion-${paramQuestion.id}`
        );
        if (element && element.value) {
          paramQuestionAnswers.push({
            paramQID: paramQuestion.id,
            answer: element.value,
          });
        }
      }
    }
    return { radioAnswers, paramQuestionAnswers };
  };
  const isFormValid = (radioAnswers, paramQuestionAnswers ) => {
    let errors = {};
    for (const parameter of courseEvaluationForm.parameters) { 
      for (const statement of parameter.statements) {
        let contains = false;
        for (const answer of radioAnswers)
          if (statement.id === answer.statementID) contains = true;
        if (!contains) {
          errors = {
            ...errors,
            [statement.id]: "Required field",
          };
        }
      }

      for (const paramQuest of parameter.parameterQuestions) {
        let containsParamQuest = false;
        for (const answer of paramQuestionAnswers)
          if (paramQuest.id === answer.paramQID) containsParamQuest = true;
        if (!containsParamQuest) {
          errors = {
            ...errors,
            [paramQuest.id]: "Required field",
          };
        }
      }

    }
    const isValid = isEmptyObject(errors);
    return { isValid, errors };
  };
  const validate = () => {
    const { radioAnswers, paramQuestionAnswers } = collectAnswers();
    const { isValid, errors } = isFormValid(radioAnswers, paramQuestionAnswers);
    if (isValid) setOpenSubmitConfirmation(true);
    else setOpenRequiredFieldsError(true);
    unstable_next(() => {
      setErrors(errors);
      setContext("courseEvaluationFormData", {
        radioAnswers,
        paramQuestionAnswers,
      });
    });
  };
  const onConfirm = () => {
    setOpenSubmitConfirmation(false);
    unstable_next(() => {
      onSubmit();
    });
  };
  const resubmit = () => {
    setOpenSubmitError(false);
    unstable_next(() => {
      onSubmit();
    });
  };
  const onSubmit = () => {
    const data = {
      studentID,
      campusID,
      termID: lastRegTermID,
      answers: radioAnswers,
      paramQAnswers: paramQuestionAnswers,
    };
    const onSuccess = () => {
      goBack();
      setOpenSubmitSuccess(true);
      // toggle(hasCompletedStudentSatisfaction, true)
      unstable_next(() => {
        getEvaluationStatus();
        setContext("courseEvaluationFormData", {
          radioAnswers: [],
          paramQuestionAnswers: [],
        });
      });
    };
    const onFail = () => {
      setOpenSubmitError(true);
    };
    saveCourseEvaluation(data, onSuccess, onFail);
  };
  const ratingIsChecked = (statemenID, ratingID) => {
    for (const answer of radioAnswers)
      if (answer.statementID === statemenID && answer.ratingID === ratingID)
        return true;
    return false;
  };
  const getParamQuestionDefaultValue = (id) => {
    for (const answer of paramQuestionAnswers)
      if (answer.paramQID === id) return answer.answer;
    return null;
  };
  const closeConfirmation = () => setOpenSubmitConfirmation(false);
  const closeError = () => setOpenSubmitError(false);
  const closeRequiredFieldsError = () => setOpenRequiredFieldsError(false);
  const goBack = () => history.goBack();

  const Parameter = memo(({ courseEvaluationForm }) => (
    <>
      {courseEvaluationForm.parameters
        ? courseEvaluationForm.parameters.map((parameter) => (
            <div key={parameter.id}>
              <Space vertical space={4} />
              <Typography variant={"h6"} weight={"medium"}>
                {parameter.sortOrderA}. {parameter.name}
              </Typography>
              {parameter.statements &&
                parameter.statements.map((statement) => (
                  <div key={statement.id}>
                    <Statement statement={statement} />
                  </div>
                ))}
              <Space vertical space={4} />
              {parameter.parameterQuestions &&
                parameter.parameterQuestions.map((parameterQuestion) => (
                  <div key={parameterQuestion.id}>
                    <ParameterQuestions parameterQuestion={parameterQuestion} />
                    <Space vertical space={4} />
                  </div>
                ))}
            </div>
          ))
        : null}
    </>
  ));
  const Statement = memo(({ statement }) => (
    <>
      <Space vertical space={2} />
      <Card
        paddingCustom={"32px 32px 24px 32px"}
        variant={"error"}
        error={errors[statement.id]}
        noHover
      >
        <Typography>
          {statement.sortOrder}. {statement.statement}
          <Typography
            size={12}
            color={"error"}
            weight={"semibold"}
            style={"italic"}
            span
          >
            &nbsp; REQUIRED
          </Typography>
        </Typography>
        <Space vertical space={2} />
        <GridContainer spacing={1}>
          {statement.ratings.map((rating) => (
            <Grid item key={rating.id} xs={12} sm={6} lg={3}>
              <Rating statement={statement} rating={rating} />
            </Grid>
          ))}
        </GridContainer>
      </Card>
    </>
  ));
  const Rating = memo(({ statement, rating }) => (
    <>
      {rating.isActive ? (
        <RadioButtonNative
          checked={ratingIsChecked(statement.id, rating.id)}
          id={`radio-${statement.id}-${rating.id}`}
          name={`radio-${statement.id}`}
          label={rating.description}
        />
      ) : null}
    </>
  ));
  const ParameterQuestions = memo(({ parameterQuestion }) => (
    <>
      <Card
        paddingCustom={"32px 32px 24px 32px"}
        variant={"error"}
        error={errors[parameterQuestion.id]}
        noHover
        >
        <Typography >
          {parameterQuestion.question}
          <Typography
              size={12}
              color={"error"}
              weight={"semibold"}
              style={"italic"}
              span
            >
              &nbsp; REQUIRED
          </Typography>
        </Typography>
        <Space vertical space={2} />
        <Input
          id={`course-evaluation-paramQuestion-${parameterQuestion.id}`}
          defaultValue={getParamQuestionDefaultValue(parameterQuestion.id)}
          type={"textarea"}
          placeholder={parameterQuestion.parameterPlaceHolder !== null ? parameterQuestion.parameterPlaceHolder: ''}
        />
      </Card>
    </>
  ));
  const EvaluationForm = memo((props) => {
    return (
      <GridContainer spacing={isDownSM ? 1 : 6}>
        <Grid item md={4} lg={3} className={clsx(firstRowGridItem)}>
          <div className={isDownSM ? null : stickyTop} style={{ top: 140 }}>
            <div>
              <FlexContainer
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant={"h5"} weight={"semibold"}>
                  Information
                </Typography>
              </FlexContainer>
              <Space vertical space={3} />
              <Grid container spacing={2} className={gridContainer}>
                {details.map((item) => (
                  <Grid key={item.label} item xs={12}>
                    <div>
                      <FlexContainer justifyContent={"start"}>
                        <FlexItem classNames={[infoLabel]}>
                          <Typography variant={"caption"}>
                            {item.label}
                          </Typography>
                        </FlexItem>
                        <Space space={1} />
                        <FlexItem classNames={[infoValue]}>
                          <Typography weight={"semibold"}>
                            {item.value}
                          </Typography>
                        </FlexItem>
                      </FlexContainer>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
            <div>
              <Space vertical space={4} />
              <NoticeContainer
                type={"Caution"}
                align={"justify"}
                variant={"warning"}
                padding={4}
                noAnimation
                text={
                  <span>
                    Entered data will be lost when you leave/refresh the page
                    without submitting.
                  </span>
                }
              ></NoticeContainer>
            </div>
          </div>
        </Grid>
        <Grid item md={8} lg={9} className={firstRowGridItem}>
          <div>
            <FlexContainer
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant={"h5"} weight={"semibold"}>
                Evaluation Form
              </Typography>
            </FlexContainer>
            <Space vertical space={3} />
            <NoticeContainer
              type={"Instruction"}
              align={"justify"}
              variant={"warning"}
              padding={4}
              noAnimation
              text={
                <span>
                  Please answer the evaluation form honestly! Rate the level of
                  your satisfaction on the identified areas. Items with
                  <Typography
                    size={12}
                    color={"error"}
                    weight={"semibold"}
                    style={"italic"}
                    span
                  >
                    {" "}
                    &nbsp;REQUIRED&nbsp;{" "}
                  </Typography>
                  badges are required fields. The evaluation form cannot be
                  submitted unless all required fields are answered. Once done
                  in answering the form, click/tap the <strong>
                    {" "}
                    Submit{" "}
                  </strong>{" "}
                  button on the top-right corner of the page to submit the
                  evaluation.
                </span>
              }
            >
              {" "}
            </NoticeContainer>
            <Parameter courseEvaluationForm={courseEvaluationForm} />
          </div>
        </Grid>
      </GridContainer>
    );
  }, isObjectEquivalent);

  return (
    <div className={root}>
      <ReactHelmet
        title={"Course Evaluation | University Services Evaluation"}
      />
      {isDownSM ? (
        <>
          <Space vertical space={3} />
          <FlexItem classNames={[headItem]}>
            <Typography variant={"h4"} align={"left"} weight={"medium"}>
              Course Evaluation 
            </Typography>
          </FlexItem>
          <div className={stickyTop}>
            <Space vertical space={1} />
            <FlexContainer
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FlexItem classNames={[headItem]} width={"100%"}>
                <FlexContainer
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <FlexContainer
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Button icon={arrowLeftLine} onClick={goBack}>
                      Back
                    </Button>
                    <Button
                      variant={"primary"}
                      icon={sendPlaneLine}
                      onClick={validate}
                      isLoading={univServicesEvaluationIsSavingEvaluationForm}
                    >
                      Submit
                    </Button>
                  </FlexContainer>
                </FlexContainer>
              </FlexItem>
            </FlexContainer>
          </div>
          <Space vertical space={1} />
          <LineBreak />
        </>
      ) : (
        <>
          <div className={stickyTop}>
            <Space vertical space={3} />
            <Space vertical space={1} />
            <FlexContainer
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FlexItem classNames={[headItem]}>
                <Typography variant={"h4"} weight={"medium"}>
                  Course Evaluation
                </Typography>
              </FlexItem>
              <FlexItem classNames={[headItem]}>
                <FlexContainer alignItems={"center"}>
                  <FlexContainer alignItems={"center"}>
                    <Space space={3} />
                    <Button icon={arrowLeftLine} onClick={goBack}>
                      Back
                    </Button>
                    <FlexItem>
                      <FlexContainer alignItems={"center"}>
                        <Space space={3} />
                        <Button
                          variant={"primary"}
                          icon={sendPlaneLine}
                          onClick={validate}
                          isLoading={
                            univServicesEvaluationIsSavingEvaluationForm
                          }
                        >
                          Submit
                        </Button>
                      </FlexContainer>
                    </FlexItem>
                  </FlexContainer>{" "}
                  :
                </FlexContainer>
              </FlexItem>
            </FlexContainer>
            <Space vertical space={1} />
            <LineBreak />
          </div>
        </>
      )}
      <Space vertical space={4} />
      {univServicesEvaluationIsGettingEvaluationForm ? (
        <FlexContainer direction={"column"} alignItems={"center"}>
          <LoadingComponents mini label={"Loading Evaluation Form"} />
          <Space vertical space={4} />
        </FlexContainer>
      ) : univServicesEvaluationErrorToGetEvaluationForm ? (
        <ErrorLoad
          reload={() => {
            if (isEmptyObject(courseEvaluationForm))
              getFacultyEvaluationForm();
          }}
        />
      ) : (
        <EvaluationForm
          courseEvaluationFormData={courseEvaluationFormData}
        />
      )}
      <MessageDialog
        open={openSubmitConfirmation}
        variant={"warning"}
        title={"Submit Confirmation"}
        description={
          <Typography span>
            Upon confirmation, your evaluation form will be submitted. Once
            done, you can <strong> NO LONGER </strong> update this evaluation.
          </Typography>
        }
        onClose={closeConfirmation}
        onConfirm={onConfirm}
      />
      <MessageDialog
        open={openSubmitSuccess}
        variant={"success"}
        title={"Evaluation Submitted"}
        singleAction
        icon={checkboxCircleLine}
        onConfirmLabel={"Go Back"}
        description={
          "Your evaluation form has been submitted successfully. " +
          "We appreciate your time and effort taking the evaluation. Thank you!"
        }
        onClose={goBack}
        onConfirm={goBack}
      />
      <MessageDialog
        open={openSubmitError}
        variant={"error"}
        title={"Submission Failed"}
        onCloseLabel={"Close"}
        onConfirmLabel={"Resubmit"}
        description={
          "Your evaluation form was not submitted successfully. " +
          "Please try again!"
        }
        onClose={closeError}
        onConfirm={resubmit}
      />
      <MessageDialog
        open={openRequiredFieldsError}
        variant={"error"}
        title={"Required Field"}
        onConfirmLabel={"Go Back"}
        singleAction
        description={
          "Some required fields were left unfilled. " +
          "Please answer them and submit the form again."
        }
        onClose={closeRequiredFieldsError}
        onConfirm={closeRequiredFieldsError}
      />
    </div>
  );
}

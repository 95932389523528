import mailSendLine from "@iconify/icons-ri/mail-send-line";
import userLine from "@iconify/icons-ri/user-line";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { createPopper } from "@popperjs/core";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CONFIG } from "../AppConfig";
import FlexContainer from "../components/core/containers/flex/FlexContainer";
import FlexItem from "../components/core/containers/flex/FlexItem";
import IconClick from "../components/core/icons/IconClick";
import LineBreak from "../components/core/LineBreak";
import Link from "../components/core/Link";
import Click from "../components/core/link/Click";
import Popper from "../components/core/popper/Popper";
import Space from "../components/core/Space";
import { TransitionSlideUp } from "../components/core/transition/slide";
import Typography from "../components/core/typography/Typography";
import { routes } from "../routes";
import USePLogo from "../static/media/img/USePLogo.png";
import { useBreakpointDown } from "../utils/hooks/mediaQuery";
import NotificationsDropdown from "./dropdown/NotificationsDropdown";
import ProfileDropdown from "./dropdown/ProfileDropdown";

function Header() {
  const styles = style();
  const history = useHistory();

  const { campus } = useSelector((state) => ({ campus: state.AUTH.campus }));
  const mailto = `mailto:${
    CONFIG.CAMPUS_CREDENTIALS[campus]?.concernsEmail ||
    "portal-obrero@usep.edu.ph"
  }`;

  const isDownSM = useBreakpointDown("sm");
  const isDownXS = useBreakpointDown("xs");

  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const toggleShowProfileDropdown = () => {
    setShowProfileDropdown((showProfileDropdown) => !showProfileDropdown);
  };

  const [showNotificationsDropdown, setShowNotificationsDropdown] = useState(
    false
  );
  const toggleShowNotificationsDropdown = () =>
    setShowNotificationsDropdown(!showNotificationsDropdown);

  useEffect(() => {
    const profileDropdownButton = document.querySelector(
      "#profile-dropdown-button"
    );
    const profileDropdownPanel = document.querySelector(
      "#profile-dropdown-panel"
    );
    if (profileDropdownButton && profileDropdownPanel) {
      createPopper(profileDropdownButton, profileDropdownPanel, {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [11, 20],
            },
          },
        ],
      });
    }
  });

  useEffect(() => {
    const notificationsDropdownButton = document.querySelector(
      "#notifications-dropdown-button"
    );
    const notificationsDropdownPanel = document.querySelector(
      "#notifications-dropdown-panel"
    );
    if (notificationsDropdownButton && notificationsDropdownPanel) {
      createPopper(notificationsDropdownButton, notificationsDropdownPanel, {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [29, 20],
            },
          },
        ],
      });
    }
  });

  const closeProfileDropdown = () => {
    setShowProfileDropdown(false);
  };

  const closeNotificationsDropdown = () => {
    setShowNotificationsDropdown(false);
  };

  return (
    <div className={styles.root}>
      <Container fixed>
        <FlexContainer
          height={60}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <FlexItem>
            <Click onClick={() => history.push(routes.dashboard)}>
              <FlexContainer alignItems={"center"}>
                <img src={USePLogo} alt={"USeP Logo"} height={36} />
                {!isDownXS && (
                  <FlexItem>
                    <FlexContainer alignItems={"center"}>
                      <Space space={2} />
                      <LineBreak vertical length={32} />
                      <Space space={2} />
                      <FlexContainer direction={"column"}>
                        <FlexContainer classNames={[styles.brandingText]}>
                          <Typography
                            size={18}
                            color={"secondary"}
                            weight={"semibold"}
                          >
                            One{" "}
                            <Typography
                              size={18}
                              color={"primary"}
                              weight={"semibold"}
                              span
                            >
                              Data.{" "}
                            </Typography>
                            One{" "}
                            <Typography
                              size={18}
                              color={"primary"}
                              weight={"semibold"}
                              span
                            >
                              USeP.{" "}
                            </Typography>
                          </Typography>
                        </FlexContainer>
                        <Typography size={14} weight={"semibold"}>
                          Student Portal
                        </Typography>
                      </FlexContainer>
                    </FlexContainer>
                  </FlexItem>
                )}
              </FlexContainer>
            </Click>
          </FlexItem>
          <FlexItem>
            <FlexContainer alignItems={"center"}>
              {/*<Click onClick={toggleDarkMode}>*/}
              {/*    <IconClick icon={isDark ? moonCloudyLine : sunCloudyLine}*/}
              {/*               title={`Change to ${isDark ? 'Light' : 'Dark'} mode`}/>*/}
              {/*</Click>*/}
              {/*<Space space={1}/>*/}
              {/*<Click onClick={toggleShowNotificationsDropdown}>*/}
              {/*    <IconClickBadge id={'notifications-dropdown-button'} icon={notification4Line}*/}
              {/*                    badgeContent={1}*/}
              {/*                    isToggled={false}/>*/}
              {/*</Click>*/}
              <Space space={1} />
              <Click onClick={toggleShowProfileDropdown}>
                <IconClick id={"profile-dropdown-button"} icon={userLine} />
              </Click>
              <Space space={2} />
              <LineBreak vertical length={24} />
              <Space space={2} />
              <Click onClick={() => {}}>
                <Link
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  href={mailto}
                >
                  <FlexContainer alignItems={"center"}>
                    <IconClick icon={mailSendLine} disableHover />
                    <Space vertical={isDownSM} space={1} />
                    {!isDownSM && (
                      <Typography weight={"medium"}>Message Us</Typography>
                    )}
                  </FlexContainer>
                </Link>
              </Click>
            </FlexContainer>
          </FlexItem>
        </FlexContainer>
      </Container>

      {!isDownSM ? (
        <Fragment>
          <Popper
            id={"profile-dropdown-panel"}
            open={showProfileDropdown}
            onClose={closeProfileDropdown}
          >
            <ProfileDropdown
              open={showProfileDropdown}
              onClose={closeProfileDropdown}
            />
          </Popper>
          <Popper
            id={"notifications-dropdown-panel"}
            open={showNotificationsDropdown}
            onClose={closeNotificationsDropdown}
          >
            <NotificationsDropdown
              open={showNotificationsDropdown}
              onClose={closeNotificationsDropdown}
            />
          </Popper>
        </Fragment>
      ) : (
        <Fragment>
          <Dialog
            open={showProfileDropdown}
            TransitionComponent={TransitionSlideUp}
            keepMounted
            fullScreen
            onClose={closeProfileDropdown}
          >
            <ProfileDropdown
              open={showProfileDropdown}
              onClose={closeProfileDropdown}
            />
          </Dialog>
          <Dialog
            open={showNotificationsDropdown}
            TransitionComponent={TransitionSlideUp}
            keepMounted
            fullScreen
            onClose={closeNotificationsDropdown}
          >
            <NotificationsDropdown
              open={showNotificationsDropdown}
              onClose={closeNotificationsDropdown}
            />
          </Dialog>
        </Fragment>
      )}
    </div>
  );
}

export default Header;

const style = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: theme.palette.background,
    position: "sticky",
    top: 0,
    zIndex: 10,
  },
  brandingText: {
    marginTop: "-2px",
  },
}));

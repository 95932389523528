import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FlexContainer from "../../core/containers/flex/FlexContainer";
import Menu from "../menu/Menu";
import Space from "../../core/Space";
import { useLocation } from "react-router";
import { isDashboardLocation, isProfileLocation } from "../../../utils/context";
import Typography from "../../core/typography/Typography";
import { useSelector } from "react-redux";
import { isDarkTheme } from "../../../utils/appSetting";
import MenuScroll from "../menu/MenuScroll";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import SlideInForwardCenter from "../../core/animation/entrance/slideInForwardCenter/slideInForwardCenter";
import LineBreak from "../../core/LineBreak";
import { routes } from "../../../routes";
import USePLogo from "../../../static/media/img/USePLogo.png";
import Icon from "@iconify/react";
import phoneCameraFill from "@iconify/icons-ri/phone-camera-fill";
import { toggle } from "../../../redux/actions/context";
import UsePEagle from "../../../static/media/img/banner.jpg";

export default function Banner() {
  const location = useLocation();
  const isDashboard = isDashboardLocation(location.pathname);
  const isProfile = isProfileLocation(location.pathname);
  const styles = style();
  const {
    studentID,
    name,
    program,
    major,
    profilePicture,
    officialEmail,
  } = useSelector((state) => ({
    studentID: state.AUTH.studentID,
    name: state.AUTH.name,
    program: state.AUTH.program,
    major: state.AUTH.major,
    profilePicture: state.AUTH.profilePicture,
    officialEmail: state.AUTH.officialEmail,
  }));
  const isDark = isDarkTheme();
  const isDownMD = useBreakpointDown("md");
  const isDownSM = useBreakpointDown("sm");

  const openUpdateProfile = () => {
    toggle("profileOpenUpdateProfilePic", true);
  };

  const menu = [
    {
      name: "Profile",
      path: routes.profile,
    },
    {
      name: "Grades",
      path: routes.grades,
    },
    {
      name: "Program Evaluation",
      path: routes.programEvaluation,
    },
    {
      name: "Statistics",
      path: routes.dashboard,
    },
    {
      name: "Enrollment",
      path: routes.enrollment,
    },
    {
      name: "Accountabilities",
      path: routes.accountabilities,
    },
    {
      name: "University Services Evaluation",
      path: routes.universityServicesEvaluation,
    },
  ];

  return (
    <div className={styles.root}>
      <div
        className={styles.bannerImage}
        style={{
          height: isDashboard || isProfile ? 200 : 70,
        }}
      />
      <Container>
        <FlexContainer
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {isDashboard || isProfile ? (
            <FlexContainer
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <SlideInForwardCenter>
                <img
                  src={profilePicture || USePLogo}
                  alt={"Profile"}
                  className={styles.avatar}
                  style={{
                    height: isDashboard || isProfile ? 125 : 80,
                    width: isDashboard || isProfile ? 125 : 80,
                    marginTop: isDashboard || isProfile ? "-93px" : "-52px",
                  }}
                />
                {/* {isProfile && (
                  <div
                    className={styles.changeProfilePicIcon}
                    onClick={openUpdateProfile}
                  >
                    <div className={styles.profileButtonWrapper}>
                      <Icon icon={phoneCameraFill} height={24} />
                    </div>
                  </div>
                )} */}
              </SlideInForwardCenter>
              <Space vertical space={3} />
              <Typography
                variant={"h6"}
                color={"primary"}
                weight={"semibold"}
                align={"center"}
              >
                {studentID}
              </Typography>
              {officialEmail && (
                <>
                  <Space vertical space={1} />
                  <Typography
                    variant={"h6"}
                    weight={"semibold"}
                    align={"center"}
                  >
                    {officialEmail}
                  </Typography>
                </>
              )}
              <Space vertical space={1} />
              <Typography size={24} weight={"semibold"} align={"center"}>
                {name?.toUpperCase()}
              </Typography>
              <Space vertical space={0.5} />
              <Typography variant={"h6"} weight={"medium"} align={"center"}>
                {program}
              </Typography>
              {major && (
                <>
                  <Space vertical space={0.5} />
                  <Typography variant={"h6"} weight={"medium"} align={"center"}>
                    major in {major}
                  </Typography>
                </>
              )}
              <Space vertical space={4} />
            </FlexContainer>
          ) : (
            <Space vertical space={4} />
          )}
        </FlexContainer>
      </Container>
      {isDownMD ? (
        <MenuScroll rootClassName={styles.menuRoot} menu={menu} />
      ) : (
        <Menu rootClassName={styles.menuRoot} menu={menu} />
      )}
      <Container fixed>
        <Space vertical space={isDashboard || isProfile ? 4 : 3} />
        {!isDownSM && (
          <div>
            <LineBreak />
            <Space vertical space={4} />
          </div>
        )}
      </Container>
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  bannerImage: {
    background: `linear-gradient(0deg, rgba(151, 57, 57, 0.1), rgba(151, 57, 57, 0.1)), url(${UsePEagle})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: theme.transitions.main,
  },
  avatar: {
    borderWidth: 10,
    borderStyle: "solid",
    borderColor: theme.palette.background,
    borderRadius: "100%",
  },
  changeProfilePicIcon: {
    height: 48,
    width: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background,
    color: theme.palette.default.main,
    borderRadius: "100%",
    position: "absolute",
    top: 0,
    left: 90,
    zIndex: 2,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  profileButtonWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    "& input[type=file]": {
      position: "absolute",
      left: 0,
      top: 0,
      opacity: 0,
    },
  },
  menuRoot: {},
}));

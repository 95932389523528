import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactHelmet from "../../../components/core/Helmet";
import Space from "../../../components/core/Space";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import Typography from "../../../components/core/typography/Typography";
import Button from "../../../components/core/button/Button";
import arrowLeftLine from "@iconify/icons-ri/arrow-left-line";
import LineBreak from "../../../components/core/LineBreak";
import globalStyle from "../../../static/styles";
import { useHistory } from "react-router";
import sendPlaneLine from "@iconify/icons-ri/send-plane-line";
import NoticeContainer from "../../../components/core/containers/NoticeContainer";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import Input from "../../../components/core/input/Input";
import Select from "../../../components/core/input/Select";
import CheckBoxNative from "../../../components/core/input/CheckBoxNative";
import { saveSurveyAnswers } from "../../../redux/actions/enrollment";
import { isEmail, isMobileNumber } from "../../../utils/string";
import MessageDialog from "../../../components/core/dialogs/MessageDialog";
import checkboxCircleLine from "@iconify/icons-ri/checkbox-circle-line";
import { routes } from "../../../routes";
import { setAuthData } from "../../../redux/actions/auth";

const Body = (props) => {
  const {
    TOGGLES: { enrollmentIsEnrollmentSurvey },
    AUTH: { campusID, termID, studentID, hasAnsweredTheEnrollmentSurvey },
  } = props;

  const { stickyTop } = globalStyle();
  const history = useHistory();

  const [connectivity, setConnectivity] = useState(0);
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [openSubmitSuccess, setOpenSubmitSuccess] = useState(false);
  const [openSubmitError, setOpenSubmitError] = useState(false);
  const [errors, setErrors] = useState([]);

  const connectivityNoneID = 3;
  const connectivityItems = [
    {
      id: 1,
      label: "Full (always online)",
    },
    {
      id: 2,
      label: "Limited",
    },
    {
      id: connectivityNoneID,
      label: "None",
    },
  ];
  const deviceItems = [
    {
      id: 1,
      label: "Phone",
    },
    {
      id: 2,
      label: "Tablet",
    },
    {
      id: 3,
      label: "Laptop",
    },
    {
      id: 4,
      label: "Desktop Computer",
    },
  ];
  const internetItems = [
    {
      id: 1,
      label: "Home Internet",
    },
    {
      id: 2,
      label: "Mobile Data",
    },
    {
      id: 3,
      label: "Internet Café",
    },
  ];

  const collectAnswers = () => {
    const email = document.getElementById("survey-email").value;
    const contactNo = document.getElementById("survey-contactNo").value;

    const devices = [];
    for (const device of deviceItems) {
      const element = document.getElementById(`survey-device-${device.id}`);
      if (element && element.checked) {
        devices.push(device.id);
      }
    }
    const otherDevices = document.getElementById("survey-device-others").value;

    const internet = [];
    let otherInternet = "";
    if (connectivity && connectivity !== connectivityNoneID) {
      for (const item of internetItems) {
        const element = document.getElementById(`survey-internet-${item.id}`);
        if (element && element.checked) {
          internet.push(item.id);
        }
      }
      otherInternet = document.getElementById("survey-internet-others").value;
    }

    return {
      email,
      contactNo,
      devices,
      otherDevices,
      connectivity,
      internet,
      otherInternet,
    };
  };

  const save = () => {
    const {
      email,
      contactNo,
      devices,
      otherDevices,
      connectivity,
      internet,
      otherInternet,
    } = collectAnswers();

    const data = {
      campusID,
      termID,
      studentID,
      email,
      contactNo,
      devices,
      otherDevices,
      connectivityType: connectivity,
      internet,
      otherInternet,
    };

    const onSuccess = () => {
      setAuthData("hasAnsweredTheEnrollmentSurvey", true);
      setOpenSubmitSuccess(true);
    };
    const onFail = () => setOpenSubmitError(true);
    saveSurveyAnswers(data, onSuccess, onFail);
  };

  const validate = () => {
    setOpenSubmitConfirmation(false);
    let isValid = true;
    const { email, contactNo, connectivity } = collectAnswers();

    const error = {};

    if (!email) {
      isValid = false;
      error["email"] = "This field is required.";
    } else {
      if (!isEmail(email)) {
        isValid = false;
        error["email"] = "This is not a valid email.";
      }
    }
    if (!contactNo) {
      isValid = false;
      error["contactNumber"] = "This field is required.";
    } else {
      if (!isMobileNumber(contactNo)) {
        isValid = false;
        error["contactNumber"] = "This is not a valid contact number.";
      }
    }
    if (!connectivity) {
      isValid = false;
      error["connectivity"] = "This field is required.";
    }

    setErrors(error);

    if (isValid) save();
  };

  const goBack = () => history.goBack();
  const redirectTORegister = () =>
    history.replace(routes.enrollmentRegistrationRegister);
  const getFormError = (name) => {
    return name in errors ? errors[name] : null;
  };

  useEffect(() => {
    if (hasAnsweredTheEnrollmentSurvey) goBack();
  });

  return (
    <>
      <ReactHelmet title={"Survey | Enrollment"} />

      <Space vertical space={3} />
      <div className={stickyTop}>
        <Space vertical space={1} />
        <FlexContainer alignItems={"center"} justifyContent={"space-between"}>
          <FlexItem>
            <Typography variant={"h4"} weight={"medium"}>
              Survey
            </Typography>
          </FlexItem>
          <FlexItem>
            <FlexContainer alignItems={"center"}>
              <Button onClick={goBack} icon={arrowLeftLine}>
                Back
              </Button>
              <Space space={3} />
              <Button
                variant={"primary"}
                icon={sendPlaneLine}
                onClick={() => setOpenSubmitConfirmation(true)}
                isLoading={enrollmentIsEnrollmentSurvey}
              >
                Submit
              </Button>
            </FlexContainer>
          </FlexItem>
        </FlexContainer>
        <Space vertical space={1} />
      </div>

      <Space vertical space={3} />
      <LineBreak />
      <Space vertical space={4} />

      <NoticeContainer
        type={"Instruction"}
        align={"justify"}
        variant={"warning"}
        padding={4}
        noAnimation
        text={
          <span>
            We will be asking for your updated contact details and information
            pertaining to your devices and internet connectivity. Please answer
            them honestly! The data gathered through this survey will be handled
            in accordance with
            <strong> RA 10173 </strong> or the
            <strong> Data Privacy Act of 2012</strong>, as well as the
            <strong> Data Privacy Policy of USeP</strong>, and will be utilized
            only for the intended purpose.
          </span>
        }
      >
        {" "}
      </NoticeContainer>

      <Space vertical space={6} />

      <GridContainer spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant={"h6"} weight={"medium"}>
            Enter your active email address.
            <Typography
              size={12}
              color={"error"}
              weight={"semibold"}
              style={"italic"}
              span
            >
              &nbsp; REQUIRED
            </Typography>
          </Typography>
          <Space vertical space={1} />
          <Input id={"survey-email"} error={getFormError("email")} />
          <Space vertical space={1} />
          <Typography>ex. wsmith@email.com (no whitespaces)</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={"h6"} weight={"medium"}>
            Enter your active contact number.
            <Typography
              size={12}
              color={"error"}
              weight={"semibold"}
              style={"italic"}
              span
            >
              &nbsp; REQUIRED
            </Typography>
          </Typography>
          <Space vertical space={1} />
          <Input
            id={"survey-contactNo"}
            error={getFormError("contactNumber")}
          />
          <Space vertical space={1} />
          <Typography>ex. 09123456789</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={"h6"} weight={"medium"}>
            What device/s do you have?
            <Typography style={"italic"} span>
              &nbsp; (leave this blank if none)
            </Typography>
          </Typography>
          <Space vertical space={2} />
          <GridContainer spacing={0}>
            {deviceItems.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <CheckBoxNative
                  id={`survey-device-${item.id}`}
                  label={item.label}
                  name={item.id}
                />
              </Grid>
            ))}
          </GridContainer>
          <Space vertical space={1.5} />
          <Typography>For others, please specify.</Typography>
          <Space vertical space={1} />
          <Input id={"survey-device-others"} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={"h6"} weight={"medium"}>
            In this current situation, what type is your internet connectivity?
            <Typography
              size={12}
              color={"error"}
              weight={"semibold"}
              style={"italic"}
              span
            >
              &nbsp; REQUIRED
            </Typography>
          </Typography>
          <Space vertical space={1} />
          <Select
            value={connectivity}
            items={connectivityItems}
            error={getFormError("connectivity")}
            onSelect={(id) => {
              setConnectivity(id);
            }}
          />

          {!connectivity ? null : connectivity !== connectivityNoneID ? (
            <>
              <Space vertical space={4} />

              <Typography variant={"h6"} weight={"medium"}>
                How do you get access to the internet?
              </Typography>
              <Space vertical space={2} />
              <GridContainer spacing={0}>
                {internetItems.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <CheckBoxNative
                      id={`survey-internet-${item.id}`}
                      // checked={true}
                      label={item.label}
                    />
                  </Grid>
                ))}
              </GridContainer>
              <Space vertical space={1.5} />
              <Typography>For others, please specify.</Typography>
              <Space vertical space={1} />
              <Input id={"survey-internet-others"} />
            </>
          ) : null}
        </Grid>
      </GridContainer>

      <MessageDialog
        open={openSubmitConfirmation}
        variant={"warning"}
        title={"Submit Confirmation"}
        description={
          <Typography span>
            Upon confirmation, your answers in this survey form will be
            submitted.
          </Typography>
        }
        onClose={() => setOpenSubmitConfirmation(false)}
        onConfirm={validate}
      />

      <MessageDialog
        open={openSubmitSuccess}
        variant={"success"}
        title={"Answers Submitted"}
        singleAction
        icon={checkboxCircleLine}
        onConfirmLabel={"Go Back"}
        description={
          "Your answers in this survey form are submitted successfully. " +
          "We appreciate your time and effort taking the survey. Thank you!"
        }
        onConfirm={goBack}
      />

      <MessageDialog
        open={openSubmitError}
        variant={"error"}
        title={"Submission Failed"}
        onCloseLabel={"Close"}
        onConfirmLabel={"Resubmit"}
        description={
          "Your answers in this survey form were not submitted successfully. " +
          "Please try again!"
        }
        onClose={() => setOpenSubmitError(false)}
        onConfirm={validate}
      />
    </>
  );
};

class EnrollmentSurvey extends Component {
  render() {
    return <Body {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  AUTH: state.AUTH,
  CONTEXT: state.CONTEXT,
  TOGGLES: state.CONTEXT.toggles,
});

export default connect(mapStateToProps)(EnrollmentSurvey);

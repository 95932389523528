import {store} from "../index";


export const dispatch = (type, payload) => {
    const {dispatch} = store
    dispatch({type, payload})
}

export const getState = () => {
    const {getState} = store
    return getState()
}
import React, { useEffect } from "react";
import clsx from "clsx";
import globalStyle from "./static/styles";
import { routes, secretRoutes } from "./routes";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { setContext } from "./redux/actions/context";
import RootRoutes from "./routes/root";
import { globalActions } from "./utils/globalFunctions";
import {
  DequeueSnackBar,
  EnqueueSnackBar,
} from "./components/core/snackbar/SnackBarProvider";
import { CONFIG } from "./AppConfig";
import SystemMaintenanceMode from "./layout/system/SystemMaintenanceMode";
import LoadingPortal from "./components/core/loader/LoadingPortal";

function App() {
  const globalStyles = globalStyle();
  const history = useHistory();
  const location = useLocation();

  const {
    isAuthenticated,
    forceChangePass,
    isGettingProfile,
    messages,
  } = useSelector((state) => ({
    isAuthenticated: state.AUTH.isAuthenticated,
    forceChangePass: state.AUTH.forceChangePass,
    isGettingProfile: state.TOGGLE.profileIsGettingData,
    messages: state.MESSAGE,
  }));

  useEffect(() => {
    globalActions();
  });

  useEffect(() => {
    if (
      secretRoutes.includes(location.pathname) ||
      secretRoutes.includes(location.pathname + "/") ||
      location.pathname === "/" ||
      location.pathname === routes.login
    ) {
      if (isAuthenticated) {
        // redirect to update account if need to update
        if (forceChangePass && location.pathname !== routes.updateAccount)
          history.replace(routes.updateAccount);
        // redirects to dashboard
        if (
          location.pathname === "/" ||
          location.pathname === routes.login ||
          (!forceChangePass && location.pathname === routes.updateAccount)
        )
          history.replace("/dashboard");
      } else {
        // save previous location.pathname for redirect after login
        if (location.pathname !== "/" && location.pathname !== routes.login)
          setContext("enteredPathname", location.pathname);
        // redirects to login
        if (location.pathname !== routes.login) history.replace("/login");
        // })
      }
    }
  });
  const openLoadingPortal = () => {
    // return true;
    return isGettingProfile;
  };

  return (
    <div className={clsx(globalStyles.root, globalStyles.app)}>
      {CONFIG.SYSTEM_MAINTENANCE_MODE ? (
        <SystemMaintenanceMode />
      ) : openLoadingPortal() ? (
        <LoadingPortal />
      ) : (
        <RootRoutes />
      )}

      {messages.length > 0 &&
        messages.map((item, index) => (
          <div key={index}>
            {item.action === "enqueue" ? (
              <EnqueueSnackBar
                message={item.message}
                variant={item.variant}
                persist={item.persist}
              />
            ) : (
              <DequeueSnackBar message={item.message} />
            )}
          </div>
        ))}

      {/* DIALOGS */}
      {/*<SurveyDialog />*/}
    </div>
  );
}

export default App;

import React from "react";
import {Image, StyleSheet, Text, View} from '@react-pdf/renderer'
import Space from "../components/space";

import USePLogo from '../../static/media/img/USePLogo.png'

const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    textBold: {
        fontWeight: 'bold'
    },
    textBlack: {
        fontWeight: 'heavy'
    },
    logo: {
        height: 75,
        width: 75,
    }
});

export default function Header({data}) {
    return (
        <View style={styles.headerContainer}>
            <Image
                src={USePLogo}
                style={styles.logo}
            />
            <Space vertical space={12}/>
            <Text style={{fontSize: 13, fontWeight: 'bold'}}>
                University of Southeastern Philippines
            </Text>
            <Space vertical space={4}/>
            <Text style={{fontSize: 12, fontStyle: 'italic'}}>
                {data.campus.toUpperCase()} CAMPUS
            </Text>
            <Space vertical space={24}/>
            <Text style={{fontSize: 15, fontWeight: 'bold'}}>
                REPORT OF GRADES
            </Text>
            <Space vertical space={4}/>
            <Text style={{fontSize: 11}}>
                {data.AYSemester}
            </Text>
        </View>
    )
}
import React, { Fragment, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "./index";
import LoadingComponents from "../components/core/loader/LoadingComponents";
import ProgramEvaluationFull from "../pages/menu/programEvaluation/ProgramEvaluationFull";

export default function HomeFullscreenRoutes() {
  return (
    <Fragment>
      <Suspense fallback={<LoadingComponents mini />}>
        <Switch>
          <Route
            exact
            path={routes.programEvaluationFullscreen}
            component={ProgramEvaluationFull}
          />
        </Switch>
      </Suspense>
    </Fragment>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import ReactHelmet from "../../../components/core/Helmet";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import Typography from "../../../components/core/typography/Typography";
import Space from "../../../components/core/Space";
import Grid from "@material-ui/core/Grid";
import Card from "../../../components/custom/card/Card";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import Badge from "../../../components/core/Badge";
import { useHistory } from "react-router";
import { routes } from "../../../routes";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import sortAsc from "@iconify/icons-ri/sort-asc";
import sortDesc from "@iconify/icons-ri/sort-desc";
import Icon from "@iconify/react";
import Click from "../../../components/core/link/Click";
import { useSelector } from "react-redux";
import { setContext, toggle } from "../../../redux/actions/context";
import {
  clearRegistrationDetails,
  clearValidationProcess,
  getRegistrations,
} from "../../../redux/actions/enrollment";
import SkeletonLoader from "../../../components/core/loader/SkeletonLoader";
import ErrorLoad from "../../../components/custom/ErrorLoad";
import EmptyContent from "../../../components/custom/EmptyContent";
import Button from "../../../components/core/button/Button";
import refreshLine from "@iconify/icons-ri/refresh-line";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";

export default function Enrollment() {
  const history = useHistory();
  const isDownXS = useBreakpointDown("xs");
  const {
    enrollmentIsLoadingRegistrations,
    enrollmentErrorToLoadRegistrations,
    enrollmentIsCheckingChangesOnRegAdvising,
    enrollmentIsCheckingRegAdvisingValidated,
    registrations,
    canRegister,
    regIDForAdvising,
  } = useSelector((state) => ({
    enrollmentIsLoadingRegistrations:
      state.CONTEXT.toggles.enrollmentIsLoadingRegistrations,
    enrollmentErrorToLoadRegistrations:
      state.CONTEXT.toggles.enrollmentErrorToLoadRegistrations,
    enrollmentIsCheckingChangesOnRegAdvising:
      state.CONTEXT.toggles.enrollmentIsCheckingChangesOnRegAdvising,
    enrollmentIsCheckingRegAdvisingValidated:
      state.CONTEXT.toggles.enrollmentIsCheckingRegAdvisingValidated,
    registrations: state.ENROLLMENT.registrations,
    canRegister: state.AUTH.canRegister,
    regIDForAdvising: state.CONTEXT.regIDForAdvising,
  }));
  const [isLatest, setIsLatest] = useState(true);

  useEffect(() => {
    getRegistrations();
  }, [registrations.length]);

  const toggleIsLatestRegistrations = () => {
    registrations.reverse();
    setIsLatest(!isLatest);
  };

  const viewRegistration = (item) => {
    clearValidationProcess();
    clearRegistrationDetails();
    setContext("registrationNumforView", item.regNum);
    toggle("registrationIsFHE", item.isFHE);
    toggle("registrationIsOfficiallyEnrolled", item.isOfficiallyEnrolled);
    history.push(routes.enrollmentRegistration);
  };

  return (
    <Fragment>
      <ReactHelmet title={"Enrollment"} />
      <FlexContainer justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant={"h5"} weight={"semibold"}>
          Registrations
        </Typography>
        <FlexItem>
          <FlexContainer justifyContent={"space-between"} alignItems={"center"}>
            {!isDownXS && (
              <FlexItem>
                <Click onClick={toggleIsLatestRegistrations}>
                  <FlexContainer alignItems={"center"}>
                    <Icon icon={isLatest ? sortDesc : sortAsc} height={18} />
                    <Space space={1} />
                    <Typography weight={"semibold"}>
                      {isLatest ? "Latest" : "Oldest"}
                    </Typography>
                  </FlexContainer>
                </Click>
              </FlexItem>
            )}
            <Space space={3} />
            <Button
              variant={"success"}
              icon={refreshLine}
              isLoading={enrollmentIsLoadingRegistrations}
              onClick={getRegistrations}
            >
              Refresh
            </Button>
          </FlexContainer>
        </FlexItem>
      </FlexContainer>
      <Space vertical space={3} />
      {enrollmentIsLoadingRegistrations ||
      enrollmentIsCheckingChangesOnRegAdvising ||
      enrollmentIsCheckingRegAdvisingValidated ? (
        <RegistrationsLoader />
      ) : enrollmentErrorToLoadRegistrations ? (
        <ErrorLoad reload={getRegistrations} />
      ) : registrations.length > 0 ? (
        <GridContainer spacing={2}>
          {registrations.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Card onClick={() => viewRegistration(item)} height={"100%"}>
                <FlexContainer
                  direction={"column"}
                  justifyContent={"space-between"}
                >
                  <FlexItem>
                    <Typography weight={"regular"}>
                      Reg#{" "}
                      <Typography weight={"regular"} variant={"h6"} span>
                        {item.regNum}
                      </Typography>
                    </Typography>
                    <Space vertical space={1} />
                    <Typography weight={"semibold"} variant={"h6"}>
                      {item.term} &nbsp;•&nbsp; AY {item.AY}
                    </Typography>
                    <Space vertical space={1} />
                    <Typography weight={"semibold"}>
                      {item.program}{" "}
                      {item.major && (
                        <Typography weight={"normal"} span>
                          ({item.major})
                        </Typography>
                      )}
                    </Typography>
                    <Space vertical space={1} />
                    <Typography>{item.regDate}</Typography>
                    <Space vertical space={3} />
                  </FlexItem>
                  <FlexItem>
                    <Badge
                      variant={
                        item.isOfficiallyEnrolled ? "success" : "warning"
                      }
                    >
                      {item.isOfficiallyEnrolled
                        ? "Officially Enrolled"
                        : "Pending Validation"}
                    </Badge>
                  </FlexItem>
                </FlexContainer>
              </Card>
            </Grid>
          ))}
        </GridContainer>
      ) : (
        <EmptyContent reload={getRegistrations} />
      )}
    </Fragment>
  );
}

export const getStatus = (status) => {
  const statusList = {
    "officially enrolled": "success",
    enrolled: "success",
    validated: "success",
    "pending validation": "warning",
    pending: "warning",
  };
  return status && statusList[status.toLowerCase()];
};

const RegistrationsLoader = () => {
  return (
    <div>
      <GridContainer spacing={2}>
        {[1, 2, 3].map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card variant={"light"}>
              <SkeletonLoader width={100} height={36} />
              <SkeletonLoader height={36} />
              <SkeletonLoader width={"75%"} height={36} />
              <Space vertical space={2} />
              <SkeletonLoader height={24} />
              <SkeletonLoader width={"40%"} height={24} />
              <SkeletonLoader width={"75%"} height={24} />
              <Space vertical space={2} />
              <SkeletonLoader width={80} height={40} />
            </Card>
          </Grid>
        ))}
      </GridContainer>
    </div>
  );
};

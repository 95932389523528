import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LineBreak from "../components/core/LineBreak";
import FlexContainer from "../components/core/containers/flex/FlexContainer";

import USePLogo from "../static/media/img/USePLogo.png";
import SDMDLogo from "../static/media/img/SDMDLogo.png";
import FlexItem from "../components/core/containers/flex/FlexItem";
import Space from "../components/core/Space";
import Typography from "../components/core/typography/Typography";
import { CONFIG } from "../AppConfig";
import Link from "../components/core/Link";
import Click from "../components/core/link/Click";
import { useBreakpointDown } from "../utils/hooks/mediaQuery";

export default function Footer() {
  const styles = style();
  const date = new Date();
  const year = date.getFullYear();
  const isDownSM = useBreakpointDown("sm");

  return (
    <div className={styles.root}>
      <Space vertical space={isDownSM ? 8 : 10} />
      <LineBreak />
      <FlexContainer direction={"column"} alignItems={"center"}>
        <FlexItem>
          <FlexContainer
            alignItems={"center"}
            classNames={[styles.logoContainer]}
          >
            <img src={USePLogo} alt={"USeP Logo"} className={styles.logo} />
            <Space space={isDownSM ? 0 : 0.5} />
            <img src={SDMDLogo} alt={"SDMD Logo"} className={styles.logo} />
          </FlexContainer>
        </FlexItem>
        <Space vertical space={1} />
        <Typography variant={"caption"} weight={"bold"} align={"center"}>
          University of Southeastern Philippines
          {isDownSM ? <br /> : <span> &nbsp;•&nbsp; </span>}
          Systems and Data Management Division
        </Typography>
        <Space vertical space={1} />
        <Typography variant={"caption"} align={"center"}>
          Copyright © {year}. All Rights Reserved.
          {isDownSM ? <br /> : <span> &nbsp;</span>}
          <Click>
            <Typography
              variant={"caption"}
              weight={"bold"}
              decoration={"underline"}
              span
            >
              Terms of Use
            </Typography>
          </Click>
          &nbsp; | &nbsp;
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={CONFIG.URL_USeP_Data_Privacy}
          >
            <Typography
              variant={"caption"}
              weight={"bold"}
              decoration={"underline"}
              span
            >
              Privacy Policy
            </Typography>
          </Link>
        </Typography>
        <Space vertical space={isDownSM ? 3 : 4} />
      </FlexContainer>
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {},
  logo: {
    height: 36,
    borderRadius: "100%",
    borderWidth: 8,
    borderStyle: "solid",
    borderColor: theme.palette.background,
  },
  logoContainer: {
    marginTop: "-26px",
  },
}));

export const sortByObjectKeyAsc = (list, key) => {
    return list.sort((a, b) => {
        if (a[key] < b[key])
            return -1
        if (a[key] > b[key])
            return 1
        return 0
    })
}

export const sortByObjectKeyDesc = (list, key) => {
    return list.sort((a, b) => {
        if (a[key] < b[key])
            return 1
        if (a[key] > b[key])
            return -1
        return 0
    })
}
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useBreakpointDown } from "../../../../utils/hooks/mediaQuery";

export default function Scrollbar({
  id,
  maxHeight,
  width,
  hiddenScroll,
  hidden,
  property,
  children,
  paddingRight,
}) {
  const isDownSM = useBreakpointDown("sm");
  const style = makeStyles((theme) => ({
    root: {
      "&::-webkit-scrollbar": {
        position: "absolute",
        backgroundColor: "transparent",
        width: hiddenScroll ? 0.01 : isDownSM ? 2 : 8,
      },
      "&::-webkit-scrollbar-thumb": {
        position: "absolute",
        left: 0,
        top: 0,
        borderRadius: isDownSM ? 2 : 8,
        width: isDownSM ? 2 : 8,
        background: hiddenScroll ? "transparent" : theme.palette.grey.shade3,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-corner": {
        backgroundColor: "transparent",
      },
      "&:hover": {},
    },
  }));

  const styles = style();

  return (
    <div
      style={{
        visibility: hidden ? "hidden" : "unset",
        maxHeight: maxHeight || 500,
        width: width || "100%",
        overflow: "auto",
        marginRight: 5,
        paddingRight,
      }}
      className={styles.root}
      id={id}
      property={property}
    >
      {children}
    </div>
  );
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "../typography/Typography";
import FlexContainer from "../containers/flex/FlexContainer";
import { formatDateTime } from "../../../utils/date";
import arrowUpLine from "@iconify/icons-ri/arrow-up-line";
import arrowDownLine from "@iconify/icons-ri/arrow-down-line";
import IconMini from "../icons/IconMini";
import FlexItem from "../containers/flex/FlexItem";
import { formatCurrency } from "../../../utils/currency";
import { isFunction } from "../../../utils/misc";
import SimplebarScrollbar from "../containers/scrollbars/simplebar/SimplebarScrollbar";
import Space from "../Space";
import arrowLeftSLine from "@iconify/icons-ri/arrow-left-s-line";
import arrowRightSLine from "@iconify/icons-ri/arrow-right-s-line";
import IconClick from "../icons/IconClick";
import skipForwardLine from "@iconify/icons-ri/skip-forward-line";
import skipBackLine from "@iconify/icons-ri/skip-back-line";
import globalStyle from "../../../static/styles";

export default function Table({
  columns = [],
  data = [],
  noScroll,
  scrollY,
  spacing,
  noHover,
  search,
  initSortIndex = null,
  initSortAsc = true,
  maxHeight = 500,
  emptyDataHeight = 250,
  paginate,
}) {
  const styles = style();
  const globalStyles = globalStyle();
  const rowsPerPageChoices = [5, 10, 15, 20];

  const [sortIndex, setSortIndex] = useState(initSortIndex);
  const [sortAsc, setSortAsc] = useState(initSortAsc);
  const [rowPerPage, setRowPerPage] = useState(1);
  const [paginateOffset, setPaginateOffset] = useState(1);
  const [page, setPage] = useState(1);

  const rowsPerPagePrev = () => {
    setRowPerPage(rowPerPage - 1);
    setPaginateOffset(1);
    setPage(1);
  };
  const rowsPerPageNext = () => {
    setRowPerPage(rowPerPage + 1);
    setPaginateOffset(1);
    setPage(1);
  };
  const pageSkipBackward = () => {
    setPaginateOffset(1);
    setPage(1);
  };
  const pagePrev = () => {
    setPaginateOffset(rowsPerPageChoices[rowPerPage] * (page - 2) + 1);
    setPage(page - 1);
  };
  const pageNext = () => {
    setPaginateOffset(rowsPerPageChoices[rowPerPage] * page + 1);
    setPage(page + 1);
  };
  const pageSkipForward = () => {
    let lastPage = Math.ceil(data.length / rowsPerPageChoices[rowPerPage]);
    setPaginateOffset(rowsPerPageChoices[rowPerPage] * (lastPage - 1) + 1);
    setPage(lastPage);
  };

  const canBePrevRowNum = () => {
    return rowPerPage !== 0;
  };

  const canBeNextRowNum = () => {
    return rowPerPage !== rowsPerPageChoices.length - 1;
  };

  const canBePrevPage = () => {
    return page !== 1;
  };

  const canBeNextPage = () => {
    if (data.length > rowsPerPageChoices[rowPerPage]) {
      return data.length / rowsPerPageChoices[rowPerPage] > page;
    } else {
      return false;
    }
  };

  const getFromRow = () => {
    return paginateOffset;
  };

  const getToRow = () => {
    if (paginateOffset + rowsPerPageChoices[rowPerPage] > data.length) {
      return data.length;
    } else {
      return paginateOffset + rowsPerPageChoices[rowPerPage] - 1;
    }
  };

  const getDataLength = () => {
    return data.length;
  };

  const sortItems = (sort) => {
    if (sort !== sortIndex) {
      if (!sortAsc) setSortAsc(true);
      setSortIndex(sort);
    } else {
      setSortAsc(!sortAsc);
    }
  };

  const sort = () => {
    if (columns[sortIndex].type === "datetime") {
      data = data.sort((a, b) => {
        return new Date(a[sortIndex]) - new Date(b[sortIndex]);
      });
    } else {
      data = data.sort((a, b) => {
        if (a[sortIndex] < b[sortIndex]) return -1;
        if (a[sortIndex] > b[sortIndex]) return 1;
        return 0;
      });
    }
    if (!sortAsc) reverse();
  };

  const reverse = () => (data = data.reverse());

  const filterBySearchKey = () => {
    let newData = [];
    for (let innerData of data) {
      for (let item of innerData) {
        if (
          item &&
          item.toString().toLowerCase().includes(search.toLowerCase())
        ) {
          newData.push(innerData);
          break;
        }
      }
    }
    return newData;
  };

  const getPage = () => {
    const newData = [];
    for (let i = paginateOffset - 1; i < data.length; i++) {
      if (i < paginateOffset - 1 + rowsPerPageChoices[rowPerPage]) {
        newData.push(data[i]);
      }
    }
    return newData;
  };

  if (search) data = filterBySearchKey();
  if (sortIndex !== null) sort(sortIndex);

  let newData = [];
  if (paginate) newData = getPage();
  else newData = data;

  return (
    <div className={styles.root}>
      <SimplebarScrollbar maxHeight={scrollY && maxHeight} disabled={noScroll}>
        <table className={styles.table}>
          <thead>
            <tr>
              {columns.map((item, index) => {
                if (item.hidden) return null;
                else
                  return (
                    <th
                      key={index}
                      align={item.align || "left"}
                      className={clsx(
                        styles.th,
                        globalStyles.textNoSelect,
                        item.noWrap && styles.noWrap,
                        item.noPaddingLeft && styles.noPaddingLeft,
                        item.noPaddingRight && styles.noPaddingRight,
                        item.classNameHeader
                      )}
                      style={{
                        minWidth: item.minWidth || "unset",
                        padding:
                          spacing === "condensed"
                            ? 5
                            : spacing === "enlarged"
                            ? 16
                            : 10,
                      }}
                      onClick={() => {
                        if (!columns[index].disableSort) sortItems(index);
                      }}
                    >
                      {sortIndex === index && !columns[index].disableSort ? (
                        <FlexContainer alignItems={"center"}>
                          <FlexItem>
                            <Typography weight={"semibold"}>
                              {item.label.toUpperCase()}
                            </Typography>
                          </FlexItem>
                          {sortIndex === index && !columns[index].disableSort && (
                            <FlexItem>
                              <FlexContainer alignItems={"center"}>
                                <IconMini
                                  icon={sortAsc ? arrowDownLine : arrowUpLine}
                                />
                              </FlexContainer>
                            </FlexItem>
                          )}
                        </FlexContainer>
                      ) : (
                        <Typography weight={"semibold"}>
                          {item.label.toUpperCase()}
                        </Typography>
                      )}
                    </th>
                  );
              })}
            </tr>
          </thead>
          <tbody>
            {newData.length > 0 &&
              newData.map((newDataRow, newDataRowIndex) => {
                return (
                  <tr
                    key={newDataRowIndex}
                    className={clsx(
                      !noHover && styles.hover,
                      columns[newDataRowIndex] &&
                        isFunction(columns[newDataRowIndex].onClick) &&
                        styles.linkHover
                    )}
                    onClick={() => {
                      if (
                        columns[newDataRowIndex] &&
                        isFunction(columns[newDataRowIndex].onClick)
                      )
                        columns[newDataRowIndex].onClick(newDataRow);
                    }}
                  >
                    {newDataRow.map((newDataCell, newDataCellIndex) => {
                      if (columns[newDataCellIndex].hidden) return null;
                      else
                        return (
                          <td
                            key={newDataCellIndex}
                            align={columns[newDataCellIndex].align || "left"}
                            className={clsx(
                              styles.td,
                              columns[newDataCellIndex].noWrap && styles.noWrap,
                              columns[newDataCellIndex].noPaddingLeft &&
                                styles.noPaddingLeft,
                              columns[newDataCellIndex].noPaddingRight &&
                                styles.noPaddingRight,
                              columns[newDataCellIndex].className
                            )}
                            style={{
                              padding:
                                spacing === "condensed"
                                  ? 5
                                  : spacing === "enlarged"
                                  ? 16
                                  : 10,
                              minWidth:
                                columns[newDataCellIndex].minWidth || "unset",
                            }}
                          >
                            <Typography
                              weight={columns[newDataCellIndex].weight}
                            >
                              {columns[newDataCellIndex].wrapper
                                ? columns[newDataCellIndex].wrapper(newDataCell)
                                : columns[newDataCellIndex].type === "datetime"
                                ? newDataCell && formatDateTime(newDataCell)
                                : columns[newDataCellIndex].type === "currency"
                                ? newDataCell
                                  ? formatCurrency(parseFloat(newDataCell))
                                  : "-"
                                : newDataCell}
                            </Typography>
                          </td>
                        );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {newData.length === 0 && (
          <div
            className={styles.emptyData}
            style={{
              height: emptyDataHeight,
            }}
          >
            <FlexContainer alignItems={"center"} justifyContent={"center"}>
              <Typography>No records found</Typography>
            </FlexContainer>
          </div>
        )}
      </SimplebarScrollbar>
      {paginate && (
        <div>
          <Space vertical space={2} />
          <FlexContainer justifyContent={"space-between"}>
            <FlexItem>
              <FlexContainer alignItems={"center"}>
                <Typography>Rows per page</Typography>
                <Space space={3} />
                <div>
                  <IconClick
                    icon={arrowLeftSLine}
                    onClick={canBePrevRowNum() && rowsPerPagePrev}
                    disableHover={!canBePrevRowNum()}
                    disableClick={!canBePrevRowNum()}
                  />
                </div>
                <Space space={1} />
                <Typography>{rowsPerPageChoices[rowPerPage]}</Typography>
                <Space space={1} />
                <div>
                  <IconClick
                    icon={arrowRightSLine}
                    onClick={canBeNextRowNum() && rowsPerPageNext}
                    disableHover={!canBeNextRowNum()}
                    disableClick={!canBeNextRowNum()}
                  />
                </div>
              </FlexContainer>
            </FlexItem>
            <FlexItem>
              <FlexContainer alignItems={"center"}>
                <Typography>
                  {newData.length > 0 ? `${getFromRow()} - ${getToRow()}` : "0"}
                  of {getDataLength()}
                </Typography>
                <Space space={3} />
                <div>
                  <IconClick
                    icon={skipBackLine}
                    disableHover={!canBePrevPage()}
                    disableClick={!canBePrevPage()}
                    onClick={canBePrevPage() && pageSkipBackward}
                  />
                </div>
                <div>
                  <IconClick
                    icon={arrowLeftSLine}
                    disableHover={!canBePrevPage()}
                    disableClick={!canBePrevPage()}
                    onClick={canBePrevPage() && pagePrev}
                  />
                </div>
                <div>
                  <IconClick
                    icon={arrowRightSLine}
                    disableHover={!canBeNextPage()}
                    disableClick={!canBeNextPage()}
                    onClick={canBeNextPage() && pageNext}
                  />
                </div>
                <div>
                  <IconClick
                    icon={skipForwardLine}
                    disableHover={!canBeNextPage()}
                    disableClick={!canBeNextPage()}
                    onClick={canBeNextPage() && pageSkipForward}
                  />
                </div>
              </FlexContainer>
            </FlexItem>
          </FlexContainer>
        </div>
      )}
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderStyle: "solid",
    borderColor: theme.palette.grey.shade4,
    borderWidth: "1px 0 0 0",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    background: theme.palette.background,
    overflow: "hidden",
    position: "sticky",
    top: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  td: {
    borderStyle: "solid",
    borderColor: theme.palette.grey.shade4,
    borderWidth: "0.5px 0 ",
  },
  hover: {
    "&:hover": {
      background: theme.palette.grey.shade5,
    },
  },
  linkHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
  noPaddingRight: {
    paddingRight: 0,
  },
  emptyData: {
    width: "100%",
    borderWidth: "1px 0",
    borderStyle: "solid",
    borderColor: theme.palette.grey.shade4,
  },
  selectRoot: {
    background: theme.palette.background,
  },
}));

import {
    ENROLLMENT_REGISTRATION_SET,
    ENROLLMENT_REGISTRATION_DETAILS_SET,
    ENROLLMENT_REGISTRATION_VALIDATION_PROCESS_SET,
    ENROLLMENT_REGISTRATION_SCHEDULE_SET,
    ENROLLMENT_REGISTRATION_OFFERED_COURSES_SET,
    ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_SET,
    ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_CLEAR,
    ENROLLMENT_REGISTRATION_SECTIONS_SET,
    ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_SET,
    ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_CLEAR,
    ENROLLMENT_REGISTRATION_ADDITIONAL_COURSES_SET,
    ENROLLMENT_CLEAR,
    ENROLLMENT_REGISTRATION_INFO_CLEAR,
    ENROLLMENT_REGISTRATION_BILLING_SET,
    ENROLLMENT_REGISTRATION_ADVISING_REMARKS_SET
} from "../types";

const init = {

    // registrations
    registrations: [],
    registrationDetails: {
        details: {},
        schedule: [],
        billing: []
    },
    validationProcess: [],

    // register
    blockSectionCourses: [],
    additionalCourses: [],
    offeredCourses: [],
    courseSchedules: [],
    sections: [],

    advisingRemarks: []
}

export default function (state=init, action) {
    switch (action.type) {

        case ENROLLMENT_REGISTRATION_SET:
            return {
                ...state,
                registrations: action.payload
            }

        case ENROLLMENT_REGISTRATION_INFO_CLEAR:
            return {
                ...state,
                registrationDetails: init.registrationDetails
            }

        case ENROLLMENT_REGISTRATION_DETAILS_SET:
            return {
                ...state,
                registrationDetails: {
                    ...state.registrationDetails,
                    details: action.payload
                }
            }

        case ENROLLMENT_REGISTRATION_VALIDATION_PROCESS_SET:
            return {
                ...state,
                validationProcess: action.payload
            }

        case ENROLLMENT_REGISTRATION_SCHEDULE_SET:
            return {
                ...state,
                ...state,
                registrationDetails: {
                    ...state.registrationDetails,
                    schedule: action.payload
                }
            }

        case ENROLLMENT_REGISTRATION_BILLING_SET:
            return {
                ...state,
                ...state,
                registrationDetails: {
                    ...state.registrationDetails,
                    billing: action.payload
                }
            }

        case ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_SET:
            return {
                ...state,
                blockSectionCourses: action.payload
            }

        case ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_CLEAR:
            return {
                ...state,
                blockSectionCourses: init.blockSectionCourses
            }

        case ENROLLMENT_REGISTRATION_ADDITIONAL_COURSES_SET:
            return {
                ...state,
                additionalCourses: action.payload
            }

        case ENROLLMENT_REGISTRATION_OFFERED_COURSES_SET:
            return {
                ...state,
                offeredCourses: action.payload
            }

        case ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_SET:
            return {
                ...state,
                courseSchedules: action.payload
            }

        case ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_CLEAR:
            return {
                ...state,
                courseSchedules: init.courseSchedules
            }

        case ENROLLMENT_REGISTRATION_SECTIONS_SET:
            return {
                ...state,
                sections: action.payload
            }

        case ENROLLMENT_REGISTRATION_ADVISING_REMARKS_SET:
            return {
                ...state,
                advisingRemarks: action.payload
            }

        case ENROLLMENT_CLEAR:
            return init

        default:
            return state
    }
}
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './app.css';
import * as serviceWorker from './serviceWorker';
import {persistor, store} from "./redux";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from 'redux-persist/lib/integration/react';
import ThemeProvider from "./static/styles/ThemeProvider";
import App from "./App";
import LoadingComponents from "./components/core/loader/LoadingComponents";
import SnackBarProvider from "./components/core/snackbar/SnackBarProvider";


ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={<h1>Loading</h1>} persistor={persistor}>
                <ThemeProvider>
                    <SnackBarProvider>
                        <Suspense fallback={<LoadingComponents/>}>
                            <App/>
                            {/*<LoadingComponents/>*/}
                        </Suspense>
                    </SnackBarProvider>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

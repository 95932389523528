// authentication
export const AUTH_LOG_IN = "AUTH_LOG_IN";
export const AUTH_LOG_OUT = "AUTH_LOG_OUT";
export const AUTH_SET = "AUTH_SET";
export const AUTH_RESET = "AUTH_RESET";

export const AUTH_UPDATE_PROFILE  = "AUTH_UPDATE_PROFILE ";


// app setting
export const SETTING_TOGGLE_DARK_THEME = "SETTING_TOGGLE_DARK_THEME";

// context
export const CONTEXT_SET = "CONTEXT_SET";
export const CONTEXT_TOGGLE = "CONTEXT_TOGGLE";
export const CONTEXT_FORM_SET = "CONTEXT_FORM_SET";
export const CONTEXT_RESET = "CONTEXT_RESET";

// dashboard
export const DASHBOARD_SET = "DASHBOARD_SET";
export const DASHBOARD_CLEAR = "DASHBOARD_CLEAR";

// grades
export const GRADES_SET = "GRADES_SET";
export const GRADES_SUMMARY_SET = "GRADES_SUMMARY_SET";
export const GRADES_CLEAR = "GRADES_CLEAR";

// academic servicesEvaluation
export const ACADEMIC_EVALUATION_SET = "ACADEMIC_EVALUATION_SET";
export const ACADEMIC_EVALUATION_CLEAR = "ACADEMIC_EVALUATION_CLEAR";

// enrollment
export const ENROLLMENT_REGISTRATION_SET = "ENROLLMENT_REGISTRATION_SET";
export const ENROLLMENT_REGISTRATION_INFO_CLEAR =
  "ENROLLMENT_REGISTRATION_INFO_CLEAR";
export const ENROLLMENT_REGISTRATION_DETAILS_SET =
  "ENROLLMENT_REGISTRATION_DETAILS_SET";
export const ENROLLMENT_REGISTRATION_VALIDATION_PROCESS_SET =
  "ENROLLMENT_REGISTRATION_VALIDATION_PROCESS_SET";
export const ENROLLMENT_REGISTRATION_SCHEDULE_SET =
  "ENROLLMENT_REGISTRATION_SCHEDULE_SET";
export const ENROLLMENT_REGISTRATION_BILLING_SET =
  "ENROLLMENT_REGISTRATION_BILLING_SET";
export const ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_SET =
  "ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_SET";
export const ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_CLEAR =
  "ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_CLEAR";
export const ENROLLMENT_REGISTRATION_ADDITIONAL_COURSES_SET =
  "ENROLLMENT_REGISTRATION_ADDITIONAL_COURSES_SET";
export const ENROLLMENT_REGISTRATION_OFFERED_COURSES_SET =
  "ENROLLMENT_REGISTRATION_OFFERED_COURSES_SET";
export const ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_SET =
  "ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_SET";
export const ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_CLEAR =
  "ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_CLEAR";
export const ENROLLMENT_REGISTRATION_SECTIONS_SET =
  "ENROLLMENT_REGISTRATION_SECTIONS_SET";
export const ENROLLMENT_REGISTRATION_ADVISING_REMARKS_SET =
  "ENROLLMENT_REGISTRATION_ADVISING_REMARKS_SET";
export const ENROLLMENT_CLEAR = "ENROLLMENT_CLEAR";

// accountability
export const ACCOUNTABILITY_SET = "ACCOUNTABILITY_SET";
export const ACCOUNTABILITY_CLEAR = "ACCOUNTABILITY_CLEAR";

// university services evaluation
export const UNIV_SERVICES_EVALUATION_AY_TERM_SET =
  "UNIV_SERVICES_EVALUATION_AY_TERM_SET";
export const UNIV_SERVICES_EVALUATION_FACULTY_LIST_SET =
  "UNIV_SERVICES_EVALUATION_FACULTY_LIST_SET";
export const UNIV_SERVICES_EVALUATION_FACULTY_FORM_SET =
  "UNIV_SERVICES_EVALUATION_FACULTY_FORM_SET";

export const UNIV_SERVICES_EVALUATION_SUBJECT_LIST_SET =
"UNIV_SERVICES_EVALUATION_SUBJECT_LIST_SET";
export const UNIV_SERVICES_EVALUATION_SUBJECT_FORM_SET =
  "UNIV_SERVICES_EVALUATION_SUBJECT_FORM_SET";


export const UNIV_SERVICES_EVALUATION_STUDENT_SATISFACTION_FORM_SET =
  "UNIV_SERVICES_EVALUATION_STUDENT_SATISFACTION_FORM_SET";
export const UNIV_SERVICES_EVALUATION_STUDENT_CUSTOMER_FEEDBACK_SET =
  "UNIV_SERVICES_EVALUATION_STUDENT_CUSTOMER_FEEDBACK_SET";
export const UNIV_SERVICES_EVALUATION_STUDENT_OFFICES_TO_EVALUATE_SET =
  "UNIV_SERVICES_EVALUATION_STUDENT_OFFICES_TO_EVALUATE_SET";
export const UNIV_SERVICES_EVALUATION_CLEAR = "UNIV_SERVICES_EVALUATION_CLEAR";

// ADDED - Course Evaluation
export const UNIV_SERVICES_EVALUATION_COURSE_EVALUATION_FORM_SET =
  "UNIV_SERVICES_EVALUATION_COURSE_EVALUATION_FORM_SET";


  

// messages
export const MESSAGE_ADD = "MESSAGE_ADD";
export const MESSAGE_REMOVE = "MESSAGE_REMOVE";
export const MESSAGE_CLEAR = "MESSAGE_CLEAR";

// TOGGLE
export const TOGGLE = "TOGGLE";
export const TOGGLE_SET = "TOGGLE_SET";
export const TOGGLE_RESET = "TOGGLE_RESET";

export default {
  // authentication
  AUTH_LOG_IN: "AUTH_LOG_IN",
  AUTH_LOG_OUT: "AUTH_LOG_OUT",
  AUTH_SET: "AUTH_SET",
  AUTH_RESET: "AUTH_RESET",
  
  AUTH_UPDATE_PROFILE: "AUTH_UPDATE_PROFILE ",

  // app setting
  SETTING_TOGGLE_DARK_THEME: "SETTING_TOGGLE_DARK_THEME",

  // context
  CONTEXT_SET: "CONTEXT_SET",
  CONTEXT_TOGGLE: "CONTEXT_TOGGLE",
  CONTEXT_FORM_SET: "CONTEXT_FORM_SET",
  CONTEXT_RESET: "CONTEXT_RESET",

  // dashboard
  DASHBOARD_SET: "DASHBOARD_SET",
  DASHBOARD_CLEAR: "DASHBOARD_CLEAR",

  // grades
  GRADES_SET: "GRADES_SET",
  GRADES_SUMMARY_SET: "GRADES_SUMMARY_SET",
  GRADES_CLEAR: "GRADES_CLEAR",

  // academic servicesEvaluation
  ACADEMIC_EVALUATION_SET: "ACADEMIC_EVALUATION_SET",
  ACADEMIC_EVALUATION_CLEAR: "ACADEMIC_EVALUATION_CLEAR",

  // enrollment
  ENROLLMENT_REGISTRATION_SET: "ENROLLMENT_REGISTRATION_SET",
  ENROLLMENT_REGISTRATION_INFO_CLEAR: "ENROLLMENT_REGISTRATION_INFO_CLEAR",
  ENROLLMENT_REGISTRATION_DETAILS_SET: "ENROLLMENT_REGISTRATION_DETAILS_SET",
  ENROLLMENT_REGISTRATION_VALIDATION_PROCESS_SET:
    "ENROLLMENT_REGISTRATION_VALIDATION_PROCESS_SET",
  ENROLLMENT_REGISTRATION_SCHEDULE_SET: "ENROLLMENT_REGISTRATION_SCHEDULE_SET",
  ENROLLMENT_REGISTRATION_BILLING_SET: "ENROLLMENT_REGISTRATION_BILLING_SET",
  ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_SET:
    "ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_SET",
  ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_CLEAR:
    "ENROLLMENT_REGISTRATION_BLOCK_SECTION_COURSES_CLEAR",
  ENROLLMENT_REGISTRATION_ADDITIONAL_COURSES_SET:
    "ENROLLMENT_REGISTRATION_ADDITIONAL_COURSES_SET",
  ENROLLMENT_REGISTRATION_OFFERED_COURSES_SET:
    "ENROLLMENT_REGISTRATION_OFFERED_COURSES_SET",
  ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_SET:
    "ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_SET",
  ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_CLEAR:
    "ENROLLMENT_REGISTRATION_COURSE_SCHEDULE_CLEAR",
  ENROLLMENT_REGISTRATION_SECTIONS_SET: "ENROLLMENT_REGISTRATION_SECTIONS_SET",
  ENROLLMENT_REGISTRATION_ADVISING_REMARKS_SET:
    "ENROLLMENT_REGISTRATION_ADVISING_REMARKS_SET",
  ENROLLMENT_CLEAR: "ENROLLMENT_CLEAR",

  // accountability
  ACCOUNTABILITY_SET: "ACCOUNTABILITY_SET",
  ACCOUNTABILITY_CLEAR: "ACCOUNTABILITY_CLEAR",

  // university services evaluation
  UNIV_SERVICES_EVALUATION_AY_TERM_SET: "UNIV_SERVICES_EVALUATION_AY_TERM_SET",
  UNIV_SERVICES_EVALUATION_FACULTY_LIST_SET:
    "UNIV_SERVICES_EVALUATION_FACULTY_LIST_SET",
  UNIV_SERVICES_EVALUATION_FACULTY_FORM_SET:
    "UNIV_SERVICES_EVALUATION_FACULTY_FORM_SET",

  UNIV_SERVICES_EVALUATION_SUBJECT_LIST_SET:
  "UNIV_SERVICES_EVALUATION_SUBJECT_LIST_SET",
  UNIV_SERVICES_EVALUATION_SUBJECT_FORM_SET:
    "UNIV_SERVICES_EVALUATION_SUBJECT_FORM_SET", 

  UNIV_SERVICES_EVALUATION_STUDENT_SATISFACTION_FORM_SET:
    "UNIV_SERVICES_EVALUATION_STUDENT_SATISFACTION_FORM_SET",
  UNIV_SERVICES_EVALUATION_STUDENT_CUSTOMER_FEEDBACK_SET:
    "UNIV_SERVICES_EVALUATION_STUDENT_CUSTOMER_FEEDBACK_SET",
  UNIV_SERVICES_EVALUATION_STUDENT_OFFICES_TO_EVALUATE_SET:
    "UNIV_SERVICES_EVALUATION_STUDENT_OFFICES_TO_EVALUATE_SET",
  UNIV_SERVICES_EVALUATION_CLEAR: "UNIV_SERVICES_EVALUATION_CLEAR",

  // ADDED - Course Evaluation
  UNIV_SERVICES_EVALUATION_COURSE_EVALUATION_FORM_SET:
    "UNIV_SERVICES_EVALUATION_COURSE_EVALUATION_FORM_SET",

  // messages
  MESSAGE_ADD: "MESSAGE_ADD",
  MESSAGE_REMOVE: "MESSAGE_REMOVE",
  MESSAGE_CLEAR: "MESSAGE_CLEAR",

  // TOGGLE
  TOGGLE: "TOGGLE",
  TOGGLE_SET: "TOGGLE_SET",
  TOGGLE_RESET: "TOGGLE_RESET",

  // FORM
  FORM_SET: "FORM_SET",
  FORM_RESET: "FORM_RESET",
};

import arrowLeftLine from "@iconify/icons-ri/arrow-left-line";
import downloadCloud2Line from "@iconify/icons-ri/download-cloud-2-line";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../../components/core/button/Button";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import FlexItem from "../../../components/core/containers/flex/FlexItem";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import NoticeContainer from "../../../components/core/containers/NoticeContainer";
import DialogContainer from "../../../components/core/dialogs/DialogContainer";
import ReactHelmet from "../../../components/core/Helmet";
import LineBreak from "../../../components/core/LineBreak";
import SkeletonLoader from "../../../components/core/loader/SkeletonLoader";
import Space from "../../../components/core/Space";
import Table from "../../../components/core/table/Table";
import Typography from "../../../components/core/typography/Typography";
import ErrorLoad from "../../../components/custom/ErrorLoad";
import CertificateOfRegistration from "../../../generatePDF/certificateOfRegistration/certificateOfRegistration";
import { GeneratePDF } from "../../../generatePDF/pdfGenerator";
import { setContext, toggle } from "../../../redux/actions/context";
import {
  getEnrollmentStatus,
  getRegisteredScheduleDetails,
  getRegistrationDetails,
} from "../../../redux/actions/enrollment";
import { routes } from "../../../routes";
import { formatCurrency } from "../../../utils/currency";
import { formatDateTime } from "../../../utils/date";
import { textToBase64Barcode } from "../../../utils/generateImageBarcode";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import print from "../../../utils/print";

export default function Registration() {
  const styles = style();
  const history = useHistory();
  const isDownSM = useBreakpointDown("sm");
  let isAssessmentValidated = false;
  let hasPaid = false;

  const [generatePDF, setGeneratePDF] = useState(false);
  const [downloadedPDF, setDownloadedPDF] = useState(false);
  const [openValidationDetails, setOpenValidationDetails] = useState(false);
  const [viewValidationData, setViewValidationData] = useState({});

  const toggleGeneratePDF = () => setGeneratePDF(!generatePDF);

  let {
    registrationNumforView,
    regIDForAdvising,
    registrationLastAdviserRemarks,
    studentID,
    name,
    status,
    isUndergrad,
    presentAddress,
    enrollmentIsLoadingRegistrationValidationProcess,
    enrollmentErrorToLoadRegistrationValidationProcess,
    enrollmentIsLoadingRegistrationDetails,
    enrollmentErrorToLoadRegistrationDetails,
    enrollmentIsLoadingRegistrationSchedules,
    enrollmentErrorToLoadRegistrationSchedules,
    registrationIsOfficiallyEnrolled,
    advisingHasChange,
    advisingIsValidated,
    enrollmentRegistrationHasFinalized,
    registrationAdvisingHasChanges,
    registrationAdvisingIsValidated,
    details,
    schedule,
    billing,
    validationProcess,
  } = useSelector((state) => ({
    registrationNumforView: state.CONTEXT.registrationNumforView,
    regIDForAdvising: state.CONTEXT.regIDForAdvising,
    registrationLastAdviserRemarks:
      state.CONTEXT.registrationLastAdviserRemarks,
    studentID: state.AUTH.studentID,
    name: state.AUTH.name,
    status: state.AUTH.status,
    isUndergrad: state.AUTH.isUndergrad,
    presentAddress: state.AUTH.presentAddress,
    enrollmentIsLoadingRegistrationValidationProcess:
      state.CONTEXT.toggles.enrollmentIsLoadingRegistrationValidationProcess,
    enrollmentErrorToLoadRegistrationValidationProcess:
      state.CONTEXT.toggles.enrollmentErrorToLoadRegistrationValidationProcess,
    enrollmentIsLoadingRegistrationDetails:
      state.CONTEXT.toggles.enrollmentIsLoadingRegistrationDetails,
    enrollmentErrorToLoadRegistrationDetails:
      state.CONTEXT.toggles.enrollmentErrorToLoadRegistrationDetails,
    enrollmentIsLoadingRegistrationSchedules:
      state.CONTEXT.toggles.enrollmentIsLoadingRegistrationSchedules,
    enrollmentErrorToLoadRegistrationSchedules:
      state.CONTEXT.toggles.enrollmentErrorToLoadRegistrationSchedules,
    registrationIsOfficiallyEnrolled:
      state.CONTEXT.toggles.registrationIsOfficiallyEnrolled,
    advisingHasChange: state.CONTEXT.toggles.advisingHasChange,
    advisingIsValidated: state.CONTEXT.toggles.advisingIsValidated,
    enrollmentRegistrationHasFinalized:
      state.CONTEXT.toggles.enrollmentRegistrationHasFinalized,
    registrationAdvisingHasChanges:
      state.CONTEXT.toggles.registrationAdvisingHasChanges,
    registrationAdvisingIsValidated:
      state.CONTEXT.toggles.registrationAdvisingIsValidated,
    details: state.ENROLLMENT.registrationDetails.details,
    schedule: state.ENROLLMENT.registrationDetails.schedule,
    billing: state.ENROLLMENT.registrationDetails.billing,
    validationProcess: state.ENROLLMENT.validationProcess,
  }));

  // registrationNumforView = 178817
  let regNumString = registrationNumforView
    ? registrationNumforView.toString().padStart(10, "0")
    : "";

  useEffect(() => {
    if (!registrationNumforView) history.replace(routes.enrollment);
  });
  useEffect(() => {
    if (validationProcess.length === 0)
      getEnrollmentStatus(registrationNumforView);
  }, [registrationNumforView, validationProcess.length]);
  useEffect(() => {
    if (Object.keys(details).length === 0 && details.constructor === Object)
      getRegistrationDetails(registrationNumforView);
  }, [details, registrationNumforView]);
  useEffect(() => {
    if (schedule.length < 1)
      getRegisteredScheduleDetails(registrationNumforView);
  }, [registrationNumforView, schedule.length]);

  const seeValidationDetails = (data) => {
    const validationDetails = {
      title: data.enrollmentStep,
      color: data.status === "successful" ? "success" : "warning",
      info: [
        {
          label: "Status",
          value: data.status.toUpperCase(),
          color: data.status === "successful" ? "success" : "warning",
        },
        {
          label: "Date Entered",
          value: formatDateTime(data.dateEntered),
        },
        {
          label: "Date Completed",
          value: formatDateTime(data.dateCompleted),
        },
        {
          label: "Date Modified",
          value: formatDateTime(data.dateModified),
        },
      ],
    };
    setViewValidationData(validationDetails);
    setOpenValidationDetails(true);
  };

  const goBack = () => {
    history.replace(routes.enrollment);
    setContext("newlySavedRegistrationID", null);
  };

  const update = () => {
    toggle("registrationHasChanges", false);
    getRegisteredScheduleDetails(regIDForAdvising);
    history.push(routes.enrollmentRegistrationRegister);
  };
  const toggleOpenValidationDetails = () =>
    setOpenValidationDetails(!openValidationDetails);

  const processingOffice = () => {
    for (let i of validationProcess) {
      if (i.status === "processing") return i.alias;
    }
    return null;
  };

  const statusList = [
    // {
    //     label: 'Registration',
    //     status: 'complete'
    // },
    // {
    //     label: 'Student Accounts Verification',
    //     status: 'complete'
    // },
    // {
    //     label: 'Payment',
    //     status: 'in-progress'
    //     // status: 'complete'
    // },
    // {
    //     label: 'Registrar Validation',
    //     status: 'pending'
    //     // status: 'complete'
    // },
    // {
    //     label: 'OSAS Scholarship Tagging',
    //     status: 'pending'
    //     // status: 'complete'
    // },
    // {
    //     label: 'Validated',
    //     status: 'pending'
    //     // status: 'complete'
    // },
  ];
  if (validationProcess.length > 0) {
    validationProcess.forEach((item) => {
      // STUDENT ACCPUNT STEP
      if (item.id === 204 && item.status === "successful")
        isAssessmentValidated = true;
      if (item.id === 280 && item.status === "successful") hasPaid = true;
      statusList.push({
        label: item.enrollmentStep,
        status: item.status,
        data: item,
      });
    });
  } else {
    if (!enrollmentIsLoadingRegistrationValidationProcess) {
      statusList.push({
        label: "Officially Enrolled",
        status: "successful",
      });
    }
  }
  const isEnrolled = () => {
    if (statusList.length === 0) return false;
    return (
      statusList[statusList.length - 1].status === "successful" &&
      statusList[statusList.length - 1].label.toLowerCase() ===
        "officially enrolled"
    );
  };

  const regDetails = [
    [
      {
        label: "Campus",
        value: details.campus || "None",
      },
      {
        label: "College",
        value: details.college || "None",
      },
      {
        label: "Academic Program",
        value: details.program || "None",
      },
    ],
    [
      {
        label: "Major Study",
        value: details.major || "None",
      },
      {
        label: "Academic Year",
        value: details.academicYear || "None",
      },
      {
        label: "Academic Term",
        value: details.academicTerm || "None",
      },
      {
        label: "Year Level",
        value: details.yearLevel || "None",
      },
      {
        label: "Scholarship",
        value: details.scholarship || "None",
      },
    ],
    [
      {
        label: "Lecture Units",
        value: `${details.lecUnits} Unit${details.lecUnits > 1 ? "s" : ""}`,
      },
      {
        label: "Laboratory Units",
        value: `${details.labUnits} Unit${details.labUnits > 1 ? "s" : ""}`,
      },
    ],
    [
      {
        label: "Date of Registration",
        value: details.registrationDate
          ? formatDateTime(details.registrationDate)
          : "-",
      },
      {
        label: "Date of Validation",
        value: details.validationDate
          ? formatDateTime(details.validationDate)
          : "-",
      },
    ],
  ];

  const schedColumns = [
    {
      label: "Code",
      align: "left",
      noWrap: true,
      weight: "semibold",
      noPaddingLeft: true,
    },
    {
      label: "Title",
      align: "left",
      minWidth: 200,
    },
    {
      label: "Lec Unit",
      align: "center",
    },
    {
      label: "Lab Unit",
      align: "center",
    },
    {
      label: "Credit Unit",
      align: "center",
    },
    {
      label: "Faculty",
      align: "left",
      hidden: !isEnrolled(),
    },
    {
      label: "Schedule",
      align: "left",
      noWrap: true,
    },
    {
      label: "Room",
      align: "left",
      noWrap: true,
    },
    {
      label: "Schedule 2",
      align: "left",
      hidden: true,
      noWrap: true,
    },
    {
      label: "Room 2",
      align: "left",
      hidden: true,
      noWrap: true,
    },
    {
      label: "Schedule 3",
      align: "left",
      hidden: true,
      noWrap: true,
    },
    {
      label: "Room 3",
      align: "left",
      hidden: true,
      noWrap: true,
    },
    {
      label: "Schedule 4",
      align: "left",
      hidden: true,
      noWrap: true,
    },
    {
      label: "Room 4",
      align: "left",
      hidden: true,
      noWrap: true,
    },
    {
      label: "Schedule 5",
      align: "left",
      hidden: true,
      noWrap: true,
    },
    {
      label: "Room 5",
      align: "left",
      hidden: true,
      noWrap: true,
      noPaddingRight: true,
    },
  ];

  const schedData = [];
  if (schedule && schedule.length > 0) {
    for (let i of schedule) {
      let faculty = i.faculty1;
      if (i.faculty2) faculty = `${faculty}, ${i.faculty2}`;
      if (i.faculty3) faculty = `${faculty}, ${i.faculty3}`;
      if (i.faculty4) faculty = `${faculty}, ${i.faculty4}`;
      if (i.faculty5) faculty = `${faculty}, ${i.faculty5}`;

      if (i.schedule2) {
        schedColumns[6].label = "Schedule 1";
        schedColumns[7].label = "Room 1";
        schedColumns[8].hidden = false;
        schedColumns[9].hidden = false;
      }

      if (i.schedule3) {
        schedColumns[10].hidden = false;
        schedColumns[11].hidden = false;
      }

      if (i.schedule4) {
        schedColumns[12].hidden = false;
        schedColumns[13].hidden = false;
      }

      if (i.schedule5) {
        schedColumns[14].hidden = false;
        schedColumns[15].hidden = false;
      }

      schedData.push([
        i.courseCode,
        i.courseTitle,
        i.lecUnits,
        i.labUnits,
        i.creditUnits,
        faculty,
        i.schedule1,
        i.room1,
        i.schedule2 || null,
        i.room2 || null,
        i.schedule3 || null,
        i.room3 || null,
        i.schedule4 || null,
        i.room4 || null,
        i.schedule5 || null,
        i.room5 || null,
      ]);
    }
  }

  const billingColumns = [
    {
      label: "Account",
      align: "left",
      weight: "semibold",
      noPaddingLeft: true,
    },
    {
      label: "Amount",
      align: "right",
      wrapper: formatCurrency,
      weight: "bold",
      noPaddingRight: true,
    },
  ];

  const billingData = [];
  if (billing.length > 0) {
    billing.forEach((item) => {
      billingData.push([item.acct, item.amount]);
    });
  }

  let reportData = {
    campus: details.campus,
    regNo: regNumString,
    regDate: details.registrationDate,
    studentID,
    gender: details.gender,
    citizenship: details.citizenship || "Filipino",
    name,
    yearLevel: details.yearLevel,
    type: details.classType,
    status: details.status,
    section: details.section,
    college: details.collegeCode,
    course: details.programCode,
    major: details.major || "None",
    AYTerm: `${details.academicYear} ${details.academicTerm}`,
    feeTemplate: details.feeTemplate || "None",
    scholarship: details.scholarship || "None",
    subjects: [],
    lecUnits: details.lecUnits || 0,
    labUnits: details.labUnits || 0,
    billing: billingData,
    isFreeHigh: details.isFreeHigh,
    presentAddress,
    barcode: textToBase64Barcode(`* ${studentID.split("").join(" ")} *`),
  };

  // reportData = {
  //     campus: 'Obrero, Davao City',
  //     regNo: '000021324',
  //     regDate: Date.now(),
  //     studentID,
  //     gender: 'male',
  //     citizenship: 'Filipino',
  //     name,
  //     yearLevel: '1st year',
  //     status: 'New',
  //     section: 'BSCS-1A',
  //     college: 'CIC',
  //     course: 'BSCS',
  //     major: 'None',
  //     AYTerm: '2021-2022',
  //     feeTemplate: 'None',
  //     scholarship: 'None',
  //     subjects: [],
  //     lecUnits: 0,
  //     labUnits: 0,
  //     billing: billingData,
  //     isFreeHigh: true,
  // }

  if (schedule && schedule.length > 0) {
    for (let i of schedule) {
      let schedRoom = [];
      if (i.schedule1)
        schedRoom.push({
          sched: i.schedule1,
          room: i.room1,
        });
      if (i.schedule2)
        schedRoom.push({
          sched: i.schedule2,
          room: i.room2,
        });
      if (i.schedule3)
        schedRoom.push({
          sched: i.schedule3,
          room: i.room3,
        });
      if (i.schedule4)
        schedRoom.push({
          sched: i.schedule4,
          room: i.room4,
        });
      if (i.schedule5)
        schedRoom.push({
          sched: i.schedule5,
          room: i.room5,
        });

      reportData.subjects.push([
        i.courseCode,
        i.courseTitle,
        i.lecUnits,
        i.labUnits,
        schedRoom,
      ]);
    }
  }

  const canGenerateReport = () => {
    print(
      "DOWNLOAD COR conditions",
      enrollmentIsLoadingRegistrationDetails,
      enrollmentErrorToLoadRegistrationDetails,
      enrollmentIsLoadingRegistrationValidationProcess,
      enrollmentErrorToLoadRegistrationValidationProcess,
      enrollmentIsLoadingRegistrationSchedules,
      enrollmentErrorToLoadRegistrationSchedules,
      // statusList.length === 0,
      // statusList[statusList.length - 1].status !== 'successful',
      !registrationIsOfficiallyEnrolled,
      !reportData.campus,
      !reportData.regNo,
      !reportData.regDate,
      !reportData.studentID,
      !reportData.gender,
      !reportData.citizenship,
      !reportData.name,

      !reportData.yearLevel,
      // !reportData.status,
      // !reportData.section,
      !reportData.college,
      !reportData.course,
      !reportData.major,
      !reportData.AYTerm,
      !reportData.feeTemplate,
      !reportData.scholarship,
      reportData.subjects.length === 0,
      reportData.billing.length === 0,
      reportData.isFreeHigh === null
    );
    return !(
      enrollmentIsLoadingRegistrationDetails ||
      enrollmentErrorToLoadRegistrationDetails ||
      enrollmentIsLoadingRegistrationValidationProcess ||
      enrollmentErrorToLoadRegistrationValidationProcess ||
      enrollmentIsLoadingRegistrationSchedules ||
      enrollmentErrorToLoadRegistrationSchedules ||
      // statusList.length === 0 ||
      // statusList[statusList.length - 1].status !== 'successful' ||
      !registrationIsOfficiallyEnrolled ||
      !reportData.campus ||
      !reportData.regNo ||
      !reportData.regDate ||
      !reportData.studentID ||
      !reportData.gender ||
      !reportData.citizenship ||
      !reportData.name ||
      !reportData.yearLevel ||
      // !reportData.status ||
      // !reportData.section ||
      !reportData.college ||
      !reportData.course ||
      !reportData.major ||
      !reportData.AYTerm ||
      !reportData.feeTemplate ||
      !reportData.scholarship ||
      reportData.subjects.length === 0 ||
      reportData.billing.length === 0 ||
      reportData.isFreeHigh === null
    );
  };

  return (
    <div className={styles.root}>
      <ReactHelmet title={"Registration | Enrollment"} />

      <Space vertical space={4} />

      {/* <PDFViewer width={'100%'} height={500}>
               <CertificateOfRegistration data={reportData}/>
            </PDFViewer> */}

      {!isDownSM ? (
        <Fragment>
          <FlexContainer alignItems={"center"} justifyContent={"space-between"}>
            <FlexItem>
              <Typography variant={"h4"} weight={"medium"}>
                Registration #
                <Typography variant={"h4"} weight={"light"} span>
                  {" "}
                  {regNumString}
                </Typography>
              </Typography>
            </FlexItem>
            <FlexItem>
              <FlexContainer alignItems={"center"}>
                <Button icon={arrowLeftLine} onClick={goBack}>
                  Back
                </Button>
                <Space space={3} />
                {generatePDF ? (
                  <GeneratePDF
                    documentComponent={
                      <CertificateOfRegistration data={reportData} />
                    }
                    filename={`certificate-of-registration-${registrationNumforView}`}
                    afterDownloaded={() => {
                      setDownloadedPDF(true);
                      setGeneratePDF(false);
                    }}
                  />
                ) : (
                  <Button
                    variant={"primary"}
                    icon={downloadedPDF ? null : downloadCloud2Line}
                    onClick={toggleGeneratePDF}
                    disabled={!canGenerateReport() || downloadedPDF}
                  >
                    <Typography color={"white"} nowrap>
                      {downloadedPDF ? "Downloaded" : "Download COR"}
                    </Typography>
                  </Button>
                )}
              </FlexContainer>
            </FlexItem>
          </FlexContainer>
          <Space vertical space={4} />
        </Fragment>
      ) : (
        <Fragment>
          <Typography variant={"h4"} weight={"medium"}>
            Registration #
            <Typography variant={"h4"} weight={"light"} span>
              {" "}
              {regNumString}
            </Typography>
          </Typography>
          <Space vertical space={3} />
          <FlexContainer alignItems={"center"} justifyContent={"space-between"}>
            <FlexItem width={"100%"}>
              <Button icon={arrowLeftLine} onClick={goBack}>
                Back
              </Button>
            </FlexItem>
            <FlexItem width={"100%"}>
              <FlexContainer alignItems={"center"} justifyContent={"flex-end"}>
                {generatePDF ? (
                  <GeneratePDF
                    documentComponent={
                      <CertificateOfRegistration data={reportData} />
                    }
                    filename={`certificate-of-registration-${registrationNumforView}`}
                    afterDownloaded={() => {
                      setDownloadedPDF(true);
                      setGeneratePDF(false);
                    }}
                  />
                ) : (
                  <Button
                    variant={"primary"}
                    icon={downloadedPDF ? null : downloadCloud2Line}
                    onClick={toggleGeneratePDF}
                    disabled={!canGenerateReport() || downloadedPDF}
                  >
                    <Typography color={"white"} nowrap>
                      {downloadedPDF ? "Downloaded" : "Download COR"}
                    </Typography>
                  </Button>
                )}
              </FlexContainer>
            </FlexItem>
          </FlexContainer>
          <Space vertical space={4} />
        </Fragment>
      )}

      <LineBreak />

      {/*{enrollmentIsLoadingRegistrationValidationProcess ||*/}
      {/*enrollmentIsLoadingRegistrationDetails ? null :*/}
      {/*    processingOffice() === 'advising' ?*/}
      {/*        registrationAdvisingIsValidated ?*/}
      {/*            <>*/}
      {/*                <Space vertical space={4}/>*/}
      {/*                <NoticeAdvisingIsValidated/>*/}
      {/*            </> :*/}
      {/*            registrationAdvisingHasChanges ?*/}
      {/*                <>*/}
      {/*                    <Space vertical space={4}/>*/}
      {/*                    <NoticeAdvisingHasChanges*/}
      {/*                        remarks={registrationLastAdviserRemarks.remarks}*/}
      {/*                        datetime={registrationLastAdviserRemarks.dateSubmitted}*/}
      {/*                    />*/}
      {/*                </> :*/}
      {/*                <>*/}
      {/*                    <Space vertical space={4}/>*/}
      {/*                    <NoticeAdvisingHasNoChanges/>*/}
      {/*                </>*/}
      {/*        : processingOffice() === 'accounts' && (!details.isFreeHigh || !isUndergrad) ?*/}
      {/*        <>*/}
      {/*            <Space vertical space={4}/>*/}
      {/*            <NoticeStudentAccountsPayingNonValidated/>*/}
      {/*        </>*/}
      {/*        : processingOffice() === 'payment' && (!details.isFreeHigh || !isUndergrad) ?*/}
      {/*            <>*/}
      {/*                <Space vertical space={4}/>*/}
      {/*                <NoticeCashierPayment/>*/}
      {/*            </> : null*/}

      {/*}*/}

      <Space vertical space={4} />

      <GridContainer spacing={4}>
        <Grid item xs={12} md={8} lg={9}>
          <div>
            <FlexContainer
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant={"h5"} weight={"semibold"}>
                Registration Details
              </Typography>
            </FlexContainer>
            <Space vertical space={3} />
            {enrollmentIsLoadingRegistrationDetails ? (
              <RegistrationDetailsLoader
                style={style}
                regDetails={regDetails}
              />
            ) : enrollmentErrorToLoadRegistrationDetails ? (
              <ErrorLoad
                reload={() => getRegistrationDetails(registrationNumforView)}
              />
            ) : (
              <Grid container spacing={2} className={styles.gridContainer}>
                {regDetails.map((detail, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    {detail.map((item, index) => (
                      <div key={index}>
                        <FlexContainer justifyContent={"start"}>
                          <FlexItem classNames={[styles.infoLabel]}>
                            <Typography variant={"caption"}>
                              {item.label}
                            </Typography>
                          </FlexItem>
                          <Space space={1} />
                          <FlexItem classNames={[styles.infoValue]}>
                            <Typography weight={"semibold"}>
                              {item.value}
                            </Typography>
                          </FlexItem>
                        </FlexContainer>
                        <Space vertical space={2} />
                      </div>
                    ))}
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <div>
            {enrollmentIsLoadingRegistrationValidationProcess ? null : registrationIsOfficiallyEnrolled ? (
              <NoticeContainer
                variant={"primary"}
                align={"justify"}
                padding={3}
                text={
                  <span>
                    You are officially enrolled. You may download a soft copy of
                    your Certificate of Registration by clicking{" "}
                    <strong>Download COR</strong>.
                  </span>
                }
              />
            ) : (
              <NoticeContainer
                variant={"primary"}
                align={"justify"}
                padding={3}
                text={
                  <span>
                    Your registration is on process. Please email the focal
                    person of your college to get an update on your
                    registration.
                  </span>
                }
              />
            )}
          </div>

          {/*<div>*/}
          {/*    <FlexContainer justifyContent={'space-between'} alignItems={'flex-start'}>*/}
          {/*        <Typography variant={'h5'} weight={'semibold'}>Validation Status Tracker</Typography>*/}
          {/*        /!*<div>*!/*/}
          {/*        /!*    <IconMini*!/*/}
          {/*        /!*        icon={refreshLine}*!/*/}
          {/*        /!*        onClick={() => getEnrollmentStatus(registrationNumforView)}*!/*/}
          {/*        /!*    />*!/*/}
          {/*        /!*</div>*!/*/}
          {/*        {enrollmentIsLoadingRegistrationValidationProcess ? null :*/}
          {/*            <div>*/}
          {/*                <IconMini*/}
          {/*                    icon={refreshLine}*/}
          {/*                    onClick={() => getEnrollmentStatus(registrationNumforView)}*/}
          {/*                >Refresh</IconMini>*/}
          {/*            </div>*/}
          {/*        }*/}
          {/*    </FlexContainer>*/}
          {/*    <Space vertical space={3}/>*/}
          {/*    {enrollmentIsLoadingRegistrationValidationProcess ? <ValidationStatusLoader/> :*/}
          {/*        enrollmentErrorToLoadRegistrationValidationProcess ?*/}
          {/*            <ErrorLoad reload={() => getEnrollmentStatus(registrationNumforView)}/> :*/}
          {/*            <FlexContainer direction={'column'} alignItems={'flex-start'}*/}
          {/*                           justifyContent={'flex-start'}>*/}
          {/*                {statusList.map((item, index) => (*/}
          {/*                    <FlexItem key={index}>*/}
          {/*                        {index ? <Space vertical space={2}/> : null}*/}
          {/*                        <Click onClick={item.status !== 'queued' ? () => {*/}
          {/*                            if (item.data) seeValidationDetails(item.data)*/}
          {/*                        } : null}>*/}
          {/*                            <FlexContainer>*/}
          {/*                                <FlexItem>*/}
          {/*                                    {item.status === 'successful' ?*/}
          {/*                                        <IconMini icon={checkLine} height={18} padding={2} filled*/}
          {/*                                                  variant={'success'}/> :*/}
          {/*                                        item.status === 'processing' ?*/}
          {/*                                            // <CircularProgress color={'secondary'} size={22}*/}
          {/*                                            //                   thickness={5}/> :*/}
          {/*                                            <IconMini icon={donutChartFill} height={18} padding={2}*/}
          {/*                                                      filled*/}
          {/*                                                      variant={'warning'}/> :*/}
          {/*                                            <IconMini icon={null} height={18} padding={2} filled/>*/}
          {/*                                    }*/}
          {/*                                </FlexItem>*/}
          {/*                                <Space space={2.5}/>*/}
          {/*                                <FlexItem classNames={[styles.statusLabel]}>*/}
          {/*                                    <Typography*/}
          {/*                                        color={*/}
          {/*                                            item.status === 'in-progress' ? 'warning' :*/}
          {/*                                                index === statusList.length - 1 && item.status === 'complete' ?*/}
          {/*                                                    'success' : null}*/}
          {/*                                        weight={*/}
          {/*                                            item.status === 'in-progress' ||*/}
          {/*                                            (index === statusList.length - 1 && item.status === 'complete') ?*/}
          {/*                                                'bold' : null}>*/}
          {/*                                        {item.label}</Typography>*/}
          {/*                                </FlexItem>*/}
          {/*                            </FlexContainer>*/}
          {/*                        </Click>*/}
          {/*                    </FlexItem>*/}
          {/*                ))}*/}
          {/*            </FlexContainer>*/}
          {/*    }*/}
          {/*    {isEnrolled() || enrollmentIsLoadingRegistrationValidationProcess ? null :*/}
          {/*        <>*/}
          {/*            <Space vertical space={3}/>*/}
          {/*            <NoticeContainer variant={'primary'} align={'justify'} padding={2}*/}
          {/*                             text={*/}
          {/*                                 <span>*/}
          {/*                                    The validation of your registration may take some time before it can proceed to another step.*/}
          {/*                                    Please come back later.*/}
          {/*                                </span>*/}
          {/*                             }*/}
          {/*            />*/}
          {/*        </>*/}
          {/*    }*/}

          {/*</div>*/}
        </Grid>
      </GridContainer>

      <Space vertical space={4} />

      <GridContainer spacing={4}>
        <Grid item xs={12} lg={8}>
          <div>
            <FlexContainer
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant={"h5"} weight={"semibold"}>
                Subject Enrolled
              </Typography>
            </FlexContainer>
            <Space vertical space={3} />
            <Table columns={schedColumns} data={schedData} initSortIndex={1} />
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div>
            <FlexContainer
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant={"h5"} weight={"semibold"}>
                Billing
              </Typography>
            </FlexContainer>
            <Space vertical space={3} />
            <Table
              columns={billingColumns}
              data={billingData}
              initSortIndex={0}
            />
            <Space vertical space={2} />
            <FlexContainer
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography weight={"semibold"}>TOTAL ASSESSMENT</Typography>
              <Typography variant={"h5"} weight={"semibold"}>
                {details.totalAssessment ? "P" : null}{" "}
                {formatCurrency(details.totalAssessment)}
              </Typography>
            </FlexContainer>
          </div>
        </Grid>
      </GridContainer>

      {/*<ContactAdviser/>*/}

      {viewValidationData.title ? (
        <DialogContainer
          open={openValidationDetails}
          variant={viewValidationData.color}
          onClose={toggleOpenValidationDetails}
        >
          <Typography variant={"h5"} weight={"semibold"}>
            {viewValidationData.title}
          </Typography>
          <Space vertical space={4} />
          <GridContainer spacing={2}>
            {viewValidationData.info.map((item, index) => (
              <Grid item key={index} xs={12}>
                <FlexContainer justifyContent={"start"}>
                  <FlexItem classNames={[styles.infoLabel]}>
                    <Typography variant={"caption"}>{item.label}</Typography>
                  </FlexItem>
                  <Space space={1} />
                  <FlexItem classNames={[styles.infoValue]}>
                    <Typography weight={"semibold"} color={item.color}>
                      {item.value}
                    </Typography>
                  </FlexItem>
                </FlexContainer>
              </Grid>
            ))}
          </GridContainer>
          <Space vertical space={5} />
          <Button fullwidth onClick={toggleOpenValidationDetails}>
            Close
          </Button>
        </DialogContainer>
      ) : null}
    </div>
  );
}

const ValidationStatusLoader = () => {
  return (
    <div>
      {[0, 1, 2, 3, 4].map((key) => (
        <div key={key}>
          {key ? <Space vertical space={2} /> : null}
          <FlexContainer alignItems={"center"}>
            <SkeletonLoader width={24} height={24} />
            <Space space={2.5} />
            <SkeletonLoader width={"100%"} height={24} />
          </FlexContainer>
        </div>
      ))}
    </div>
  );
};

const RegistrationDetailsLoader = ({ regDetails }) => {
  const styles = style();
  return (
    <Grid container spacing={2} className={styles.gridContainer}>
      {regDetails.map((detail, index) => (
        <Grid key={index} item xs={12} md={6}>
          {detail.map((item, index) => (
            <div key={index}>
              <FlexContainer justifyContent={"start"}>
                <FlexItem classNames={[styles.infoLabel]}>
                  <SkeletonLoader width={"90%"} height={24} />
                </FlexItem>
                <Space space={1} />
                <FlexItem classNames={[styles.infoValue]}>
                  <SkeletonLoader height={24} />
                </FlexItem>
              </FlexContainer>
              <Space vertical space={2} />
            </div>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

const style = makeStyles((theme) => ({
  root: {},
  gridContainer: {
    width: "calc(100% + 16px)",
  },
  infoLabel: {
    width: "35%",
  },
  infoValue: {
    width: "calc(65% - 16px)",
    margin: "-2px",
  },
  statusLabel: {
    marginTop: 1,
  },
}));

import axios from 'axios'

import {dispatch, getState} from "./index";
import {CONFIG} from "../../AppConfig";
import {isFunction} from "../../utils/misc";
import print from "../../utils/print";
import {toggle} from "./context";
import {ACCOUNTABILITY_CLEAR, ACCOUNTABILITY_SET} from "../types";
import {getHTTPConfig} from "../../utils/requests";

const URL = CONFIG.URL_API

export const getAccountabilities = (onSuccess, onFail) => {
    toggle('isGettingAccountability', true)
    toggle('isErrorGettingAccountability', false)

    const {studentID, campusID} = getState().AUTH

    const url = `${URL}enrollment/getAccountabilities/${studentID}/${campusID}`

    axios.get(url, getHTTPConfig()).then(result => {
        print('GET ACCOUNTABILITY', result)

        const data = result.data
        const payload = {
            hasAccountability: data.hasAccountability,
            outstandingBalance: data.outstandingBalance
        }

        toggle('accountabilityChecked', true)
        dispatch(ACCOUNTABILITY_SET, payload)
        toggle('isGettingAccountability', false)
        if (isFunction(onSuccess)) onSuccess()

    }).catch(error => {
        print('GET ACCOUNTABILITY', error)
        toggle('isErrorGettingAccountability', true)
        toggle('isGettingAccountability', false)
        if (isFunction(onFail)) onFail()
    })

}

export const clearAccountability = () => {
    dispatch(ACCOUNTABILITY_CLEAR)
}
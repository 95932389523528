import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function LineBreak({ length, vertical }) {
  const styles = style();
  return (
    <div
      className={styles.root}
      style={{
        height: vertical ? (length ? length : "100%") : 1,
        width: !vertical ? (length ? length : "100%") : 1,
      }}
    />
  );
}

const style = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey.shade4,
  },
}));

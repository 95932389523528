import axios from 'axios'

import {dispatch, getState} from "./index";
import {CONFIG} from "../../AppConfig";
import {isFunction} from "../../utils/misc";
import print from "../../utils/print";
import {setContext, toggle} from "./context";
import {ACADEMIC_EVALUATION_CLEAR, ACADEMIC_EVALUATION_SET} from "../types";
import {sortByObjectKeyDesc} from "../../utils/sort";
import {getHTTPConfig} from "../../utils/requests";
import {checkIsRegular} from "./enrollment";

const URL = CONFIG.URL_API

export const getAcademicEvaluation = (onSuccess, onFail) => {
    toggle('isGettingAcademicEvaluation', true)

    const {studentID, campusID} = getState().AUTH

    const url = `${URL}student/getAcademicEvaluation/${studentID}/${campusID}`

    axios.get(url, getHTTPConfig()).then(result => {
        print('GET ACADEMIC EVALUATION', result)

        const payload = {
            semesters: [],
        }

        for (let i of result.data) {

            // get Years and Terms and put in the payload
            let containsSemester = false
            for (let semester of payload.semesters) {
                if (semester.label === i.Semester) containsSemester = true
            }
            if (!containsSemester) {
                payload.semesters.push({
                    id: i.Semester,
                    label: i.Semester,
                    data: {}
                })
                payload[i.Semester] = []
            }
            payload.semesters = sortByObjectKeyDesc(payload.semesters, 'label')

            payload[i.Semester].push({
                semester: i.Semester,
                courseCode: i.CourseCode,
                courseTitle: i.CourseTitle,
                lecUnits: i.LectUnits,
                labUnits: i.LabUnits,
                creditUnits: i.CreditUnits,
                final: i.Final,
                reExam: i.ReExam,
                creditedUnits: i.CreditTheUnit ? 'Credited' : '',
                remarks: i.Remarks,
                prereq: i.PreRequisites,
                prereqPassed: i.PreRequisites && i.PreRequisitePassed ? 'Passed' : '',
                equivalent: i.Equivalent,
                yearStanding: i.YearStanding,
                AYTermTaken: i.AcademicYearTermTaken,
                yearLevelTaken: i.YearLevelTaken,
                takenFromOtherSchool: i.TakenFromOtherSchool,
                dateEntered: i.DateEntered,
                datePosted: i.DatePosted,
            })
        }

        payload.semesters = payload.semesters.reverse()

        // set initial view
        setContext('academicEvaluationSemester', 'all')

        dispatch(ACADEMIC_EVALUATION_SET, payload)
        toggle('isGettingAcademicEvaluation', false)
        if (isFunction(onSuccess)) onSuccess()

    }).catch(error => {
        print('GET ACADEMIC EVALUATION', error)
        toggle('isGettingAcademicEvaluation', false)
        if (isFunction(onFail)) onFail()
    })

}

export const reEvaluate = (onSuccess, onFail, studentID, campusID, token) => {
    toggle('isReEvaluatingAcademicEvaluation', true)
    toggle('isFailedToReEvaluateAcademicEvaluation', false)

    if (!studentID) studentID = getState().AUTH.studentID
    if (!campusID) campusID = getState().AUTH.campusID

    const url = `${URL}student/reEvaluate/${studentID}/${campusID}`

    axios.get(url, getHTTPConfig(token)).then(result => {
        print('RE EVALUATE ACADEMIC EVALUATION', result)

        const onSuccess = () => {
            if (result.data) {
                getAcademicEvaluation()
            }
            toggle('isReEvaluatingAcademicEvaluation', false)
            if (isFunction(onSuccess)) onSuccess()
        }
        checkIsRegular(onSuccess)

    }).catch(error => {
        print('RE EVALUATE ACADEMIC EVALUATION', error)
        toggle('isFailedToReEvaluateAcademicEvaluation', true)
        toggle('isReEvaluatingAcademicEvaluation', false)
        if (isFunction(onFail)) onFail()
    })

}

export const clearAcademicEvaluationData = () => {
    dispatch(ACADEMIC_EVALUATION_CLEAR)
}
import React, { useState, useEffect } from "react";
import ReactHelmet from "../components/core/Helmet";
import { makeStyles } from "@material-ui/core/styles";
import FlexContainer from "../components/core/containers/flex/FlexContainer";
import Vector from "../static/media/img/LoginCover2.png";
import Container from "@material-ui/core/Container";
import USePLogo from "../static/media/img/USePLogo.png";
import Space from "../components/core/Space";
import Typography from "../components/core/typography/Typography";
import { toggleDarkMode } from "../redux/actions/appSetting";
import moonCloudyLine from "@iconify/icons-ri/moon-cloudy-line";
import sunCloudyLine from "@iconify/icons-ri/sun-cloudy-line";
import { isDarkTheme } from "../utils/appSetting";
import Icon from "@iconify/react";
import Click from "../components/core/link/Click";
import Select from "../components/core/input/Select";
import Input from "../components/core/input/Input";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { login } from "../redux/actions/auth";
import { routes } from "../routes";
import Checkbox from "../components/core/input/Checkbox";
import Button from "../components/core/button/Button";
import Link from "../components/core/Link";
import { CONFIG } from "../AppConfig";
import { setContext } from "../redux/actions/context";
import MessageDialog from "../components/core/dialogs/MessageDialog";
import DataPrivacyDialog from "../components/Dialogs/DataPrivacyDialog";
import { campuses } from "../constants/campuses";
import { useForm } from "react-hook-form";
import SendUsMessage from "../components/SendUsMessage";
import { toggle, toggleSet } from "../redux/actions/toggle";
import globalStyle from "../static/styles";

export default function LoginMobile() {
  const styles = style();
  const globalStyles = globalStyle();

  const history = useHistory();
  const date = new Date();
  const year = date.getFullYear();
  const isDark = isDarkTheme();

  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    setValue,
    watch,
  } = useForm();

  const {
    loginFormError,
    loginFormErrorDescription,
    showPassword,
    isLoggingIn,
    enteredPathname,
  } = useSelector((state) => ({
    enteredPathname: state.CONTEXT.enteredPathname,
    loginFormError: state.CONTEXT.loginFormError,
    loginFormErrorDescription: state.CONTEXT.loginFormErrorDescription,
    showPassword: state.TOGGLE.loginShowPassword,
    isLoggingIn: state.TOGGLE.loginIsSubmitting,
  }));


  const [isCapsLockOn, setIsCapsLockOn] = useState(false);


  const onLogin = (data) => {
    login(data.idNumber, data.password, data.campus, () => {
      history.replace(enteredPathname ? enteredPathname : routes.dashboard);
    });
  };

  const toggleShowPassword = () => {
    toggle("loginShowPassword");
  };
  const campusOnSelect = (id) => {
    clearErrors("campus");
    setValue("campus", id);
  };

  const onEnter = (event) => {
    if (event.key === "Enter") handleSubmit(onLogin)();
  };

  const handleCapsLock = (event) => {
    if (event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  const closeErrorDialog = () => setContext("loginFormError", null);
  const forgotPassword = () => history.push(routes.forgotPassword);

  useEffect(() => {
    register("campus", { required: "Campus is required" });
  });

  return (
    <>
      <div className={styles.root}>
        <ReactHelmet title={"Login"} />
        <Container fixed>
          <Space vertical space={2.5} />
          <FlexContainer justifyContent={"flex-end"}>
            <Click onClick={toggleDarkMode}>
              <Icon icon={isDark ? moonCloudyLine : sunCloudyLine} width={28} />
            </Click>
          </FlexContainer>
          <FlexContainer direction={"column"} alignItems={"center"}>
            <Space vertical space={10} />
            <img src={USePLogo} alt={"USeP Logo"} className={styles.usep} />
            <Space vertical space={3} />
            <Typography
              size={28}
              color={"secondary"}
              weight={"medium"}
              align={"center"}
            >
              One{" "}
              <Typography size={28} color={"primary"} weight={"medium"} span>
                Data.{" "}
              </Typography>
              One{" "}
              <Typography size={28} color={"primary"} weight={"medium"} span>
                USeP.{" "}
              </Typography>
            </Typography>
            <Space vertical space={0.5} />
            <Typography variant={"h4"} weight={"medium"} align={"center"}>
              Student Portal
            </Typography>
            <Space vertical space={8} />
            <img src={Vector} alt={"Vector"} className={styles.vector} />

            <Typography variant={"h4"} weight={"semibold"} align={"center"}>
              Hello there!
            </Typography>
            <Space vertical space={0.5} />
            <Typography variant={"h5"} weight={"light"} align={"center"}>
              Please login to get started.
            </Typography>
            <Space vertical space={4} />
            <Select
              onSelect={campusOnSelect}
              value={watch("campus")}
              items={campuses}
              placeholder={"Campus"}
              size={"medium"}
              error={errors.campus?.message}
              errorAlign={"center"}
            />
            <Space vertical space={2} />
            <Input
              inputRef={register({ required: "ID Number is required" })}
              name={"idNumber"}
              placeholder={"ID Number"}
              size={"medium"}
              error={errors.idNumber?.message}
              onKeyDown={onEnter}
              errorAlign={"center"}
            />
            <Space vertical space={2} />
            {isCapsLockOn && (
              <Typography  weight={"semibold"}>
              Caps Lock is On!
            </Typography>
            )}
            <Input
              inputRef={register({ required: "Password is required" })}
              name={"password"}
              type={showPassword ? "text" : "password"}
              placeholder={"Password"}
              size={"medium"}
              error={errors.password?.message}
              // onKeyDown={onEnter}
              errorAlign={"center"}
              onKeyDown={(e) => {
                handleCapsLock(e);
                onEnter(e);
              }}
            />
            <Space vertical space={3} />
            <div className={globalStyles.textNoSelect}>
              <FlexContainer alignItems={"center"} onClick={toggleShowPassword}>
                <Checkbox checked={showPassword} color={"primary"} />
                <Space space={2} />
                <Typography>Show Password</Typography>
              </FlexContainer>
            </div>

            <Space vertical space={4} />

            <Button
              variant={"primary"}
              size={"large"}
              onClick={handleSubmit(onLogin)}
              isLoading={isLoggingIn}
            >
              Log me in
            </Button>

            <Space vertical space={5} />

            <Click onClick={forgotPassword}>
              <Typography>Forgot password?</Typography>
            </Click>

            <Space vertical space={7} />

            <Typography align={"right"}>Having trouble?</Typography>
            <Click onClick={() => toggleSet("loginOpenMessage", true)}>
              <Typography weight={"bold"} align={"right"}>
                Send us a message
              </Typography>
            </Click>

            <Space vertical space={5} />

            <Typography variant={"caption"} align={"center"}>
              Copyright © {year}. All Rights Reserved.
            </Typography>
            <Typography variant={"caption"} align={"center"}>
              <Click>
                <Typography
                  variant={"caption"}
                  weight={"bold"}
                  decoration={"underline"}
                  span
                >
                  Terms of Use
                </Typography>
              </Click>
              &nbsp; | &nbsp;
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={CONFIG.URL_USeP_Data_Privacy}
              >
                <Typography
                  variant={"caption"}
                  weight={"bold"}
                  decoration={"underline"}
                  span
                >
                  Privacy Policy
                </Typography>
              </Link>
            </Typography>
            <Space vertical space={3} />
          </FlexContainer>
        </Container>
      </div>
      <MessageDialog
        open={loginFormError}
        variant={"error"}
        title={loginFormError}
        onConfirmLabel={"Okay"}
        singleAction
        onClose={closeErrorDialog}
        description={loginFormErrorDescription}
        onConfirm={closeErrorDialog}
      />

      <DataPrivacyDialog />
      <SendUsMessage />
    </>
  );
}

const style = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
  },
  usep: {
    height: 120,
  },
  vector: {
    width: `100%`,
    marginBottom: 24,
    marginTop: -24,
    [theme.breakpoints.up("sm")]: {
      width: `75%`,
    },
  },
}));

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Space from "../../core/Space";
import Typography from "../../core/typography/Typography";
import FlexContainer from "../../core/containers/flex/FlexContainer";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import { renderConfetti } from "../../core/Confetti";
import PulsateHeartbeat from "../../core/animation/attention/pulsateHeartbeat/pulsateHeartbeat";
import { Icon } from "@iconify/react";
import partyPopper from "@iconify/icons-noto-v1/party-popper";
import { useSelector } from "react-redux";

export default function GraduateDashboard() {
  const isDownXS = useBreakpointDown("xs");
  const styles = style();

  const { program, major, cqpa } = useSelector((state) => ({
    program: state.AUTH.program,
    major: state.AUTH.major,
    cqpa: state.DASHBOARD.cqpa,
  }));

  const toggleConfetti = () => {
    // renderConfetti(0, 20, true);
  };

  return (
    <div className={styles.root}>
      <FlexContainer direction={"column"} alignItems={"center"}>
        {/*<PulsateHeartbeat infinite>*/}
        <Icon
          icon={partyPopper}
          className={styles.confettiEmoji}
          // onClick={toggleConfetti}
        />
        {/*</PulsateHeartbeat>*/}
        <Space vertical space={3} />
        <Typography
          variant={!isDownXS && "h4"}
          size={isDownXS && 30}
          weight={isDownXS ? "semibold" : "semibold"}
          color={"primary"}
          align={"center"}
        >
          CONGRATULATIONS!
        </Typography>
        <Space vertical space={3} />
        <Typography variant={"h6"} weight={"normal"} align={"center"}>
          for completing the academic requirements for the
        </Typography>
        <Space vertical space={1} />
        <Typography variant={"h5"} weight={"semibold"} align={"center"}>
          {program}
        </Typography>
        <Space vertical space={1} />
        {/* <Typography variant={"h6"} weight={"normal"} align={"center"}>
          program {major && "major in"} <strong>{major}</strong> with a
          Cumulative Quality Point Average (CQPA) of
          <strong> {cqpa} </strong>.
        </Typography> */}
        <Typography variant={"h6"} weight={"normal"} align={"center"}>
          Please coordinate with your College/School for the remaining
          requirements for graduation.
        </Typography>
      </FlexContainer>
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
  },
  gradCap: {
    height: 130,
    "&:hover": {
      cursor: "pointer",
    },
  },
  confettiEmoji: {
    fontSize: 100,
    // "&:hover": {
    //   cursor: "pointer",
    // },
  },
}));

import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import getCroppedImg from "./crop";
import { styles } from "./styles";
import Space from "../Space";
import Typography from "../typography/Typography";
import Button from "../button/Button";

const ImageCrop = ({
  classes,
  image,
  aspectRatio = 1,
  onChangePicture,
  onCrop,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      onCrop(croppedImage);
    } catch (e) {}
  }, [croppedAreaPixels, rotation]);

  return (
    <div>
      <div className={classes.cropContainer}>
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <Space vertical space={1} />
      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Zoom
          </Typography>
          <Space space={3} />
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ container: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
      </div>
      <Space vertical space={4} />
      <div className={classes.controls}>
        <Button onClick={onChangePicture}>Change Picture</Button>
        <Space space={3} />
        <Button onClick={onCropImage} variant={"primary"}>
          CROP
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(ImageCrop);

var JsBarcode = require("jsbarcode");

export function textToBase64Barcode(value) {
  var canvas = document.createElement("canvas");
  JsBarcode(canvas, value, {
    format: "CODE128",
    width: 1,
    height: 50,
    fontSize: 15,
  });
  return canvas.toDataURL("image/png");
}

import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { isDarkTheme } from "../../../utils/appSetting";
import { isFunction } from "../../../utils/misc";
import FlexContainer from "../containers/flex/FlexContainer";
import Icon from "@iconify/react";
import Space from "../Space";
import FlexItem from "../containers/flex/FlexItem";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import globalStyle from "../../../static/styles";
import { CircularProgress } from "@material-ui/core";

const Button = ({
  children,
  variant,
  size,
  fullwidth,
  onClick,
  disableHover,
  nowrap,
  icon,
  nolabel,
  isLoading,
  disabled,
  customStyles,
}) => {
  const isDark = isDarkTheme();
  const styles = style();
  const globalStyles = globalStyle();

  let color = "#FFFFFF";
  let minWidth = 100;
  if (
    (!variant && !isDark) ||
    (variant === "default" && !isDark) ||
    (variant === "grey1" && !isDark) ||
    (variant === "grey4" && isDark) ||
    (variant === "grey5" && isDark)
  )
    color = "#575757";
  if (size === "small") minWidth = nolabel ? 0 : 80;
  if (size === "medium") minWidth = nolabel ? 0 : 120;
  if (size === "large") minWidth = nolabel ? 0 : 120;
  if (fullwidth) minWidth = "100%";

  return (
    <button
      className={clsx(
        styles.root,
        globalStyles.textNoSelect,
        size === "small" && styles.small,
        size === "medium" && styles.medium,
        size === "large" && styles.large,
        variant === "primary" && styles.primary,
        variant === "success" && styles.success,
        variant === "warning" && styles.warning,
        variant === "error" && styles.error,
        variant === "grey1" && styles.grey1,
        variant === "grey2" && styles.grey2,
        variant === "grey3" && styles.grey3,
        variant === "grey4" && styles.grey4,
        variant === "grey5" && styles.grey5,
        isLoading && styles.loading,
        disabled && styles.disabledButton,
        !disableHover && styles.rootHover,
        !disableHover && variant === "primary" && styles.primaryHover,
        !disableHover && variant === "success" && styles.successHover,
        !disableHover && variant === "warning" && styles.warningHover,
        !disableHover && variant === "error" && styles.errorHover,
        !disableHover && variant === "grey1" && styles.grey1Hover,
        !disableHover && variant === "grey2" && styles.grey2Hover,
        !disableHover && variant === "grey3" && styles.grey3Hover,
        !disableHover && variant === "grey4" && styles.grey4Hover,
        !disableHover && variant === "grey5" && styles.grey5Hover
      )}
      style={{
        color,
        minWidth,
        whiteSpace: nowrap ? "nowrap" : "unset",
        ...customStyles,
      }}
      onClick={() => {
        if (!isLoading && isFunction(onClick) && !disabled) onClick();
      }}
    >
      {isLoading ? (
        <FlexContainer justifyContent={"center"} alignItems={"center"}>
          {/*<Loader type="ThreeDots" color="white" height={20} width={20} />*/}
          <CircularProgress color={"white"} style={{ height: 20, width: 20 }} />
        </FlexContainer>
      ) : icon ? (
        <FlexContainer justifyContent={"center"} alignItems={"center"}>
          <Icon icon={icon} width={20} />
          {!nolabel && (
            <FlexItem>
              <FlexContainer justifyContent={"center"} alignItems={"center"}>
                <Space
                  space={size === "small" ? 0.5 : size === "medium" ? 1.5 : 1}
                />
                {children}
              </FlexContainer>
            </FlexItem>
          )}
        </FlexContainer>
      ) : (
        children
      )}
    </button>
  );
};

const style = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey.shade5,
    padding: "8px 16px",
    borderRadius: 100,
    border: "unset",
    fontWeight: 600,
    fontSize: "1rem",
    outline: "unset",
  },
  rootHover: {
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.grey.shade4,
    },
  },
  primary: {
    background: theme.palette.primary.gradient,
    color: "#FFFFFF",
  },
  primaryHover: {
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.buttonOverlay + theme.palette.primary.gradient,
    },
  },
  success: {
    background: theme.palette.success.gradient,
    color: "#FFFFFF",
  },
  successHover: {
    "&:hover": {
      background: theme.palette.buttonOverlay + theme.palette.success.gradient,
    },
  },
  warning: {
    background: theme.palette.warning.gradient,
    color: "#FFFFFF",
  },
  warningHover: {
    "&:hover": {
      background: theme.palette.buttonOverlay + theme.palette.warning.gradient,
    },
  },
  error: {
    background: theme.palette.error.gradient,
    color: "#FFFFFF",
  },
  errorHover: {
    "&:hover": {
      background: theme.palette.buttonOverlay + theme.palette.error.gradient,
    },
  },
  grey1: {
    background: theme.palette.grey.gradient1,
  },
  grey1Hover: {
    "&:hover": {
      background: theme.palette.buttonOverlay + theme.palette.default.gradient1,
    },
  },
  grey2: {
    background: theme.palette.grey.gradient2,
    color: "#FFFFFF",
  },
  grey2Hover: {
    "&:hover": {
      background: theme.palette.buttonOverlay + theme.palette.default.gradient2,
    },
  },
  grey3: {
    background: theme.palette.grey.gradient3,
    color: "#FFFFFF",
  },
  grey3Hover: {
    "&:hover": {
      background: theme.palette.buttonOverlay + theme.palette.default.gradient3,
    },
  },
  grey4: {
    background: theme.palette.grey.gradient4,
  },
  grey4Hover: {
    "&:hover": {
      background: theme.palette.buttonOverlay + theme.palette.default.gradient4,
    },
  },
  grey5: {
    background: theme.palette.grey.gradient5,
  },
  grey5Hover: {
    "&:hover": {
      background: theme.palette.buttonOverlay + theme.palette.default.gradient5,
    },
  },
  small: {
    padding: "6px 12px",
    fontSize: ".75rem",
  },
  medium: {
    padding: "12px 24px",
    fontSize: "1rem",
    fontWeight: "bold",
    borderRadius: 22,
  },
  large: {
    padding: "16px 32px",
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  loading: {
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  disabledButton: {
    background: theme.palette.grey.gradient2,
    "&:hover": {
      cursor: "not-allowed",
      background: theme.palette.grey.gradient2,
    },
  },
}));

export default memo(Button);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

export default function SkeletonLoader({ height, width, borderRadius }) {
  const style = makeStyles((theme) => ({
    root: {
      display: "block",
      backgroundColor: theme.palette.grey.shade5,
      margin: 0,
    },
    text: {
      margin: 0,
      height: height || 36,
      width: width || "100%",
      borderRadius: borderRadius || theme.shape.borderRadiusNormal,
    },
  }));
  const styles = style();
  return (
    <Skeleton
      variant={"text"}
      classes={{ root: styles.root, text: styles.text }}
    />
  );
}

import DialogContainer from "./core/dialogs/DialogContainer";
import { toggleSet } from "../redux/actions/toggle";
import Typography from "./core/typography/Typography";
import Space from "./core/Space";
import GridContainer from "./core/containers/grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import Link from "./core/Link";
import { CONFIG } from "../AppConfig";
import Button from "./core/button/Button";
import React from "react";
import { useSelector } from "react-redux";
import { campuses } from "../constants/campuses";

export default function SendUsMessage() {
  const { openMessage } = useSelector((state) => ({
    openMessage: state.TOGGLE.loginOpenMessage,
  }));
  return (
    <>
      <DialogContainer
        open={openMessage}
        onClose={() => toggleSet("loginOpenMessage", false)}
      >
        <Typography variant={"h5"} weight={"semibold"}>
          Send Us a Message
        </Typography>
        <Space vertical space={3} />
        <Typography align={"justify"}>
          Please select your respective campus. Upon selection, you will be
          forwarded to an email client where you will construct your message.
        </Typography>
        <Space vertical space={3} />
        <GridContainer spacing={1}>
          {campuses.map((campus) => (
            <Grid item xs={6} key={campus.id}>
              <Link
                target={"_blank"}
                rel={"noopener noreferrer"}
                href={`mailto:${
                  CONFIG.CAMPUS_CREDENTIALS[campus.label.toLowerCase()]
                    .concernsEmail
                }`}
              >
                <Button variant={"primary"} fullwidth>
                  {campus.label}
                </Button>
              </Link>
            </Grid>
          ))}
        </GridContainer>
        <Space vertical space={4} />
        <Button fullwidth onClick={() => toggleSet("loginOpenMessage", false)}>
          Close
        </Button>
      </DialogContainer>
    </>
  );
}

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function Popper({ id, children, open, onClose }) {
  const styles = style();
  const containerRef = React.createRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return function cleanup() {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target))
      onClose();
  };

  return (
    <div
      id={id}
      className={styles.root}
      ref={containerRef}
      style={{
        visibility: open ? "visible" : "hidden",
      }}
    >
      {children}
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    background: theme.palette.background,
    minWidth: 348,
    borderRadius: theme.shape.borderRadiusSmall,
    boxShadow: `0px 0px 3px ${theme.palette.grey.shade3}`,
    zIndex: 10,
  },
}));

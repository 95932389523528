import {DateTime} from "luxon";

export const formatDate = datetime => {
    if (!datetime) return '-'
    const lux = DateTime.fromISO(parseDateTimeTimezone(datetime))
    const date = lux.toLocaleString(DateTime.DATE_FULL)
    return `${date}`
}

export const formatDateSlash = datetime => {
    if (!datetime) return '-'
    const lux = DateTime.fromISO(parseDateTimeTimezone(datetime))
    const date = lux.toLocaleString()
    return `${date}`
}

export const formatDateTime = datetime => {
    if (!datetime) return '-'
    const lux = DateTime.fromISO(parseDateTimeTimezone(datetime))
    const date = lux.toLocaleString(DateTime.DATE_FULL)
    const time = lux.toLocaleString(DateTime.TIME_SIMPLE)
    return `${date} ${time}`
}

export const formatDateTimeWithISO = datetime => {
    if (!datetime) return '-'
    const lux = DateTime.fromISO(datetime)
    const date = lux.toLocaleString(DateTime.DATE_FULL)
    const time = lux.toLocaleString(DateTime.TIME_SIMPLE)
    return `${date} ${time}`
}

export const parseDateTimeTimezone = datetime => {
    try{ datetime = datetime.substring(0,19) } catch (e) { }
    return DateTime.fromISO(datetime).setZone('Asia/manila').toISO()
}

import React, { memo } from "react";
import Typography from "../core/typography/Typography";
import Space from "../core/Space";
import Button from "../core/button/Button";
import FlexContainer from "../core/containers/flex/FlexContainer";

const ErrorLoad = ({ reload, noButton, description }) => {
  return (
    <div>
      <FlexContainer
        height={300}
        alignItems={"center"}
        justifyContent={"center"}
        direction={"column"}
      >
        <div>
          <Typography variant={"h6"} align={"center"} weight={"medium"}>
            Something's not right
          </Typography>
          <Space vertical space={1} />
          <Typography align={"center"} weight={"light"}>
            {description || "Please try to reload"}
          </Typography>
          {!noButton && (
            <div>
              <Space vertical space={3} />
              <Button onClick={reload} fullwidth>
                Reload
              </Button>
            </div>
          )}
        </div>
      </FlexContainer>
    </div>
  );
};

export default memo(ErrorLoad);

import {GRADES_CLEAR, GRADES_SET, GRADES_SUMMARY_SET} from "../types";

const init = {
    AYTerms: [],
    summary: {
        GWA: 0,
        units: 0,
        CQPAs: 0,
        CQPA: 0
    }
}

export default function (state=init, action) {
    switch (action.type) {

        case GRADES_SET:
            return {
                ...state,
                ...action.payload
            }

        case GRADES_SUMMARY_SET:
            return {
                ...state,
                summary: action.payload
            }

        case GRADES_CLEAR:
            return init

        default:
            return state
    }
}
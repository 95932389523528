import React from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import Space from "../components/space";

import EnrolledSeal from "../../static/media/img/OfficiallyEnrolledSeal.png";
import StudentCopySeal from "../../static/media/img/StudentsCopySeal.png";

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    fontSize: 10,
  },
  headerLeft: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "25%",
  },
  officiallyEnrolled: {
    // width: "100%",
    height: 24,
    marginBottom: 4,
  },
  header: {
    textAlign: "center",
    width: "50%",
  },
  headerRight: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "25%",
  },
  studentsCopyContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  studentsCopy: {
    height: 32,
  },
  textBold: {
    fontWeight: "bold",
  },
  textBlack: {
    fontWeight: "heavy",
  },
});

export default function Header({ data }) {
  return (
    <View style={styles.headerContainer}>
      <View style={styles.headerLeft}>
        <View style={styles.officiallyEnrolledContainer}>
          <Image src={EnrolledSeal} style={styles.officiallyEnrolled} />
        </View>
        <Text style={{ fontSize: 8, fontWeight: "bold" }}>
          Reg.No.: {data.regNo}
        </Text>
      </View>
      <View style={styles.header}>
        <Text style={{ fontSize: 7 }}>Republic of the Philippines</Text>
        <Space vertical space={1} />
        <Text style={[styles.textBold, { fontSize: 8 }]}>
          UNIVERSITY OF SOUTHEASTERN PHILIPPINES
        </Text>
        <Space vertical space={1} />
        <Text style={{ fontSize: 7 }}>{data.campus}</Text>
        <Space vertical space={10} />
        <Text style={[styles.textBold, { fontSize: 8 }]}>
          CERTIFICATE OF REGISTRATION AND BILLING
        </Text>
      </View>
      <View style={styles.headerRight}>
        <View>
          <View style={styles.studentsCopyContainer}>
            <Image src={StudentCopySeal} style={styles.studentsCopy} />
          </View>
        </View>
      </View>
    </View>
  );
}

import React from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";


export default function CardInfo(
    {variant, children, onClick, borderColor, padding, minHeight, height, paddingCustom, borderTopWidth,
        borderBottomWidth, borderLeftWidth, borderRightWidth, paddingRight, paddingLeft, disabled}) {

    const theme = useTheme()

    borderColor = variant ? theme.palette[variant].main : theme.palette.default.main

    const style = makeStyles(theme => ({
        root: {
            minHeight: height ? `calc(${minHeight} - 80px)` : null,
            height: height ? `calc(${height} - 80px)` : null,
            padding: disabled ? 0 : theme.spacing(padding || 0),
            paddingLeft: disabled ? 0 :  theme.spacing(paddingLeft || 3),
            paddingRight: disabled ? 0 :  theme.spacing(paddingRight || 3),
            borderTopWidth: borderTopWidth || 0,
            borderBottomWidth: borderBottomWidth || 0,
            borderLeftWidth: borderLeftWidth || 1,
            borderRightWidth: borderRightWidth || 0,
            borderStyle: 'solid',
            border: disabled ? 'unset !important' : null,
            borderColor,
            '&': {
                padding: paddingCustom
            }
        },
        hover: {}
    }))

    const {root} = style()

    return (
        <div className={root} onClick={onClick}> {children} </div>
    )
}

import React, { Fragment, memo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

const Typography = ({
  children,
  variant,
  family,
  color,
  lineHeight,
  weight,
  style,
  align,
  size,
  span,
  transform,
  decoration,
  nowrap,
  classNames = [],
  customStyles,
}) => {
  const theme = useTheme();

  weight = weight
    ? weight === "light"
      ? 300
      : weight === "normal"
      ? "normal"
      : weight === "medium"
      ? 500
      : weight === "semibold"
      ? 600
      : weight === "bold"
      ? "bold"
      : weight
    : 500;

  color = color
    ? color === "primary"
      ? theme.palette.primary.main
      : color === "success"
      ? theme.palette.success.main
      : color === "secondary"
      ? theme.palette.secondary.main
      : color === "warning"
      ? theme.palette.warning.main
      : color === "error"
      ? theme.palette.error.main
      : color === "default"
      ? theme.palette.default.main
      : color === "grey1"
      ? theme.palette.grey.shade1
      : color === "grey2"
      ? theme.palette.grey.shade2
      : color === "grey3"
      ? theme.palette.grey.shade3
      : color === "grey4"
      ? theme.palette.grey.shade4
      : color === "grey5"
      ? theme.palette.grey.shade5
      : color
    : theme.palette.default.main;

  const styles = useStyle();

  const rootClassNames = clsx(
    variant === "h1" && styles.h1,
    variant === "h2" && styles.h2,
    variant === "h3" && styles.h3,
    variant === "h4" && styles.h4,
    variant === "h5" && styles.h5,
    variant === "h6" && styles.h6,
    variant === "caption" && styles.caption,
    ...classNames
  );

  const rootStyles = {
    whiteSpace: nowrap && "nowrap",
    fontFamily: family,
    color,
    fontWeight: weight,
    fontStyle: style,
    lineHeight,
    textDecoration: decoration,
    textTransform: transform,
    fontSize: size,
    textAlign: align,
    ...customStyles,
  };

  return (
    <Fragment>
      {span ? (
        <span className={rootClassNames} style={rootStyles}>
          {children}
        </span>
      ) : (
        <div className={rootClassNames} style={rootStyles}>
          {children}
        </div>
      )}
    </Fragment>
  );
};

const useStyle = makeStyles((theme) => ({
  h1: {
    fontSize: "6rem",
  },
  h2: {
    fontSize: "3.75rem",
  },
  h3: {
    fontSize: "3rem",
  },
  h4: {
    fontSize: "2.125rem",
  },
  h5: {
    fontSize: "1.5rem",
  },
  h6: {
    fontSize: "1.25rem",
  },
  caption: {
    fontSize: "0.875rem",
  },
}));

export default memo(Typography);

import React from "react";
import Icon from "@iconify/react";
import checkFill from "@iconify/icons-ri/check-fill";
import FlexContainer from "../containers/flex/FlexContainer";
import useTheme from "@material-ui/core/styles/useTheme";

export default function Checkbox({ checked, disabled, onChange }) {
  const theme = useTheme();
  return (
    <div
      style={{
        height: checked ? 24 : 22,
        width: checked ? 24 : 22,
        borderWidth: checked ? 0 : 1,
        borderStyle: "solid",
        borderColor: disabled
          ? theme.palette.grey.shade3
          : checked
          ? theme.palette.primary.main
          : theme.palette.grey.shade2,
        borderRadius: theme.shape.borderRadiusSmall,
        background: checked ? theme.palette.primary.gradient : "transparent",
      }}
      onClick={!disabled && onChange && onChange}
    >
      <FlexContainer alignItems={"center"} justifyContent={"center"}>
        <Icon
          icon={checkFill}
          width={20}
          style={{
            color: checked ? "white" : theme.palette.background,
          }}
        />
      </FlexContainer>
    </div>
  );
}

import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import useTheme from "@material-ui/core/styles/useTheme";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Space from "../Space";
import Button from "../button/Button";
import Typography from "../typography/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  container: {
    border: "1px solid " + theme.palette.grey.shade3,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      cursor: "pointer",
    },
  },
  error: {
    border: "1px solid " + theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  errorBox: {
    padding: "12px 16px",
    border: "1px solid " + theme.palette.error.main,
    borderRadius: theme.shape.borderRadius,
  },
}));

const Dropzone = (props) => {
  const theme = useTheme();
  const styles = useStyles();
  const [error, setError] = useState(null);

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "32px 16px",
    color: theme.palette.grey.shade3,
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100% !important",
    "&:hover": {
      cursor: "pointer",
    },
  };

  const activeStyle = {
    borderColor: theme.palette.success.main,
  };

  const acceptStyle = {
    borderColor: theme.palette.success.main,
  };

  const rejectStyle = {
    borderColor: theme.palette.error.main,
  };

  const onDropAccepted = useCallback((file) => {
    props.onDropzoneAdd(file[0]);
    setError(null);
  }, []);

  const onDropRejected = useCallback((file) => {
    for (let error of file[0].errors) {
      if (error.code === "file-invalid-type") {
        setError(
          "Invalid file format. File must be in .JPG or .PNG format. Please try again!"
        );
        break;
      } else if (error.code === "file-too-large") {
        setError(`File size limit exceeded. Please try again!`);
      }
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: [".jpg", ".png"],
    maxSize: props.maxFileSize * 1000000,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );
  let isEmpty = true;
  const files = acceptedFiles.map((file) => (
    <div
      style={{
        textAlign: "center",
        maxWidth: 300,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    />
  ));

  return (
    <div className={styles.root}>
      {error && (
        <>
          <div className={styles.errorBox}>
            <Typography weight={"semibold"} color={"error"}>
              {error}
            </Typography>
          </div>
          <Space vertical space={3} />
        </>
      )}

      <div
        style={{ width: "100%" }}
        className={clsx(styles.container, props.error && styles.error)}
      >
        <div {...getRootProps({ style })}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "100%" }}>
              <input {...getInputProps()} />
              <div
                style={{
                  width: "100%",
                }}
              >
                {!isEmpty ? (
                  <div>{files}</div>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Space vertical space={8} />
                    <Typography variant={"h5"}>Drag file here</Typography>
                    <Space vertical space={2} />
                    <Typography variant={"h5"}>or</Typography>
                    <Space vertical space={2} />
                    <div
                      style={{
                        backgroundColor: theme.palette.grey.S1,
                        height: 40,
                        borderRadius: 5,
                        width: 120,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: theme.palette.grey.S4,
                      }}
                    >
                      <Button variant={"primary"}>BROWSE</Button>
                    </div>
                    <Space vertical space={8} />
                    <Typography variant={"body1"} align={"center"}>
                      File format must be in <strong>JPEG</strong> or{" "}
                      <strong>PNG</strong>.
                    </Typography>
                    <Typography variant={"body1"} align={"center"}>
                      Filesize must not exceed <strong>2 MB</strong>.
                    </Typography>
                    <Space vertical space={4} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.error && (
        <div>
          <Space vertical space={1} />
          <Typography variant={"caption"} className={styles.errorMessage}>
            {props.error}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Dropzone;

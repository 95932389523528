import React, { Fragment, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import BlankPage from "../pages/BlankPage";
import PageNotFound from "../pages/PageNotFound";
import { routes } from "./index";
import { useBreakpointDown } from "../utils/hooks/mediaQuery";
import LoadingComponents from "../components/core/loader/LoadingComponents";
import ProtectedPage from "../pages/ProtectedPage";
import UpdateAccount from "../pages/updateAccount/UpdateAccount";

import LoginMobile from "../pages/LoginMobile";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import ForgotPasswordMobile from "../pages/ForgotPasswordMobile";

import HomeLayout from "../layout/HomeLayout";
import HomeSubLayout from "../layout/HomeSubLayout";
import HomeFullScreenLayout from "../layout/HomeFullScreenLayout";

export default function RootRoutes() {
  const isDownSM = useBreakpointDown("sm");

  return (
    <Fragment>
      <Suspense fallback={<LoadingComponents />}>
        <Switch>
          <Route exact path={"/"} component={BlankPage} />
          <Route
            exact
            path={routes.login}
            component={isDownSM ? LoginMobile : Login}
          />
          <Route
            exact
            path={routes.forgotPassword}
            component={isDownSM ? ForgotPasswordMobile : ForgotPassword}
          />
          <Route exact path={routes.updateAccount} component={UpdateAccount} />

          {/* HOME ROUTES */}
          <Route exact path={routes.dashboard} component={HomeLayout} />
          <Route exact path={routes.grades} component={HomeLayout} />
          <Route exact path={routes.programEvaluation} component={HomeLayout} />
          <Route exact path={routes.enrollment} component={HomeLayout} />
          <Route exact path={routes.accountabilities} component={HomeLayout} />
          <Route
            exact
            path={routes.universityServicesEvaluation}
            component={HomeLayout}
          />
          <Route exact path={routes.profile} component={HomeLayout} />

          {/* HOME SUB ROUTES */}
          <Route
            exact
            path={routes.enrollmentSurvey}
            component={HomeSubLayout}
          />
          <Route
            exact
            path={routes.enrollmentRegistration}
            component={HomeSubLayout}
          />
          <Route
            exact
            path={routes.enrollmentRegistrationRegister}
            component={HomeSubLayout}
          />
          <Route
            exact
            path={routes.enrollmentRegistrationRegisterSchedule}
            component={HomeSubLayout}
          />
          <Route
            exact
            path={routes.universityServicesEvaluationFaculty}
            component={HomeSubLayout}
          />
          <Route
            exact
            path={routes.universityServicesEvaluationStudentSatisfaction}
            component={HomeSubLayout}
          />

          {/* ADDED - Course Evaluation */}
          <Route
            exact
            path={routes.universityServicesEvaluationCourseEvaluation}
            component={HomeSubLayout}
          />

          <Route
            exact
            path={routes.universityServicesEvaluationSubject}
            component={HomeSubLayout}
          />


          <Route
            exact
            path={routes.universityServicesEvaluationClientsFeedback}
            component={HomeSubLayout}
          />

          {/* HOME FULLSCREEN ROUTES */}
          <Route
            exact
            path={routes.programEvaluationFullscreen}
            component={HomeFullScreenLayout}
          />

          <Route path={routes.protectedPage} component={ProtectedPage} />
          <Route path={"/"} component={PageNotFound} />
        </Switch>
      </Suspense>
    </Fragment>
  );
}

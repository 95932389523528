import { combineReducers } from "redux";

import AUTH from "./auth";
import CONTEXT from "./context";
import APP_SETTING from "./appSetting";
import DASHBOARD from "./dashboard";
import GRADES from "./grades";
import ACADEMIC_EVALUATION from "./programEvaluation";
import ENROLLMENT from "./enrollment";
import ACCOUNTABILITY from "./accountability";
import UNIV_SERVICES_EVALUATION from "./univeristyServicesEvaluation";
import MESSAGE from "./message";
import TOGGLE from "./toggle";
import FORM from "./form";

export default combineReducers({
  AUTH,
  DASHBOARD,
  GRADES,
  ACADEMIC_EVALUATION,
  ENROLLMENT,
  ACCOUNTABILITY,
  UNIV_SERVICES_EVALUATION,
  CONTEXT,
  APP_SETTING,
  MESSAGE,
  TOGGLE,
  FORM,
});
